import React, { useState, useEffect } from 'react';
import {
  Card,
  Row,
  Col,
  Form,
  Spin,
  Checkbox
} from 'antd';
import { Text } from '../../../../uikit/Text';
import { TextArea } from '../../../../uikit/TextArea';
import { CustomButton } from '../../../../uikit/Button';
import http from "../../../../api";
import { toast } from "react-toastify";
import { CheckCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { fetchApplication } from '../../../../actions/applications';
import CustomSelect from '../../../../uikit/Select';

const RiskCheckbox = (props) => {
  const {risks, setRisk, type, value, label} = props;
  return (
    <Checkbox
      style={{ margin: "5px 0 0 20px" }}
      key={`${type}-${value}`}
      checked={risks[type] === value}
      onChange={() => {
        setRisk(type, value)
      }}
    >
      {label}
    </Checkbox> 
  )
}

const Risk = (props) => {
  const { id, appRisks, applicationMessage } = props;
  const [ form ] = Form.useForm();

  const [ message, setMessage ] = useState('');
  const [ errors, setErrors] = useState({});
  const [ isEditMode, setIsEditmode] = useState(false);
  const [ risks, setRisks ] = useState({
    service: 'AVERAGE',
    customer: 'AVERAGE',
    distribution: 'HIGH',
    area: 'LOW'
  })

  useEffect( () => {
    if(appRisks != null)
      setRisks(appRisks)
    setMessage(appRisks?.message)
    form.setFieldsValue({'message': appRisks?.message});
  }, [appRisks])

  const buttonText = () => {
    if (!isEditMode)
      return "Uppdatera"
    return "Spara"
  }

  const handleMessage = (e) => {
    if (!isEditMode) {
      setIsEditmode(true)
    } else {
      http(`/api/v1/application/${id}/risk`, {
        method: 'POST',
        data: { "message": message }
      })
      .then((res) => {
        setMessage(res.data.data.message)
        form.setFieldsValue({'message': res.data.data.message});
        toast.success(
          <div className="toast-body">
              <CheckCircleOutlined className="toast__icon toast__icon--success" />
              <p className="toast-text">{`Kommentar sparad`}</p>
          </div>)
        setIsEditmode(false);
      })
      .catch(err => {
        if (!!err.response && !!err.response.data && !!err.response.data.errors) {
          setErrors(err.response.data.errors)
        }
      });
    }
  }

  const setRisk = (type, value) => {
    risks[type] = value;
    setRisks((risks) => {
			http(`/api/v1/application/${id}/risk`, {
				method: "POST",
				responseType: "application/json",
				data: {
					[type]: value,
				},
			}).catch(error => {
        setErrors(error.response.data.errors)
      });
			return {...risks, [type]: value};
		})
  }

  useEffect(() => {
    setMessage(applicationMessage);
    form.setFieldsValue({'message': applicationMessage});
  }, [applicationMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Spin spinning={props.loading}>
      <Row
        className="dashboard loanOffersTableWrapper" gutter={24}
      >
        <Col xl={24} lg={24}	md={24} sm={24} xs={24} style={{marginBottom: 30}}>
          <Card
            className="cardDetaill"
            style={{minHeight: '100px', height: '100%'}}
            bodyStyle={{
              paddingBottom: 24,
              borderRadius: 6
            }}
          >
            <Text texttype="back" colortype="back" customclass="cardTitle">
              Riskbedömning
            </Text>
            <Row gutter={16}>
              <Col padding={10} className="gutter_row" xl={24} lg={24}	md={24} sm={24} xs={24}>
                <Form 
                  form={form}
                  initialValues={{message: props.applicationMessage}}
                >
                  <Row gutter={24}>
                    <Col padding={10} className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
                      <div className="labelitem-risk">{'Tjänst:'}</div>
                    </Col>
                    <Col className="gutter_row" xl={14} lg={16}	md={16} sm={24} xs={24}>
                      <RiskCheckbox 
                        risks= {risks}
                        setRisk={setRisk}
                        type='service'
                        value='LOW'
                        label='Låg'
                      />
                      <RiskCheckbox 
                        risks= {risks}
                        setRisk={setRisk}
                        type='service'
                        value='AVERAGE'
                        label='Medel'
                      />
                      <RiskCheckbox 
                        risks= {risks}
                        setRisk={setRisk}
                        type='service'
                        value='HIGH'
                        label='Hög'
                      />
                      <RiskCheckbox 
                        risks= {risks}
                        setRisk={setRisk}
                        type='service'
                        value='UNACCEPTABLE'
                        label='Oacceptabel'
                      />
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col padding={10} className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
                      <div className="labelitem-risk">{'Kund:'}</div>
                    </Col>
                    <Col className="gutter_row" xl={14} lg={16}	md={16} sm={24} xs={24}>
                      <RiskCheckbox 
                        risks= {risks}
                        setRisk={setRisk}
                        type='customer'
                        value='LOW'
                        label='Låg'
                      />
                      <RiskCheckbox 
                        risks= {risks}
                        setRisk={setRisk}
                        type='customer'
                        value='AVERAGE'
                        label='Medel'
                      />
                      <RiskCheckbox 
                        risks= {risks}
                        setRisk={setRisk}
                        type='customer'
                        value='HIGH'
                        label='Hög'
                      />
                      <RiskCheckbox 
                        risks= {risks}
                        setRisk={setRisk}
                        type='customer'
                        value='UNACCEPTABLE'
                        label='Oacceptabel'
                      />
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col padding={10} className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
                      <div className="labelitem-risk">{'Distributionskanal:'}</div>
                    </Col>
                    <Col className="gutter_row" xl={14} lg={16}	md={16} sm={24} xs={24}>
                      <RiskCheckbox 
                        risks= {risks}
                        setRisk={setRisk}
                        type='distribution'
                        value='LOW'
                        label='Låg'
                      />
                      <RiskCheckbox 
                        risks= {risks}
                        setRisk={setRisk}
                        type='distribution'
                        value='AVERAGE'
                        label='Medel'
                      />
                      <RiskCheckbox 
                        risks= {risks}
                        setRisk={setRisk}
                        type='distribution'
                        value='HIGH'
                        label='Hög'
                      />
                      <RiskCheckbox 
                        risks= {risks}
                        setRisk={setRisk}
                        type='distribution'
                        value='UNACCEPTABLE'
                        label='Oacceptabel'
                      />
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col padding={10} className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
                      <div className="labelitem-risk">{'Geografiskt område:'}</div>
                    </Col>
                    <Col className="gutter_row" xl={14} lg={16}	md={16} sm={24} xs={24}>
                      <RiskCheckbox 
                        risks= {risks}
                        setRisk={setRisk}
                        type='area'
                        value='LOW'
                        label='Låg'
                      />
                      <RiskCheckbox 
                        risks= {risks}
                        setRisk={setRisk}
                        type='area'
                        value='AVERAGE'
                        label='Medel'
                      />
                      <RiskCheckbox 
                        risks= {risks}
                        setRisk={setRisk}
                        type='area'
                        value='HIGH'
                        label='Hög'
                      />
                      <RiskCheckbox 
                        risks= {risks}
                        setRisk={setRisk}
                        type='area'
                        value='UNACCEPTABLE'
                        label='Oacceptabel'
                      />
                    </Col>
                  </Row>
                  <Text texttype="back" colortype="back" customclass="cardTitle" style={{marginTop: '15px'}}>
                    Kommentar vid avvikelse från Bettres standard risknivåer
                  </Text>
                  <TextArea
                    key="message"
                    name="message"
                    validateStatus={ !!errors.message ? 'error' : ''}
                    help={!!errors.message ? String(errors.message) : null}
                    inputprops={{
                        rows: 3,
                        placeholder: 'Fyll i',
                        autoSize: true,
                        disabled: !isEditMode,
                        style: {resize: 'none'},
                        onChange: (e) => {
                          setMessage(e.target.value);
                          form.setFieldsValue(e.target.value);
                          setErrors({ ...errors, message: '' });
                        }
                    }}
                  />
                </Form>
              </Col>
            </Row>
            <Row>
              <CustomButton
                buttontype="type5"
                colortype="type8"
                size="large"
                style={{margin: '0 auto'}}
                onClick={(e) => {
                  handleMessage(e)
                }}
              >
                {buttonText()}
              </CustomButton>
            </Row>
          </Card>
        </Col>
      </Row>
    </Spin>
  )
};

const mapStateToProps = (store) => {
  return {
    application: store.applications.application,
    applicationMessage: !!store.applications.application.message ? store.applications.application.message : '',
    loading: store.applications.isFetching_application,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApplication: (id) => dispatch(fetchApplication(id)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Risk);
