import React, { useState } from "react";
import { Card, Row, Col, Form, Spin, message } from "antd";
import http from "../../../../api";
import { Table } from "antd";
import { connect } from "react-redux";
import { fetchApplicationEvents } from "../../../../actions/applications";
import CustomTable from "../../../../components/CumtomTable/index";
const topColResponsiveProps3 = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 24,
    style: {
      marginBottom: 24,
    },
};
const AmlFiles = (props) => {
	const { Column } = Table;
	const { events, getEvents, isFetching, events_pagination, id } = props;
	const getType = (role) => {
		switch (role) {
			case 'CUSTOMER':
				return 'Kund'
			case 'ADMIN':
				return 'Admin'
			case 'CREDITOR':
				return 'Bank'
			default:
				return '-'
			
			
		}
	}
	return (
		<Row className="dashboard loanOffersTableWrapper" gutter={24}>
			<Col {...topColResponsiveProps3}>
				<Card
					style={{ marginBottom: 30 }}
					bodyStyle={{
						padding: "0",
						borderRadius: 6,
					}}
				>
					<div className="tableTitle">Loggar</div>
					<Spin spinning={isFetching}>
						<CustomTable
							id={id}
							className="tableLoanOffersTable"
							dataSource={events}
							dataSourcePagination={events_pagination}
							fetchData={getEvents}
						>
							<Column
								title="id"
								dataIndex="id"
								key="id"
								sorter={true}
								render={(text, record) => {
									return (
										<span>
											{record?.id}
										</span>
									);
								}}
							/>
							<Column
								title="Typ"
								dataIndex="user.role"
								key="user.role"
								sorter={true}
								render={(text, record) => {
									return (
										<span>
											{getType(record?.user?.role)}
										</span>
									);
								}}
							/>
							<Column
								title="Användare"
								dataIndex="user.firstName"
								key="user.firstName"
								sorter={true}
								render={(text, record) => {
									return (
										<span>
											{record?.user?.name}
										</span>
									);
								}}
							/>
							<Column
								title="Meddelande"
								dataIndex="message"
								key="message"
								sorter={true}
								render={(text, record) => {
									return (
										<span>
											{record?.message}
										</span>
									);
								}}
							/>
							<Column
								title="Skapat"
								dataIndex="createdAt"
								key="status"
								sorter={true}
							/>
						</CustomTable>
					</Spin>
				</Card>
			</Col>
		</Row>
	);
};

const mapStateToProps = (store) => {
	return {
		events: store.applications.events,
		events_pagination: store.applications.events_pagination,
		isFetching: store.applications.isFetching_events,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getEvents: (page, size, sortField, sorrtOrder, filters, data, id) =>
			dispatch(
				fetchApplicationEvents(
					page,
					size,
					sortField,
					sorrtOrder,
					filters,
					data,
					id
				)
			),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AmlFiles);
