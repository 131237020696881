import React from 'react';
import { Alert } from 'antd';
import QueueAnim from 'rc-queue-anim';
// import styles from './uikit.less';

const Message = ({ content,type }) => (
	<QueueAnim 
		className="demo-content"
		animConfig={[
		{ opacity: [1, 0], translateY: [0, -50] }
		]}
		style={{borderRadius: 6}}
	>
		<div className="demo-thead" key="a">
			<Alert
				style={{
					marginBottom: 10
				}}
				closable
				description={content}
				type={type}
				showIcon
			/>
		</div>

	</QueueAnim>
);

export default Message;