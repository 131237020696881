import { fetchMe } from '../UserActions';

export const init = () => async (dispatch) => {
    dispatch(showGlobalLoader());

    await dispatch(fetchMe());
    dispatch(hideGlobalLoader());
    return dispatch(loaded());
}

export function showGlobalLoader() {
    return {
        type: 'app/showLoader',
    };
}

export function hideGlobalLoader() {
    return {
        type: 'app/hideLoader',
    };
}

export function loaded() {
    return {
        type: 'app/loaded'
    }
}

