import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import avatarIcon from "../../assets/formicons/people.svg"
import './index.scss';
import {Tab} from "bootstrap"; // eslint-disable-line
import { LogoutOutlined, UserOutlined, PlusSquareOutlined } from '@ant-design/icons'; // for dropdowns
import { deleteTokens } from "../../utils/auth";

class AvatarDropdown extends Component {
    handleLogout = () => {
        deleteTokens();
        this.props.history.push('/login');
    }

    render() {

        return (
            <div className="btn-group">
                <button 
                    type="button"
                    className="btn-secondary header-avatar-btn"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <img src={avatarIcon} className="avatar-icon" alt="avatar icon"/>
                </button>
                <ul className="dropdown-menu">
                    <li>
                        <a 
                            className="dropdown-link dropdown-item"
                            href="/profile"
                        >
                            <UserOutlined className="dropdown-link-icon"/>
                            Profil
                        </a>
                    </li>
                    <li>
                        <hr 
                            className="dropdown-divider"
                            style={{margin: 0, border: 'none', height: '2px', backgroundColor: 'rgba(255, 255, 255, 0.6)'}}
                        />
                    </li>
                    <li>
                        <a 
                            className="dropdown-link dropdown-item"
                            href="/dashboard"
                        >
                            <PlusSquareOutlined className="dropdown-link-icon"/>
                            Plattform
                        </a>
                    </li>
                    <li>
                        <hr 
                            className="dropdown-divider"
                            style={{margin: 0, border: 'none', height: '2px', backgroundColor: 'rgba(255, 255, 255, 0.6)'}}
                        />
                    </li>
                    <li>
                        <button 
                            className="dropdown-link dropdown-item dropdown-item__last"
                            onClick={this.handleLogout}
                        >
                            <LogoutOutlined className="dropdown-link-icon"/>
                            Logga ut
                        </button>
                    </li>
                </ul>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        currentUser: store.me.currentUser
    }
}

export default withRouter(connect(
    mapStateToProps
)(AvatarDropdown))
