import React from "react";
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { fetchLoanOffer } from '../../../../actions/loan-offers';
import { fetchCreditRequest } from '../../../../actions/credit-requests';
import CompanyInfo from './sections/companyInfo';
import UsersInfo from './sections/UsersInfo';
import './style.scss';
import DebtorInfo from "./sections/DebtorInfo";

const CompanyDetails = (props) => {

    return (
        <>
            <div className='goBack'>
                <div style={{paddingBottom: 30}} className={"backLink backBtn titleWrapper"}>
                    <Link
                        to={
                            {
                                pathname: '/companies'
                            }
                        }
                        style={{float: 'left', paddingTop:5, fontSize: 13, fontWeight: 400, color: '#9199AA'}}
                    >
                        <ArrowLeftOutlined style={{ marginRight: 14 }} />
                        Gå tillbaka
                    </Link>
                    <h2 className='text-center'>Lägg till/Editera Låntagare</h2>
                </div>  
            </div>
        <div>
            <CompanyInfo />
            <UsersInfo />
            <DebtorInfo/>
        </div>
        </>
    );
}

const mapStateToProps = (store) => {
    return {
        user: store.me.profile,
        creditRequest: store.creditRequests.creditRequest,
        loanOffers: store.loanOffers.list,
        loanOfferDataById: store.loanOffers.loanOffer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchLoanOffer: (id) => dispatch(fetchLoanOffer(id)),
        fetchCreditRequest: (id) => dispatch(fetchCreditRequest(id)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);