import React from 'react';
import { Typography } from 'antd';
// import './uikit.scss';

export const Text = (props) => {
	let classes;
	let colorClass;
	let customclass = props.customclass ? props.customclass : false;

	if (props.texttype === 'h1') {
		classes = "textH1";
	} else if (props.texttype === 'h1small') {
		classes = "textH1Small";
	} else if (props.texttype === 'h2large') {
		classes = "textH2Large";
	} else if (props.texttype === 'h2') {
		classes = "textH2";
	} else if (props.texttype === 'h3') {
		classes = "textH3";
	} else if (props.texttype === 'h3light') {
		classes = "textH3Light";
	} else if (props.texttype === 'webinfo') {
		classes = "textWebInfo";
	} else if (props.texttype === 'webmenu') {
		classes = "textWebMenu";
	} else if (props.texttype === 'headline') {
		classes = "textHeadline";
	} else if (props.texttype === 'title') {
		classes = "textTitle";
	} else if (props.texttype === 'lines') {
		classes = "textLines";
	} else if (props.texttype === 'info') {
		classes = "textInfo";
	} else if (props.texttype === 'button') {
		classes = "textButton";
	} else if (props.texttype === 'steps') {
		classes = "textSteps";
	} else if (props.texttype === 'back') {
		classes = "textBack";
	} else if (props.texttype === 'link') {
		classes = "textLink";
	} else {
		classes = "textTitle";
	}

	if (props.colortype === 'primary') {
		colorClass = "textPrimaryColor";
	} else if (props.colortype === 'secondary') {
		colorClass = "textSecondaryColor";
	} else if (props.colortype === 'light') {
		colorClass = "textLightColor";
	} else if (props.colortype === 'back') {
		colorClass = "textBackColor";
	} else if (props.colortype === 'info') {
		colorClass = "textInfoColor";
	} else {
		colorClass = "textDarkColor";
	}

	return props.level ? (
		<Typography.Text className={[ classes, colorClass, customclass ].join(' ')} {...props} />
	) : (
		<Typography.Title className={[ classes, colorClass, customclass ].join(' ')} {...props} />
	);
};

export default Text;
