import React from 'react';
import {Redirect, Route} from "react-router-dom";
import { isLoggedIn } from "../../utils/auth";
import MasterLayout from "../../layouts/admin/Master/MasterLayout";

const PrivateRoute = ({component: Component, ...rest}) => {

    return (
        <Route {...rest} render={props =>
            isLoggedIn() ? (
                <MasterLayout>
                    <Component {...props} />
                </MasterLayout>
            ) : (
                <Redirect to={{ pathname: "/login" }} />
            )
        } />
    );
}

export default PrivateRoute;