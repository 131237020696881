import { BackTop } from "antd";
import { VerticalAlignTopOutlined } from "@ant-design/icons";

export const BackToTop = () => {
	return (
		<BackTop duration={100}>
			<div className="backTop">
				<VerticalAlignTopOutlined />
			</div>
		</BackTop>
	);
};

export default BackToTop;
