import React from 'react';
import {
  Card,
  // Table,
  Row,
  Col
} from 'antd';
import { connect } from 'react-redux';
import '../style.scss';
import { Text } from '../../../../uikit/Text';
// import CreditRequestDetails from '../../../admin/CreditRequestDetails';

const BusinessInfo = (props) => {
  const { application, company } = props;

  const topColResponsiveProps = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 11,
    style: {
      marginBottom: 24,
    },
  };

  return (
    <>
      <Col {...topColResponsiveProps}>
        <Card
          // loading={loading}
          className="cardDetaill"
          style={{marginBottom: 30}}
          bodyStyle={{
          padding: '28',
          borderRadius: 6
          }}
        >
          <Text texttype="back" colortype="back" customclass="cardTitle">
            Företagsuppgifter
          </Text>
          <Row gutter={16}>
            <Col padding={10} className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
              <div className="labelitem">{'Kredittagare:'}</div>
            </Col>
            <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
              <div className="valueitem">{application?.debtorName || '-'}</div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col padding={10} className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
              <div className="labelitem">{'Huvudkonto:'}</div>
            </Col>
            <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
              <div className="valueitem">{company?.name || '-'}</div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col padding={10} className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
              <div className="labelitem">{'Org. nr:'}</div>
            </Col>
            <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
              <div className="valueitem">{application?.debtorCompanyNumber || '-'}</div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col padding={10} className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
              <div className="labelitem">{'Adress:'}</div>
            </Col>
            <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
              <div className="valueitem">{application?.debtorCompanyAddress || '-'}</div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col padding={10} className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
              <div className="labelitem">{'Post nr:'}</div>
            </Col>
            <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
              <div className="valueitem">{application?.debtorCompanyZip || '-'}</div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col padding={10} className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
              <div className="labelitem">{'Ort:'}</div>
            </Col>
            <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
              <div className="valueitem">{application?.debtorCompanyCity|| '-'}</div>
            </Col>
          </Row>
          {
            !!company?.logo && (
              <div style={{height: "90px", textAlign: "center"}}>
                <img style={{height: "90px"}} src={`data:image/png;base64,${company?.logo}`} alt="logo" />
              </div>
            )
          }
        </Card>
      </Col>
    </>
  );
}

export default connect(({ creditRequests }) => ({
  application: creditRequests?.creditRequest?.application?.data || {},
  company: creditRequests?.creditRequest?.application?.company || {}
}))(BusinessInfo);
