import React from "react";
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { fetchLoanOffer } from '../../../../actions/loan-offers';
import { fetchCreditRequest } from '../../../../actions/credit-requests';
import BankInfo from './sections/BankInfo';
import BankUsersInfo from './sections/BankUsersInfo';
import './style.scss';

const CompanyDetails = (props) => {

    return (
        <>
            <div className='goBack'>
                <div style={{paddingBottom: 30}} className={"backLink backBtn titleWrapper"}>
                    <Link
                        to="#" 
                        onClick={() => { props.history.push('/users/banks') }} 
                        style={{float: 'left', paddingTop:5, fontSize: 13, fontWeight: 400, color: '#9199AA'}}
                    >
                        <ArrowLeftOutlined style={{ marginRight: 14 }} />
                        Gå tillbaka
                    </Link>
                    <h2 className='text-center'>Lägg till/Editera Låntagare</h2>
                </div>  
            </div>
        <div>
            <BankInfo />
            <BankUsersInfo />
        </div>
        </>
    );
}

const mapStateToProps = (store) => {
    return {
        // user: store.me.profile,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchLoanOffer: (id) => dispatch(fetchLoanOffer(id)),
        fetchCreditRequest: (id) => dispatch(fetchCreditRequest(id)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);