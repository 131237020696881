import React from 'react';
import { Link } from "react-router-dom";
import {
  Card,
  Table,
  Row,
  Col,
  Spin
} from 'antd';
import { getStatus, getProduct } from '../../../../utils/helpers';
import moment from 'moment';
import { connect } from 'react-redux';
import { fetchApplicationLoanOffers } from '../../../../actions/loan-offers'
import CustomTable from '../../../../components/CumtomTable/index';

const topColResponsiveProps3 = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 24,
  style: {
    marginBottom: 24,
  },
};

const { Column } = Table;

const CreditRequestsTable = (props) => {
  const { loanOffers, id } = props;

  return (
    <Row className="dashboard loanOffersTableWrapper" gutter={24} >
      <Col {...topColResponsiveProps3}>
        <Card
            style={{marginBottom: 30}}
            bodyStyle={{
              padding: '0',
              borderRadius: 6
            }}
          >
            <div className="tableTitle">Låneerbjudanden</div>
            <Spin spinning={props.isLoading}>

              <CustomTable
                className="tableLoanOffersTable" 
                dataSource={loanOffers}
                dataSourcePagination={props.loanOffersPagination}
                fetchData={props.fetchApplicationLoanOffers}
                id={id}
              >
                <Column
                  title="id"
                  dataIndex="id"
                  key="id"
                  sorter={true}
                  style={{textAlign: 'start'}}
                  render={(text, record) => (
                    <div className='actionBtn'>
                      <Link 
                        to={`${'/loan-offers/detail?id='+record?.creditRequest?.id}`}
                        style={{color: 'inherit', fontWeight: 800}}
                      >
                        {record?.id}
                      </Link>
                      <div className='width'></div>
                    </div>
                  )}
                />
                <Column
                  title="Långivare"
                  dataIndex="creditRequest.company.name"
                  key="creditRequest.company.name"
                  sorter={true}
                  render={(text, record) => (
                    <span className="tableFile">{record?.creditRequest?.company?.name}</span>
                  )}
                />
                <Column
                  title="Status"
                  dataIndex="status"
                  key="status"
                  sorter={true}
                  render={(text, record) => {
                    return (
                      <span>
                        {getStatus(record?.status, record?.status)}
                      </span>
                    )
                  }}
                />
                <Column
                  title="Belopp"
                  dataIndex="amount"
                  key="amount"
                  sorter={true}
                  render={(text, record) => (
                    <span>
                      {!!record && !!record.amount ? `${record?.amount} SEK` : 0}
                    </span>
                  )}
                />
                <Column
                  title="Ändamål"
                  dataIndex="creditRequest.application.type"
                  key="creditRequest.application.type"
                  sorter={true}
                  render={(text, record) => (
                    <span>
                      {getProduct(record?.creditRequest?.application?.type) || '-'}
                    </span>
                  )}
                />
                <Column
                  title="Ränta"
                  dataIndex="interest"
                  key="interest"
                  sorter={true}
                  render={(text, record) => (
                    <span >
                      {text ? `${text} %` : 0}
                    </span>
                  )}
                />
                <Column
                  title="Löptid"
                  dataIndex="duration"
                  key="duration"
                  sorter={true}
                  render={(text, record) => (
                    <span>
                      {text ? `${text} Mån` : '-'}
                    </span>
                  )}
                />
                <Column
                  title="Kreditförfrågan"
                  dataIndex="creditRequest.id"
                  key="creditRequest.id"
                  sorter={true}
                  render={(text, record) => (
                    <span>
                      {!!record?.creditRequest?.id ? record.creditRequest.id : '-'}
                    </span>
                  )}
                />

            </CustomTable>

          </Spin>
        </Card>
      </Col>
    </Row>
  )
};

const mapStateToProps = (store) => {
  return {
    loanDataById: store.applications.application,
    loanOffers: store.loanOffers.applicationLoanOffers,
    loanOffersPagination: store.loanOffers.applicationLoanOffers_pagination,
    isLoading: store.loanOffers.isFetching_applicationLoanOffers
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApplicationLoanOffers: (currentPage, pageSize, sortField, sortOrder, filters, data, id) => dispatch(
      fetchApplicationLoanOffers(currentPage, pageSize, sortField, sortOrder, filters, data, id)
    )
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditRequestsTable);

