import React, { useState, useCallback, useEffect } from "react";
import { Checkbox, Space, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const TableFilter = (props) => {
	const {
		setSelectedKeys,
		selectedKeys,
		confirm,
		clearFilters,
		filters,
		setChecked,
		removeChecked,
	} = props;

	window.onbeforeunload = (event) => {
		// Show prompt based on state
		removeChecked();
	};
	useEffect(() => {
		return () => {
			removeChecked();
		};
	}, []);

	const handleSearch = (selectedKeys, confirm) => {
		confirm();
	};

	const handleReset = (clearFilters) => {
		removeChecked();
		clearFilters();
	};

	return (
		<div
			className="filterDropdown"
			style={{
				padding: 8,
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Checkbox.Group
				className="filtersCheckbox"
				options={filters.map((item) => {
					return { value: item.value, label: item.text };
				})}
				value={selectedKeys}
				onChange={(checked) => {
					setChecked(checked);
					setSelectedKeys(checked);
				}}
			/>
			<Space>
				<Button
					className="filterBtn filterRtnSearch"
					type="primary"
					onClick={() => handleSearch(selectedKeys, confirm)}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90 }}
				>
					Search
				</Button>
				<Button
					className="filterBtn filterRtnReset"
					onClick={() => handleReset(clearFilters)}
					size="small"
					style={{ width: 90 }}
				>
					Reset
				</Button>
			</Space>
		</div>
	);
};

export default TableFilter;
