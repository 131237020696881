import React, { useState } from "react";
import { Form } from "antd";
// import { message } from 'antd';
import { CustomButton } from "../../uikit/Button";
import { CustomInput } from "../../uikit/Input";
import { connect } from "react-redux";
import http from "../../api";
import { toast } from "react-toastify";
import { CheckCircleOutlined } from "@ant-design/icons";
import "./BaseProfile.scss";
import LogoUploader from "../../uikit/LogoUploader";

const BaseProfile = (props) => {
    const [form] = Form.useForm();
    const { currentUser } = props;

    const [errors, setErrors] = useState({});

    const handleFinish = (values) => {
        http(`/api/v1/user`, {
            method: "PUT",
            data: values,
        })
            .then((res) => {
                toast.success(
                    <div className="toast-body">
                        <CheckCircleOutlined className="toast__icon toast__icon--success" />
                        <p className="toast-text">{`Profile has been changed`}</p>
                    </div>
                );
            })
            .catch((err) => {
                if (
                    !!err.response &&
                    !!err.response.data &&
                    !!err.response.data.errors
                ) {
                    setErrors(err.response.data.errors);
                }
            });
    };

    return (
        <div className="baseView">
            <div className="left">
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={handleFinish}
                    initialValues={currentUser}
                    hideRequiredMark
                    style={{ width: "100%" }}
                >
                    <CustomInput
                        key="firstName"
                        label="Förnamn"
                        name="firstName"
                        validateStatus={!!errors.firstName ? "error" : ""}
                        help={
                            !!errors.firstName ? String(errors.firstName) : null
                        }
                        inputprops={{
                            placeholder: "Ange Förnamn och mell...",
                            onChange: (e) => {
                                setErrors({ ...errors, firstName: "" });
                            },
                        }}
                    />
                    <CustomInput
                        key="lastName"
                        label="Efternamn"
                        name="lastName"
                        validateStatus={!!errors.lastName ? "error" : ""}
                        help={
                            !!errors.lastName ? String(errors.lastName) : null
                        }
                        inputprops={{
                            placeholder: "Ange efternamn",
                            onChange: (e) => {
                                setErrors({ ...errors, lastName: "" });
                            },
                        }}
                    />
                    <CustomInput
                        label="Telefonnummer"
                        key="phoneNumber"
                        name="phoneNumber"
                        validateStatus={!!errors.phoneNumber ? "error" : ""}
                        help={
                            !!errors.phoneNumber
                                ? String(errors.phoneNumber)
                                : null
                        }
                        inputprops={{
                            placeholder: "Ange telefonnummer",
                            onChange: (e) => {
                                setErrors({ ...errors, phoneNumber: "" });
                            },
                        }}
                    />
                    <CustomInput
                        label="Personnummer"
                        key="ssn"
                        name="ssn"
                        validateStatus={!!errors.ssn ? "error" : ""}
                        help={!!errors.ssn ? String(errors.ssn) : null}
                        inputprops={{
                            placeholder: "Ange personnummer",
                            onChange: (e) => {
                                setErrors({ ...errors, ssn: "" });
                            },
                        }}
                    />
                    {window.location.host.toString().includes("creditor") && (
                        <LogoUploader
                            id={currentUser?.company?.id}
                            logo={currentUser?.company?.logo}
                        />
                    )}
                    <Form.Item>
                        <div
                            className="buttons"
                            style={{
                                width: "fit-content",
                                margin: "20px auto 0",
                            }}
                        >
                            <CustomButton
                                buttontype="type5"
                                colortype="type8"
                                size="large"
                                htmlType="submit"
                            >
                                Uppdatera
                            </CustomButton>
                        </div>
                    </Form.Item>
                </Form>
            </div>
            <div className="right">
                {/* <AvatarView avatar={getAvatarURL()} />*/}
            </div>
        </div>
    );
};

const mapStateToProps = (store) => {
    return {
        currentUser: store.me.profile,
    };
};

export default connect(mapStateToProps)(BaseProfile);
