import React, {Fragment, useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { fetchLoanOffer } from '../../../../actions/loan-offers';
import { fetchCreditRequest } from '../../../../actions/credit-requests';
import { Text } from '../../../../uikit/Text';
import { CustomButton } from '../../../../uikit/Button';
import {
    Card,
    Row,
    Col,
    Spin
} from 'antd';
// import { toast } from "react-toastify";
// import { CheckCircleOutlined } from '@ant-design/icons';
import { getQueryParam, getStatus } from '../../../../utils/helpers';
// import http from "../../../../api";
import DateModal from './DateModal';
import './style.scss';

const LoanOfferDetails = (props) => {
    const { 
        loanOfferDataById,
        // loanOfferList
    } = props;

    let id = '';
    if (!!getQueryParam('id')) {
      id = getQueryParam('id')
    }

    const [ showModal, setShowModal ] = useState(false);

    useEffect(() => {
        props.fetchLoanOffer(id);
        props.fetchCreditRequest(id);
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

    const topColResponsiveProps = {
        xs: 24,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 24,
        style: {
          marginBottom: 24,
        },
    };

    const handleCancel = async () => {
        setShowModal(value => !value);
    };
   
    return (
        <>
            <div className='goBack'>
                <div style={{paddingBottom: 30}} className={"backLink backBtn titleWrapper"}>
                    <Link
                        to={
                            {
                                pathname: '/loan-offers'
                            }
                        }
                        style={{float: 'left', paddingTop:5, fontSize: 13, fontWeight: 400, color: '#9199AA'}}
                    >
                        <ArrowLeftOutlined style={{ marginRight: 14 }} />
                        Gå tillbaka
                    </Link>
                    <h2 className='text-center'>Låneerbjudanden</h2>
                </div>  
            </div>
            <div>
                <Card
                    style={{marginBottom: 30 }}
                    bodyStyle={{
                        padding: '28',
                        borderRadius: 6,
                        textAlign: 'center'
                    }}
                >
                    <h2 className="detailsHeader" style={{marginBottom: 0, display: 'inline-block', position: 'relative'}}>
                        {`Aktivt erbjudande för kreditförfrågan ${loanOfferDataById?.creditRequest?.id}`}
                    </h2>
                </Card>
                <Spin spinning={props.loading}>
                    <div style={{background: '#FFFFFF'}}>
                        <Row gutter={24} type="flex">
                            <Col {...topColResponsiveProps}>
                                <Card
                                    // loading={loading}
                                    className="cardDetaill bidDetaill"
                                    style={{marginBottom: 30, height: '100%'}}
                                    bodyStyle={{
                                    padding: '28',
                                    borderRadius: 6
                                    }}
                                >
                                        <Text texttype="back" colortype="back" customclass="cardTitle">
                                        Grunduppgifter
                                        </Text>
                                        <Row gutter={16}>
                                            <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                                <div className="labelitem">{'Belopp:'}</div>
                                            </Col>
                                            <Col className="gutter_row" xl={10} lg={8} md={8} sm={24} xs={24}>
                                                <div className="valueitem">
                                                    {!!loanOfferDataById.amount ? `${loanOfferDataById.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} SEK` : '-'}
                                                    </div>
                                            </Col>
                                        </Row>  
                                        <Row gutter={16}>
                                            <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                                <div className="labelitem">{'Ränta för lånet:'}</div>
                                            </Col>
                                            <Col className="gutter_row" xl={10} lg={8} md={8} sm={24} xs={24}>
                                                <div className="valueitem">
                                                    {!!loanOfferDataById && !!loanOfferDataById.interest ? loanOfferDataById.interest + '%' : '-' }
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                                <div className="labelitem">{'Budstatus:'}</div>
                                            </Col>
                                            <Col className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
                                                <div className="valueitem">
                                                    {!!loanOfferDataById.status ? getStatus(loanOfferDataById.status) : "-"}
                                                </div>
                                            </Col>
                                        </Row> 
                                        <Row gutter={16}>
                                            <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                                <div className="labelitem">
                                                {`Avgift utgår för lånet:`}
                                                </div>
                                            </Col>
                                            <Col className="gutter_row" xl={10} lg={8} md={8} sm={24} xs={24}>
                                                <div className="valueitem">{loanOfferDataById?.feeTypes ? 'Ja' : 'Nej'}</div>
                                            </Col>
                                        </Row>
                                        {!!loanOfferDataById.feeTypes && (
                                            loanOfferDataById.feeTypes.map((feeType, index) => (
                                            <Fragment key={index}>
                                                <Row gutter={16}>
                                                    <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                                        <div className="labelitem">{'Angiven avgiftsform för lånet:'}</div>
                                                    </Col>
                                                    <Col className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
                                                        <div className="valueitem">{feeType.feeType || '-'}</div>
                                                    </Col>
                                                </Row>
                                                <Row gutter={16}>
                                                    <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                                        <div className="labelitem">
                                                        {`Avgift för lånet:`}
                                                        </div>
                                                    </Col>
                                                    <Col className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
                                                        <div className="valueitem">
                                                            {!!feeType.feeAmount ? `${feeType.feeAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} SEK` : '-'}
                                                        </div>
                                                    </Col>
                                                </Row>   
                                            </Fragment>
                                            ))
                                        )}
                                </Card>
                            </Col>
                        </Row>
                        <Row gutter={24} type="flex">
                            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Card
                                    // loading={loading}
                                    className="cardDetaill bidDetaill"
                                    style={{marginBottom: 30, height: '100%'}}
                                    bodyStyle={{
                                    padding: '28',
                                    borderRadius: 6
                                    }}
                                >
                                    <Text texttype="back" colortype="back" customclass="cardTitle">
                                        Säkerhet och borgen
                                    </Text>
                                    <Row gutter={16}>
                                        <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                            <div className="labelitem">{'Säkerhetsvillkor för lånet:'}</div>
                                        </Col>
                                        <Col className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
                                            <div className="valueitem" style={{whiteSpace: 'pre-wrap'}}>
                                                {loanOfferDataById?.securityTerms || 'N/A'}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                    <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                        <div className="labelitem">{'Borgen krävs för lånet:'}</div>
                                    </Col>
                                    <Col className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
                                        <div className="valueitem">{loanOfferDataById?.guarantors ? 'ja' : 'Nej'}</div>
                                    </Col>
                                    </Row>
                                    {!!loanOfferDataById.guarantors && (
                                    loanOfferDataById.guarantors.map((guarantor, index) => (
                                        <Fragment key={index}>
                                            <Row gutter={16}>
                                                <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                                    <div className="labelitem">{'Borgensman:'}</div>
                                                </Col>
                                                <Col className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
                                                    <div className="valueitem">
                                                        {`${!!guarantor.guarantorSSN ? guarantor.guarantorSSN : '-'}
                                                        ${!!guarantor.guarantorName ? guarantor.guarantorName : '-'}`}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                                    <div className="labelitem">{'Borgenssumma:'}</div>
                                                </Col>
                                                <Col className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
                                                    <div className="valueitem">
                                                        {!!guarantor?.guaranteeAmount ? guarantor.guaranteeAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' SEK' :  '-'}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Fragment>
                                        ))
                                    )}
                            </Card>
                            </Col>
                        </Row>
                        <Row gutter={24} type="flex">
                            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Card
                                    // loading={loading}
                                    className="cardDetaill bidDetaill"
                                    style={{marginBottom: 30, height: '100%'}}
                                    bodyStyle={{
                                    padding: '28',
                                    borderRadius: 6
                                    }}
                                >
                                    <Text texttype="back" colortype="back" customclass="cardTitle">
                                    Amortering och löptid
                                    </Text>
                                    <Row gutter={16}>
                                        <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                            <div className="labelitem">{'Amorteringstyp för lånet:'}</div>
                                        </Col>
                                        <Col className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
                                            <div className="valueitem">
                                                {loanOfferDataById?.amortizationType || '-'}
                                            </div>
                                        </Col>
                                    </Row>
                                    {!!loanOfferDataById.amortizationCustomType && (
                                        <Row gutter={16}>
                                            <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                                <div className="labelitem">{'Amorteringstakt:'}</div>
                                            </Col>
                                            <Col className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
                                                <div className="valueitem">
                                                    {loanOfferDataById?.amortizationCustomType}
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row gutter={16}>
                                    <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                        <div className="labelitem">{'Löptid för lånet:'}</div>
                                    </Col>
                                    <Col className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
                                        <div className="valueitem">
                                            {!!loanOfferDataById.duration ? loanOfferDataById.duration + ' mån' : '-'}
                                        </div>
                                    </Col>
                                    </Row>
                                </Card>
                            </Col>
                            
                            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Card
                                    // loading={loading}
                                    className="cardDetaill bidDetaill"
                                    style={{marginBottom: 30, height: '100%'}}
                                    bodyStyle={{
                                    padding: '28',
                                    borderRadius: 6
                                    }}
                                >
                                    <Text texttype="back" colortype="back" customclass="cardTitle">
                                        Handläggningstid
                                    </Text>
                                    <Row gutter={16}>
                                    <Col padding={10} className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
                                        <div className="labelitem">{'Handläggningstid för lånet:'}</div>
                                    </Col>
                                    </Row>
                                    <Row gutter={16}>
                                    <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
                                        <div className="valueitem">
                                            {!!loanOfferDataById.processingTime ? loanOfferDataById.processingTime + ' dagar' : '-'}
                                        </div>
                                    </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        {!!loanOfferDataById.otherTerms && !!loanOfferDataById.otherTerms.length && (
                            <Col xl={24} lg={24} md={20} sm={24} xs={24}>
                                <Card
                                    // loading={loading}
                                    className="cardDetaill bidDetaill"
                                    style={{marginBottom: 30, height: '100%'}}
                                    bodyStyle={{
                                    padding: '28',
                                    borderRadius: 6
                                    }}
                                >
                                    <Text texttype="back" colortype="back" customclass="cardTitle">
                                        Övriga villkor
                                    </Text>
                                    <Row gutter={16}>
                                        <Col className="gutter_row" xl={24} lg={16}	md={16} sm={24} xs={24}>
                                            <ul className="message-list">
                                                {loanOfferDataById?.otherTerms.map((term, index) => {
                                                    if (!!term && !!term.otherTerm) {
                                                        return (
                                                            <li 
                                                                className="messageText-list"
                                                                style={{marginLeft: '15px'}}
                                                                key={index}
                                                            >
                                                                {term?.otherTerm}
                                                            </li>
                                                        )
                                                    } else return null
                                                })}
                                            </ul>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        )}

                        <div className="rowbuttons">
                            {loanOfferDataById.status === 'STATUS_ACTIVE' && (
                                <Link to={`${'/loan-offers/detail/edit?id='+id}`}>
                                    <CustomButton 
                                        buttontype="type5"
                                        colortype="type8"
                                        size="large"
                                        style={{margin: '0 10px 20px'}}
                                        disabled={loanOfferDataById.status !== 'STATUS_ACTIVE'}
                                    >
                                        Editera
                                    </CustomButton>
                                </Link>
                            )}
                            {loanOfferDataById.status === 'STATUS_APPROVED' && (
                                <CustomButton
                                    className="approvedBtn"
                                    buttontype="type5"
                                    colortype="type8"
                                    size="large"
                                    // style={{margin: '0 10px 20px', minWidth: '285px'}}
                                    disabled={loanOfferDataById.status !== 'STATUS_APPROVED'}
                                    onClick={() => {setShowModal(true)}}
                                >
                                    Sätt kreditförfrågan som utbetald
                                </CustomButton>
                            )}
                        </div>
                    </div>
                </Spin>
                <DateModal
                    showLeadDialog={showModal}
                    handleCancel={handleCancel}
                    id={id}
                />
            </div>
        </>
    );
}

const mapStateToProps = (store) => {
    return {
        user: store.me.profile,
        creditRequest: store.creditRequests.creditRequest,
        loanOffers: store.loanOffers.list,
        loanOfferDataById: store.loanOffers.loanOffer,
        loading: store.loanOffers.isFetching_loanOffer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchLoanOffer: (id) => dispatch(fetchLoanOffer(id)),
        fetchCreditRequest: (id) => dispatch(fetchCreditRequest(id)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanOfferDetails);