const initialState = {
    list: [],
	list_pagination: {},
	isLoaded_list: false,
	isFetching_list: false,

	company: {},
	isLoaded_company: false,
	isFetching_company: false,

	companyUsers: [],
	isLoaded_companyUsers: false,
	isFetching_companyUsers: false,
	isLoaded_debtors: false,

	user: {},
	isLoaded_user: false,
	isFetching_user: false,
};

export function companiesReducer(state = initialState, action) {
    switch (action.type) {
		case 'companies/setList':
            return {
                ...state,
                list: action.list,
				list_pagination: action.pagination,
                isLoaded_list: true,
            };

		case 'companies/showLoader':
			return {
				...state,
				isFetching_list: true,
			};

		case 'companies/hideLoader':
			return {
				...state,
				isFetching_list: false,
			};

		case 'companies/setCompany':
			return {
				...state,
				company: action.company,
				isLoaded_company: true
			};

		case 'company/showLoader':
			return {
				...state,
				isFetching_company: true,
			};

		case 'company/hideLoader':
			return {
				...state,
				isFetching_company: false,
			};

		case 'companies/setCompanyUsers':
			return {
				...state,
				companyUsers: action.companyUsers,
				isLoaded_companyUsers: true
			};
	
		case 'companies/setDebtors':
			return {
				...state,
				debtors: action.debtors,
				isLoaded_debtors: true
			};
	
		case 'company-users/showLoader':
			return {
				...state,
				isFetching_companyUsers: true,
			};

		case 'company-users/hideLoader':
			return {
				...state,
				isFetching_companyUsers: false,
			};

		case 'company-debtors/showLoader':
			return {
				...state,
				isFetching_debtors: true,
			};

		case 'company-debtors/hideLoader':
			return {
				...state,
				isFetching_debtors: false,
			};
	
		case 'company-users/setUser':
			return {
				...state,
				user: action.user,
				isLoaded_user: true
			};

		case 'company-user/showLoader':
			return {
				...state,
				isFetching_user: true,
			};

		case 'company-user/hideLoader':
			return {
				...state,
				isFetching_user: false,
			};

        default:
            return state;
    }
}
