import React, {Component} from "react";
import {withRouter} from "react-router";
import "./index.scss";
// import HeaderLeft from "./HeaderLeft";
// import MenuButton from "./MenuButton";

class Header extends Component {
    render() {
        return (
            <header className="bt-header">
                <div className="d-flex justify-content-between align-items-center" style={{height: '80px'}}>
                    {this.props.children}
                </div>
            </header>
        );
    }
}

export default withRouter(Header);