import React, { useEffect} from "react";
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { Text } from '../../../../uikit/Text';
import {
    Form,
    Card,
    Spin,
    Row,
    Col
} from 'antd';
import { getQueryParam } from '../../../../utils/helpers';
import { fetchCompany, fetchCompanyUsers } from '../../../../actions/users/companies';
import BusinessInfoForm from './components/BusinessInfoForm';
import './style.scss';


const CompanyDetails = (props) => {
    const { 
        company,
    } = props;

    let id = '';
    if (!!getQueryParam('id')) {
      id = getQueryParam('id')
    } 

    const [ form ] = Form.useForm();

    useEffect(() => {
        props.fetchCompany(id);
        props.fetchCompanyUsers(id);
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

        return (
            <>
                <div className='goBack'>
                    <div style={{paddingBottom: 30}} className={"backLink backBtn titleWrapper"}>
                        <Link
                            to={
                                {
                                    pathname: '/companies/detail',
                                    search: `?id=${id}`
                                }
                            }
                            style={{float: 'left', paddingTop:5, fontSize: 13, fontWeight: 400, color: '#9199AA'}}
                        >
                            <ArrowLeftOutlined style={{ marginRight: 14 }} />
                            Gå tillbaka
                        </Link>
                        <h2 className='text-center'>
                            {!!company && company.name ? company.name : 'Användare' }
                        </h2>
                    </div>  
                </div>
            <div>
                <div style={{background: '#FFFFFF'}}>
                    <Row gutter={24} type="flex">
                        <Col xl={7} lg={10} md={22} sm={22} xs={24}>
                            <Card
                                // loading={loading}
                                className="cardDetaill bidDetaill"
                                style={{height: '100%'}}
                                bodyStyle={{
                                padding: '28',
                                borderRadius: 6
                                }}
                            >
                                <Text texttype="back" colortype="back" customclass="cardTitle">
                                    Företagsuppgifter
                                </Text>
                                <Spin spinning={props.loadingCompany}>
                                    <BusinessInfoForm form={form} />
                                </Spin>
                          </Card>
                        </Col>
                    </Row>
                </div>
            </div>
          </>
        );
    // }
}

const mapStateToProps = (store) => {
    return {
        company: store.companies.company,
        loadingCompany: store.companies.isFetching_company,
        user: store.me.profile,
        users: store.companies.companyUsers,
        loadingCompanyUsers: store.companies.isFetching_companyUsers,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCompany: (id) => dispatch(fetchCompany(id)),
        fetchCompanyUsers: (id) => dispatch(fetchCompanyUsers(id)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);