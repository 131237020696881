import http from "../api";
// import { toast } from "react-toastify";

export function setUser(profile) {
    return {
        type: 'user/setUser',
        profile: profile,
    }
}

export const fetchMe = () => async (dispatch) => {
    dispatch(showLoader());
    await (await http.get('/api/v1/user/me')
        .then(response => {
            dispatch(hideLoader());
            if (!!response && !!response.data && !!response.data.data) {
                return dispatch(setUser(response.data.data));
            }
        })
        .catch(err => {
            dispatch(hideLoader());
        })
    )
}

export function showLoader() {
    return {
        type: 'user/showLoader',
    };
}

export function hideLoader() {
    return {
        type: 'user/hideLoader',
    };
}
