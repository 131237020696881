import React, { useEffect } from 'react';
import { Spin } from 'antd';
import NotificatiosList from '../NotificationsList';
import { connect } from 'react-redux';
import { fetchLastNotifications } from '../../../actions/notifications';
import { QuestionCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { ReactComponent as DollarIcon } from '../../../assets/notifications/dollar.svg';
import { ReactComponent as FileIcon } from '../../../assets/notifications/file.svg';
import { ReactComponent as PriceIcon } from '../../../assets/notifications/price-tag.svg';
import { ReactComponent as CourtGavelIcon } from '../../../assets/notifications/gavel.svg';
import { getPriorDate } from '../../../utils/helpers';
import "./styles.scss";

const NotificatiosListWrapper = (props) => {
  const { notifications, isLoaded, fetchLastNotifications } = props;
  const priorDate = getPriorDate();

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchLastNotifications(priorDate)
    }, 4000);

    return () => clearTimeout(timer);
  }, [notifications]) // eslint-disable-line react-hooks/exhaustive-deps

  const getIconType = (item) => {
    let icon;

    if (item.iconType === 'paper') {
      icon = <FileIcon className={`${!!item.isRead && 'notificationIsRead'} notificationItemIcon`} /> ;
    } else if (item.iconType === 'court_gavel') {
      icon = (
        <CourtGavelIcon 
          style={{height: '18px', width: '18px'}} 
          className={`${!!item.isRead && 'notificationFilledIsRead'} notificationFilledItemIcon`} 
        />
      );;
    } else if (item.iconType === 'question') {
      icon = <QuestionCircleOutlined className={`${!!item.isRead && 'notificationIsRead'} notificationItemIconAnt`} /> ;
    } else if  (item.iconType === 'dollar') {
      icon = <DollarIcon className={`${!!item.isRead && 'notificationIsRead'} notificationItemIcon`} />;
    } else if  (item.iconType === 'check_mark') {
      icon = <CheckCircleOutlined className={`${!!item.isRead && 'notificationIsRead'} notificationItemIconAnt`} />;
    } else if (item.iconType === 'price') {
      icon = (
        <PriceIcon 
          style={{height: '18px', width: '18px'}} 
          className={`${!!item.isRead && 'notificationFilledIsRead'} notificationFilledItemIcon`} 
        />
      );
    } else {
      icon = null
    }

    return icon
  }

  return (
    <div>
      {!notifications.length && !isLoaded ? ( 
        <Spin size="middle" style={{display: 'flex', justifyContent: 'center'}}/>
      ) : (
        <NotificatiosList getIconType={getIconType}/>
      )}
    </div>
  )
}

const mapStateToProps = (store) => {
  return {
    notifications: store.notifications.lastNotifications,
    isLoaded: store.notifications.isLoaded_lastNotifications
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
      fetchLastNotifications: (date) => dispatch(fetchLastNotifications(date))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificatiosListWrapper);
