import React, { useState } from "react";
import { Spin, Form } from "antd";
import { CustomButton } from "../../uikit/Button";
import { getQueryParam } from "../../utils/helpers";
import { CheckCircleOutlined } from "@ant-design/icons";
import { deleteTokens } from "../../utils/auth";
import history from "../../utils/history";
import http from "../../api";
import { toast, ToastContainer } from "react-toastify";
import { CustomInput } from "../../uikit/Input";
import Header from "../../components/header/Header";
import AvatarDropdownLogin from "../../components/AvatarDropdownLogin";
import { ReactComponent as Keyicon } from "../../assets/formicons/key.svg";
import { connect } from "react-redux";
import "./style.scss";

const PasswordConfirmation = (props) => {
	const [form] = Form.useForm();
	const { loading, currentUser, history } = props;
	let isPasswordConfirmation =
		window.location.pathname === "/account/profile/password-confirmation";
	const code = !!getQueryParam("code") ? getQueryParam("code") : "";
	console.log("history", history);
	// const [ returnLink, setReturnLink ] = useState('');
	const [errors, setErrors] = useState({});

	const handleChange = (e, name) => {
		setErrors((prevState) => ({
			...prevState,
			[name]: "",
		}));
	};

	const handleFinish = (values) => {
		http(`/api/v1/user/password${isPasswordConfirmation ? "" : "-reset"}`, {
			method: isPasswordConfirmation ? "PUT" : "POST",
			data: {
				...values,
				code: code,
			},
		})
			.then((res) => {
				toast.success(
					<div className="toast-body">
						<CheckCircleOutlined className="toast__icon toast__icon--success" />
						<p className="toast-text">
							<span style={{ fontWeight: 700 }}>Klart</span>{" "}
							<br /> Lösenordet är nu ändrat.
						</p>
					</div>
				);
				deleteTokens();
				setTimeout(() => {
					history.push("/login");
				}, 1500);
			})

			// setReturnLink('Go to profile settings')

			.catch((err) => {
				if (
					!!err.response &&
					!!err.response.data &&
					!!err.response.data.errors
				) {
					setErrors(err.response.data.errors);
				}
			});
	};

	return (
		<div>
			<ToastContainer />
			<Header>
				<AvatarDropdownLogin />
			</Header>
			<Spin spinning={loading}>
				<div className="confrmWrapper masterLayout-content">
					<div style={{ margin: "0 auto" }}>
						<p className="confirmTitle">
							Vänligen ange ditt lösenord
						</p>
						<Form
							layout="vertical"
							form={form}
							onFinish={handleFinish}
							initialValues={currentUser}
							hideRequiredMark
						>
							<Form.Item>
								<CustomInput
									name="password"
									label="Nytt lösenord"
									className={
										form.getFieldValue("password") &&
										form.getFieldValue("password").length >
											0 &&
										"password"
									}
									validateStatus={
										!!errors.password || !!errors.code
											? "error"
											: ""
									}
									help={
										!!errors.password
											? String(errors.password)
											: !!errors.code
											? String(errors.code)
											: null
									}
									onChange={(e) =>
										handleChange(e, "password")
									}
									inputprops={{
										type: "password",
										placeholder: "Nytt lösenord",
										prefix: <Keyicon />,
									}}
								/>
								<CustomInput
									label="Bekräfta lösenord"
									name="passwordConfirmation"
									className={
										form.getFieldValue("password") &&
										form.getFieldValue("password").length >
											0 &&
										"password"
									}
									validateStatus={
										!!errors.passwordConfirmation
											? "error"
											: ""
									}
									help={
										!!errors.passwordConfirmation
											? String(
													errors.passwordConfirmation
											  )
											: null
									}
									onChange={(e) =>
										handleChange(e, "passwordConfirmation")
									}
									inputprops={{
										type: "password",
										placeholder: "Bekräfta lösenord",
										prefix: <Keyicon />,
									}}
								/>
								<div
									style={{
										width: 250,
										margin: "20px 0 0",
										display: "flex",
										justifyContent: "center",
									}}
								>
									<CustomButton
										buttontype="type5"
										colortype="type8"
										size="large"
										htmlType="submit"
									>
										Bekräfta
									</CustomButton>
								</div>
							</Form.Item>
						</Form>
						{/* <div style={{textAlign: 'center'}}>
							<a href="/profile" style={{fontSize: '13px', fontWeight: 400, color: 'rgb(145, 153, 170)'}}>
								{returnLink}
							</a>
						</div> */}
					</div>
				</div>
			</Spin>
		</div>
	);
};

const mapStateToProps = (store) => {
	return {
		currentUser: store.me.profile,
		loading: store.me.isLoading,
	};
};

export default connect(mapStateToProps)(PasswordConfirmation);
