import React, { useState } from 'react';
import { Spin, Form, message } from 'antd';
import { CustomButton } from '../../uikit/Button';
import { getQueryParam } from '../../utils/helpers';
import { CheckCircleOutlined } from '@ant-design/icons';
import http from "../../api";
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import './style.scss';


const EmailConfirmation = (props) => {
	const [ form ] = Form.useForm();
	const { loading, currentUser } = props;
	const code = !!getQueryParam('code') ? getQueryParam('code') : ''; 

	const [ returnLink, setReturnLink ] = useState('');

	const handleFinish = () => {

		http(`/api/v1/user/email-change`, {
			method: 'POST',
			data: { code: code }
				})
				.then((res) => {

					toast.success(
						<div className="toast-body">
							<CheckCircleOutlined className="toast__icon toast__icon--success" />
							<p className="toast-text">{`Email has been changed`}</p>
						</div>)
					setReturnLink('Return to profile settings');
				})
				.catch(err => {
					if (!!err.response && !!err.response.data && !!err.response.data.errors && !!err.response.data.errors.code) {
						message.error(err.response.data.errors.code)
					}
				});
	};

	return (
		<div>
			<Spin spinning={loading}>
				<div className="confrmWrapper">
					<div style={{margin: '0 auto'}}>
						<p className="confirmTitle" >Vänligen bekräfta ny e-post</p>
						<Form
							layout="vertical"
							form={form}
							onFinish={handleFinish}
							initialValues={currentUser}
							hideRequiredMark
						>
							<Form.Item>
								<div style={{width: 250, margin: '20px 0 0', display: 'flex', justifyContent: 'center'}}>
									<CustomButton 
										buttontype="type5"
										colortype="type8"
										size="large"
										htmlType="submit"
									>
										Bekräfta
									</CustomButton>
								</div>
							</Form.Item>
						</Form>
						<div style={{textAlign: 'center'}}>
							<a href="/profile" style={{fontSize: '13px', fontWeight: 400, color: 'rgb(145, 153, 170)'}}>
								{returnLink}
							</a>
						</div>
					</div>
				</div>
			</Spin>
		</div>


	);
};

const mapStateToProps = (store) => {
	return {
	  currentUser: store.me.profile,
	  loading: store.me.isLoading
	};
  };
  
  export default connect(mapStateToProps)(EmailConfirmation);