import React, { useState } from "react";
import { CustomButton } from "../../uikit/Button";
import { CustomInput } from "../../uikit/Input";

import { Text } from "../../uikit/Text";
import { CloseOutlined } from "@ant-design/icons";

const ResetPasswordEmail = (props) => {
	const { closePopup, onClick } = props;

	const [email, setEmail] = useState("");
	const [errors, setErrors] = useState({});

	return (
		<div className="loginPopup-container">
			<div className="loginPopup-loader small">
				<CloseOutlined onClick={closePopup} className="loginPopup-close"/>
				<p style={{ marginBottom: "20px" }}>
					<Text texttype="info" colortype="info">
						Ange email kopplad till ditt konto.
					</Text>
				</p>
				<CustomInput
					key="email"
					name="email"
					validateStatus={!!errors.email ? "error" : ""}
					help={!!errors.email ? String(errors.email) : null}
					inputprops={{
						placeholder: "Ange e-postadress",
						onChange: (e) => {
							setEmail(e.target.value);
							setErrors({ ...errors, name: "" });
						},
					}}
				/>
				<CustomButton
					buttontype="type4DarkWider"
					type="primary"
					onClick={() => {
						onClick(email, setErrors)
					}}
				>
					Skicka
				</CustomButton>
			</div>
		</div>
	);
};
export default ResetPasswordEmail;
