const initialState = {
    list: [],
	list_pagination: {},
	isLoaded_list: false,
	isFetching_list: false,

	admin: {},
	isLoaded_admin: false,
	isFetching_admin: false,

	banks: [],
	isFetching_banks: false,
	isLoaded_banks: false,
};

export function usersReducer(state = initialState, action) {
    switch (action.type) {
		case 'admins/setList':
            return {
                ...state,
                list: action.list,
				list_pagination: action.pagination,
                isLoaded_list: true,
            };

		case 'admins/showLoader':
			return {
				...state,
				isFetching_list: true,
			};

		case 'admins/hideLoader':
			return {
				...state,
				isFetching_list: false,
			};

		case 'users/setAdmin':
			return {
				...state,
				admin: action.admin,
				isLoaded_admin: true
			};

		case 'admin/showLoader':
			return {
				...state,
				isFetching_admin: true,
			};

		case 'admin/hideLoader':
			return {
				...state,
				isFetching_admin: false,
			};

		case 'users/setBanks':
			return {
				...state,
				banks: action.banks,
				banks_pagination: action.pagination,
				isLoaded_banks: true,
			};

		case 'users/setBank':
			return {
				...state,
				bank: action.bank,
				isLoaded_bank: true
			};

		case 'bank/showLoader':
			return {
				...state,
				isFetching_bank: true,
			};

		case 'bank/hideLoader':
			return {
				...state,
				isFetching_bank: false,
			};

        default:
            return state;
    }
}
