import React, { Component } from "react";
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import profile from '../../assets/website/profile.png';
import { Card, Row, Col, Spin } from 'antd';
import http from "../../api";
import NotificationsTable from "../Notifications/NotificationsTable";
import { fetchNotifications } from '../../actions/notifications';
import { fetchLoanOffers } from '../../actions/loan-offers';
import { fetchCreditRequests } from '../../actions/credit-requests';
import './styles.scss'

class Dashboard extends Component {
    state = {
        notifications: [],
        selectedNotifications: [],
        fullList: false,
        activeApplications: 0,
        isLoadingActiveApplications: false,
        activeLoanOffers: 0,
        isLoadingAactiveLoanOffers: false,
    }

    componentDidMount() {
        this.props.fetchCreditRequests();
        this.props.fetchLoanOffers();
        this.getActiveApplications();
        this.getActiveLoanOffers();
    }

    getActiveApplications = () => {
        this.setState({
            isLoadingActiveApplications: true
        })
        http(`/api/v1/application/count/active`, {
          method: 'GET'
        })
        .then((request) => {
            this.setState({
                isLoadingActiveApplications: false
            })
          if(!!request && !!request.data && !!request.data.data) {
            this.setState({
                activeApplications: request.data.data
            })
          }
        })
        .catch(err => {
            this.setState({
                isLoadingActiveApplications: false
            })
        });
      }

  
      getActiveLoanOffers = () => {
        this.setState({
            isLoadingAactiveLoanOffers: true
        })
        http(`/api/v1/application/loan-offers/count/active`, {
          method: 'GET'
        })
        .then((request) => {
            this.setState({
                isLoadingAactiveLoanOffers: false
            })
          if(!!request && !!request.data && !!request.data.data) {
            this.setState({
                activeLoanOffers: request.data.data
            })
          }
        })
        .catch(err => {
            this.setState({
                isLoadingAactiveLoanOffers: false
            })
        });
      }

    handleNotifications = () => {
        const {selectedNotifications, fullList} = this.state;

        if (!fullList && !!selectedNotifications) {
            this.setState({
                selectedNotifications: this.props.notifications,
                fullList: !fullList
            })
        } else if (fullList && !!selectedNotifications) {
            this.setState({
                selectedNotifications: this.props.notifications.slice(0, 4),
                fullList: !fullList
            })
        }
    }

    render() {
        const { user } = this.props;
        const { activeApplications, 
            isLoadingActiveApplications, 
            activeLoanOffers, 
            isLoadingAactiveLoanOffers 
        } = this.state;

        return (
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Card
                    className="dashboardCard"
                    style={{marginBottom: 30}}
                    bodyStyle={{
                        padding: '0',
                        borderRadius: 6
                    }}
                >
                    <div className="cardWrapper row">
                        {/* <div className="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-8 ant-col-lg-8 ant-col-xl-8"> */}
                        {/* <div className="col-6 col-lg-4 mb-4 dashboardTitlesWrapper">
                            <div className="title1" >Välkommen tillbaka till Bettre</div>
                            <Spin spinning={this.props.isLoadingUser}>
                                <div className="title2">{`${user?.firstName} ${user?.lastName}`}</div>
                            </Spin>
                        </div> */}
                        <Col xl={8} lg={8} md={8} sm={12} xs={24} className="dashboardTitlesWrapper">
                            <div className="title1" >Välkommen tillbaka till Bettre</div>
                            <Spin spinning={this.props.isLoadingUser}>
                                <div className="title2">{`${user?.firstName} ${user?.lastName}`}</div>
                            </Spin>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={10}>
                            <div>
                                <img className="dashboardLogo" src={profile} alt="user"/>
                            </div>
                        </Col>
                    </div>
                    <div className="card-body">
                        <Spin spinning={isLoadingActiveApplications}>
                            <Row className="bodyWrapper" >
                                <Col className="list" xl={12} lg={8} md={8} sm={12} xs={18}>
                                    <span>Du har</span>
                                    <span className="number">{activeApplications}</span>
                                    <span className="dashboardListText"> 
                                        {activeApplications > 1 ? 'aktiva kreditförfrågningar': 'aktiv kreditförfrågan'}
                                    </span>
                                </Col>
                                <Col className="cards" xs={4} style={{textAlign: 'start', marginLeft: '20px'}}>
                                    <span style={{color: '#00C1D4'}} className="paymentType">
                                        <Link to="/credit-request">{'Visa'}</Link>
                                    </span>
                                </Col>
                            </Row>
                        </Spin>
                        <Spin spinning={isLoadingAactiveLoanOffers}>
                            <Row className="bodyWrapper" style = {{ paddingBottom: "34px" }}>
                                <Col className="list" xl={12} lg={8}	md={8} sm={12} xs={18}>
                                    <span>Du har</span>
                                    <span className="number">{activeLoanOffers}</span>
                                    <span className="dashboardListText">obesvarade låneerbjudanden</span>
                                </Col>
                                <Col className="cards" xs={4} style={{textAlign: 'start', marginLeft: '20px'}}>
                                    <span className="paymentType">
                                        <Link to="/loan-offers">{'Visa'}</Link>
                                    </span>
                                </Col>
                            </Row>
                        </Spin>
                    </div>
                </Card>

                <Col className="card" xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Card
                        // loading={loading}
                        bodyStyle={{
                            padding: '0',
                            borderRadius: 6
                        }}
                    >
                    <div className="card-header" style={{paddingBottom: 0}}>Senaste notifieringarna </div>
                    <div className="card-body">
                        <NotificationsTable />
                    </div>
                    </Card>
                </Col>  
            </Col>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.me.profile,
        isLoadingUser: store.me.isLoading,
        notifications: store.notifications.list,
        isLoaded: store.notifications.isLoaded_list
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCreditRequests: () => dispatch(fetchCreditRequests()),
        fetchLoanOffers: () => dispatch(fetchLoanOffers()),
        fetchNotifications: (date) => dispatch(fetchNotifications(date)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));