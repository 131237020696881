import React from 'react';
import { Spin } from 'antd';

const DivStyle = {
    position: 'fixed',
    zIndex: '20000000',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

export default class GlobalLoader extends React.Component {

    render() {
        const isLoading = this.props.isLoading;
        return(
            <>
            { isLoading ? (
                <div
                    style={DivStyle}
                    className="loader"
                >
                    <Spin size="large" />
                </div>
                ) : ( <></> )
            }
            </>
        );
    }
}
