const initialState = {
    list: [],
	listPagination: {},
	isLoaded_list: false,
	isFetching_list: false,

	lastNotifications: [],
	isLoaded_lastNotifications: false,
	isFetching_lastNotifications: false,
};

export function notificationsReducer(state = initialState, action) {
    switch (action.type) {
		case 'notifications/setList':
            return {
                ...state,
                list: action.list.data,
				listPagination: action.list.pagination,
                isLoaded_list: true,
            };

		case 'notifications/showLoader':
			return {
				...state,
				isFetching_list: true,
			};

		case 'notifications/hideLoader':
			return {
				...state,
				isFetching_list: false,
			};

		case 'notifications/setLastNotifications':
			return {
				...state,
				lastNotifications: action.lastNotifications,
				isLoaded_lastNotifications: true
			};

		case 'lastNotifications/showLoader':
			return {
				...state,
				isFetching_lastNotifications: true,
			};

		case 'lastNotifications/hideLoader':
			return {
				...state,
				isFetching_lastNotifications: false,
			};

        default:
            return state;
    }
}
