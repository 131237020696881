import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
	ArrowLeftOutlined,
	PlusCircleOutlined,
	MinusCircleOutlined,
} from "@ant-design/icons";
import { Text } from "../../../../uikit/Text";
import { CustomButton } from "../../../../uikit/Button";
import { CustomInput } from "../../../../uikit/Input";
import { CustomSelect } from "../../../../uikit/Select";
import { TextArea } from "../../../../uikit/TextArea";
import { Form, Card, Row, Col } from "antd";
import LocalLoader from "../../../../components/Loaders/LocalLoader";
import { getQueryParam } from "../../../../utils/helpers";
import { connect } from "react-redux";
import { fetchCreditRequest } from "../../../../actions/credit-requests";
import { fetchCreditRequests } from "../../../../actions/credit-requests";
import { fetchLoanOffer } from "../../../../actions/loan-offers";
import { createLoanOffer } from "../../../../actions/loan-offers";
import { withRouter } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import LoanOfferGuarantors from "./LoanOfferGuarantors";
import LoanOfferOtherTerms from "./LoanOfferOtherTerms";
import { toast } from "react-toastify";
import { CheckCircleOutlined } from "@ant-design/icons";

import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";
import enGb from 'date-fns/locale/en-GB';
import Uploader from "./uploader";
registerLocale('en-gb', enGb);

const formItemLayout = {
	labelCol: {
		span: 5,
	},
	wrapperCol: {
		span: 19,
	},
};

const LoanOfferForm = (props) => {
	const { creditRequest } = props;
	const [form] = Form.useForm();
	const requiredFields = [
		"amount",
		"interest",
		"amortizationType",
		"securityTerms",
		"processingTime",
		"expiryDate",
	];
	const amountRef = useRef();
	const interestRef = useRef();
	const amortizationTypeRef = useRef();
	const securityTermsRef = useRef();
	const processingTimeRef = useRef();
	const expiryDateRef = useRef();
	requiredFields.forEach((field) => {});
	let id = "";
	if (!!getQueryParam("id")) {
		id = getQueryParam("id");
	}

	function scrollToAmountRef() {
		amountRef.current.scrollIntoView({ behavior: "smooth" });
	}
	function scrollToInterestRef() {
		interestRef.current.scrollIntoView({ behavior: "smooth" });
	}
	function scrollToAmortizationType() {
		amortizationTypeRef.current.scrollIntoView({ behavior: "smooth" });
	}
	function scrollToSecurityTermsRef() {
		securityTermsRef.current.scrollIntoView({ behavior: "smooth" });
	}
	function scrollToProcessingTimeRef() {
		processingTimeRef.current.scrollIntoView({ behavior: "smooth" });
	}
	function scrollToExpiryDateRef() {
		expiryDateRef.current.scrollIntoView({ behavior: "smooth" });
	}
	const [data, setData] = useState({});
	const [amortizationType, setAmortizationType] = useState("");
	const [amortizationIntervals, setAmortizationIntervals] = useState("");
	const [guarantorType, setGuarantorType] = useState({});
	const [guaranteeType, setGuaranteeType] = useState("");
	const [startDate, setStartDate] = useState(null);
	const [noLoanFee, setNoLoanFee] = useState(false);
	const [errors, setErrors] = useState({});

	const creditRequestId = getQueryParam("id");

	useEffect(() => {
		props.fetchCreditRequests();
		props.fetchCreditRequest(id).then((res) => {
			if (
				!!res.creditRequest.loanOffer &&
				!!res.creditRequest.loanOffer.noFeeType
			) {
				setNoLoanFee(res.creditRequest.loanOffer.noFeeType);
			}

			if (
				!!res.creditRequest.loanOffer &&
				!!res.creditRequest.loanOffer.expiryDate
			) {
				setStartDate(new Date(res.creditRequest.loanOffer.expiryDate));
			}

			if (
				!!res.creditRequest.loanOffer &&
				!!res.creditRequest.loanOffer.amortizationType
			) {
				setAmortizationType(
					res.creditRequest.loanOffer.amortizationType
				);
			}
			if (
				!!res.creditRequest.loanOffer &&
				!!res.creditRequest.loanOffer.amortizationIntervals
			) {
				setAmortizationIntervals(
					res.creditRequest.loanOffer.amortizationIntervals
				);
			}
			if (
				!!res.creditRequest.loanOffer &&
				!!res.creditRequest.loanOffer.guarantors
			) {
				let guarantorType =
					res.creditRequest.loanOffer.guarantors.reduce(
						(acc, guarantor, index) => ({
							...acc,
							[index]:
								!!guarantor && !!guarantor.guarantorType
									? guarantor.guarantorType
									: null,
						}),
						{}
					);

				setGuarantorType(guarantorType);

				let guaranteeType =
					res.creditRequest.loanOffer.guarantors.reduce(
						(acc, guarantor, index) => ({
							...acc,
							[index]:
								!!guarantor && !!guarantor.guaranteeType
									? guarantor.guaranteeType
									: null,
						}),
						{}
					);

				setGuaranteeType(guaranteeType);
			}
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!!creditRequest.loanOffer) {
			const feeTypes =
				!!creditRequest.loanOffer &&
				!!creditRequest.loanOffer.feeTypes &&
				!!creditRequest.loanOffer.feeTypes.length
					? creditRequest.loanOffer.feeTypes.map((feeType) => {
							if (!!feeType) {
								return {
									...feeType,
									feeAmount: !!feeType.feeAmount
										? feeType.feeAmount
												.toString()
												.replace(
													/\B(?=(\d{3})+(?!\d))/g,
													","
												)
										: null,
								};
							} else return null;
					  })
					: null;

			const guarantors =
				!!creditRequest.loanOffer &&
				!!creditRequest.loanOffer.guarantors &&
				!!creditRequest.loanOffer.guarantors.length
					? creditRequest.loanOffer.guarantors.map((guarantor) => {
							if (!!guarantor) {
								return {
									...guarantor,
									guaranteeAmount:
										!!guarantor &&
										!!guarantor.guaranteeAmount
											? guarantor.guaranteeAmount
													.toString()
													.replace(
														/\B(?=(\d{3})+(?!\d))/g,
														","
													)
											: null,
								};
							} else return null;
					  })
					: null;

			setData({
				...props.creditRequest.loanOffer,
				amount:
					!!props.creditRequest.loanOffer &&
					!!props.creditRequest.loanOffer.amount
						? props.loanOffer.amount
								.toString()
								.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						: null,
				feeTypes,
				guarantors,
			});

			form.setFieldsValue({
				...creditRequest.loanOffer,
				amount:
					!!creditRequest.loanOffer &&
					!!creditRequest.loanOffer.amount
						? creditRequest.loanOffer.amount
								.toString()
								.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						: null,
				feeTypes,
				guarantors,
			});
		}
	}, [creditRequest]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleDateChange = (date) => {
		setStartDate(date);
		setErrors({ ...errors, expiryDate: "" });
	};

	const onSubmit = () => {
		let values = form.getFieldsValue();
		let otherTerms = !!values.otherTerms
			? values.otherTerms.map((item, index) => {
					return {
						...item,
						otherTermId: index,
					};
			  })
			: null;

		const feeTypes = !!values.feeTypes
			? values.feeTypes.map((feeType) => {
					if (!!feeType) {
						return {
							...feeType,
							feeAmount:
								!!feeType.feeAmount &&
								!!feeType.feeAmount.length
									? feeType.feeAmount
											.toString()
											.replace(/\D/g, "")
									: null,
						};
					} else return {};
			  })
			: null;

		const guarantors =
			!!values.guarantors && !!values.guarantors.length
				? values.guarantors.map((guarantor) => {
						if (!!guarantor) {
							return {
								...guarantor,
								guaranteeAmount: !!guarantor.guaranteeAmount
									? guarantor.guaranteeAmount
											.toString()
											.replace(/\D/g, "")
									: null,
							};
						} else return {};
				  })
				: null;

		const updatedvalues = {
			...values,
			creditRequest,
			status: "CREATED",
			otherTerms,
			noFeeType: noLoanFee,
			expiryDate: !!startDate
				? new Date(startDate)
						.toLocaleDateString("sv-SE")
						.split(".")
						.join("-")
				: null,
			amount: values["amount"]
				? Number(values["amount"].toString().replace(/\D/g, ""))
				: null,
			interest: values["interest"]
				? Number(values["interest"].toString().replace(/,/g, "."))
				: null,
			feeTypes,
			guarantors,
		};

		props
			.createLoanOffer(creditRequestId, updatedvalues)
			.then((res) => {
				props.history.push(`/loan-offers/detail?id=${id}`);
				toast.success(
					<div className="toast-body">
						<CheckCircleOutlined className="toast__icon toast__icon--success" />
						<p className="toast-text">
							<span style={{ fontWeight: 700 }}>Skickat</span>{" "}
							<br /> Ditt låneerbjudande har nu skickats till
							kredittagaren
						</p>
					</div>
				);
			})
			.catch((errors) => {
				if (
					!!errors &&
					!!errors.response &&
					!!errors.response.data &&
					!!errors.response.data.errors
				) {
					setErrors(errors.response.data.errors);
					if (errors.response.data.errors.amount) {
						scrollToAmountRef();
					} else if (errors.response.data.errors.interest) {
						scrollToInterestRef();
					} else if (
						errors.response.data.errors.amortizationType ||
						errors.response.data.errors.amortizationIntervals
					) {
						scrollToAmortizationType();
					} else if (errors.response.data.errors.securityTerms) {
						scrollToSecurityTermsRef();
					} else if (errors.response.data.errors.processingTime) {
						scrollToProcessingTimeRef();
					} else if (errors.response.data.errors.expiryDate) {
						scrollToExpiryDateRef();
					}

					let keys = Object.keys(errors.response.data.errors);
					const label = document.querySelector(
						`label[for="${keys[0]}"]`
					);

					if (!!label) {
						label.scrollIntoView();
					}
				}
			});
	};

	return (
		<div style={{ position: "relative" }}>
			<div className="goBack">
				<div
					style={{ paddingBottom: 30 }}
					className={"backLink backBtn titleWrapper"}
				>
					<Link
						to={{
							pathname: "/loan-offers/detail",
							search: `?id=${id}`,
						}}
						style={{
							float: "left",
							paddingTop: 5,
							fontSize: 13,
							fontWeight: 400,
							color: "#9199AA",
						}}
					>
						<ArrowLeftOutlined style={{ marginRight: 14 }} />
						Gå tillbaka
					</Link>
					<h2
						className="text-center"
						style={{ marginBottom: "30px" }}
					>
						Mina låneerbjudanden
					</h2>
					<h2
						className="text-center"
						style={{ marginBottom: "10px" }}
					>
						Skapa bud
					</h2>
				</div>
			</div>
			<Row className="dashboard" gutter={24}>
				<Col
					xl={24}
					lg={24}
					md={24}
					sm={24}
					xs={24}
					style={{ marginBottom: 30 }}
				>
					<Card
						className="cardDetaill"
						style={{
							marginBottom: 30,
							minHeight: "100px",
							height: "100%",
						}}
						bodyStyle={{
							padding: "28",
							borderRadius: 6,
						}}
					>
						<LocalLoader
							loading={props.isFetching_creditRequest}
							height="10vh"
						>
							<Text
								texttype="back"
								colortype="back"
								customclass="cardTitle"
							>
								Grunduppgifter
							</Text>
							<Form
								{...formItemLayout}
								form={form}
								// layout="vertical"
								className="stepForm"
								hideRequiredMark
								initialValues={data}
								onFinish={onSubmit}
								scrollToFirstError
							>
								<Row ref={amountRef} style={{ width: "100%" }}>
									<Col style={{ maxWidth: "264px" }}>
										<CustomInput
											name="amount"
											key="amount"
											label="Erbjudet lånebelopp"
											validateStatus={
												!!errors.amount ? "error" : ""
											}
											help={
												!!errors.amount
													? String(errors.amount)
													: null
											}
											inputprops={{
												suffix: "SEK",
												placeholder: "Fyll i summa",
												maxLength: 25,
												onChange: (e) => {
													const { value } = e.target;
													let newval = !!value
														? value.replace(
																/\D/g,
																""
														  )
														: "";
													form.setFieldsValue({
														amount: newval.replace(
															/\B(?=(\d{3})+(?!\d))/g,
															","
														),
													});
													setErrors({
														...errors,
														amount: "",
													});
												},
											}}
										/>
									</Col>
								</Row>
								<Row
									ref={interestRef}
									style={{ width: "100%" }}
								>
									<Col style={{ maxWidth: "264px" }}>
										<CustomInput
											name="interest"
											key="interest"
											label="Ange ränta för lånet"
											validateStatus={
												!!errors.interest ? "error" : ""
											}
											help={
												!!errors.interest
													? String(errors.interest)
													: null
											}
											inputprops={{
												suffix: "%",
												placeholder: "Ange räntesats",
												maxLength: 5,
												onChange: (e) => {
													setErrors({
														...errors,
														interest: "",
													});
												},
											}}
										/>
									</Col>
								</Row>
								<Row style={{ flexDirection: "column" }}>
									<Col style={{ maxWidth: "264px" }}>
										<p
											style={{
												fontWeight: 700,
												fontSize: "18px",
												color: "#2a2b2d",
												lineHeight: "23px",
											}}
										>
											Ange avgift för lånet
										</p>
										{
											<Form.List
												name="feeTypes"
												key="feeTypes"
											>
												{(fields, { add, remove }) => {
													return (
														<div className="listWrapper">
															<CustomButton
																buttontype="type8"
																colortype="type8"
																size="large"
																style={{
																	margin: "0 0 1rem 0",
																	width: "100%",
																	display:
																		"flex",
																	alignItems:
																		"center",
																	border: "2px solid transparent",
																	boxShadow:
																		"none",
																}}
																onClick={(
																	e
																) => {
																	add();
																}}
															>
																Lägg till
																<PlusCircleOutlined
																	style={{
																		color: "#008aaa",
																	}}
																/>
															</CustomButton>

															{fields.map(
																(
																	field,
																	index
																) => {
																	return (
																		<div
																			key={
																				field.key
																			}
																			style={{
																				display:
																					"flex",
																				marginBottom: 8,
																			}}
																			align="start"
																			className="listWrapper"
																		>
																			<div>
																				{/* {index !== 0 && (<Divider className="formDivider" style={{marginTop: '0'}} />)} */}
																				<CustomSelect
																					name={[
																						field.name,
																						"feeType",
																					]}
																					fieldKey={[
																						field.fieldKey,
																						"feeType",
																					]}
																					inputprops={{
																						size: "large",
																						placeholder:
																							"Välj ett utav",
																						style: {
																							minWidth:
																								"100%",
																						},
																					}}
																					options={[
																						{
																							value: "",
																							label: "Välj avgiftstyp",
																						},
																						{
																							value: "Uppläggningsavgift",
																							label: "Uppläggningsavgift",
																						},
																						{
																							value: "Limitavgift",
																							label: "Limitavgift",
																						},
																						{
																							value: "Aviavgift",
																							label: "Aviavgift",
																						},
																						{
																							value: "Administrationsavgift",
																							label: "Administrationsavgift",
																						},
																					]}
																				/>
																				<CustomInput
																					name={[
																						field.name,
																						"feeAmount",
																					]}
																					fieldKey={[
																						field.fieldKey,
																						"feeAmount",
																					]}
																					inputprops={{
																						suffix: "SEK",
																						placeholder:
																							"Fyll i summa",
																						// style: {minWidth: '264px'},
																						style: {
																							minWidth:
																								"100%",
																						},
																						maxLength: 10,
																						onChange:
																							(
																								e
																							) => {
																								const {
																									value,
																								} =
																									e.target;
																								let newval =
																									!!value
																										? value.replace(
																												/\D/g,
																												""
																										  )
																										: "";
																								const feeTypes =
																									form.getFieldValue(
																										"feeTypes"
																									);

																								Object.assign(
																									feeTypes[
																										field
																											.key
																									],
																									{
																										feeAmount:
																											newval.replace(
																												/\B(?=(\d{3})+(?!\d))/g,
																												","
																											),
																									}
																								);
																								form.setFieldsValue(
																									{
																										feeTypes,
																									}
																								);
																							},
																					}}
																				/>
																			</div>

																			<CustomButton
																				buttontype="type8"
																				colortype="type8"
																				size="large"
																				style={{
																					margin: "0 0 20px 10px",
																					maxWidth:
																						"40px",
																					padding:
																						"0",
																					display:
																						"flex",
																					alignItems:
																						"center",
																					justifyContent:
																						"center",
																					border: "2px solid transparent",
																					boxShadow:
																						"none",
																				}}
																				onClick={() =>
																					remove(
																						field.name
																					)
																				}
																			>
																				<MinusCircleOutlined />
																			</CustomButton>
																		</div>
																	);
																}
															)}
														</div>
													);
												}}
											</Form.List>
										}
									</Col>
								</Row>
								<Text
									texttype="back"
									colortype="back"
									customclass="cardTitle"
								>
									Amortering och löptid
								</Text>
								<Row ref={amortizationTypeRef}>
									<Col
										xl={24}
										lg={24}
										md={24}
										sm={24}
										xs={24}
										className="amorteringList"
									>
										<CustomSelect
											key="amortizationType"
											label="Ange amorteringstyp och amorteringstakt"
											name="amortizationType"
											validateStatus={
												!!errors.amortizationType
													? "error"
													: ""
											}
											help={
												!!errors.amortizationType
													? String(
															errors.amortizationType
													  )
													: null
											}
											inputprops={{
												size: "large",
												placeholder:
													"Välj amorteringstyp",
												style: { maxWidth: "264px" },
												onChange: (val) => {
													setAmortizationType(val);
													setErrors({
														...errors,
														amortizationType: "",
													});
												},
											}}
											options={[
												{
													value: "Amorteringsfritt",
													label: "Amorteringsfritt",
												},
												{
													value: "Rak amortering",
													label: "Rak amortering",
												},
												{
													value: "Annuitet",
													label: "Annuitet",
												},
												{
													value: "Anpassad",
													label: "Anpassad",
												},
												{
													value: "Bullet",
													label: "Bullet",
												},
											]}
										/>
										{amortizationType === "Anpassad" && (
											<CustomInput
												key="amortizationCustomType"
												name="amortizationCustomType"
												inputprops={{
													placeholder:
														"Anpassad amorteringstyp",
													style: {
														maxWidth: "264px",
													},
												}}
											/>
										)}
										{!!amortizationType &&
											amortizationType !==
												"Amorteringsfritt" &&
											amortizationType !== "Bullet" && (
												<CustomSelect
													key="amortizationIntervals"
													name="amortizationIntervals"
													validateStatus={
														!!errors.amortizationIntervals
															? "error"
															: ""
													}
													help={
														!!errors.amortizationIntervals
															? String(
																	errors.amortizationIntervals
															  )
															: null
													}
													inputprops={{
														size: "large",
														placeholder:
															"Välj amorteringstakt",
														style: {
															maxWidth: "264px",
														},
														onChange: (val) => {
															setAmortizationIntervals(
																val
															);
															setErrors({
																...errors,
																amortizationIntervals:
																	"",
															});
														},
													}}
													options={[
														{
															value: "",
															label: "Välj amorteringstakt",
														},
														{
															value: "Månad",
															label: "Månad",
														},
														{
															value: "Kvartal",
															label: "Kvartal",
														},
														{
															value: "Anpassad",
															label: "Anpassad",
														},
													]}
												/>
											)}
										{!!amortizationType &&
											amortizationIntervals ===
												"Anpassad" && (
												<CustomInput
													key="amortizationCustomIntervals"
													name="amortizationCustomIntervals"
													inputprops={{
														placeholder:
															"Anpassad amorteringstakt",
														style: {
															maxWidth: "264px",
														},
													}}
												/>
											)}
									</Col>
								</Row>
								<Row>
									<CustomInput
										label="Löptid"
										key="duration"
										name="duration"
										inputprops={{
											placeholder: "Månader",
											style: { maxWidth: "264px" },
											maxLength: 3,
										}}
									/>
								</Row>

								<Text
									texttype="back"
									colortype="back"
									customclass="cardTitle"
									style={{ marginTop: "10px" }}
								>
									Säkerhet och borgen
								</Text>
								<Row ref={securityTermsRef}>
									<Col
										xl={24}
										lg={24}
										md={24}
										sm={24}
										xs={24}
									>
										<TextArea
											key="securityTerms"
											name="securityTerms"
											label="Vilka säkerheter kräver ni för krediten"
											validateStatus={
												!!errors.securityTerms
													? "error"
													: ""
											}
											help={
												!!errors.securityTerms
													? String(
															errors.securityTerms
													  )
													: null
											}
											inputprops={{
												rows: 3,
												placeholder:
													"Fyll i villkor för säkerhet",
												style: { resize: "none" },
												autoSize: true,
												onChange: (e) => {
													setAmortizationIntervals(
														e.target.value
													);
													setErrors({
														...errors,
														securityTerms: "",
													});
												},
											}}
										/>
									</Col>
								</Row>
								<p
									style={{
										fontWeight: 700,
										fontSize: "18px",
										color: "#2a2b2d",
										lineHeight: "23px",
									}}
								>
									Borgensman
								</p>
								<LoanOfferGuarantors
									form={form}
									guarantorTypeForm={guarantorType}
									guaranteeTypeForm={guaranteeType}
								/>
								<Text
									texttype="back"
									colortype="back"
									customclass="cardTitle"
									style={{ marginTop: "24px" }}
								>
									{"Handläggning & villkor"}
								</Text>
								<Row ref={processingTimeRef}>
									<Col
										xl={24}
										lg={24}
										md={24}
										sm={24}
										xs={24}
									>
										<CustomInput
											key="processingTime"
											name="processingTime"
											label="Handläggningstid"
											validateStatus={
												!!errors.processingTime
													? "error"
													: ""
											}
											help={
												!!errors.processingTime
													? String(
															errors.processingTime
													  )
													: null
											}
											inputprops={{
												placeholder:
													"Ange antal arbetsdagar",
												style: { maxWidth: "264px" },
												onChange: (e) => {
													const { value } = e.target;
													form.setFieldsValue({
														processingTime:
															value.replace(
																/[^0-9]+/g,
																""
															),
													});
													setErrors({
														...errors,
														processingTime: "",
													});
												},
											}}
										/>
									</Col>
									<Col
										className="dateWrapper"
										style={{ maxWidth: "264px" }}
									>
										<p
											ref={expiryDateRef}
											style={{
												fontWeight: 700,
												fontSize: "18px",
												color: "#2a2b2d",
												lineHeight: "23px",
											}}
										>
											Ange giltighetstid erbjudande
										</p>
										<DatePicker
											className={`date-picker ${
												!!errors.expiryDate
													? "date-picker-error"
													: null
											}`}
											calendarClassName="date-picker-calendar"
											selected={startDate}
											locale="en-gb"
											dateFormat="yyyy-MM-dd"
											placeholderText="yyyy-MM-dd"
											onChange={handleDateChange} //only when value has changed
										/>
										{!!errors.expiryDate && (
											<span className="date-picker-error-helper">
												{!!errors.expiryDate &&
													errors.expiryDate[0]}
											</span>
										)}
									</Col>
								</Row>
								<LoanOfferOtherTerms form={form} />
								<Row style={{marginBottom: '20px'}}>
									<Col
										xl={24}
										lg={24}
										md={24}
										sm={24}
										xs={24}
									>
										<Uploader creditRequest={creditRequest} />
									</Col>
								</Row>
							</Form>
						</LocalLoader>

						<div className="rowbuttons">
							<Link to={`${"/loan-offers/detail/edit?id=" + id}`}>
								<CustomButton
									buttontype="type5"
									colortype="type8"
									size="large"
									style={{
										margin: "0 10px 20px",
										minWidth: "270px",
									}}
									onClick={onSubmit}
								>
									Lämna bud / Uppdatera bud
								</CustomButton>
							</Link>
						</div>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

const mapStateToProps = (store) => {
	return {
		creditRequest: store.creditRequests.creditRequest,
		loanOffer: store.creditRequests.creditRequest.loanOffer,
		isFetching_creditRequest: store.creditRequests.isFetching_creditRequest,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createLoanOffer: (id, data) => dispatch(createLoanOffer(id, data)),
		fetchLoanOffer: (id) => dispatch(fetchLoanOffer(id)),
		fetchCreditRequest: (id) => dispatch(fetchCreditRequest(id)),
		fetchCreditRequests: () => dispatch(fetchCreditRequests()),
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(LoanOfferForm)
);
