import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import avatarIcon from "../../assets/formicons/people.svg"
import './index.scss';
import { Tab } from "bootstrap"; // eslint-disable-line
import {  UserOutlined, MailOutlined, QuestionCircleOutlined } from '@ant-design/icons';

class AvatarDropdownLogin extends Component {

    render() {

        return (
            <div className="btn-group">
                <button 
                    type="button"
                    className="btn-secondary header-avatar-btn"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <img src={avatarIcon} className="avatar-icon" alt="avatar"/>
                </button>
                <ul className="dropdown-menu">
                    <li>
                        <a 
                            className="dropdown-link dropdown-item"
                            href="/profile"
                        >
                            <UserOutlined className="dropdown-link-icon"/>
                            Profile
                        </a>
                    </li>
                    <li>
                        <hr 
                            className="dropdown-divider"
                            style={{margin: 0, border: 'none', height: '2px', backgroundColor: 'rgba(255, 255, 255, 0.6)'}}
                        />
                    </li>
                    <li>
                        <hr 
                            className="dropdown-divider"
                            style={{margin: 0, border: 'none', height: '2px', backgroundColor: 'rgba(255, 255, 255, 0.6)'}}
                        />
                    </li>
                    <li>
                        <a 
                            className="dropdown-link dropdown-item"
                            href="/forms/newloan"
                        >
                            <QuestionCircleOutlined className="dropdown-link-icon" />
					        FAQ
                        </a>
                    </li>
                    <li>
                        <hr 
                            className="dropdown-divider"
                            style={{margin: 0, border: 'none', height: '2px', backgroundColor: 'rgba(255, 255, 255, 0.6)'}}
                        />
                    </li>
                    <li>
                        <a 
                            className="dropdown-link dropdown-item dropdown-item__last"
                            href="/dashboard"
                        >
                            <MailOutlined className="dropdown-link-icon"/>
					        Contact us
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        currentUser: store.me.currentUser
    }
}

export default withRouter(connect(
    mapStateToProps
)(AvatarDropdownLogin))
