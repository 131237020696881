import React, { Component } from "react";
import "./MenuBanks.scss"
import { Link } from "react-router-dom";
import { ReactComponent as HomeIcon } from '../../../assets/admin/home.svg';
import { ReactComponent as EnquiryIcon } from '../../../assets/admin/enquiry.svg';
import { ReactComponent as ClipboardIcon } from '../../../assets/admin/clipboard.svg';
import { ReactComponent as DollarIcon } from '../../../assets/notifications/dollar.svg';

class Menu extends Component {
    
    render() {

        const path = window.location.pathname;

        return (
            <>
                <ul className="leftPanel-menu">
                    <li 
                        className={`leftPanel-menu-item ${path === '/dashboard' ? 'leftPanel-menu-item-selected' : ''}`}
                    >
                        <Link 
                            to={`/dashboard`}
                            className={`leftPanel-menu-item-link ${path === '/dashboard' ? 'menu-item-selected' : ''}`}
                        >
                            <HomeIcon style={{marginRight: '12px'}} />
                            <span>Hem/Översikt</span>
                        </Link>
                    </li>
                    <li 
                        className={`leftPanel-menu-item ${path.includes('/credit-request') ? 'leftPanel-menu-item-selected' : ''}`}
                    >
                        <Link 
                            to={`/credit-request`}
                            className="leftPanel-menu-item-link"
                        >
                            <EnquiryIcon style={{marginRight: '12px'}} />
                            <span>Kreditförfrågan</span>
                        </Link>
                    </li>
                    <li 
                        className={`leftPanel-menu-item ${path.includes('/loan-offers') ? 'leftPanel-menu-item-selected' : ''}`}
                    >
                        <Link 
                            to={`/loan-offers`}
                            className="leftPanel-menu-item-link"
                        >
                            <ClipboardIcon style={{marginRight: '12px'}} />
                            <span>Mina låneerbjudanden</span>
                        </Link>
                    </li>
                    <li 
                        className={`leftPanel-menu-item ${path.includes('/credit/list')
                            || path.includes('/credit/detail')
                            ? 'leftPanel-menu-item-selected' 
                            : ''}`}
                    >
                        <Link 
                            to={`/credit/list`}
                            className="leftPanel-menu-item-link"
                        >
                            <DollarIcon style={{marginRight: '12px'}} />
                            <span>Min utlåning</span>
                        </Link>
                    </li>
                </ul>

                <div className="text-center mt-5 call">
                    <div className="callTitle">Ring bettre</div>
                    <h2>010 - 424 80 80</h2>
                    <div className="callImg">
                        <img src='/admin/callImg.png' alt="calling girl"/>
                    </div>
                </div>

            </>
        );
    }
}

export default Menu;