import React, { Component } from 'react';
import { Menu } from 'antd';
import { withRouter } from "react-router-dom";
import BaseProfile from './BaseProfile';
import SecurityProfile from './SecurityProfile';
import EmailProfile from './EmailProfile';
import { connect } from 'react-redux';
import './style.scss';

const { Item } = Menu;

class Profile extends Component {
	main = undefined;

	constructor(props) {
		super(props);
		const menuMap = {
			base: 'Profil',
			security: 'Lösenord',
			email: 'E-post'
		};
		this.state = {
			mode: 'horizontal',
			menuMap,
			selectKey: 'base'
		};
	}

	componentDidMount() {
		window.addEventListener('resize', this.resize);
    this.resize();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.resize);
	}

	getMenu = () => {
		const { menuMap } = this.state;
		return Object.keys(menuMap).map((item) => <Item key={item} className="profileMenuItem">{menuMap[item]}</Item>);
	};

	getRightTitle = () => {
		const { selectKey, menuMap } = this.state;
		return menuMap[selectKey];
	};

	selectKey = (key) => {
		this.setState({
			selectKey: key
		});
	};

	resize = () => {
		if (!this.main) {
			return;
		}

		requestAnimationFrame(() => {
			if (!this.main) {
				return;
			}

			let mode = 'inline';
			const { offsetWidth } = this.main;

			if (this.main.offsetWidth < 641 && offsetWidth > 400) {
				mode = 'horizontal';
			}

			if (window.innerWidth < 768 && offsetWidth > 400) {
				mode = 'horizontal';
			}

			this.setState({
				mode
			});
		});
	};

	renderChildren = () => {
		const { selectKey } = this.state;

		switch (selectKey) {
			case 'base':
				return <BaseProfile />;

			case 'security':
				return <SecurityProfile />;

			case 'email':
				return <EmailProfile />;

			default:
				break;
		}

		return null;
	};

	render() {
		const { currentUser } = this.props;

		if (!currentUser.id) {
			return '';
		}

		const { mode, selectKey } = this.state;
		return (
			<div>
				<div
					className="main"
					ref={(ref) => {
						if (ref) {
							this.main = ref;
						}
					}}
				>
					<div className="leftMenu">
						<Menu 
							mode={mode}
							className="profileMenuList"
							selectedKeys={[ selectKey ]} 
							onClick={({ key }) => this.selectKey(key)}
						>
							{this.getMenu()}
						</Menu>
					</div>
					<div className="profile-right">
						<div className="profile-title">{this.getRightTitle()}</div>
						{this.renderChildren()}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (store) => {
  return {
    currentUser: store.me.profile
  };
};

export default withRouter(connect(mapStateToProps)(Profile));
