import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CustomButton } from "./Button";
import { getQueryParam } from "../utils/helpers";
import { CloseCircleOutlined } from "@ant-design/icons";

export const Search = (props) => {
	const { search, setSearch, fetchData } = props;
	// const [search, setSearch] = useState("");
	const history = useHistory();

	useEffect(() => {
		let search = getQueryParam("search");
		setSearch(search === null || search === undefined ? "" : search);
	}, []);

	const onChange = (e) => {
		let value = e.target.value;
		setSearch(value);
		history.push({
			search: value !== "" ? `search=${value}` : "",
		});
	};

	const onFocus = (e) => {
		e.target.select();
	};

	const onClear = () => {
		setSearch("");
		history.push({
			search: "",
		});
	};

	const onEnter = (e) => {
		if (e.key === "Enter") {
			fetchData();
		}
	};

	return (
		<div className="search-wrapper">
			<input
				className={`search-input ${
					search === "" ? "" : "search-active"
				}`}
				value={search}
				onChange={onChange}
				onFocus={onFocus}
				type="search"
				name="search"
				placeholder="Fyll i"
				onKeyDown={onEnter}
			></input>
			<CloseCircleOutlined
				width="20px"
				height="20px"
				className="search-input-clear"
				onClick={onClear}
			/>
			<button onClick={fetchData} className="search-button">
				Sök
			</button>
		</div>
	);
};

export default Search;
