import React from "react";
import { Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import "./index.scss";

const MenuBtn = (props) => {

	return (
		<div className="menuButton" style={{marginLeft: '10px'}}>
			<Button
				type="primary"
				icon={<MenuOutlined />}
				onClick={() => props.handleCollapsedMenu(false)}
			/>
		</div>
	)
}

export default MenuBtn;