import React, { Component } from "react";
import { Spin } from 'antd';
import "./index.scss";

// const DivStyle = {
//     position: 'fixed',
//     zIndex: '20000000',
//     top: '0',
//     left: '0',
//     right: '0',
//     bottom: '0',
//     backgroundColor: '#fff',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center'
// };

export default class LocalLoader extends Component {

    render() {
        const { loading, height, children } = this.props;

        return (
            <div>
                {loading ? (
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={height ? {minHeight: height } : {}}
                    >
                        <Spin size="large" />
                        {/* <div className="spinner-grow" style={{width: "3rem", height: "3rem"}} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div> */}

                    </div>
                ) : (
                    <div>
                        {children}
                    </div>
                )}
            </div>
        );
    }
}