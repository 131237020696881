import React, { useState, useEffect } from "react";
import {
	Form,
	// Spin,
	Row,
	Col,
	Checkbox,
} from "antd";
import { withRouter } from "react-router-dom";
import { CustomInput } from "../../../../../uikit/Input";
import { CustomSelect } from "../../../../../uikit/Select";
import { connect } from "react-redux";
import http from "../../../../../api";
import { getQueryParam } from "../../../../../utils/helpers";
import { CustomButton } from "../../../../../uikit/Button";
import { TextArea } from "../../../../../uikit/TextArea";
import { fetchUsersAdmins } from "../../../../../actions/users/admins";
import "./style.scss";
import LogoUploader from "../../../../../uikit/LogoUploader";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { CSSTransition } from 'react-transition-group';

const formItemLayout = {
	labelCol: {
		span: 5,
	},
	wrapperCol: {
		span: 19,
	},
};

const BusinessInfoForm = (props) => {
	const { company, form, admins, fetchUsersAdmins, me } = props;
	const [errors, setErrors] = useState({});
	const [id, setId] = useState({});
	const [isFollowUp, setFollowUp] = useState(false);
	const [followUpDate, setFollowUpDate] = useState(null);
	useEffect(() => {
		if (!!company) {
			form.setFieldsValue({
				adminId: company.adminId,
				name: company.name,
				companyNumber: company.companyNumber,
				address: company.address,
				zip: company.zip,
				city: company.city,
				type: company.type,
				status: company.status,
				description: company.description,
				followUpCheck: isFollowUp,
				followUpDate: company.followUpDate,
				followUpComment: company.followUpComment,
				businessProbability: company.businessProbability,
				quality: company.quality
			});
			setFollowUpDate(company.followUpDate);
			setFollowUp(company.followUpCheck)
		}

		if (!!getQueryParam("id")) {
			setId(getQueryParam("id"));
		}
	}, [company, form, id]);

	useEffect(() => {
		if (admins?.length === 0) fetchUsersAdmins(0, 2147483647);
	}, []);

	const onSubmit = (e) => {
		e.preventDefault()
		let values = form.getFieldsValue();

		http(`/api/v1/company/${id}`, {
			method: "put",
			data: {
				...values,
				country: company.country,
				structureDescription: company.structureDescription,
				previousProjects: company.previousProjects,
				followUpCheck: isFollowUp,
				followUpDate: !!followUpDate
					? new Date(followUpDate)
							.toLocaleDateString("sv-SE")
							.split(".")
							.join("-")
					: null,
			},
		})
			.then((res) => {
				props.history.push(`${"/companies/detail?id=" + id}`);
			})
			.catch((err) => {
				if (!!err?.response?.data?.errors) {
					setErrors(err.response.data.errors);
				}
			});
	};

	let selectAdmins =
		admins.length > 0 && !!me
			? admins
					?.filter((a) => a.id !== me.id)
					?.map((a) => {
						return { value: a.id, label: a.name };
					})
			: [];
	selectAdmins.unshift({ value: me.id, label: "Jag" });

	// let selectAdmins = ((admins.length > 0 && !!me) ? admins?.map( a => {
	//   return { value: a.id, label: a.name }
	// }) : [])

	const onCheck = () => {
		setFollowUp(!isFollowUp);
	};

	const handleDateChange = (date) => {
		setFollowUpDate(date);
		setErrors({ ...errors, followUpDate: "" });
	};
	console.log(followUpDate)
	return (
		<>
			<Form
				{...formItemLayout}
				form={form}
				layout="vertical"
				className="companyForm"
				hideRequiredMark
				initialValues={props.company}
				// initialValues={data}
				onFinish={onSubmit}
			>
				<Row>
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<p
							style={{
								fontWeight: 700,
								fontSize: "18px",
								color: "#2a2b2d",
								lineHeight: "23px",
							}}
						>
							Lägg till uppgifter om företaget nedan
						</p>
						<CustomInput
							name="name"
							key="name"
							label="Företagsnamn"
							validateStatus={!!errors.name ? "error" : ""}
							help={!!errors.name ? String(errors.name) : null}
							inputprops={{
								placeholder: "Ange företagsnamn",
								onChange: (e) => {
									setErrors({ ...errors, name: "" });
								},
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<CustomInput
							name="companyNumber"
							key="companyNumber"
							label="Org. nr"
							validateStatus={
								!!errors.companyNumber ? "error" : ""
							}
							help={
								!!errors.companyNumber
									? String(errors.companyNumber)
									: null
							}
							inputprops={{
								placeholder: "Ange org. nr 10 siffror",
								maxLength: 10,
								onChange: (e) => {
									const { value } = e.target;
									form.setFieldsValue({
										companyNumber: value,
									});
									setErrors({
										...errors,
										companyNumber: "",
									});
								},
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<CustomInput
							name="website"
							key="website"
							label="Hemsida"
							validateStatus={!!errors.website ? "error" : ""}
							help={
								!!errors.companyNumber
									? String(errors.companyNumber)
									: null
							}
							inputprops={{
								placeholder: "Hemsida",
								onChange: (e) => {
									const { value } = e.target;
									form.setFieldsValue({
										website: value,
									});
									setErrors({
										...errors,
										website: "",
									});
								},
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<CustomInput
							label="E-postadress"
							key="email"
							name="email"
							validateStatus={!!errors.email ? "error" : ""}
							help={!!errors.email ? String(errors.email) : null}
							inputprops={{
								placeholder: "E-postadress",
								disabled: true,
								onChange: (e) => {
									setErrors({ ...errors, email: "" });
								},
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<CustomInput
							label="Adress"
							key="address"
							name="address"
							validateStatus={!!errors.address ? "error" : ""}
							help={
								!!errors.address ? String(errors.address) : null
							}
							inputprops={{
								placeholder: "Ange gatuadress",
								onChange: (e) => {
									setErrors({ ...errors, address: "" });
								},
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<CustomInput
							label="Post nr"
							key="zip"
							name="zip"
							validateStatus={!!errors.zip ? "error" : ""}
							help={!!errors.zip ? String(errors.zip) : null}
							inputprops={{
								placeholder: "Ange postnummer",
								onChange: (e) => {
									setErrors({ ...errors, zip: "" });
								},
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<CustomInput
							label="Ort"
							key="city"
							name="city"
							validateStatus={!!errors.city ? "error" : ""}
							help={!!errors.city ? String(errors.city) : null}
							inputprops={{
								placeholder: "Ange postort",
								onChange: (e) => {
									setErrors({ ...errors, city: "" });
								},
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<TextArea
							label="Beskrivning"
							key="description"
							name="description"
							validateStatus={!!errors.description ? "error" : ""}
							help={
								!!errors.description
									? String(errors.description)
									: null
							}
							inputprops={{
								autoSize: true,
								placeholder: "Beskrivning",
								onChange: (e) => {
									setErrors({ ...errors, description: "" });
								},
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<CustomSelect
							key="status"
							label="Företagets status"
							name="status"
							validateStatus={!!errors.status ? "error" : ""}
							help={
								!!errors.status ? String(errors.status) : null
							}
							// style={{width: '264px'}}
							inputprops={{
								size: "large",
								placeholder: "Välj företagets status",
								style: { fontWeight: "300" },
								onChange: (val) => {
									setErrors({
										...errors,
										status: "",
									});
								},
							}}
							options={[
								{
									value: "STATUS_IN_REVIEW",
									label: "Under granskning",
								},
								{
									value: "STATUS_REJECTED",
									label: "Nekad",
								},
								{ value: "STATUS_APPROVED", label: "Godkänd" },
								{
									value: "STATUS_VERIFIED",
									label: "Verifierad",
								},
							]}
						/>
					</Col>
				</Row>
				<Row>
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<CustomSelect
							key="careerType"
							label="Företagets bolagstyp"
							name="careerType"
							validateStatus={!!errors.careerType ? "error" : ""}
							help={
								!!errors.careerType
									? String(errors.careerType)
									: null
							}
							// style={{width: '264px'}}
							inputprops={{
								size: "large",
								placeholder: "Välj företagets bolagstyp",
								style: {
									fontWeight: "300",
								},
								onChange: (val) => {
									setErrors({
										...errors,
										careerType: "",
									});
								},
							}}
							options={[
								{
									value: "BUILDER",
									label: "Bostadsutvecklare",
								},
								{
									value: "REAL_ESTATE",
									label: "Fastighetsbolag",
								},
								{
									value: "COOPERATIVE",
									label: "Bostadsrättsförening",
								},
							]}
						/>
					</Col>
				</Row>
				<Row>
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<CustomSelect
							key="adminId"
							label="Administratör"
							name="adminId"
							validateStatus={!!errors.adminId ? "error" : ""}
							help={
								!!errors.adminId ? String(errors.adminId) : null
							}
							// style={{width: '264px'}}
							inputprops={{
								size: "large",
								placeholder: "Välj administratör",
								style: { fontWeight: "300" },
								onChange: (val) => {
									setErrors({
										...errors,
										adminId: "",
									});
								},
							}}
							options={selectAdmins}
						/>
					</Col>
				</Row>
				<Row>
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<CustomSelect
							key="inChannel"
							label="Kanal in"
							name="inChannel"
							validateStatus={!!errors.inChannel ? "error" : ""}
							help={
								!!errors.inChannel ? String(errors.inChannel) : null
							}
							// style={{width: '264px'}}
							inputprops={{
								size: "large",
								placeholder: "Välj kanal in",
								style: { fontWeight: "300" },
								onChange: (val) => {
									setErrors({
										...errors,
										inChannel: "",
									});
								},
							}}
							options={[
								{label: 'Linkedin', value: 'LINKEDIN_POST'},
								{label: 'Tips av en vän', value: 'LINKEDIN_MAIL'},
								{label: 'Google annons', value: 'GOOGLE_SEARCH_PAID'},
								{label: 'Sökmotor', value: 'GOOGLE_SEARCH'},
								{label: 'Instagram', value: 'INSTAGRAM'},
								{label: 'Mynewsdesk', value: 'MY_NEWS_DESK'},
								{label: 'Övrigt', value: 'OTHER'}
							]}
						/>
					</Col>
				</Row>
				<CSSTransition
					in={form.getFieldValue("inChannel") === "OTHER"}
					timeout={300}
					classNames="inChannelOther"
					unmountOnExit
				> 
					<Row>
						<Col xl={24} lg={24} md={24} sm={24} xs={24}>
							<TextArea
								label="Kanal in övrigt beskrivning"
								key="inChannelOther"
								name="inChannelOther"
								validateStatus={!!errors.inChannelOther ? "error" : ""}
								help={
									!!errors.inChannelOther
										? String(errors.inChannelOther)
										: null
								}
								inputprops={{
									autoSize: true,
									placeholder: "Kanal in övrigt beskrivning",
									onChange: (e) => {
										setErrors({ ...errors, inChannelOther: "" });
									},
								}}
							/>
						</Col>
					</Row>
				</CSSTransition>
				<Row>
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<CustomSelect
							key="businessProbability"
							label="Sannolikhet för affär"
							name="businessProbability"
							validateStatus={!!errors.businessProbability ? "error" : ""}
							help={
								!!errors.businessProbability ? String(errors.businessProbability) : null
							}
							// style={{width: '264px'}}
							inputprops={{
								size: "large",
								placeholder: "Välj sannolikhet för affär",
								style: { fontWeight: "300" },
								onChange: (val) => {
									setErrors({
										...errors,
										businessProbability: "",
									});
								},
							}}
							options={[
								{label: '0', value: 0},
								{label: '1', value: 1},
								{label: '2', value: 2},
								{label: '3', value: 3},
								{label: '4', value: 4},
								{label: '5', value: 5}
							]}
						/>
					</Col>
				</Row>
				<Row>
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<CustomSelect
							key="quality"
							label="Kundkvalitet"
							name="quality"
							validateStatus={!!errors.quality ? "error" : ""}
							help={
								!!errors.quality ? String(errors.quality) : null
							}
							// style={{width: '264px'}}
							inputprops={{
								size: "large",
								placeholder: "Välj kundkvalitet",
								style: { fontWeight: "300" },
								onChange: (val) => {
									setErrors({
										...errors,
										quality: "",
									});
								},
							}}
							options={[
								{label: '0', value: 0},
								{label: '1', value: 1},
								{label: '2', value: 2},
								{label: '3', value: 3},
								{label: '4', value: 4},
								{label: '5', value: 5},
								{label: '6', value: 6},
								{label: '7', value: 7},
								{label: '8', value: 8},
								{label: '9', value: 9},
								{label: '10', value: 10},
							]}
						/>
					</Col>
				</Row>
				<Row>
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<Checkbox
							style={{ marginBottom: "24px" }}
							key="followUpCheck"
							checked={isFollowUp}
							onChange={onCheck}
						>
							Sätt återkomst
						</Checkbox>
					</Col>
				</Row>
				{isFollowUp && (
					<>
						<Row>
							<Col xl={24} lg={24} md={24} sm={24} xs={24}>
								<label
									style={{
										fontSize: "18px",
										color: "#2a2b2d",
										paddingBottom: "5px",
									}}
								>
									Ange datum
								</label>
								<DatePicker
									className={`date-picker ${
										!!errors.followUpDate
											? "date-picker-error"
											: ""
									}`}
									calendarClassName="date-picker-calendar"
									selected={!!followUpDate ? moment(followUpDate).toDate() : null}
									dateFormat="yyyy-MM-dd"
									placeholderText="yyyy-MM-dd"
									onChange={handleDateChange} //only when value has changed
								/>
								{!!errors.followUpDate && (
									<span style={{ color: "#f80059" }}>
										{!!errors.followUpDate &&
											errors.followUpDate[0]}
									</span>
								)}
								<div
									style={{
										marginBottom: "24px",
									}}
								></div>
							</Col>
						</Row>
						<Row>
							<Col xl={24} lg={24} md={24} sm={24} xs={24}>
								<TextArea
									label="Ange kommentar"
									key="followUpComment"
									name="followUpComment"
									validateStatus={
										!!errors.followUpComment ? "error" : ""
									}
									help={
										!!errors.followUpComment
											? String(errors.followUpComment)
											: null
									}
									inputprops={{
										autoSize: true,
										placeholder: "Ange kommentar",
										onChange: (e) => {
											setErrors({ ...errors, followUpComment: "" });
										},
									}}
								/>
							</Col>
						</Row>
					</>
				)}
				<Row>
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						<LogoUploader id={company?.id} logo={company?.logo} />
					</Col>
				</Row>
				<Row>
					<div className="rowbuttons" style={{ marginTop: 20 }}>
						<CustomButton
							buttontype="type5"
							colortype="type8"
							size="large"
							style={{ margin: "0 10px 20px" }}
							htmlType="submit"
							onClick={onSubmit}
						>
							Editera
						</CustomButton>
					</div>
				</Row>
			</Form>
		</>
	);
};

const mapStateToProps = (store) => {
	return {
		me: store.me.profile,
		company: store.companies.company,
		loading: store.companies.isFetching_company,
		admins: store.users.list,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		// createLoanOffer: (id, data) => dispatch(createLoanOffer(id, data)),
		fetchUsersAdmins: (currentPage, pageSize, sortField, sorrtOrder) =>
			dispatch(
				fetchUsersAdmins(currentPage, pageSize, sortField, sorrtOrder)
			),
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(BusinessInfoForm)
);
