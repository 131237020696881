import React, { useEffect, useState } from "react";
import { Card, Spin, Table, Col, Button } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "../style.scss";
import { Text } from "../../../../../uikit/Text";
import { fetchCompanyUsers } from "../../../../../actions/users/companies";
import { getQueryParam } from "../../../../../utils/helpers";
import { CustomButton } from "../../../../../uikit/Button";
import {
    CheckCircleFilled,
    DeleteOutlined,
    EditOutlined,
} from "@ant-design/icons";
import DeleteModal from "../../../DeleteModal";
import { deleteUser } from "../../../../../actions/users/admins";
import { toast } from "react-toastify";

const BankUsersInfo = (props) => {
    const { users } = props;
    const { Column } = Table;

    const [userToDelete, setUserToDelete] = useState(null);

    const topColResponsiveProps = {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24,
        style: {
            marginBottom: 24,
        },
    };

    let id = "";
    if (!!getQueryParam("id")) {
        id = getQueryParam("id");
    }

    useEffect(() => {
        props.fetchCompanyUsers(id);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onDeleteOpen = (user) => () => {
        setUserToDelete(user);
    };

    const onSuccess = () => {
        props.fetchCompanyUsers(id);
        setUserToDelete(null);
        toast.success(
            <div className="toast-body">
                <CheckCircleFilled className="toast__icon toast__icon--success" />
                <p className="toast-text">User has been deleted!</p>
            </div>
        );
    };

    const onDeleteUser = (user) => () => {
        props.deleteUser(user.id, onSuccess);
    };

    const onModalCancel = () => {
        setUserToDelete(null);
    };

    const getRole = (role) => {
        if (role === "CUSTOMER") {
            role = "Kund";
        } else if (role === "CREDITOR") {
            role = "Kreditgivare";
        } else {
            role = "-";
        }

        return role;
    };

    return (
        <Spin spinning={props.loading}>
            <div>
                <Col {...topColResponsiveProps}>
                    <Card
                        // loading={loading}
                        className="cardDetaill"
                        style={{ marginBottom: 30, height: "100%" }}
                        bodyStyle={{
                            padding: "28",
                            borderRadius: 6,
                        }}
                    >
                        <Text
                            texttype="back"
                            colortype="back"
                            customclass="cardTitle"
                        >
                            Användaruppgifter
                        </Text>
                        <p
                            style={{
                                fontWeight: 700,
                                fontSize: "18px",
                                color: "#2a2b2d",
                                lineHeight: "23px",
                            }}
                        >
                            Lägg till uppgifter om användare nedan. För att
                            lägga till flera användare, klicka på ikonen nedan
                        </p>
                        <Table
                            dataSource={users}
                            pagination={false}
                            rowKey="id"
                            className="usersList"
                        >
                            <Column
                                title="Förnamn"
                                dataIndex="firstName"
                                key="firstName"
                                render={(text, record) => (
                                    <span>{record?.firstName || "-"}</span>
                                )}
                            />
                            <Column
                                title="Efternamn"
                                dataIndex="lastName"
                                key="lastName"
                                render={(text, record) => (
                                    <span>{record?.lastName || "-"}</span>
                                )}
                            />
                            <Column
                                title="Telefonnummer"
                                dataIndex="phoneNumber"
                                key="phoneNumber"
                                render={(text, record) => (
                                    <span>{record?.phoneNumber || "-"}</span>
                                )}
                            />
                            <Column
                                title="E-postadress"
                                dataIndex="email"
                                key="email"
                                render={(text, record) => (
                                    <span>{record?.email || "-"}</span>
                                )}
                            />
                            <Column
                                title="Roll"
                                dataIndex="role"
                                key="role"
                                render={(text, record) => (
                                    <span>{getRole(record?.role) || "-"}</span>
                                )}
                            />
                            <Column
                                dataIndex="role"
                                key="role"
                                render={(text, record) => (
                                    <div className="actionBtn">
                                        <Link
                                            to={`${
                                                "/users/banks/detail/user-edit?id=" +
                                                record?.id
                                            }`}
                                        >
                                            <button
                                                buttontype="type10"
                                                className="buttonType10"
                                                size="small"
                                                style={{
                                                    width: "100%",
                                                    backgroundColor: "#fff",
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <EditOutlined />
                                            </button>
                                        </Link>
                                        <Button
                                            className="buttonType10 iconBtnTable widthNone"
                                            styles={{ width: "fit-conent" }}
                                            onClick={onDeleteOpen(record)}
                                        >
                                            <DeleteOutlined className="iconDelete" />
                                        </Button>
                                    </div>
                                )}
                            />
                        </Table>

                        <div className="rowbuttons">
                            <Link to={`/users/banks/detail/user?id=${id}`}>
                                <CustomButton
                                    buttontype="type5"
                                    colortype="type8"
                                    size="large"
                                    style={{ margin: "0 10px 20px" }}
                                >
                                    Lägg till användare
                                </CustomButton>
                            </Link>
                        </div>
                    </Card>
                </Col>
            </div>
            <DeleteModal
                show={!!userToDelete}
                isLoading={false}
                title="Delete user"
                description={`Are you sure you want to delete user - ${userToDelete?.firstName} ${userToDelete?.lastName} (ID: ${userToDelete?.id})`}
                handleCancel={onModalCancel}
                onDelete={onDeleteUser(userToDelete)}
            />
        </Spin>
    );
};

const mapStateToProps = (store) => {
    return {
        users: store.companies.companyUsers,
        loading: store.companies.isFetching_companyUsers,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCompanyUsers: (id) => dispatch(fetchCompanyUsers(id)),
        deleteUser: (id, onSuccess) => dispatch(deleteUser(id, onSuccess)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BankUsersInfo);
