import { setCookie, getCookie, deleteCookie } from './cookie';
import axios from 'axios';

export function isLoggedIn() {
    if (!getCookie('accessToken')) {
        return false;
    }
    return true;
}

export function saveTokens(data) {
    // const { accessToken, refreshToken, expiresIn } = data;

    const { accessToken } = data;

    localStorage.setItem('antd-pro-accesstoken', accessToken); // auto reload
    setCookie('accessToken', accessToken, {});
    //setCookie('refresh_token', refresh_token, {}, keep_logged);

    // Change Authorization header for the next requests
    axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
}

export function deleteTokens() {
    deleteCookie('accessToken');
    //deleteCookie('refresh_token');
}

export function setAccessToken(accesstoken) {
	const proAccesstoken = typeof accesstoken === 'string' ? [ accesstoken ] : accesstoken;
	localStorage.setItem('antd-pro-accesstoken', proAccesstoken); // auto reload
	// reloadAuthorized();
}

export function getAccessToken() {
	const accesstoken = localStorage.getItem('antd-pro-accesstoken'); // auto reload
	return accesstoken;
}

export function removeAccessToken() {
	localStorage.removeItem('antd-pro-accesstoken'); // auto reload

	// reloadAuthorized();
}