import React, { Component } from "react";
import "./LeftPanelAdmin.scss"
import { Link } from "react-router-dom";
import { ReactComponent as HomeIcon } from '../../../assets/admin/home.svg';
import { ReactComponent as EnquiryIcon } from '../../../assets/admin/enquiry.svg';
import { ReactComponent as ClipboardIcon } from '../../../assets/admin/clipboard.svg';
import { ReactComponent as FolderIcon } from '../../../assets/admin/folder.svg';
import { ReactComponent as UserIcon } from '../../../assets/formicons/people.svg';
import { ReactComponent as DollarIcon } from '../../../assets/notifications/dollar.svg';


class MenuAdmin extends Component {
    
    render() {

        const path = window.location.pathname;

        return (

            <ul className="leftPanel-menu">
                <li 
                    className={`leftPanel-menu-item ${path === '/dashboard' ? 'leftPanel-menu-item-selected' : ''}`}
                >
                    <Link 
                        to={`/dashboard`}
                        className={`leftPanel-menu-item-link ${path === '/dashboard' ? 'menu-item-selected' : ''}`}
                    >
                        <HomeIcon style={{marginRight: '12px'}} />
                        <span>Hem/Översikt</span>
                    </Link>
                </li>
                <li 
                    className={`leftPanel-menu-item ${path.includes('/credit-request') ? 'leftPanel-menu-item-selected' : ''}`}
                >
                    <Link 
                        to={`/credit-request`}
                        className="leftPanel-menu-item-link"
                    >
                        <EnquiryIcon style={{marginRight: '12px'}} />
                        <span>Kreditförfrågningar</span>
                    </Link>
                </li>
                <li 
                    className={`leftPanel-menu-item ${path.includes('/loan-offers') ? 'leftPanel-menu-item-selected' : ''}`}
                >
                    <Link
                        to={`/loan-offers`}
                        className="leftPanel-menu-item-link"
                    >
                        <FolderIcon style={{marginRight: '12px'}} />
                        <span>Låneerbjudanden</span>
                    </Link>
                </li>
                <li 
                    className={`leftPanel-menu-item ${(path.includes('/credit/list') || path.includes('/credit/detail')) ? 'leftPanel-menu-item-selected' : ''}`}
                >
                    <Link 
                        to={`/credit/list`}
                        className="leftPanel-menu-item-link"
                    >
                        <DollarIcon style={{marginRight: '12px'}} />
                        <span>Krediter</span>
                    </Link>
                </li>
                <li 
                    className={`leftPanel-menu-item ${path.includes('/companies') ? 'leftPanel-menu-item-selected' : ''}`}
                >
                    <Link 
                        to={`/companies`}
                        className="leftPanel-menu-item-link"
                    >
                        <ClipboardIcon style={{marginRight: '12px'}} />
                        <span>Företag</span>
                    </Link>
                </li>
                <li 
                    className={`leftPanel-menu-item ${path.includes('/users/admins') || path.includes('/admin/new')  ? 'leftPanel-menu-item-selected' : ''}`}
                >
                    <Link 
                        to={`/users/admins`}
                        className="leftPanel-menu-item-link"
                    >
                        <UserIcon style={{marginRight: '12px'}} className="userIcon" />
                        <span>Administratörer</span>
                    </Link>
                </li>
                <li 
                    className={`leftPanel-menu-item ${path.includes('/users/banks') ? 'leftPanel-menu-item-selected' : ''}`}
                >
                    <Link 
                        to={`/users/banks`}
                        className="leftPanel-menu-item-link"
                    >
                        <UserIcon style={{marginRight: '12px'}} className="userIcon" />
                        <span>Banks</span>
                    </Link>
                </li>
            </ul>
        );
    }
}

export default MenuAdmin;