import http from "../../../api";

export function showLoader() {
    return {
        type: 'admins/showLoader',
    };
}

export function hideLoader() {
    return {
        type: 'admins/hideLoader',
    };
}

export function fetchBanks(page, size, sortField, sorrtOrder) {
    return (dispatch) => {
        dispatch(showLoader());

        return http.get("api/v1/companies", { ///api/v1/banks
            params: {
                type: "TYPE_CREDITOR",
                page: !!page ? page : 0,
                size: !!size ? size : 10,
                sort: `${!!sortField ? sortField : 'id'},${!!sorrtOrder ? sorrtOrder : 'DESC'}`
            }
        })
            .then((response) => {
                dispatch(setBanks(response.data));
                dispatch(hideLoader());
            })
            .catch(err => {
                dispatch(hideLoader());
            })
    }
    
}

export function setBanks(banks) {
    return {
        type: 'users/setBanks',
        banks: banks.data,
        pagination: banks.pagination
    };
}

export function showAdminLoader() {
    return {
        type: 'admin/showLoader',
    };
}

export function hideAdminLoader() {
    return {
        type: 'admin/hideLoader',
    };
}


export const fetchUserBank = (id) => async (dispatch) => {
    dispatch(showAdminLoader());
    return await http.get(`/api/v1/company/${id}`)
    .then(res => {
        dispatch(hideAdminLoader());
        return dispatch ({
            type: 'users/setBank',
            bank: res.data.data,
        });
    })
    .catch(err => {
        dispatch(hideAdminLoader());
    })
}