import http from "../../api";
// import { toast } from "react-toastify";
// import { CloseCircleOutlined } from '@ant-design/icons';

export function showLoader() {
    return {
        type: 'applications/showLoader',
    };
}

export function hideLoader() {
    return {
        type: 'applications/hideLoader',
    };
}

export function fetchApplications(page, size, sortField, sorrtOrder, filters={}, search) {
    // const statusFilter = !!filters.status ? {status: [...filters.status.map((item) => {
    //     return `in,${item}`
    //   })]} : {};
    let params = {
        page: !!page ? page : 0,
        size: !!size ? size : 10,
        sort: `${!!sortField ? sortField : 'id'},${!!sorrtOrder ? sorrtOrder : 'DESC'}`,
        myCreditRequests: filters?.myCreditRequests
        // filter: JSON.stringify(statusFilter)
    }
    if (search !== null && search !== undefined && search !== '')
        params = {
            ...params,
            search
        }
        const filterString = {
            status: !!filters?.applicationCanceled ? ['in,STATUS_CANCELED'] : ['ex,STATUS_CANCELED']
        }
        if(!!filters && filters.status?.length > 0)
            filterString.status = [...filterString.status, `in,${filters.status}`]
        
    // const filterString = !!filters && filters.status?.length > 0 ? `?filter=%7B%22status%22%3A%5B%22in%2C${filters.status}%22%5D%7D&fields=%2A.%2A` : '';
    return (dispatch) => {
        dispatch(showLoader(true));
        return http.get(`api/v1/applications?filter=${encodeURIComponent(JSON.stringify(filterString))}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            params
        })
            .then((response) => {
                // toast.success(
                //     <div className="toast-body">
                //         <CloseCircleOutlined className="toast__icon toast__icon--success" />
                //         <p className="toast-text">{`Tostify Request success`}</p>
                //     </div>
                // )
                dispatch(setApplications(response.data));
                dispatch(hideLoader(false));
            })
            .catch(err => {
                dispatch(hideLoader())
            })
    }
}

export function showApplicationLoader() {
    return {
        type: 'application/showLoader',
    };
}

export function hideApplicationLoader() {
    return {
        type: 'application/hideLoader',
    };
}

export const fetchApplication = (id) => async (dispatch) => {
    dispatch(showApplicationLoader());
        return await http.get(`/api/v1/application/${id}`)
        .then(res => {
            dispatch ({
                type: 'applications/setApplication',
                application: res.data.data,
            });
            dispatch(hideApplicationLoader())
        })
        .catch(err => {
            dispatch(hideApplicationLoader())
        })
}

export function showApplicationEventsLoader() {
    return {
        type: 'applicationEvents/showLoader',
    };
}

export function hideApplicationEventsLoader() {
    return {
        type: 'applicationEvents/hideLoader',
    };
}

export const fetchApplicationEvents = (page, size, sortField, sorrtOrder, filters={}, data, id) => async (dispatch) => {
    dispatch(showApplicationEventsLoader());
        return await http.get(`/api/v1/application/${id}/events`, {
            params: {
                page: !!page ? page : 0,
                size: !!size ? size : 10,
                sort: `${!!sortField ? sortField : 'id'},${!!sorrtOrder ? sorrtOrder : 'DESC'}`
            }
        })
        .then(res => {
            dispatch ({
                type: 'applications/setApplicationEvents',
                events: res.data.data,
                pagination: res.data.pagination
            });
            dispatch(hideApplicationEventsLoader())
        })
        .catch(err => {
            dispatch(hideApplicationEventsLoader())
        })
}

export function setApplications(applications) {
    return {
        type: 'applications/setList',
        list: applications.data,
        pagination: applications.pagination
    };
}
