const initialState = {
    qr: null,
    orderRef: null,
    autoStartToken: null,
    errors: {},
};

export const BANKID_AUTH_SUCCESS = "bankid/auth/success";
export const BANKID_COLLECT_SUCCESS = "bankid/collect/success";
export const BANKID_CLEAR = "bankid/clear";
export const BANKID_ERRORS = "bankid/errors";
export const BANKID_USERS = "bankid/users";

export function bankIdReducer(state = initialState, { type, payload }) {
    switch (type) {
        case BANKID_AUTH_SUCCESS:
            return {
                ...state,
                ...payload,
            };

        case BANKID_COLLECT_SUCCESS:
            return {
                ...state,
                qr: payload?.qr,
                orderRef: payload?.orderRef,
            };

        case BANKID_CLEAR:
            return {
                ...state,
                ...initialState,
            };

        case BANKID_ERRORS:
            return {
                ...state,
                ...initialState,
                errors: payload,
            };

        case BANKID_USERS:
            return {
                ...state,
                qr: null,
                users: payload,
            };

        default:
            return state;
    }
}
