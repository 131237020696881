import React, { useState } from 'react';
import { Modal } from 'antd';
import { Form, Col, Spin } from 'antd';
import { CustomButton } from '../../../../uikit/Button';
import http from "../../../../api";
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { CheckCircleOutlined } from '@ant-design/icons';
import DatePicker from "react-datepicker";
import { fetchApplicationCreditRequests } from '../../../../actions/credit-requests';
import { fetchApplication } from '../../../../actions/applications';
import { fetchLoanOffer } from '../../../../actions/loan-offers';
import { fetchCreditRequest } from '../../../../actions/credit-requests';
import "./style.scss";

export const DateModal = (props) => {
	const { 
		title, 
		showLeadDialog, 
		handleCancel,
		id
	} = props;
	const [ form ] = Form.useForm();

	const [ startDate, setStartDate] = useState(null);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ errors, setErrors ] = useState({});

    const handleStatus = (status) => {
		http(`/api/v1/credit-request/${id}/status/paid-out`, {
			method: 'POST'
			})
			.then((response) => {
				props.fetchLoanOffer(id);
				toast.success(
					<div className="toast-body">
						<CheckCircleOutlined className="toast__icon toast__icon--success" />
						<p className="toast-text">{`Statusen har ändrats för bearbetning`}</p>
					</div>)
			})
			.catch((err) => {})
	}

	const onToggleViewDialog = async () => {
		handleCancel(false);
	};

	const handleDateChange = (date) => {
        setStartDate(date);
        setErrors({ ...errors, actualPayoutDate: '' });
    }

	const onSubmit = () => {
		setIsLoading(true);
		http(`api/v1/credit-request/${id}/loan-offer`, {
			method: 'PUT',
			data: {
				actualPayoutDate: !!startDate
					? new Date(startDate).toLocaleDateString('sv-SE').split(".").join("-")
					: null,
			}
		})
		.then(function(response) {
			setIsLoading(false);
			handleStatus();
			props.handleCancel(false);
			toast.success(
				<div className="toast-body">
					<CheckCircleOutlined className="toast__icon toast__icon--success" />
					<p className="toast-text">{`Status has been changed to paid out`}</p>
				</div>)
			props.fetchLoanOffer(id);
			props.fetchCreditRequest(id);
		})
		.catch(function(error) {
			setIsLoading(false);
			if (!!error?.response?.data?.errors?.actualPayoutDate ) {
				setErrors(error.response.data.errors);
			}
		});
	};

	return (
		<Modal
			className="modalContainer"     
			animation="zoom"
			maskAnimation="fade"
			forceRender
			visible={showLeadDialog}
			title={title}
			size={'large'}
			footer={false}        
			destroyOnClose
			bodyStyle={{
				padding: '32px 30px 48px',
			}}

			onCancel={() =>onToggleViewDialog()}
		>
			<div className="uploadModal" style={{textAlign:'center'}}>
				<h2 style={{marginBottom: 30, padding: "0 10px"}}>{`Vilket utbetalninsgdatum har krediten?`}</h2>
				<Spin spinning={isLoading}>
					<Form
						form={form}
						className="stepForm"
						hideRequiredMark
						onFinish={onSubmit}
					>
						<Col>
							<DatePicker
								className={`date-picker ${!!errors.actualPayoutDate ? 'date-picker-error' : null}`}
								calendarClassName="date-picker-calendar"
								selected={startDate}
								dateFormat="yyyy-MM-dd"
								placeholderText="yyyy-MM-dd"
								onChange={handleDateChange} //only when value has changed
							/>
							{!!errors.actualPayoutDate && (
								<span style={{color: "#f80059"}}>{!!errors.actualPayoutDate && errors.actualPayoutDate[0]}</span>
							)}
						</Col>
					</Form>
					<div className="buttons dateModalBtns" style={{marginTop: 15}}>
						<CustomButton 
							buttontype="type6"
							size="large"
							className="dateModalBtn"
							onClick={() => {onSubmit()}}
						>
							Spara
						</CustomButton>
						<CustomButton 
							buttontype="type8"
							size="large"
							className="dateModalBtn"
							onClick={props.handleCancel}
						>
							Avbryt
						</CustomButton>
					</div>
				</Spin>
			</div>
		</Modal>
	);
};

const mapStateToProps = (store) => {
	return {
	  loanDataById: store.applications.application,
	  creditRequests: store.creditRequests.applicationCreditRequests	
	}
  };

const mapDispatchToProps = (dispatch) => {
	return {
	  fetchApplicationCreditRequests: (id) => dispatch(fetchApplicationCreditRequests(id)),
	  fetchApplication: (id) => dispatch(fetchApplication(id)),
	  fetchLoanOffer: (id) => dispatch(fetchLoanOffer(id)),
	  fetchCreditRequest: (id) => dispatch(fetchCreditRequest(id)),
	}
  };

export default connect(mapStateToProps, mapDispatchToProps)(DateModal);
