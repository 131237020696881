const initialState = {
    profile: {},
    isLoading: false,
}

export function userReducer(state = initialState, action) {
    switch (action.type) {
        case 'user/setUser':
            return {
                ...state,
                profile: action.profile,
                // isLoading: false,
                // isLoaded: true
            };

        case 'user/showLoader':
            return {
                ...state,
                isLoading: true,
            };

        case 'user/hideLoader':
            return {
                ...state,
                isLoading: false,
            };

        default:
            return state;
    }
}

