import React, { useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { Text } from '../../../../uikit/Text';
import { CustomButton } from '../../../../uikit/Button';
import {
    Form,
    Card,
    Row,
    Col
} from 'antd';
import { fetchUserBank } from '../../../../actions/users/banks';
import http from "../../../../api";
import { CustomInput } from '../../../../uikit/Input';
import './style.scss';

const BankDetailsForm = (props) => {
    const { 
        company,
    } = props;


    const [ form ] = Form.useForm();

    const [ errors, setErrors] = useState({});

    useEffect(() => {

	}, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = () => {
        let values = form.getFieldsValue();

        http(`/api/v1/company`, {
            method: 'POST',
            data: {
                ...values,
                role: 'CREDITOR',
                type: "TYPE_CREDITOR"
            }
        })
            .then((res) => {
                props.history.push(`/users/banks`)
            })
            .catch(err => {
                // console.log('err', err);
                if (!!err.response && !!err.response.data && !!err.response.data.errors) {
                    setErrors(err.response.data.errors)
                }

                let keys = Object.keys(err.response.data.errors);
                const label = document.querySelector(`label[for="${keys[0]}"]`);

                if (!!label) {
                    label.scrollIntoView();
                }
            })

	};

        return (
            <>
                <div className='goBack'>
                    <div style={{paddingBottom: 30}} className={"backLink backBtn titleWrapper"}>
                        <Link
                            to="#" 
                            onClick={() => { props.history.push('/users/banks') }} 
                            style={{float: 'left', paddingTop:5, fontSize: 13, fontWeight: 400, color: '#9199AA'}}
                        >
                            <ArrowLeftOutlined style={{ marginRight: 14 }} />
                            Gå tillbaka
                        </Link>
                        <h2 className='text-center'>
                            {!!company && company.name ? company.name : 'Användare' }
                        </h2>
                    </div>  
                </div>
            <div>
                <div style={{background: '#FFFFFF'}}>
                    <Row gutter={24} type="flex">
                        <Col xl={7} lg={10} md={22} sm={22} xs={24}>
                            <Card
                                className="cardDetaill bidDetaill"
                                style={{height: '100%'}}
                                bodyStyle={{
                                padding: '28',
                                borderRadius: 6
                                }}
                            >
                                <Text texttype="back" colortype="back" customclass="cardTitle">
                                    Bank
                                </Text>
                                {/* <Spin spinning={props.loadingAdmin}> */}
                                    <Form
                                        form={form}
                                        layout="vertical"
                                        className="companyForm"
                                        hideRequiredMark
                                        // initialValues={bank}
                                        onFinish={onSubmit}
                                    >
                                        <Row>
                                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                                <CustomInput
                                                    name="name"
                                                    key="name"
                                                    label="Företagsnamn:"
                                                    validateStatus={ !!errors.name ? 'error' : ''}
                                                    help={!!errors.name ? String(errors.name) : null}
                                                    inputprops={{
                                                        placeholder: 'Förnamn',
                                                        onChange: (e) => {
                                                            setErrors({ ...errors, name: '' });
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                                <CustomInput
                                                    name="companyNumber"
                                                    key="companyNumber"
                                                    label="Org. nr"
                                                    validateStatus={ !!errors.companyNumber ? 'error' : ''}
                                                    help={!!errors.companyNumber ? String(errors.companyNumber) : null}
                                                    inputprops={{
                                                        placeholder: 'Ange org. nr 10 siffror',
                                                        maxLength: 10,
                                                        onChange: (e) => {
                                                            const { value } = e.target;	
                                                            form.setFieldsValue({
                                                                'companyNumber': value
                                                            });
                                                            setErrors({
                                                                ...errors,
                                                                companyNumber: ''
                                                            });
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                                <CustomInput
                                                    label="E-postadress"
                                                    key="email"
                                                    name="email"
                                                    validateStatus={ !!errors.email ? 'error' : ''}
                                                    help={!!errors.email ? String(errors.email) : null}
                                                    inputprops={{ 
                                                        placeholder: 'E-postadress',
                                                        onChange: (e) => {
                                                            setErrors({ ...errors, email: '' });
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                                <CustomInput
                                                    label="Adress"
                                                    key="address"
                                                    name="address"
                                                    validateStatus={ !!errors.address ? 'error' : ''}
                                                    help={!!errors.address ? String(errors.address) : null}
                                                    inputprops={{ 
                                                        placeholder: 'Ange gatuadress',
                                                        onChange: (e) => {
                                                            setErrors({ ...errors, address: '' });
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                                <CustomInput
                                                    label="Postort"
                                                    key="city"
                                                    name="city"
                                                    validateStatus={ !!errors.city ? 'error' : ''}
                                                    help={!!errors.city ? String(errors.city) : null}
                                                    inputprops={{ 
                                                        placeholder: 'Ange postort',
                                                        onChange: (e) => {
                                                            setErrors({ ...errors, city: '' });
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                                <CustomInput
                                                    label="Land"
                                                    key="country"
                                                    name="country"
                                                    validateStatus={ !!errors.country ? 'error' : ''}
                                                    help={!!errors.country ? String(errors.country) : null}
                                                    inputprops={{ 
                                                        placeholder: 'Ange land',
                                                        onChange: (e) => {
                                                            setErrors({ ...errors, country: '' });
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                                <CustomInput
                                                    label="Post nr"
                                                    key="zip"
                                                    name="zip"
                                                    inputprops={{ placeholder: 'Ange postnummer'}}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                                <CustomInput
                                                    label="Hemsida"
                                                    key="website"
                                                    name="website"
                                                    inputprops={{ placeholder: 'Ange hemsida'}}
                                                />
                                            </Col>
                                        </Row>
                                    </Form>
                                {/* </Spin> */}
                          </Card>
                        </Col>
                    </Row>
                    <Row>
                        <div className="rowbuttons">
                            <CustomButton
                                buttontype="type5"
                                colortype="type8"
                                size="large"
                                style={{margin: '0 10px 20px'}}
                                onClick={onSubmit}
                            >
                                Lägg till
                            </CustomButton>
                      </div>
                    </Row>
                </div>
            </div>
          </>
        );
    // }
}

const mapStateToProps = (store) => {
    return {
        bank: store.users.bank,
        user: store.me.profile,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUserBank: (id) => dispatch(fetchUserBank(id)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BankDetailsForm);