import React from 'react';
import {
  Card,
  Row,
  Col
} from 'antd';
import { connect } from 'react-redux';
import { Text } from '../../../../uikit/Text';
import { getStatus, getProduct } from '../../../../utils/helpers';
import '../style.scss';

const CreditInfo = (props) => {
  const { application } = props;

  const topColResponsiveProps1 = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 13,
    style: {
      marginBottom: 24,
    },
  };

  const formatDate = (date) => {
    if (date) {
      return new Date(date).toLocaleDateString('sv-SE')
    }
    return '-'
  }

  return (
    <>
      <Col {...topColResponsiveProps1} className="creditInfoCard">
        <Card
            loading={props.isLoading}
            className="cardDetaill"
            style={{marginBottom: 30}}
            bodyStyle={{
            padding: '28',
            borderRadius: 6
            }}
        >
          <Text texttype="back" colortype="back" customclass="cardTitle">
              Kredituppgifter
          </Text>
          <Row gutter={16}>
              <Col padding={10} className="gutter_row" xl={8} lg={8}	md={8} sm={24} xs={24}>
                <div className="labelitem">{'Status:'}</div>
              </Col>
              <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
                  <div className="valueitem">
                    {/* {application?.status} */}
                    {getStatus(application?.status) || '-'}
                  </div>
              </Col>
          </Row>
          <Row gutter={16}>
              <Col padding={10} className="gutter_row" xl={8} lg={8}	md={8} sm={24} xs={24}>
              <div className="labelitem">{'ID:'}</div>
              </Col>
              <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
                  <div className="valueitem">{application?.id || '-'}</div>
              </Col>
          </Row>
          <Row gutter={16}>
              <Col padding={10} className="gutter_row" xl={8} lg={8}	md={8} sm={24} xs={24}>
              <div className="labelitem">{'Datum:'}</div>
              </Col>
              <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
                  <div className="valueitem">
                  {formatDate(application?.createdAt) || '-'}
                  </div>
              </Col>
          </Row>
          <Row gutter={16}>
              <Col padding={10} className="gutter_row" xl={8} lg={8}	md={8} sm={24} xs={24}>
                <div className="labelitem">{'Produkt:'}</div>
              </Col>
              <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
                  <div className="valueitem">
                  {getProduct(application?.type) || '-'}
                  </div>
              </Col>
          </Row>
          <Row gutter={16}>
              <Col padding={10} className="gutter_row" xl={8} lg={8}	md={8} sm={24} xs={24}>
              <div className="labelitem">{'Bud:'}</div>
              </Col>
              <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
                  <div className="valueitem">{application?.loanOfferCount || '0'}</div>
              </Col>
          </Row>
          <Row gutter={16}>
              <Col padding={10} className="gutter_row" xl={8} lg={8}	md={8} sm={24} xs={24}>
              <div className="labelitem">{'Belopp:'}</div>
              </Col>
              <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
                  <div className="valueitem">
                    {!!application?.data?.amount 
                      ? `${application?.data?.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} SEK` 
                      : '0 SEK'}
                  </div>
              </Col>
          </Row>
        </Card>
      </Col>
    </>
  );
}

const mapStateToProps = (store) => {
  return {
      creditRequest: store.creditRequests.creditRequest,
      application: store.applications.application,
      isLoading: store.applications.isFetching_application
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
      // fetchCreditRequest: (id) => dispatch(fetchCreditRequest(id))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditInfo);
