import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Text } from "../../../../uikit/Text";
import { CustomButton } from "../../../../uikit/Button";
import { Form, Card, Spin, Row, Col } from "antd";
import { getQueryParam } from "../../../../utils/helpers";
import { fetchUserBank } from "../../../../actions/users/banks";
import http from "../../../../api";
import { CustomInput } from "../../../../uikit/Input";
import { CustomSelect } from "../../../../uikit/Select";
import { TextArea } from "../../../../uikit/TextArea";

import "./style.scss";
import LogoUploader from "../../../../uikit/LogoUploader";

const AdminDetailsForm = (props) => {
	const { company } = props;
    
	let id = "";
	if (!!getQueryParam("id")) {
		id = getQueryParam("id");
	}

	const [form] = Form.useForm();

	const [errors, setErrors] = useState({});
	const [bank, setBank] = useState({});

	useEffect(() => {
		props.fetchUserBank(id).then((res) => {
			if (!!res && !!res.bank) {
				setBank({
                    id: res.bank.id,
                    logo: res.bank.logo,
					name: res.bank.name,
					companyNumber: res.bank.companyNumber,
					address: res.bank.address,
					zip: res.bank.zip,
					city: res.bank.city,
					country: res.bank.country,
					type: res.bank.type,
					email: res.bank.email,
					website: res.bank.website,
					status: res.bank.status,
					description: res.bank.description,
					previousProjects: res.bank.previousProjects,
					structureDescription: res.bank.structureDescription
				});
				form.setFieldsValue({
					name: res.bank.name,
					companyNumber: res.bank.companyNumber,
					address: res.bank.address,
					zip: res.bank.zip,
					city: res.bank.city,
					type: res.bank.type,
					email: res.bank.email,
					website: res.bank.website,
					status: res.bank.status,
					description: res.bank.description
				});
			}
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const onSubmit = () => {
		let values = form.getFieldsValue();

		http(`/api/v1/company/${id}`, {
			method: "put",
			data: {
				...values,
				country: bank.country,
				structureDescription: bank.structureDescription,
				previousProjects: bank.previousProjects,
				status: bank.status
			},
		})
			.then((res) => {
				props.history.push(`${"/users/banks/detail?id=" + id}`); //props.history.push(`${'/companies/detail?id='+id}`)
			})
			.catch((err) => {
				if (!!err?.response?.data?.errors) {
					setErrors(err.response.data.errors);
				}
			});
	};

	return (
		<>
			<div className="goBack">
				<div
					style={{ paddingBottom: 30 }}
					className={"backLink backBtn titleWrapper"}
				>
					<Link
						to={
							{
								pathname: `/users/banks/detail`,
								search: `?id=${id}`
							}
						}
						style={{
							float: "left",
							paddingTop: 5,
							fontSize: 13,
							fontWeight: 400,
							color: "#9199AA",
						}}
					>
						<ArrowLeftOutlined style={{ marginRight: 14 }} />
						Gå tillbaka
					</Link>
					<h2 className="text-center">
						{!!company && company.name ? company.name : "Användare"}
					</h2>
				</div>
			</div>
			<div>
				<div style={{ background: "#FFFFFF" }}>
					<Row gutter={24} type="flex">
						<Col xl={7} lg={10} md={22} sm={22} xs={24}>
							<Card
								className="cardDetaill bidDetaill"
								style={{ height: "100%" }}
								bodyStyle={{
									padding: "28",
									borderRadius: 6,
								}}
							>
								<Text
									texttype="back"
									colortype="back"
									customclass="cardTitle"
								>
									Användare
								</Text>
								<Spin spinning={props.loadingBank}>
									<Form
										form={form}
										layout="vertical"
										className="companyForm"
										hideRequiredMark
										initialValues={bank}
										onFinish={onSubmit}
									>
										<Row>
											<Col
												xl={24}
												lg={24}
												md={24}
												sm={24}
												xs={24}
											>
												<CustomInput
													name="name"
													key="name"
													label="Företagsnamn:"
													validateStatus={
														!!errors.name
															? "error"
															: ""
													}
													help={
														!!errors.name
															? String(
																	errors.name
															  )
															: null
													}
													inputprops={{
														placeholder: "Förnamn",
														onChange: (e) => {
															setErrors({
																...errors,
																name: "",
															});
														},
													}}
												/>
											</Col>
										</Row>
										<Row>
											<Col
												xl={24}
												lg={24}
												md={24}
												sm={24}
												xs={24}
											>
												<CustomInput
													name="companyNumber"
													key="companyNumber"
													label="Org. nr"
													validateStatus={
														!!errors.companyNumber
															? "error"
															: ""
													}
													help={
														!!errors.companyNumber
															? String(
																	errors.companyNumber
															  )
															: null
													}
													inputprops={{
														placeholder:
															"Ange org. nr 10 siffror",
														maxLength: 10,
														onChange: (e) => {
															const { value } =
																e.target;
															form.setFieldsValue(
																{
																	companyNumber:
																		value,
																}
															);
															setErrors({
																...errors,
																companyNumber:
																	"",
															});
														},
													}}
												/>
											</Col>
										</Row>
										<Row>
											<Col
												xl={24}
												lg={24}
												md={24}
												sm={24}
												xs={24}
											>
												<CustomInput
													name="website"
													key="website"
													label="Hemsida"
													validateStatus={
														!!errors.website
															? "error"
															: ""
													}
													help={
														!!errors.companyNumber
															? String(
																	errors.companyNumber
															  )
															: null
													}
													inputprops={{
														placeholder: "Hemsida",
														onChange: (e) => {
															const { value } =
																e.target;
															form.setFieldsValue(
																{
																	website:
																		value,
																}
															);
															setErrors({
																...errors,
																website: "",
															});
														},
													}}
												/>
											</Col>
										</Row>
										<Row>
											<Col
												xl={24}
												lg={24}
												md={24}
												sm={24}
												xs={24}
											>
												<CustomInput
													label="E-postadress"
													key="email"
													name="email"
													validateStatus={
														!!errors.email
															? "error"
															: ""
													}
													help={
														!!errors.email
															? String(
																	errors.email
															  )
															: null
													}
													inputprops={{
														placeholder:
															"E-postadress",
														disabled: true,
														onChange: (e) => {
															setErrors({
																...errors,
																email: "",
															});
														},
													}}
												/>
											</Col>
										</Row>
										<Row>
											<Col
												xl={24}
												lg={24}
												md={24}
												sm={24}
												xs={24}
											>
												<CustomInput
													label="Adress"
													key="address"
													name="address"
													validateStatus={
														!!errors.address
															? "error"
															: ""
													}
													help={
														!!errors.address
															? String(
																	errors.address
															  )
															: null
													}
													inputprops={{
														placeholder:
															"Ange gatuadress",
														onChange: (e) => {
															setErrors({
																...errors,
																lastName: "",
															});
														},
													}}
												/>
											</Col>
										</Row>
										<Row>
											<Col
												xl={24}
												lg={24}
												md={24}
												sm={24}
												xs={24}
											>
												<CustomInput
													label="Post nr"
													key="zip"
													name="zip"
													validateStatus={
														!!errors.zip
															? "error"
															: ""
													}
													help={
														!!errors.zip
															? String(errors.zip)
															: null
													}
													inputprops={{
														placeholder:
															"Ange postnummer",
														onChange: (e) => {
															setErrors({
																...errors,
																zip: "",
															});
														},
													}}
												/>
											</Col>
										</Row>
										<Row>
											<Col
												xl={24}
												lg={24}
												md={24}
												sm={24}
												xs={24}
											>
												<CustomInput
													label="Ort"
													key="city"
													name="city"
													validateStatus={
														!!errors.city
															? "error"
															: ""
													}
													help={
														!!errors.city
															? String(
																	errors.city
															  )
															: null
													}
													inputprops={{
														placeholder:
															"Ange postort",
														onChange: (e) => {
															setErrors({
																...errors,
																city: "",
															});
														},
													}}
												/>
											</Col>
										</Row>
										<Row>
											<Col
												xl={24}
												lg={24}
												md={24}
												sm={24}
												xs={24}
											>
												<TextArea
													label="Beskrivning"
													key="description"
													name="description"
													validateStatus={
														!!errors.description
															? "error"
															: ""
													}
													help={
														!!errors.description
															? String(
																	errors.description
															  )
															: null
													}
													inputprops={{
														autoSize: true,
														placeholder:
															"Beskrivning",
														onChange: (e) => {
															setErrors({
																...errors,
																description: "",
															});
														},
													}}
												/>
											</Col>
										</Row>
										<Row>
											<Col
												xl={24}
												lg={24}
												md={24}
												sm={24}
												xs={24}
											>
                                                <LogoUploader id={bank?.id} logo={bank?.logo}/>
                                            </Col>
										</Row>
									</Form>
								</Spin>
							</Card>
						</Col>
					</Row>
					<Row>
						<div className="rowbuttons">
							<CustomButton
								buttontype="type5"
								colortype="type8"
								size="large"
								style={{ margin: "0 10px 20px" }}
								onClick={onSubmit}
							>
								Editera
							</CustomButton>
						</div>
					</Row>
				</div>
			</div>
		</>
	);
	// }
};

const mapStateToProps = (store) => {
	return {
		banks: store.users.banks,
		loadingBank: store.users.isFetching_banks,
		user: store.me.profile,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchUserBank: (id) => dispatch(fetchUserBank(id)),
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(AdminDetailsForm)
);
