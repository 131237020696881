import React, { useState, useCallback } from 'react';
import { Form }  from 'antd';
import { Upload, Spin } from 'antd';
import UploaderSelect from '../../../../uikit/uploaderSelect';
import http from "../../../../api";
import Message from '../../../../uikit/Message';
import { LoadingOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const UploaderMultipleFiles = (props) => {
	const [ fileType, setFileType ] = useState('');
	const [ validationerror, setValidationerror ] = useState([]);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ fileList, setFilelist ] = useState([]);

	const handleSelect = (value) => {
		setFileType(value)
	}

	const handleUpload = (files=[], id, filesType) => {
		setFilelist([]);
		setValidationerror([]);
		setIsLoading(true);
		
		// const MAX_SIZE = 15728640;
		// let maxSizeError = files.some(file => {
		// 	if (!!file && !!file.size && file.size > MAX_SIZE) {
		// 		setIsLoading(false);
		// 		setValidationerror([ 'Max storlek om 15MB på fil överskriden.' ]);
		// 		return true;
		// 	} else return false;
		// });

		// if (!!maxSizeError) {
		// 	return;
		// }

		const formData = new FormData();
		files.forEach((file, i) => {
			if (!!file) {
				formData.append(`files[${i}].file`, file.originFileObj);
				formData.append(`files[${i}].fileType`, filesType);
			}
		})

		http(`/api/v1/application/${id}/files`, {
			method: 'post',
			data: formData,
		})
		.then(function(response) {
			setIsLoading(false);
			props.handleChange(response)
		})
		.catch(function(error) {
			if (!!error && !!error?.response?.data) {
				if (!!error.response.data.message) {
					setValidationerror([ error.response.data.message ]);
				}
				if (!!error.response.data.error) {
					setValidationerror([ error.response.data.error ]);
				}
				if (!!error.response.data.errors && !!error.response.data.errors.files) {
					setValidationerror([ error.response.data.errors.files ]);
				}
				if(!!error.response.data.errors 
					&& Object.keys(error.response.data.errors).some((key) => key.startsWith('file'))
				) {
					setValidationerror([ Object.values(error.response.data.errors)[0]]);
				}
				if(!!error.response.data.status && error.response.data.status === 500) {
					setValidationerror([ "Ett fel uppstod. Uppladdning av file lyckades inte" ]);
				}
			}
			setIsLoading(false);
			  props.handleChange(error)
		});
		setIsLoading(false);
	};

	const debouncedChangeHandler = useCallback(debounce(handleUpload, 500), []); // eslint-disable-line react-hooks/exhaustive-deps

	const innerProps = {
		showUploadList: false,
		multiple: true,
		onChange: info => {
			if(!!info && !!info.fileList) {
				setFilelist(info.fileList);
			}
		},
		customRequest: files => {
			debouncedChangeHandler(fileList, props.applicationId, fileType);
		}
	};

	return (
		<Form >
			<UploaderSelect 
				applicationType={props.applicationType}
				projectType={props.projectType} 
				onChange={handleSelect}
			/>
			<Upload.Dragger
				{...innerProps}
				name="file"
				accept="image/jpg, image/jpeg, image/png, application/pdf, application/msword, application/vnd.ms-excel,
				application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
				.doc, .docx, .rtf,
				application/vnd.openxmlformats-officedocument.wordprocessingml.document,
				application/rtf,
				application/msword,
				application/vnd.ms-word.document.macroenabled.12,
				application/vnd.openxmlformats-officedocument.wordprocessingml.document,
				application/vnd.ms-word.template.macroenabled.12,
			   	application/vnd.openxmlformats-officedocument.wordprocessingml.template"
				fileList={fileList}
				disabled={!fileType}
			>
				<div className="ant-upload-text">Bifoga fil
					{isLoading && (<Spin indicator={antIcon} style={{marginLeft: '16px'}} />)}
				</div>
			</Upload.Dragger>
			{validationerror.map((error, index) =>
				<div style={{marginTop: '24px'}} key={index} >
					<Message content={error} type="error" />
				</div>

			)}
		</Form>
	);
};

export default UploaderMultipleFiles;
