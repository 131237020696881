import React, { useState } from 'react';
import {
  Button,
  Card,
  Table,
  Row,
  Col,
  Spin
} from 'antd';
import { ReactComponent as SearchIcon } from '../../../../assets/admin/search.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/admin/download.svg';
import moment from 'moment';
import { connect } from 'react-redux';
import http from "../../../../api";
import { PreviewModal } from "../Modal/PreviewModal";

const topColResponsiveProps3 = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 24,
  style: {
    marginBottom: 24,
  },
};

const { Column } = Table;

const FilesList = (props) => {
  const { loanDataById } = props;

  const [ selectedFile, setSelectedFile ] = useState({});
  const [ showPreviewModal, setShowPreviewModal ] = useState(false);
  // const [ isDownloading, setIsDownloading ] = useState(false);
  // const [ isDownloadingArchive, setIsDownloadingArchive ] = useState(false);

  const handleCancelPreview = async () => {
    setShowPreviewModal(value => !value);
    setSelectedFile({})
  };

  const handleDownloadArchive = () => {

		http(`/api/v1/application/${loanDataById?.id}/zip`, {
			method: 'get',
			responseType: 'blob'
		})
		.then((blob) => {
		  // Create blob link to download
		  const url = window.URL.createObjectURL(
			new Blob([blob.data]),
		  );

		  const link = document.createElement('a');
		  link.href = url;
		  link.setAttribute(
			'download',
			`${loanDataById?.id} Applikationsfiler.zip`,
		  );
	  
		  // Append to html link element page
		  document.body.appendChild(link);
	  
		  // Start download
		  link.click();
	  
		  // Clean up and remove the link
		  link.parentNode.removeChild(link);
		})
		.catch(err => {
			// console.log('err', err);
		});
	}

  const handleDownloadFile = async (file) => {

		http(`/api/v1/application/${file.id}/file`, {
			method: 'get',
			responseType: 'blob'
		})
		.then((blob) => {
		  // Create blob link to download
		  const url = window.URL.createObjectURL(
			new Blob([blob.data]),
		  );

		  const link = document.createElement('a');
		  link.href = url;
		  link.setAttribute(
			'download',
			`${file.name}`,
		  );
	  
		  // Append to html link element page
		  document.body.appendChild(link);
	  
		  // Start download
		  link.click();
	  
		  // Clean up and remove the link
		  link.parentNode.removeChild(link);
		})
		.catch(err => {
			// console.log('err', err);
		});

		// onToggleViewDialog();
	}

  const handlePrewiewModal = async (file) => {
    http(`/api/v1/application/${file.id}/file`, {
			method: 'get',
			responseType: 'blob'
		})
    .then(blob => {
      const url = window.URL.createObjectURL(
        new Blob([blob.data], { type: blob.data.type })
      );
      
      let extensions = ['doc', 'docx', 'rtf', 'xls', 'xlsx'];
      let isMsDocFileType = extensions.some(extension => extension === file.extension);
      if (isMsDocFileType) {
        setSelectedFile(file)
        setShowPreviewModal(value => !value);
      } else {
        window.open(url);
      }
    })
  };

  return (
    <Row className="dashboard" gutter={24} >
    <Col {...topColResponsiveProps3}>
      <Card
          // loading={loading}
          style={{marginBottom: 30}}
          bodyStyle={{
            padding: '0',
            borderRadius: 6
          }}
        >
          <div className="tableTitle">
              <div>{'Alla bifogade filer '}</div>
              <div style={{display: "flex", alignItems: "center"}}>
                <div>Ladda ner alla</div>
                <Button
                  className="iconBtn"
                  onClick={() => handleDownloadArchive()}
                  style={{padding: '2px 5px 5px 5px'}}
                >
                  <DownloadIcon />
              </Button>
              </div>
            </div>
          <Spin spinning={props.isLoading}>
            <Table
              key="id"
              dataSource={loanDataById?.files ? loanDataById.files : [] }
              pagination={false}
              rowKey="id"
              scroll={{ x: 'fit-content' }}
            >
              <Column
                title="Fil"
                dataIndex="name"
                key="name"
                render={(text, record) => (
                  <span className="tableFile">{record.name || '-'}</span>
                )}
              />
              <Column
                title="Type"
                dataIndex="name"
                key="type"
                render={(text, record) => (
                  <span>
                    {record.fileType || '-'}
                  </span>
                )}
              />
              <Column
                title="Tillagt"
                dataIndex="date"
                key="date"
                render={(text, record) => (
                  <span className="tableFile">{moment(record.createdAt).format('YYYY-MM-DD') || '-'}</span>
                )}
              />
              <Column
                title="Åtgärder"
                dataIndex="action"
                key="action"
                render={(text, record) => (
                  <div className="iconBtnsWrapper">
                    <Button
                      className="iconBtnTable" 
                      onClick={() => handlePrewiewModal(record)}
                    >
                      <SearchIcon />
                    </Button>
                    <Button
                      className="iconBtnTable"
                      onClick={() => handleDownloadFile(record)}
                    >
                      <DownloadIcon />
                    </Button>
                  </div>
                )}
              />
          </Table>
        </Spin>
      </Card>
    </Col>
    <PreviewModal
      showLeadDialog={showPreviewModal}
      handleCancel={handleCancelPreview}
      onSubmit={handleCancelPreview}
      fileItem={selectedFile}
    />
  </Row>
  )
};

const mapStateToProps = (store) => {
  return {
    loanDataById: store.creditRequests.creditRequest.application,
    isLoading: store.creditRequests.isFetching_creditRequest
  }
};

export default connect(mapStateToProps)(FilesList);

