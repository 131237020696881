import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { fetchLoanOffer } from "../../../../actions/loan-offers";
import { fetchCreditRequest } from "../../../../actions/credit-requests";
import { Text } from "../../../../uikit/Text";
import { getStatus } from "../../../../utils/helpers";
import { CustomButton } from "../../../../uikit/Button";
import { Card, Spin, Row, Col } from "antd";
import { getQueryParam } from "../../../../utils/helpers";
import "./style.scss";
import DateModal from "../../../creditor/LoanOffers/LoanOfferDetails/DateModal";
import http from "../../../../api";
import { toast } from "react-toastify";
import { CheckCircleOutlined } from "@ant-design/icons";
import FileItem from "../../../creditor/LoanOffers/LoanOfferForm/FileItem";

const LoanOfferDetails = (props) => {
	const { creditRequest, loanOfferDataById } = props;

	let id = "";
	if (!!getQueryParam("id")) {
		id = getQueryParam("id");
	}

	const [showModal, setShowModal] = useState(false);

	const handleCancel = async () => {
		setShowModal((value) => !value);
	};

	useEffect(() => {
		props.fetchLoanOffer(id);
		props.fetchCreditRequest(id);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const topColResponsiveProps = {
		xs: 24,
		sm: 12,
		md: 12,
		lg: 12,
		xl: 24,
		style: {
			marginBottom: 24,
		},
	};

	let bankName = creditRequest?.company?.name;

	const approve = () => {
		http(`/api/v1/loan-offer/${id}/status/approve`, {
			method: "post",
		})
			.then(() => {
				props.fetchLoanOffer(id);
				toast.success(
					<div className="toast-body">
						<CheckCircleOutlined className="toast__icon toast__icon--success" />
						<p className="toast-text">{`Låneerbjudandet accepterades.`}</p>
					</div>
				);
			})
			.catch();
	};

	const approveByAdmin = () => {
		http(`/api/v1/credit-request/${id}/loan-offer/approve`, {
			method: "post",
		})
			.then(() => {
				props.fetchLoanOffer(id);
				toast.success(
					<div className="toast-body">
						<CheckCircleOutlined className="toast__icon toast__icon--success" />
						<p className="toast-text">{`Låneerbjudandet skickat till kund.`}</p>
					</div>
				);
			})
			.catch();
	};

	const decline = () => {
		http(`/api/v1/loan-offer/${id}/status/decline`, {
			method: "post",
		})
			.then(() => {
				props.fetchLoanOffer(id);
				toast.success(
					<div className="toast-body">
						<CheckCircleOutlined className="toast__icon toast__icon--success" />
						<p className="toast-text">{`Låneerbjudandet avböjdes.`}</p>
					</div>
				);
			})
			.catch();
	};

	const onFileDelete = () => {
		props.fetchCreditRequest(id);
	};

	return (
		<>
			<div className="goBack">
				<div
					style={{ paddingBottom: 30 }}
					className={"backLink backBtn titleWrapper"}
				>
					<Link
						to={{
							pathname: "/loan-offers",
						}}
						style={{
							float: "left",
							paddingTop: 5,
							fontSize: 13,
							fontWeight: 400,
							color: "#9199AA",
						}}
					>
						<ArrowLeftOutlined style={{ marginRight: 14 }} />
						Gå tillbaka
					</Link>
					<h2 className="text-center">Låneerbjudanden</h2>
				</div>
			</div>
			<div>
				<Spin spinning={props.loadingCreditRequest}>
					<Card
						style={{ marginBottom: 30 }}
						bodyStyle={{
							padding: "28",
							borderRadius: 6,
							textAlign: "center",
						}}
					>
						<h2
							className="detailsHeader"
							style={{
								marginBottom: 0,
								display: "inline-block",
								position: "relative",
							}}
						>
							{`Aktivt bud för Kreditförfrågan ${loanOfferDataById?.creditRequest?.id} 
                        från ${bankName}`}
						</h2>
					</Card>
					<h2 className="collapseTit">
						Låneerbjudande {loanOfferDataById?.id}
					</h2>
					<h2 className="collapseTit subTitle">
						{`från ${bankName}`}
					</h2>
				</Spin>
				<Spin spinning={props.loadingLoanOffer}>
					<div style={{ background: "#FFFFFF" }}>
						<Row gutter={24} type="flex">
							<Col {...topColResponsiveProps}>
								<Card
									// loading={loading}
									className="cardDetaill bidDetaill"
									style={{ marginBottom: 30, height: "100%" }}
									bodyStyle={{
										padding: "28",
										borderRadius: 6,
									}}
								>
									<Text
										texttype="back"
										colortype="back"
										customclass="cardTitle"
									>
										Grunduppgifter
									</Text>
									<Row gutter={16}>
										<Col
											padding={10}
											className="gutter_row"
											xl={10}
											lg={16}
											md={16}
											sm={24}
											xs={24}
										>
											<div className="labelitem">
												{"Belopp:"}
											</div>
										</Col>
										<Col
											className="gutter_row"
											xl={10}
											lg={8}
											md={8}
											sm={24}
											xs={24}
										>
											<div className="valueitem">
												{!!loanOfferDataById.amount
													? `${loanOfferDataById.amount
															.toString()
															.replace(
																/\B(?=(\d{3})+(?!\d))/g,
																","
															)} SEK`
													: ""}
											</div>
										</Col>
									</Row>
									<Row gutter={16}>
										<Col
											padding={10}
											className="gutter_row"
											xl={10}
											lg={16}
											md={16}
											sm={24}
											xs={24}
										>
											<div className="labelitem">
												{"Ränta för lånet:"}
											</div>
										</Col>
										<Col
											className="gutter_row"
											xl={10}
											lg={8}
											md={8}
											sm={24}
											xs={24}
										>
											<div className="valueitem">
												{!!loanOfferDataById &&
												!!loanOfferDataById.interest
													? loanOfferDataById.interest +
													  "%"
													: "-"}
											</div>
										</Col>
									</Row>
									<Row gutter={16}>
										<Col
											padding={10}
											className="gutter_row"
											xl={10}
											lg={16}
											md={16}
											sm={24}
											xs={24}
										>
											<div className="labelitem">
												{`Avgift utgår för lånet:`}
											</div>
										</Col>
										<Col
											className="gutter_row"
											xl={10}
											lg={16}
											md={16}
											sm={24}
											xs={24}
										>
											<div className="valueitem">
												{loanOfferDataById?.feeTypes
													? "Ja"
													: "Nej"}
											</div>
										</Col>
									</Row>
									{!!loanOfferDataById.feeTypes &&
										loanOfferDataById.feeTypes.map(
											(feeType, index) => (
												<React.Fragment key={index}>
													<Row gutter={16}>
														<Col
															padding={10}
															className="gutter_row"
															xl={10}
															lg={16}
															md={16}
															sm={24}
															xs={24}
														>
															<div className="labelitem">
																{
																	"Angiven avgiftsform för lånet:"
																}
															</div>
														</Col>
														<Col
															className="gutter_row"
															xl={10}
															lg={8}
															md={8}
															sm={24}
															xs={24}
														>
															<div className="valueitem">
																{feeType.feeType ||
																	"-"}
															</div>
														</Col>
													</Row>
													<Row gutter={16}>
														<Col
															padding={10}
															className="gutter_row"
															xl={10}
															lg={16}
															md={16}
															sm={24}
															xs={24}
														>
															<div className="labelitem">
																{`Avgift för lånet:`}
															</div>
														</Col>
														<Col
															className="gutter_row"
															xl={10}
															lg={8}
															md={8}
															sm={24}
															xs={24}
														>
															<div className="valueitem">
																{!!feeType.feeAmount
																	? `${feeType.feeAmount
																			.toString()
																			.replace(
																				/\B(?=(\d{3})+(?!\d))/g,
																				","
																			)} SEK`
																	: "-"}
															</div>
														</Col>
													</Row>
												</React.Fragment>
											)
										)}
								</Card>
							</Col>
							<Col xl={12} lg={12} md={12} sm={24} xs={24}>
								<Card
									// loading={loading}
									className="cardDetaill bidDetaill"
									style={{ marginBottom: 30, height: "100%" }}
									bodyStyle={{
										padding: "28",
										borderRadius: 6,
									}}
								>
									<Text
										texttype="back"
										colortype="back"
										customclass="cardTitle"
									>
										Budstatus
									</Text>
									<Row gutter={16}>
										<Col
											padding={10}
											className="gutter_row"
											xl={10}
											lg={10}
											md={16}
											sm={24}
											xs={24}
										>
											<div className="labelitem">
												{"Status:"}
											</div>
										</Col>
										<Col
											className="gutter_row"
											xl={10}
											lg={8}
											md={8}
											sm={24}
											xs={24}
										>
											{!!loanOfferDataById.status
												? getStatus(
														loanOfferDataById.status,
														loanOfferDataById.status
												  )
												: "-"}
										</Col>
									</Row>
									{/* <Row gutter={16}>
                                <Col padding={10} className="gutter_row" xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <div className="labelitem">{'Godkänt av Bettre'}</div>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col padding={10} className="gutter_row" xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <div className="labelitem">{'Motttaget av '}</div>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col padding={10} className="gutter_row" xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <div className="labelitem">{'Senast läst av'}</div>
                                </Col>
                            </Row> */}
								</Card>
							</Col>
						</Row>
						<Row gutter={24} type="flex">
							<Col xl={12} lg={12} md={12} sm={24} xs={24}>
								<Card
									// loading={loading}
									className="cardDetaill bidDetaill"
									style={{ marginBottom: 30, height: "100%" }}
									bodyStyle={{
										padding: "28",
										borderRadius: 6,
									}}
								>
									<Text
										texttype="back"
										colortype="back"
										customclass="cardTitle"
									>
										Säkerhet och borgen
									</Text>
									<Row gutter={16}>
										<Col
											padding={10}
											className="gutter_row"
											xl={10}
											lg={16}
											md={16}
											sm={24}
											xs={24}
										>
											<div className="labelitem">
												{"Säkerhetsvillkor för lånet:"}
											</div>
										</Col>
										<Col
											className="gutter_row"
											xl={10}
											lg={8}
											md={8}
											sm={24}
											xs={24}
										>
											<div
												className="valueitem"
												style={{
													whiteSpace: "pre-wrap",
												}}
											>
												{loanOfferDataById?.securityTerms ||
													"N/A"}
											</div>
										</Col>
									</Row>
									<Row gutter={16}>
										<Col
											padding={10}
											className="gutter_row"
											xl={10}
											lg={16}
											md={16}
											sm={24}
											xs={24}
										>
											<div className="labelitem">
												{"Borgen krävs för lånet:"}
											</div>
										</Col>
										<Col
											className="gutter_row"
											xl={10}
											lg={8}
											md={8}
											sm={24}
											xs={24}
										>
											<div className="valueitem">
												{loanOfferDataById?.guarantors
													? "ja"
													: "Nej"}
											</div>
										</Col>
									</Row>
									{!!loanOfferDataById.guarantors &&
										loanOfferDataById.guarantors.map(
											(guarantor, index) => (
												<React.Fragment key={index}>
													<Row gutter={16}>
														<Col
															padding={10}
															className="gutter_row"
															xl={10}
															lg={16}
															md={16}
															sm={24}
															xs={24}
														>
															<div className="labelitem">
																{"Borgensman:"}
															</div>
														</Col>
														<Col
															className="gutter_row"
															xl={10}
															lg={8}
															md={8}
															sm={24}
															xs={24}
														>
															<div className="valueitem">
																{`${
																	!!guarantor.guarantorSSN
																		? guarantor.guarantorSSN
																		: "-"
																}
                                                ${
													!!guarantor.guarantorName
														? guarantor.guarantorName
														: "-"
												}`}
															</div>
														</Col>
													</Row>
													<Row gutter={16}>
														<Col
															padding={10}
															className="gutter_row"
															xl={10}
															lg={16}
															md={16}
															sm={24}
															xs={24}
														>
															<div className="labelitem">
																{
																	"Borgenssumma:"
																}
															</div>
														</Col>
														<Col
															className="gutter_row"
															xl={10}
															lg={8}
															md={8}
															sm={24}
															xs={24}
														>
															<div className="valueitem">
																{!!guarantor.guaranteeAmount
																	? guarantor.guaranteeAmount
																			.toString()
																			.replace(
																				/\B(?=(\d{3})+(?!\d))/g,
																				","
																			) +
																	  " SEK"
																	: "-"}
															</div>
														</Col>
													</Row>
												</React.Fragment>
											)
										)}
								</Card>
							</Col>
						</Row>
						<Row gutter={24} type="flex">
							<Col xl={12} lg={12} md={12} sm={24} xs={24}>
								<Card
									// loading={loading}
									className="cardDetaill bidDetaill"
									style={{ marginBottom: 30, height: "100%" }}
									bodyStyle={{
										padding: "28",
										borderRadius: 6,
									}}
								>
									<Text
										texttype="back"
										colortype="back"
										customclass="cardTitle"
									>
										Amortering och löptid
									</Text>
									<Row gutter={16}>
										<Col
											padding={10}
											className="gutter_row"
											xl={10}
											lg={16}
											md={16}
											sm={24}
											xs={24}
										>
											<div className="labelitem">
												{"Amorteringstyp för lånet:"}
											</div>
										</Col>
										<Col
											className="gutter_row"
											xl={10}
											lg={8}
											md={8}
											sm={24}
											xs={24}
										>
											<div className="valueitem">
												{loanOfferDataById?.amortizationType ||
													"-"}
											</div>
										</Col>
									</Row>
									{!!loanOfferDataById.amortizationCustomType && (
										<Row gutter={16}>
											<Col
												padding={10}
												className="gutter_row"
												xl={10}
												lg={16}
												md={16}
												sm={24}
												xs={24}
											>
												<div className="labelitem">
													{"Amorteringstakt:"}
												</div>
											</Col>
											<Col
												className="gutter_row"
												xl={10}
												lg={8}
												md={8}
												sm={24}
												xs={24}
											>
												<div className="valueitem">
													{
														loanOfferDataById?.amortizationCustomType
													}
												</div>
											</Col>
										</Row>
									)}
									<Row gutter={16}>
										<Col
											padding={10}
											className="gutter_row"
											xl={10}
											lg={16}
											md={16}
											sm={24}
											xs={24}
										>
											<div className="labelitem">
												{"Löptid för lånet:"}
											</div>
										</Col>
										<Col
											className="gutter_row"
											xl={10}
											lg={8}
											md={8}
											sm={24}
											xs={24}
										>
											<div className="valueitem">
												{!!loanOfferDataById?.duration
													? loanOfferDataById.duration +
													  " mån"
													: "-"}
											</div>
										</Col>
									</Row>
								</Card>
							</Col>

							<Col xl={12} lg={12} md={12} sm={24} xs={24}>
								<Card
									// loading={loading}
									className="cardDetaill bidDetaill"
									style={{ marginBottom: 30, height: "100%" }}
									bodyStyle={{
										padding: "28",
										borderRadius: 6,
									}}
								>
									<Text
										texttype="back"
										colortype="back"
										customclass="cardTitle"
									>
										Handläggningstid
									</Text>
									<Row gutter={16}>
										<Col
											padding={10}
											className="gutter_row"
											xl={10}
											lg={8}
											md={8}
											sm={24}
											xs={24}
										>
											<div className="labelitem">
												{"Handläggninsgtid för lånet:"}
											</div>
										</Col>
									</Row>
									<Row gutter={16}>
										<Col
											className="gutter_row"
											xl={10}
											lg={16}
											md={16}
											sm={24}
											xs={24}
										>
											<div className="valueitem">
												{loanOfferDataById?.processingTime +
													" dagar" || "-"}
											</div>
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
						{!!loanOfferDataById.otherTerms &&
							!!loanOfferDataById.otherTerms.length && (
								<Col xl={24} lg={24} md={20} sm={24} xs={24}>
									<Card
										// loading={loading}
										className="cardDetaill bidDetaill"
										style={{
											marginBottom: 30,
											height: "100%",
										}}
										bodyStyle={{
											padding: "28",
											borderRadius: 6,
										}}
									>
										<Text
											texttype="back"
											colortype="back"
											customclass="cardTitle"
										>
											Övriga villkor
										</Text>
										<Row gutter={16}>
											<Col
												className="gutter_row"
												xl={24}
												lg={16}
												md={16}
												sm={24}
												xs={24}
											>
												<ul className="message-list">
													{loanOfferDataById?.otherTerms.map(
														(term, index) => {
															if (
																!!term &&
																!!term.otherTerm
															) {
																return (
																	<li
																		className="messageText-list"
																		style={{
																			marginLeft:
																				"15px",
																		}}
																		key={
																			index
																		}
																	>
																		{
																			term?.otherTerm
																		}
																	</li>
																);
															} else return null;
														}
													)}
												</ul>
											</Col>
										</Row>
									</Card>
								</Col>
							)}
						{!!creditRequest?.file && (
							<Row gutter={16}>
								<Col
									className="gutter_row"
									xl={24}
									lg={16}
									md={16}
									sm={24}
									xs={24}
								>
									<Card
										// loading={loading}
										className="cardDetaill bidDetaill"
										style={{
											marginBottom: 30,
											height: "100%",
										}}
										bodyStyle={{
											padding: "28",
											borderRadius: 6,
										}}
									>
										<Text
											texttype="back"
											colortype="back"
											customclass="cardTitle"
										>
											Bifogad offert
										</Text>
										<FileItem
											id={creditRequest?.id}
											file={creditRequest?.file}
											setFile={onFileDelete}
										/>
									</Card>
								</Col>
							</Row>
						)}
						<div style={{ textAlign: "center" }}>
							{loanOfferDataById.status === "STATUS_APPROVED" && (
								<CustomButton
									className="approvedBtn"
									buttontype="type5"
									colortype="type8"
									size="large"
									// style={{margin: '0 10px 20px', minWidth: '285px'}}
									onClick={() => {
										setShowModal(true);
									}}
								>
									Sätt kreditförfrågan som utbetald
								</CustomButton>
							)}
							{!loanOfferDataById?.isApproved && (
								<CustomButton
									className="approvedBtn"
									buttontype="type5"
									colortype="type8"
									size="large"
									// style={{margin: '0 10px 20px', minWidth: '285px'}}
									onClick={approveByAdmin}

								>
									Skicka till kund
								</CustomButton>
							)}
							{loanOfferDataById.status !== "STATUS_APPROVED" &&
								loanOfferDataById.status !==
									"STATUS_DECLINED" &&
								loanOfferDataById.status !==
									"STATUS_DECLINED_BY_BANK" && (
									<>
										<CustomButton
											className="approvedBtn"
											buttontype="type5"
											colortype="type8"
											size="large"
											// style={{margin: '0 10px 20px', minWidth: '285px'}}
											onClick={approve}
										>
											Godkänn
										</CustomButton>
										<CustomButton
											className="approvedBtn"
											buttontype="type6"
											// colortype="type8"
											size="large"
											// style={{margin: '0 10px 20px', minWidth: '285px'}}
											onClick={decline}
										>
											Avböj
										</CustomButton>
									</>
								)}
						</div>
					</div>
				</Spin>
			</div>
			<DateModal
				showLeadDialog={showModal}
				handleCancel={handleCancel}
				id={id}
			/>
		</>
	);
	// }
};

const mapStateToProps = (store) => {
	return {
		creditRequest: store.creditRequests.creditRequest,
		loadingCreditRequest: store.creditRequests.isFetching_creditRequest,
		loanOfferDataById: store.loanOffers.loanOffer,
		loadingLoanOffer: store.loanOffers.isFetching_loanOffer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchLoanOffer: (id) => dispatch(fetchLoanOffer(id)),
		fetchCreditRequest: (id) => dispatch(fetchCreditRequest(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanOfferDetails);
