import axios from "axios";
import config from "../config";
import history from "../utils/history";
import {deleteTokens, isLoggedIn} from "../utils/auth";
import {getCookie} from "../utils/cookie";
// import { notification } from 'antd';
import { toast } from "react-toastify";
import { CloseCircleOutlined } from '@ant-design/icons';

const instance = axios.create({
    //baseURL: process.env.API_BASE_URL,
    baseURL: config.API_DOMAIN
});

const isHandlerEnabled = (config={}) => {
    return !(config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled);
}

const requestHandler = (request) => {
    if (isHandlerEnabled(request)) {

        request.headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Accept': 'application/json'
        };

        if ( isLoggedIn() ) {
            const accessToken = getCookie('accessToken');
            request.headers.Authorization = `Bearer ${accessToken}`;
        }
    }

    return request
}


const errorHandler = (error) => {

    if (isHandlerEnabled(!!error && error.config)) {
        // Handle errors
        const { response } = error;

        if (response && response.status) {
            const { status, config } = response;
    
            if (status === 401) {
                deleteTokens();
                history.push({
                    pathname: '/login'
                });
            } else if (status === 1403) {
                toast.error(
                    <div className="toast-body">
                        <CloseCircleOutlined className="toast__icon toast__icon--error" />
                        <p className="toast-text">{response.data.message}</p>
                    </div>)
            } else if (status !== 422 && status !== 1404) {
                toast.error(
                    <div className="toast-body">
                        <CloseCircleOutlined className="toast__icon toast__icon--error" />
                        <p className="toast-text">{`${status}: ${!!config.url ? config.url : ''}`}</p>
                    </div>)
                // notification.error({
                //     message: `Request error ${status}: ${!!config.url ? config.url : ''}`,
                //     description: errorText
                // });
            }
        } else if (!response) {
            toast.error(
                <div className="toast-body">
                    <CloseCircleOutlined className="toast__icon toast__icon--error" />
                    <p className="toast-text">{`Your network cannot connect to the server`}</p>
                </div>)
            // notification.error({
            //     description: 'Your network cannot connect to the server',
            //     message: 'Network issue'
            // });
        }
    }
    return Promise.reject({ ...error })
}

const successHandler = (response) => {
    if (isHandlerEnabled(response.config)) {
        // Handle responses
    }
    return response
}

instance.interceptors.request.use(request => requestHandler(request));
instance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
)

export default instance;