import http from "../../../api";
// import {showGlobalLoader} from "../app";

export function showLoader() {
    return {
        type: 'companies/showLoader',
    };
}

export function hideLoader() {
    return {
        type: 'companies/hideLoader',
    };
}

export function fetchCompanies(page, size, sortField, sorrtOrder, filters, search) {
    return (dispatch) => {
        dispatch(showLoader());

        let params = {
            type: 'TYPE_DEBTOR',
            page: !!page ? page : 0,
            size: !!size ? size : 10,
            sort: `${!!sortField ? sortField : 'id'},${!!sorrtOrder ? sorrtOrder : 'DESC'}`,
            myCreditRequests: !!filters?.myCreditRequests,
        }

        if ( search !== null && search !== undefined && search !== '')
            params = {
                ...params,
                search
            }
        const filterString = {
            status: !!filters?.includeRejected ? ['in,STATUS_REJECTED'] : ['ex,STATUS_REJECTED']
        }

        return http.get(`/api/v1/companies?filter=${encodeURIComponent(JSON.stringify(filterString))}`, {
            params
        })
            .then((response) => {
                dispatch(setCompanies(response.data));
                dispatch(hideLoader());
            })
            .catch(err => {
                dispatch(hideLoader());
            })
    }
    
}

export function fetchCompaniesExport(page, size, sortField, sorrtOrder, filters, search) {
    return (dispatch) => {
        dispatch(showLoader());

        let params = {
            type: 'TYPE_DEBTOR',
            page: !!page ? page : 0,
            size: !!size ? size : 10,
            sort: `${!!sortField ? sortField : 'id'},${!!sorrtOrder ? sorrtOrder : 'DESC'}`,
            myCreditRequests: !!filters?.myCreditRequests,
        }

        if ( search !== null && search !== undefined && search !== '')
            params = {
                ...params,
                search
            }
        const filterString = {
            status: !!filters?.includeRejected ? ['in,STATUS_REJECTED'] : ['ex,STATUS_REJECTED']
        }

        return http(`/api/v1/companies/export?filter=${encodeURIComponent(JSON.stringify(filterString))}`, {
            params,
            method: 'GET',
			responseType: 'blob'
        })
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob.data]), {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});

				const link = document.createElement("a");
				link.href = url;
                let hed = blob.headers['content-disposition']
                const filename = hed.replaceAll('attachment; filename=', '')
				link.setAttribute("download", `${filename}`);

				// Append to html link element page
				document.body.appendChild(link);

				// Start download
				link.click();

				// Clean up and remove the link
				link.parentNode.removeChild(link);
                dispatch(hideLoader());
            })
            .catch(err => {
                dispatch(hideLoader());
            })
    }
    
}

export function setCompanies(companies) {

    return {
        type: 'companies/setList',
        list: companies.data,
        pagination: companies.pagination
    };
}

export function showCompanyLoader() {
    return {
        type: 'company/showLoader',
    };
}

export function hideCompanyLoader() {
    return {
        type: 'company/hideLoader',
    };
}

export const fetchCompany = (id) => async (dispatch) => {
    dispatch(showCompanyLoader());
    return await http.get(`/api/v1/company/${id}`)
    .then(res => {
        dispatch(hideCompanyLoader());
        return dispatch ({
            type: 'companies/setCompany',
            company: res.data.data,
        });
    })
    .catch(err => {
        dispatch(hideCompanyLoader());
    })
}

export const fetchCompanyUsers = (id) => async (dispatch) => {
    dispatch ({
        type: 'company-users/showLoader'
    });
    return await http.get(`/api/v1/company/${id}/users`)
        .then(res => {
            dispatch ({
                type: 'company-users/hideLoader'
            });

            return dispatch ({
                type: 'companies/setCompanyUsers',
                companyUsers: res.data.data,
            });
        })
        .catch(err => {
            dispatch ({
                type: 'company-users/hideLoader'
            });
        })

}

export const fetchDebtors = (id) => async (dispatch) => {
    dispatch ({
        type: 'company-debtors/showLoader'
    });
    return await http.get(`/api/v1/company/${id}/debtors`)
        .then(res => {
            dispatch ({
                type: 'company-debtors/hideLoader'
            });

            return dispatch ({
                type: 'companies/setDebtors',
                debtors: res.data.data,
            });
        })
        .catch(err => {
            dispatch ({
                type: 'company-debtors/hideLoader'
            });
        })

}

export const fetchCompanyUser = (id) => async (dispatch) => {
    dispatch(showUserLoader());
    return await http.get(`/api/v1/user/${id}`)
    .then(res => {
        dispatch(hideUserLoader());
        return dispatch ({
            type: 'company-users/setUser',
            user: res.data.data,
        });
    })
    .catch(err => {
        dispatch(hideUserLoader());
    })
}

export function showUserLoader() {
    return {
        type: 'company-user/showLoader',
    };
}

export function hideUserLoader() {
    return {
        type: 'company-user/hideLoader',
    };
}