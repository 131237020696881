import { combineReducers } from "redux";
import { appReducer } from './app'
import { userReducer } from './me';
import { loanOffersReducer } from './loanOffers';
import { creditRequestsReducer } from './creditRequests';
import { applicationsReducer } from './applications';
import { companiesReducer } from './companies';
import { notificationsReducer } from './notifications';
import { usersReducer } from './users';
import { bankIdReducer } from './bankid';

export const rootReducer = combineReducers({
    app: appReducer,
    me: userReducer,
    loanOffers: loanOffersReducer,
    creditRequests: creditRequestsReducer,
    applications: applicationsReducer,
    companies: companiesReducer,
    notifications: notificationsReducer,
    users: usersReducer,
    bankId: bankIdReducer,
});