import React, { useState } from 'react';
import { Form } from 'antd';
// import { message } from 'antd';
import { CustomButton } from '../../uikit/Button';
import { CustomInput } from '../../uikit/Input';
import { connect } from 'react-redux';
import http from "../../api";
import { toast } from "react-toastify";
import { CheckCircleOutlined } from '@ant-design/icons';
import './BaseProfile.scss';


const EmailProfile = (props) => {
	const [ form ] = Form.useForm();
	const { currentUser } = props;

	const [ errors, setErrors ] = useState({});

	const handleFinish = (values) => {
		http(`/api/v1/user/request-email-change`, {
			method: 'POST',
			data: values
				})
				.then((res) => {

			toast.success(
				<div className="toast-body">
					<CheckCircleOutlined className="toast__icon toast__icon--success" />
					<p className="toast-text">{`Confirmation email has been sand`}</p>
				</div>)
				})
				.catch(err => {
					if (!!err.response && !!err.response.data && !!err.response.data.errors) {
						setErrors(err.response.data.errors)
					}
				});
	};

	return (
		<div className="baseView">
			<div className="left">
				<Form
					layout="vertical"
					form={form}
					onFinish={handleFinish}
					initialValues={currentUser}
					hideRequiredMark
					style={{width: '100%'}}
				>
					<CustomInput
						label="E-postadress"
						key="email"
						name="email"
						validateStatus={ !!errors.email ? 'error' : ''}
						help={!!errors.email ? String(errors.email) : null}
						inputprops={{ 
							placeholder: 'Ange e-postadress',
							onChange: (e) => {
								setErrors({ ...errors,	name: '' });
							}
						}}
					/>
					<Form.Item>
						<div className="buttons" style={{width: 250, margin: '20px auto 0'}}>
							<CustomButton 
								buttontype="type5"
								colortype="type8"
								size="large"
								htmlType="submit"
							>
								Uppdatera
							</CustomButton>
						</div>
					</Form.Item>
				</Form>
			</div>
			<div className="right">{/* <AvatarView avatar={getAvatarURL()} />*/}</div>
		</div>
	);
};

const mapStateToProps = (store) => {
	return {
	  currentUser: store.me.profile,
	  company: !!store.me.profile.company ? store.me.profile.company : {}
	};
  };
  
  export default connect(mapStateToProps)(EmailProfile);
