import React, {Component} from 'react';
import GlobalLoader from "../components/Loaders/GlobalLoader";
import { connect } from "react-redux";
class PublicLayout extends Component {
    render() {
        return (
            <>
                { this.props.children }
                <GlobalLoader isLoading={this.props.app.showLoader}/>
            </>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        app: store.app,
    };
};

export default connect(mapStateToProps)(PublicLayout);