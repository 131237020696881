// export const getPageQuery = () => parse(window.location.href.split('?')[1]);
import { toast } from "react-toastify";
import TableFilter from "../uikit/TableFilter";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import "../scss/_toastify.scss";
import { useState } from "react";

const optionsAll = [
    {
        value: "Balansrapport - bostadsrättsförening",
        label: "Balansrapport - bostadsrättsförening",
    },
    { value: "Boverket garanti", label: "Boverket garanti" },
    { value: "Bygglov", label: "Bygglov" },
    { value: "Ekonomisk plan", label: "Ekonomisk plan" },
    { value: "Fastighetskalkyl", label: "Fastighetskalkyl" },
    { value: "Fastighetsutdrag", label: "Fastighetsutdrag" },
    { value: "Finansieringskalkyl", label: "Finansieringskalkyl" },
    { value: "Förhandsavtal", label: "Förhandsavtal" },
    {
        value: "Förteckning fastigheter/tillgångar i koncernen",
        label: "Förteckning fastigheter/tillgångar i koncernen",
    },
    { value: "Hyreslista/rentroll", label: "Hyreslista/rentroll" },
    { value: "Info kring hyreskö", label: "Info kring hyreskö" },
    { value: "Investeringsstöd", label: "Investeringsstöd" },
    { value: "Kassaflödeskalkyl", label: "Kassaflödeskalkyl" },
    {
        value: "Kassaflödeskalkyl färdigställd fastighet",
        label: "Kassaflödeskalkyl färdigställd fastighet",
    },
    { value: "Köpeavtal förvärv", label: "Köpeavtal förvärv" },
    {
        value: "Köpeavtal på fastigheterna i projektet",
        label: "Köpeavtal på fastigheterna i projektet",
    },
    { value: "Lyftplan/tidsplan", label: "Lyftplan/tidsplan" },
    { value: "Offert föreningslån", label: "Offert föreningslån" },
    {
        value: "Offert slutfinansiering färdigställd fastighet",
        label: "Offert slutfinansiering färdigställd fastighet",
    },
    {
        value: "Oreviderad balansrapport kredittagare",
        label: "Oreviderad balansrapport kredittagare",
    },
    {
        value: "Oreviderad balansrapport moderbolag",
        label: "Oreviderad balansrapport moderbolag",
    },
    {
        value: "Oreviderad balansrapport för bolagsborgen",
        label: "Oreviderad balansrapport för bolagsborgen",
    },
    {
        value: "Oreviderad resultatrapport kredittagare",
        label: "Oreviderad resultatrapport kredittagare",
    },
    {
        value: "Oreviderad resultatrapport moderbolag",
        label: "Oreviderad resultatrapport moderbolag",
    },
    {
        value: "Oreviderad resultatrapport för bolagsborgen",
        label: "Oreviderad resultatrapport för bolagsborgen",
    },
    { value: "Projektkalkyl", label: "Projektkalkyl" },
    { value: "Projektpresentation", label: "Projektpresentation" },
    { value: "Signerat entreprenadavtal", label: "Signerat entreprenadavtal" },
    { value: "Slutbesked", label: "Slutbesked" },
    { value: "Startbesked", label: "Startbesked" },
    {
        value: "Underlag nuvarande finansiering",
        label: "Underlag nuvarande finansiering",
    },
    {
        value: "Underlag som styrker återbetalning av brygga",
        label: "Underlag som styrker återbetalning av brygga",
    },
    {
        value: "Uppdaterat koncernschema upp till fysiska personer",
        label: "Uppdaterat koncernschema upp till fysiska personer",
    },
    { value: "Upplåtelseavtal", label: "Upplåtelseavtal" },
    { value: "Värdering fastighet", label: "Värdering fastighet" },
    {
        value: "Värdering som färdigställd hyresfastighet",
        label: "Värdering som färdigställd hyresfastighet",
    },
    {
        value: "Årsredovisning kredittagare",
        label: "Årsredovisning kredittagare",
    },
    { value: "Årsredovisning moderbolag", label: "Årsredovisning moderbolag" },
    {
        value: "Årsredovisning för bolagsborgen",
        label: "Årsredovisning för bolagsborgen",
    },
    { value: "Övrigt", label: "Övrigt" },
];

const optionsHyresratter = [
    {
        value: "Aktieöverlåtelseavtal fastighetsförvärvet",
        label: "Aktieöverlåtelseavtal fastighetsförvärvet",
    },
    {
        value: "Beskrivning hyregästtillträde",
        label: "Beskrivning hyregästtillträde",
    },
    { value: "Boverket garanti", label: "Boverket garanti" },
    { value: "Bygglov", label: "Bygglov" },
    { value: "CV på utvecklaren", label: "CV på utvecklaren" },
    { value: "Entreprenadsavtal", label: "Entreprenadsavtal" },
    {
        value: "Ev avtal för försäljning vid färdigställd fastighet",
        label: "Ev avtal för försäljning vid färdigställd fastighet",
    },
    { value: "Ev underleverantörsavtal", label: "Ev underleverantörsavtal" },
    { value: "Ev värdering av marken", label: "Ev värdering av marken" },
    { value: "Finansieringskalkyl", label: "Finansieringskalkyl" },
    {
        value: "Förhandsvärdering med investeringsstöd",
        label: "Förhandsvärdering med investeringsstöd",
    },
    {
        value: "Förhandsvärdering utan investeringsstöd",
        label: "Förhandsvärdering utan investeringsstöd",
    },
    {
        value: "Försäkringar för projektet (utvecklaren och byggaren)",
        label: "Försäkringar för projektet (utvecklaren och byggaren)",
    },
    { value: "Info kring hyreskö", label: "Info kring hyreskö" },
    { value: "Investeringsstöd", label: "Investeringsstöd" },
    {
        value: "Kassaflödeskalkyl färdigställd fastighet",
        label: "Kassaflödeskalkyl färdigställd fastighet",
    },
    {
        value: "Köpeavtal på fastigheterna i projektet",
        label: "Köpeavtal på fastigheterna i projektet",
    },
    { value: "Lyftplan/tidsplan", label: "Lyftplan/tidsplan" },
    { value: "Lägenhetslista", label: "Lägenhetslista" },
    { value: "Offert slutfinansiering", label: "Offert slutfinansiering" },
    {
        value: "Offert slutfinansiering färdigställd fastighet",
        label: "Offert slutfinansiering färdigställd fastighet",
    },
    {
        value: "Oreviderad balansrapport för bolagsborgen",
        label: "Oreviderad balansrapport för bolagsborgen",
    },
    {
        value: "Oreviderad balansrapport kredittagare",
        label: "Oreviderad balansrapport kredittagare",
    },
    {
        value: "Oreviderad balansrapport moderbolag",
        label: "Oreviderad balansrapport moderbolag",
    },
    {
        value: "Oreviderad resultatrapport för bolagsborgen",
        label: "Oreviderad resultatrapport för bolagsborgen",
    },
    {
        value: "Oreviderad resultatrapport kredittagare",
        label: "Oreviderad resultatrapport kredittagare",
    },
    {
        value: "Oreviderad resultatrapport moderbolag",
        label: "Oreviderad resultatrapport moderbolag",
    },
    {
        value: "Projektekonomi fullt uthyrd hyresfastighet",
        label: "Projektekonomi fullt uthyrd hyresfastighet",
    },
    { value: "Projektkalkyl", label: "Projektkalkyl" },
    { value: "Projektpresentation", label: "Projektpresentation" },
    { value: "Registreringsbevis", label: "Registreringsbevis" },
    { value: "Signerat entreprenadavtal", label: "Signerat entreprenadavtal" },
    { value: "Startbesked", label: "Startbesked" },
    {
        value: "Uppdaterat koncernschema upp till fysiska personer",
        label: "Uppdaterat koncernschema upp till fysiska personer",
    },
    {
        value: "Värdering fastighet hyresfastighet",
        label: "Värdering fastighet hyresfastighet",
    },
    {
        value: "Årsredovisning för bolagsborgen",
        label: "Årsredovisning för bolagsborgen",
    },
    {
        value: "Årsredovisning kredittagare",
        label: "Årsredovisning kredittagare",
    },
    { value: "Årsredovisning moderbolag", label: "Årsredovisning moderbolag" },
    { value: "Övrigt", label: "Övrigt" },
];

const optionsBostadsratter = [
    {
        value: "Aktieöverlåtelseavtal fastighetsförvärvet",
        label: "Aktieöverlåtelseavtal fastighetsförvärvet",
    },
    {
        value: "Avtal och reverser för transporten",
        label: "Avtal och reverser för transporten",
    },
    { value: "Bokningsavtal", label: "Bokningsavtal" },
    { value: "Boverket garanti", label: "Boverket garanti" },
    { value: "Bygglov", label: "Bygglov" },
    { value: "CV på utvecklaren", label: "CV på utvecklaren" },
    {
        value: "Ekonomiska planen (intygsgiven)",
        label: "Ekonomiska planen (intygsgiven)",
    },
    { value: "Entreprenadsavtal", label: "Entreprenadsavtal" },
    { value: "Ev andra reverser", label: "Ev andra reverser" },
    { value: "Ev underleverantörsavtal", label: "Ev underleverantörsavtal" },
    { value: "Ev värdering av marken", label: "Ev värdering av marken" },
    { value: "Finansieringskalkyl", label: "Finansieringskalkyl" },
    { value: "Förhandsavtal", label: "Förhandsavtal" },
    {
        value: "Förhandsvärdering som hyresfastighet",
        label: "Förhandsvärdering som hyresfastighet",
    },
    {
        value: "Försäkringar för projektet (föreningen och byggaren)",
        label: "Försäkringar för projektet (föreningen och byggaren)",
    },
    {
        value: "Köpeavtal på fastigheterna i projektet",
        label: "Köpeavtal på fastigheterna i projektet",
    },
    { value: "Lyftplan/tidsplan", label: "Lyftplan/tidsplan" },
    {
        value: "Mäklarprospekt (projektbeskrivning)",
        label: "Mäklarprospekt (projektbeskrivning)",
    },
    {
        value: "Oreviderad balansrapport för bolagsborgen",
        label: "Oreviderad balansrapport för bolagsborgen",
    },
    {
        value: "Oreviderad balansrapport kredittagare",
        label: "Oreviderad balansrapport kredittagare",
    },
    {
        value: "Oreviderad balansrapport moderbolag",
        label: "Oreviderad balansrapport moderbolag",
    },
    {
        value: "Oreviderad resultatrapport för bolagsborgen",
        label: "Oreviderad resultatrapport för bolagsborgen",
    },
    {
        value: "Oreviderad resultatrapport kredittagare",
        label: "Oreviderad resultatrapport kredittagare",
    },
    {
        value: "Oreviderad resultatrapport moderbolag",
        label: "Oreviderad resultatrapport moderbolag",
    },
    { value: "Projektkalkyl", label: "Projektkalkyl" },
    { value: "Registreringsbevis", label: "Registreringsbevis" },
    { value: "Signerat entreprenadavtal", label: "Signerat entreprenadavtal" },
    { value: "Startbesked", label: "Startbesked" },
    {
        value: "Uppdaterat koncernschema upp till fysiska personer",
        label: "Uppdaterat koncernschema upp till fysiska personer",
    },
    { value: "Upplåtelseavtal", label: "Upplåtelseavtal" },
    {
        value: "Årsredovisning för bolagsborgen",
        label: "Årsredovisning för bolagsborgen",
    },
    {
        value: "Årsredovisning kredittagare",
        label: "Årsredovisning kredittagare",
    },
    { value: "Årsredovisning moderbolag", label: "Årsredovisning moderbolag" },
    { value: "Övrigt", label: "Övrigt" },
];

const optionsEstateLoan = [
    { value: "Fastighetskalkyl", label: "Fastighetskalkyl" },
    {
        value: "Förteckning fastigheter/tillgångar i koncernen",
        label: "Förteckning fastigheter/tillgångar i koncernen",
    },
    { value: "Hyreslista/rentroll", label: "Hyreslista/rentroll" },
    {
        value: "Oreviderad balansrapport kredittagare",
        label: "Oreviderad balansrapport kredittagare",
    },
    {
        value: "Oreviderad balansrapport moderbolag",
        label: "Oreviderad balansrapport moderbolag",
    },
    {
        value: "Oreviderad balansrapport för bolagsborgen",
        label: "Oreviderad balansrapport för bolagsborgen",
    },
    {
        value: "Oreviderad resultatrapport kredittagare",
        label: "Oreviderad resultatrapport kredittagare",
    },
    {
        value: "Oreviderad resultatrapport moderbolag",
        label: "Oreviderad resultatrapport moderbolag",
    },
    {
        value: "Oreviderad resultatrapport för bolagsborgen",
        label: "Oreviderad resultatrapport för bolagsborgen",
    },
    { value: "Registreringsbevis", label: "Registreringsbevis" },
    {
        value: "Uppdaterat koncernschema upp till fysiska personer",
        label: "Uppdaterat koncernschema upp till fysiska personer",
    },
    { value: "Värdering fastighet", label: "Värdering fastighet" },
    {
        value: "Årsredovisning kredittagare",
        label: "Årsredovisning kredittagare",
    },
    { value: "Årsredovisning moderbolag", label: "Årsredovisning moderbolag" },
    {
        value: "Årsredovisning för bolagsborgen",
        label: "Årsredovisning för bolagsborgen",
    },
    { value: "Övrigt", label: "Övrigt" },
];

const optionsRefinancing = [
    { value: "Fastighetskalkyl", label: "Fastighetskalkyl" },
    {
        value: "Förteckning fastigheter/tillgångar i koncernen",
        label: "Förteckning fastigheter/tillgångar i koncernen",
    },
    { value: "Hyresavtal", label: "Hyresavtal" },
    { value: "Hyreslista/rentroll", label: "Hyreslista/rentroll" },
    {
        value: "Oreviderad balansrapport kredittagare",
        label: "Oreviderad balansrapport kredittagare",
    },
    {
        value: "Oreviderad balansrapport moderbolag",
        label: "Oreviderad balansrapport moderbolag",
    },
    {
        value: "Oreviderad balansrapport för bolagsborgen",
        label: "Oreviderad balansrapport för bolagsborgen",
    },
    {
        value: "Oreviderad resultatrapport kredittagare",
        label: "Oreviderad resultatrapport kredittagare",
    },
    {
        value: "Oreviderad resultatrapport moderbolag",
        label: "Oreviderad resultatrapport moderbolag",
    },
    {
        value: "Oreviderad resultatrapport för bolagsborgen",
        label: "Oreviderad resultatrapport för bolagsborgen",
    },
    { value: "Registreringsbevis", label: "Registreringsbevis" },
    {
        value: "Underlag nuvarande finansiering",
        label: "Underlag nuvarande finansiering",
    },
    {
        value: "Uppdaterat koncernschema upp till fysiska personer",
        label: "Uppdaterat koncernschema upp till fysiska personer",
    },
    { value: "Värdering fastighet", label: "Värdering fastighet" },
    {
        value: "Årsredovisning kredittagare",
        label: "Årsredovisning kredittagare",
    },
    { value: "Årsredovisning moderbolag", label: "Årsredovisning moderbolag" },
    {
        value: "Årsredovisning för bolagsborgen",
        label: "Årsredovisning för bolagsborgen",
    },
    { value: "Övrigt", label: "Övrigt" },
];

const optionsShortTermLoan = [
    { value: "Fastighetskalkyl", label: "Fastighetskalkyl" },
    {
        value: "Förteckning fastigheter/tillgångar i koncernen",
        label: "Förteckning fastigheter/tillgångar i koncernen",
    },
    { value: "Hyreslista/rentroll", label: "Hyreslista/rentroll" },
    {
        value: "Oreviderad balansrapport kredittagare",
        label: "Oreviderad balansrapport kredittagare",
    },
    {
        value: "Oreviderad balansrapport moderbolag",
        label: "Oreviderad balansrapport moderbolag",
    },
    {
        value: "Oreviderad balansrapport för bolagsborgen",
        label: "Oreviderad balansrapport för bolagsborgen",
    },
    {
        value: "Oreviderad resultatrapport kredittagare",
        label: "Oreviderad resultatrapport kredittagare",
    },
    {
        value: "Oreviderad resultatrapport moderbolag",
        label: "Oreviderad resultatrapport moderbolag",
    },
    {
        value: "Oreviderad resultatrapport för bolagsborgen",
        label: "Oreviderad resultatrapport för bolagsborgen",
    },
    { value: "Registreringsbevis", label: "Registreringsbevis" },
    {
        value: "Uppdaterat koncernschema upp till fysiska personer",
        label: "Uppdaterat koncernschema upp till fysiska personer",
    },
    {
        value: "Underlag som styrker återbetalning av brygga",
        label: "Underlag som styrker återbetalning av brygga",
    },
    { value: "Värdering fastighet", label: "Värdering fastighet" },
    {
        value: "Årsredovisning kredittagare",
        label: "Årsredovisning kredittagare",
    },
    { value: "Årsredovisning moderbolag", label: "Årsredovisning moderbolag" },
    {
        value: "Årsredovisning för bolagsborgen",
        label: "Årsredovisning för bolagsborgen",
    },
    { value: "Övrigt", label: "Övrigt" },
];

const optionsPropertyAquision = [
    { value: "Aktielöverlåtelseavtalet", label: "Aktielöverlåtelseavtalet" },
    { value: "Fastighetskalkyl", label: "Fastighetskalkyl" },
    {
        value: "Förteckning fastigheter/tillgångar i koncernen",
        label: "Förteckning fastigheter/tillgångar i koncernen",
    },
    { value: "Hyreslista/rentroll", label: "Hyreslista/rentroll" },
    { value: "Hyresavtal", label: "Hyresavtal" },
    { value: "Köpeavtal förvärv", label: "Köpeavtal förvärv" },
    {
        value: "Oreviderad balansrapport kredittagare",
        label: "Oreviderad balansrapport kredittagare",
    },
    {
        value: "Oreviderad balansrapport moderbolag",
        label: "Oreviderad balansrapport moderbolag",
    },
    {
        value: "Oreviderad balansrapport för bolagsborgen",
        label: "Oreviderad balansrapport för bolagsborgen",
    },
    {
        value: "Oreviderad resultatrapport kredittagare",
        label: "Oreviderad resultatrapport kredittagare",
    },
    {
        value: "Oreviderad resultatrapport moderbolag",
        label: "Oreviderad resultatrapport moderbolag",
    },
    {
        value: "Oreviderad resultatrapport för bolagsborgen",
        label: "Oreviderad resultatrapport för bolagsborgen",
    },
    { value: "Proforma balansräkning", label: "Proforma balansräkning" },
    { value: "Registreringsbevis", label: "Registreringsbevis" },
    {
        value: "Uppdaterat koncernschema upp till fysiska personer",
        label: "Uppdaterat koncernschema upp till fysiska personer",
    },
    { value: "Värdering fastighet", label: "Värdering fastighet" },
    {
        value: "Årsredovisning kredittagare",
        label: "Årsredovisning kredittagare",
    },
    { value: "Årsredovisning moderbolag", label: "Årsredovisning moderbolag" },
    {
        value: "Årsredovisning för bolagsborgen",
        label: "Årsredovisning för bolagsborgen",
    },
    { value: "Övrigt", label: "Övrigt" },
];

const optionsOmbildning = [
    { value: "Avtal förvaltning", label: "Avtal förvaltning" },
    { value: "Bokningsavtal", label: "Bokningsavtal" },
    { value: "Ekonomisk plan", label: "Ekonomisk plan" },
    {
        value: "Ekonomisk översikt (kalkyl)",
        label: "Ekonomisk översikt (kalkyl)",
    },
    { value: "Försäkringsbrev", label: "Försäkringsbrev" },
    { value: "Förvaltningsavtal", label: "Förvaltningsavtal" },
    { value: "Hyresavtal lokal", label: "Hyresavtal lokal" },
    { value: "Hyreskontrakt lokal", label: "Hyreskontrakt lokal" },
    { value: "Köpeavtal", label: "Köpeavtal" },
    { value: "Proformabalansräkning", label: "Proformabalansräkning" },
    { value: "Registreringsbevis", label: "Registreringsbevis" },
    { value: "Stadgar", label: "Stadgar" },
    { value: "Teknisk statusbesiktning", label: "Teknisk statusbesiktning" },
    { value: "Teknisk statusbesiktning", label: "Teknisk statusbesiktning" },
    { value: "Upplåtelseavtal", label: "Upplåtelseavtal" },
    { value: "Värdering fastighet", label: "Värdering fastighet" },
    { value: "Översiktskalkyl tillträde", label: "Översiktskalkyl tillträde" },
    { value: "Övrigt", label: "Övrigt" },
];

const optionsNyproduktion = [
    { value: "Balansrapport förening", label: "Balansrapport förening" },
    { value: "Bokningsavtal", label: "Bokningsavtal" },
    { value: "Ekonomisk plan", label: "Ekonomisk plan" },
    { value: "Förhandsavtal", label: "Förhandsavtal" },
    { value: "Försäkringsbrev", label: "Försäkringsbrev" },
    { value: "Förvaltningsavtal", label: "Förvaltningsavtal" },
    { value: "Hyresavtal lokal", label: "Hyresavtal lokal" },
    { value: "Köpeavtal", label: "Köpeavtal" },
    { value: "Projektpresentation", label: "Projektpresentation" },
    { value: "Registreringsbevis", label: "Registreringsbevis" },
    { value: "Slutbesked", label: "Slutbesked" },
    { value: "Teknisk statusbesiktning", label: "Teknisk statusbesiktning" },
    { value: "Upplåtelseavtal", label: "Upplåtelseavtal" },
    { value: "Värdering fastighet", label: "Värdering fastighet" },
    { value: "Översiktskalkyl tillträde", label: "Översiktskalkyl tillträde" },
    { value: "Övrigt", label: "Övrigt" },
];

export const handleOptions = (type, projectType) => {
    switch (type) {
        case "TYPE_BUILDING_CREDIT":
            if (projectType === "hyresrätter") {
                return optionsHyresratter; // 'Byggnadskreditiv'
            } else {
                return optionsBostadsratter;
            }

        case "TYPE_BUILDING_CREDIT_HYRESRATTER":
            return optionsHyresratter;

        case "TYPE_BUILDING_CREDIT_BOSTADSRATTER":
            return optionsBostadsratter;

        case "TYPE_REAL_ESTATE_LOAN":
        case "TYPE_REAL_ESTATE_LOAN_AUTO":
            return optionsEstateLoan; // 'Fastighetslån'

        case "TYPE_REFINANCING":
            return optionsRefinancing; // 'Refinansiering'

        case "TYPE_SHORT_TERM_LOAN":
            return optionsShortTermLoan; //'Brygglån'

        case "TYPE_PROPERTY_ACQUISITION":
            return optionsPropertyAquision; // 'Fastighetsförvärv'

        case "TYPE_ASSOCIATION_LOAN":
            if (projectType === "Ombildning") {
                return optionsOmbildning; // 'Föreningslån'
            } else {
                return optionsNyproduktion;
            }

        default:
            return optionsAll;
    }
};

export function getQueryParam(name) {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get(name) || "";
}

export const priorDate = new Date(
    new Date().setDate(new Date().getDate() - 30)
).toLocaleDateString("sv-SE");

export function getPriorDate() {
    return new Date(
        new Date().setDate(new Date().getDate() - 30)
    ).toLocaleDateString("sv-SE");
}

export const getColumnSearchProps = ({
    isChecked = () => [],
    setChecked = (value) => {},
    removeChecked = () => {},
}) => ({
    filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
    }) => (
        <TableFilter
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
            filters={filters}
            setChecked={setChecked}
            removeChecked={removeChecked}
        />
    ),
    filteredValue: isChecked(),
});

export const getStatus = (status, loanOfferStatus = "") => {
    let className = "";
    let value = "-";
    if (loanOfferStatus === "STATUS_DECLINED_BY_BANK") {
        className = "red";
        value = "Avslagen";
    } else if (status === "STATUS_DRAFT") {
        className = "black";
        value = "Utkast";
    } else if (status === "STATUS_ACTIVE") {
        className = "black";
        value = "Aktiv";
    } else if (status === "STATUS_CANCELED") {
        className = "red";
        value = "Avslag";
    } else if (status === "STATUS_CLOSED") {
        className = "red";
        value = "Avslutad";
    } else if (status === "STATUS_REJECTED") {
        className = "red";
        value = "Avböjt";
    } else if (status === "STATUS_LOST") {
        className = "red";
        value = "Förlorad";
    } else if (status === "STATUS_PROCESSING") {
        className = "black";
        value = "Under handläggning";
    } else if (status === "STATUS_IN_REVIEW") {
        className = "black";
        value = "In review";
    } else if (status === "STATUS_OFFER_PUT") {
        className = "black";
        value = "Bud lagt";
    } else if (status === "STATUS_DECLINED") {
        className = "red";
        value = "Avböjt";
    } else if (status === "STATUS_APPROVED") {
        className = "green";
        value = "Godkänt";
    } else if (status === "CREATED") {
        className = "black";
        value = "Aktiv";
    } else if (status === "STATUS_PAID_OUT") {
        className = "green";
        value = "Utbetald";
    } else if (status === "STATUS_PAID_DOWN") {
        className = "black";
        value = "Återbetald";
    }

    return <span className={`${className}`}>{value}</span>;
};

export const getProduct = (type) => {
    switch (type) {
        case "TYPE_BUILDING_CREDIT":
            return "Byggnadskreditiv";

        case "TYPE_BUILDING_CREDIT_HYRESRATTER":
            return "Byggnadskreditiv hyresrätter";

        case "TYPE_BUILDING_CREDIT_BOSTADSRATTER":
            return "Byggnadskreditiv bostadsrätter";

        case "TYPE_REAL_ESTATE_LOAN":
            return "Fastighetslån Large";

        case "TYPE_REAL_ESTATE_LOAN_AUTO":
            return "Fastighetslån Small";

        case "TYPE_REFINANCING":
            return "Refinansiering";

        case "TYPE_SHORT_TERM_LOAN":
            return "Brygglån";

        case "TYPE_PROPERTY_ACQUISITION":
            return "Fastighetsförvärv";

        case "TYPE_ASSOCIATION_LOAN":
            return "Föreningslån";

        default:
            return "-";
    }
};

export const getFilter = (name) => {
    let store = localStorage.getItem(name);
    return store === null || store === undefined || !store
        ? []
        : JSON.parse(store);
};

export const setFilter = (name, value) => {
    localStorage.setItem(name, JSON.stringify(value));
};

export const isFiltered = (filters, filterName) => {
    return !!filters.find((i) => i === filterName);
};

export const successAlert = (message) => {
    toast.success(
        <div className="toast-body">
            <CheckCircleOutlined className="toast__icon toast__icon--success" />
            <p className="toast-text">{message}</p>
        </div>
    );
};

export const errorAlert = (message) => {
    toast.error(
        <div className="toast-body">
            <CloseCircleOutlined className="toast__icon toast__icon--error" />
            <p className="toast-text">{message}</p>
        </div>
    );
};

export const useSsn = (initialSsn = "") => {
    const [ssnInner, setInnerSsn] = useState(initialSsn);

    function setFormatedSsn(rawSsn) {
        let newval = rawSsn !== undefined ? rawSsn.replace(/[^-\d]/gm, "") : "";
        newval =
            !!newval.match(/-/g) && newval.match(/-/g).length > 1
                ? ssnInner
                : newval;
        setInnerSsn(newval);
    }

    return [ssnInner, setFormatedSsn];
};
