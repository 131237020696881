import React from "react";
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { Text } from '../../../../uikit/Text';
// import { CustomButton } from '../../../../uikit/Button';
import {
    Form,
    Card,
    Spin,
    Row,
    Col
} from 'antd';
import { getQueryParam } from '../../../../utils/helpers';
import { fetchCompany, fetchCompanyUsers } from '../../../../actions/users/companies';
// import http from "../../../../api";
import UsersForm from './components/UsersForm';
import './style.scss';


const CompanyUsersDetailsForm = (props) => {
    const { 
        company,
    } = props;

    let id = '';
    if (!!getQueryParam('id')) {
      id = getQueryParam('id')
    } 

    const [ form ] = Form.useForm();


        return (
            <>
                <div className='goBack'>
                    <div style={{paddingBottom: 30}} className={"backLink backBtn titleWrapper"}>
                        <Link
                            to={
                                {
                                    pathname: '/companies/detail',
                                    search: `?id=${id}`
                                }
                            }
                            onClick={() => { props.history.goBack(); }} 
                            style={{float: 'left', paddingTop:5, fontSize: 13, fontWeight: 400, color: '#9199AA'}}
                        >
                            <ArrowLeftOutlined style={{ marginRight: 14 }} />
                            Gå tillbaka
                        </Link>
                        <h2 className='text-center'>
                            {!!company && company.name ? company.name : 'Användare' }
                        </h2>
                    </div>  
                </div>
            <div>
                <div style={{background: '#FFFFFF'}}>
                    <Row gutter={24} type="flex">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Card
                                // loading={loading}
                                className="cardDetaill bidDetaill"
                                style={{height: '100%'}}
                                bodyStyle={{
                                padding: '28',
                                borderRadius: 6
                                }}
                            >
                                <Text texttype="back" colortype="back" customclass="cardTitle">
                                    Användare
                                </Text>
                                <p 
                                    style={{
                                        fontWeight: 700,
                                        fontSize: '18px',
                                        color: '#2a2b2d',
                                        lineHeight: '23px'
                                    }}
                                >
                                    Lägg till uppgifter om användare nedan.
                                </p>
                                <Spin spinning={props.loadingCompanyUsers}>
                                    <UsersForm form={form} companyId={id}/>
                                </Spin>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
          </>
        );
    // }
}

const mapStateToProps = (store) => {
    return {
        company: store.companies.company,
        loadingCompany: store.companies.isFetching_company,
        user: store.me.profile,
        users: store.companies.companyUsers,
        loadingCompanyUsers: store.companies.isFetching_companyUsers,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCompany: (id) => dispatch(fetchCompany(id)),
        fetchCompanyUsers: (id) => dispatch(fetchCompanyUsers(id)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyUsersDetailsForm);