const initialState = {
    list: [],
	list_pagination: {},
	isLoaded_list: false,
	isFetching_list: false,

	creditRequest: {},
	isFetching_creditRequest: false,
	isLoaded_creditRequest: false,

	banks: [],
	isFetching_banks: false,
	isLoaded_banks: false,

	applicationCreditRequests: [],
	isFetching_applicationCreditRequests: false,
	isLoaded_applicationCreditRequests: false,
};

export function creditRequestsReducer(state = initialState, action) {
    switch (action.type) {
		case 'credit-requests/setList':
            return {
                ...state,
                list: action.list,
				list_pagination: action.pagination,
                isLoaded_list: true
            };

		case 'credit-requests/showLoader':
			return {
				...state,
				isFetching_list: true,
			};

		case 'credit-requests/hideLoader':
			return {
				...state,
				isFetching_list: false,
			};

		case 'credit-requests/setCreditRequest':
			return {
				...state,
				creditRequest: action.creditRequest,

				isLoaded_creditRequest: true
			};

		case 'credit-request/showLoader':
			return {
				...state,
				isFetching_creditRequest: true,
			};

		case 'credit-request/hideLoader':
			return {
				...state,
				isFetching_creditRequest: false,
			};

		case 'credit-requests/setApplicationCreditRequests':
			return {
				...state,
				applicationCreditRequests: action.creditRequests,
				isLoaded_applicationCreditRequests: true
			};

		case 'credit-requests/setBanks':
			return {
				...state,
				banks: action.banks,
				isLoaded_banks: true,
			};

        default:
            return state;
    }
}
