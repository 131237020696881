import React from 'react';
import { Drawer} from 'antd';
import "./styles.scss";

const RightPanelDrawer = (props) => {
  const { onClose, visible, title } = props;

  return (
    <Drawer
      className="customDrawer"
      title={!!title ? title : ''}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      style={{ position: 'absolute' }}
      getContainer={false}
    >
      {props.children}
    </Drawer>
  )
}

export default RightPanelDrawer;