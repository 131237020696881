import React, { Component, useEffect } from "react";
import { Table } from "antd";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ReactComponent as EyeIcon } from "../../../../assets/admin/eye.svg";
import { Spin, Checkbox, Space, Button } from "antd";
import {
    CheckCircleFilled,
    DeleteOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import { getStatus, getProduct } from "../../../../utils/helpers";
import CustomTable from "../../../../components/CumtomTable/index";
import { fetchApplications } from "../../../../actions/applications";
import { getColumnSearchProps } from "../../../../utils/helpers";
import { Search } from "../../../../uikit/Search";
import {
    getQueryParam,
    getFilter,
    setFilter,
    isFiltered,
} from "../../../../utils/helpers";

import "./style.scss";
import TableFilter from "../../../../uikit/TableFilter";
import DeleteModal from "../../DeleteModal";
import { toast } from "react-toastify";
import { deleteApplication } from "../../../../actions/users/admins";
class CreditRequestTable extends Component {
    constructor() {
        super();
        this.state = {
            search: "",
        };
    }

    componentDidMount() {
        setFilter("applications-filter", []);
    }

    saveRequestParams = (
        currentPage,
        pageSize,
        sortField,
        sorrtOrder,
        filters
    ) => {
        this.setState({
            currentPage,
            pageSize,
            sortField,
            sorrtOrder,
            filters,
        });
    };

    getApplications = () => {
        const {
            currentPage,
            pageSize,
            sortField,
            sorrtOrder,
            filters,
            search,
        } = this.state;
        this.props.fetchApplications(
            currentPage,
            pageSize,
            sortField,
            sorrtOrder,
            filters,
            search
        );
    };

    setSearch = (search) => {
        this.setState({ search: search });
    };

    formatDate = (item, column) => {
        let value;
        if (item && item[column]) {
            value = new Date(item[column]).toLocaleDateString("sv-SE");
        } else {
            value = "-";
        }
        // return dateStr.toString().slice(0, 10).split('-').reverse().join('.');
        return value;
    };

    onDeleteOpen = (user) => () => {
        this.setState({ applicationToDelete: user });
    };

    onSuccess = () => {
        this.props.fetchApplications(0, 10, null, null);
        this.setState({ applicationToDelete: null });
        toast.success(
            <div className="toast-body">
                <CheckCircleFilled className="toast__icon toast__icon--success" />
                <p className="toast-text">Application has been deleted!</p>
            </div>
        );
    };

    onDeleteApplication = (user) => () => {
        this.props.deleteApplication(user.id, this.onSuccess);
    };

    onModalCancel = () => {
        this.setState({ applicationToDelete: null });
    };

    render() {
        const { Column } = Table;
        const { applicationToDelete } = this.state;

        const columns = [
            {
                title: "id",
                dataIndex: "id",
                key: "id",
                sorter: true,
                style: { textAlign: "start" },
                render: (text, record) => (
                    <div className="actionBtn">
                        <Link
                            to={`${"/credit-request/detail?id=" + record?.id}`}
                            style={{
                                color: "inherit",
                                fontWeight: 800,
                            }}
                        >
                            {record?.id || "-"}
                        </Link>
                        <div className="width"></div>
                    </div>
                ),
            },
            {
                title: "Låntagare",
                dataIndex: "debtorName",
                key: "debtorName",
                sorter: true,
                className: "debtorCell",
                style: { maxWidth: "300px" },
                render: (text, record) => record?.data?.debtorName || "-",
            },
            {
                title: "Huvudkonto",
                dataIndex: "user.company.name",
                key: "user.company.name",
                sorter: true,
                className: "debtorCell",
                style: { maxWidth: "300px" },
                render: (text, record) => record?.company?.name || "-",
            },
            {
                title: "Datum",
                dataIndex: "createdAt",
                key: "created_at",
                sorter: true,
                render: (data, record) => (
                    <span>{this.formatDate(record, "createdAt") || "-"}</span>
                ),
            },
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                sorter: true,
                filters: [
                    { text: "Aktiv", value: "STATUS_ACTIVE", field: "status" },
                    // { text: 'Draft', value: 'STATUS_DRAFT' },
                ],
                render: (text, record) => (
                    <span>{getStatus(record?.status) || "-"}</span>
                ),
                ...getColumnSearchProps({
                    isChecked: () => localStorage.getItem("status"),
                    setChecked: (value) =>
                        localStorage.setItem("status", value),
                    removeChecked: () => localStorage.removeItem("status"),
                }),
            },
            {
                title: "Belopp",
                dataIndex: "amount",
                key: "amount",
                sorter: true,
                render: (text, record) => (
                    <span>
                        {!!record?.data?.amount
                            ? `${record.data.amount
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} SEK`
                            : "-"}
                    </span>
                ),
            },
            {
                title: "Ändamål",
                dataIndex: "type",
                key: "type",
                sorter: true,
                render: (text, record) => (
                    <span>{getProduct(record?.type) || "-"}</span>
                ),
            },
            {
                title: "Antal bud",
                dataIndex: "loanOfferCount",
                key: "loanOfferCount",
                sorter: true,
                render: (text, record) => (
                    <span>
                        {!!record?.loanOfferCount ? record.loanOfferCount : 0}
                    </span>
                ),
            },
            {
                key: "myCreditRequests",
                dataIndex: "myCreditRequests",
                className: "table-custom-filter",
                filters: [
                    {
                        text: "Mina kreditförfrågningar",
                        value: "myCreditRequests",
                    },
                    {
                        text: "Avslagna",
                        value: "applicationCanceled",
                    },
                    // { text: 'Draft', value: 'STATUS_DRAFT' },
                ],
                render: (text, record) => (
                    <div className="actionBtn">
                        <Link
                            to={`${"/credit-request/detail?id=" + record?.id}`}
                        >
                            <button
                                buttontype="type10"
                                className="buttonType10"
                                size="small"
                                style={{
                                    width: "100%",
                                    backgroundColor: "#fff",
                                    display: "inline-flex",
                                    alignItems: "center",
                                }}
                            >
                                <EyeIcon
                                    style={{
                                        marginRight: 10,
                                        width: 20,
                                    }}
                                />
                                Visa
                            </button>
                        </Link>
                        <Button
                            className="buttonType10 iconBtnTable widthNone"
                            styles={{ width: "fit-conent" }}
                            onClick={this.onDeleteOpen(record)}
                        >
                            <DeleteOutlined className="iconDelete" />
                        </Button>
                        <div className="width"></div>
                    </div>
                ),
                ...getColumnSearchProps({
                    isChecked: () => getFilter("applications-filter"),
                    setChecked: (value) =>
                        setFilter("applications-filter", value),
                }),
            },
        ];
        return (
            <Spin spinning={this.props.loading}>
                <Search
                    search={this.state.search}
                    setSearch={this.setSearch}
                    fetchData={this.getApplications}
                />
                <CustomTable
                    className="tableLoanOffersTable"
                    dataSource={this.props.loanList}
                    dataSourcePagination={this.props.listPagination}
                    fetchData={this.props.fetchApplications}
                    columns={columns}
                />
                <DeleteModal
                    show={!!applicationToDelete}
                    isLoading={false}
                    title="Delete user"
                    description={`Are you sure you want to delete application - ${applicationToDelete?.id})`}
                    handleCancel={this.onModalCancel}
                    onDelete={this.onDeleteApplication(applicationToDelete)}
                />
            </Spin>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        loanList: store.applications.list,
        listPagination: store.applications.list_pagination,
        loading: store.applications.isFetching_list,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteApplication: (id, onSuccess) =>
            dispatch(deleteApplication(id, onSuccess)),
        fetchApplications: (
            currentPage,
            pageSize,
            sortField,
            sorrtOrder,
            filters,
            data
        ) => {
            let filter = getFilter("applications-filter");
            filters = {
                ...filters,
                myCreditRequests: isFiltered(filter, "myCreditRequests"),
                applicationCanceled: isFiltered(filter, "applicationCanceled"),
            };
            let search = getQueryParam("search");
            return dispatch(
                fetchApplications(
                    currentPage,
                    pageSize,
                    sortField,
                    sorrtOrder,
                    filters,
                    search
                )
            );
        },
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CreditRequestTable)
);
