import React, {Component} from "react";
import {withRouter} from "react-router";
import logo from '../../assets/logo.svg';
import logoText from '../../assets/logo_text.svg';
import "./index.scss";

class HeaderLeft extends Component {

    render() {
        const { history } = this.props;

        return (
            <div className="left me-auto align-items-center" >
                <div className="d-flex" onClick={() => history.push('/')}>
                    <div className="headerLogo">
                        <img src={logo} alt="logo symbol"/>
                    </div>
                    <div className="headerLogoText">
                        <img src={logoText} alt="logo text"/>
                    </div>
                </div >
                {this.props.children}
            </div>
        );
    }
}

export default withRouter(HeaderLeft);