import React from 'react';
import { Route } from "react-router-dom";
// import { deleteTokens, isLoggedIn } from "../utils/auth";
import PublicLayout from "../layouts/PublicLayout";

const ConfirmRoute = ({component: Component, ...rest}) => {

    // useEffect(() => {
    //     isLoggedIn() && deleteTokens();
    //   }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Route
            {...rest}
            render={(props) =>
                <PublicLayout>
                    <Component {...props} />
                </PublicLayout>
            }
        />
    );
}

export default ConfirmRoute;
