import React from 'react';
import {
  Card,
  Row,
  Col
} from 'antd';
import { connect } from 'react-redux';
import '../style.scss';
import { Text } from '../../../../uikit/Text';
import { getProduct } from '../../../../utils/helpers';

const CreditInfo = (props) => {
  const { creditRequest } = props;

  const topColResponsiveProps1 = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 13,
    style: {
      marginBottom: 24,
    },
  };

  const formatDate = (date) => {
    if (date) {
      return new Date(date).toLocaleDateString('sv-SE')
    }
    return '-'
  }

  const getStatus = (cr) => {
    let status = cr?.status
    let className = '';
    let value = '-';

    if (cr?.loanOffer?.status === "STATUS_DECLINED_BY_BANK"){
        className = 'red';
        value = 'Avslagen';
    } else if (status === 'STATUS_DRAFT') {
        className = 'black';
        value = 'Utkast';
    } else if  (status === 'STATUS_ACTIVE') {
        className = 'black';
        value = 'Aktiv';
    } else if  (status === 'STATUS_CANCELED') {
        className = 'red';
        value = 'Avslag';
    } else if  (status === 'STATUS_REJECTED') {
        className = 'red';
        value = 'Avböjt';
    } else if (status === "STATUS_LOST") {
      className = "red";
      value = "Förlorad";
    }else if (status === 'STATUS_PROCESSING') {
        className = 'black';
        value = 'Under handläggning';
    } else if  (status === 'STATUS_IN_REVIEW') {
        className = 'black';
        value = 'In review';
    } else if (status === 'STATUS_OFFER_PUT') {
        className = 'black';
        value = 'Bud lagt';
    } else if  (status === 'STATUS_DECLINED') {
        className = 'red';
        value = 'Avslagen';
    } else if (status === 'STATUS_APPROVED') {
        className = 'green';
        value = 'Godkänt';  
    } else if (status === 'CREATED') {
        className = 'black';
        value = 'Aktiv';  
    } else if  (status === 'STATUS_PAID_OUT') {
        className = 'green';
        value = 'Utbetald';
    } else if  (status === 'STATUS_PAID_DOWN') {
        className = 'black';
        value = 'Återbetald';
    }

    return <span className={`${className}`}>{value}</span>
}

  return (
    <>
      <Col {...topColResponsiveProps1} className="creditInfoCard">
        <Card
            // loading={loading}
            className="cardDetaill"
            style={{marginBottom: 30}}
            bodyStyle={{
            padding: '28',
            borderRadius: 6
            }}
        >
          <Text texttype="back" colortype="back" customclass="cardTitle">
              Kredituppgifter
          </Text>
          <Row gutter={16}>
              <Col padding={10} className="gutter_row" xl={8} lg={8}	md={8} sm={24} xs={24}>
                <div className="labelitem">{'Status:'}</div>
              </Col>
              <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
                  <div className="valueitem">
                    {getStatus(creditRequest)}
                  </div>
              </Col>
          </Row>
          <Row gutter={16}>
              <Col padding={10} className="gutter_row" xl={8} lg={8}	md={8} sm={24} xs={24}>
                <div className="labelitem">{'ID:'}</div>
              </Col>
              <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
                  <div className="valueitem">{creditRequest?.id}</div>
              </Col>
          </Row>
          <Row gutter={16}>
              <Col padding={10} className="gutter_row" xl={8} lg={8}	md={8} sm={24} xs={24}>
              <div className="labelitem">{'Datum:'}</div>
              </Col>
              <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
                  <div className="valueitem">
                  {formatDate(creditRequest?.createdAt)}
                  </div>
              </Col>
          </Row>
          <Row gutter={16}>
              <Col padding={10} className="gutter_row" xl={8} lg={8}	md={8} sm={24} xs={24}>
              <div className="labelitem">{'Produkt:'}</div>
              </Col>
              <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
                  <div className="valueitem">
                  {getProduct(creditRequest?.application?.type)}
                  </div>
              </Col>
          </Row>
          <Row gutter={16}>
              <Col padding={10} className="gutter_row" xl={8} lg={8}	md={8} sm={24} xs={24}>
              <div className="labelitem">{'Önskad löptid:'}</div>
              </Col>
              <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
                  <div className="valueitem">{creditRequest?.application?.data?.period || '0'}</div>
              </Col>
          </Row>
          <Row gutter={16}>
              <Col padding={10} className="gutter_row" xl={8} lg={8}	md={8} sm={24} xs={24}>
              <div className="labelitem">{'Belopp:'}</div>
              </Col>
              <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
                  <div className="valueitem">
                    {!!creditRequest?.application?.data?.amount 
                      ? `${creditRequest?.application?.data?.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} SEK` 
                      : '0 SEK'}
                  </div>
              </Col>
          </Row>
        </Card>
      </Col>
    </>
  );
}

const mapStateToProps = (store) => {
  return {
    creditRequest: store.creditRequests.creditRequest,
    // application: store.applications.application
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
      // fetchCreditRequest: (id) => dispatch(fetchCreditRequest(id))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditInfo);
