import React, { useEffect } from "react";
import { Card, Spin, Table, Col, Form } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "../style.scss";
import { Text } from "../../../../../uikit/Text";
import { fetchDebtors } from "../../../../../actions/users/companies";
import { getQueryParam } from "../../../../../utils/helpers";
import { CustomButton } from "../../../../../uikit/Button";
import { EditOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { ReactComponent as EyeIcon } from "../../../../../assets/admin/eye.svg";
import AdminLinkSelect from "./AdminLinkSelect";
import { fetchUsersAdmins } from "../../../../../actions/users/admins";

const UsersInfo = (props) => {
	const { debtors, admins, fetchUsersAdmins, me } = props;
	const { Column } = Table;

	const topColResponsiveProps = {
		xs: 24,
		sm: 24,
		md: 24,
		lg: 24,
		xl: 24,
		style: {
			marginBottom: 24,
		},
	};

	let id = "";
	if (!!getQueryParam("id")) {
		id = getQueryParam("id");
	}

	useEffect(() => {
		props.fetchCompanyUsers(id);
		if (admins?.length === 0) fetchUsersAdmins(0, 2147483647);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const getRole = (role) => {
		if (role === "CUSTOMER") {
			role = "Kund";
		} else if (role === "CREDITOR") {
			role = "Kreditgivare";
		} else if (role === "ADMIN") {
			role = "Administrator";
		} else {
			role = "-";
		}

		return role;
	};
	return (
		<Spin spinning={props.loading}>
			<div>
				<Col {...topColResponsiveProps}>
					<Card
						// loading={loading}
						className="cardDetaill"
						style={{ marginBottom: 30, height: "100%" }}
						bodyStyle={{
							padding: "28",
							borderRadius: 6,
						}}
					>
						<Text
							texttype="back"
							colortype="back"
							customclass="cardTitle"
						>
							Gäldenärsinformation
						</Text>
						<Table
							dataSource={debtors}
							pagination={false}
							rowKey="id"
							className="usersList"
						>
							<Column
								title="ID"
								dataIndex="id"
								key="id"
								render={(text, record) => (
									<span>{record?.id || "-"}</span>
								)}
							/>
							<Column
								title="Kredittagare"
								dataIndex="debtorName"
								key="debtorName"
								render={(text, record) => (
									<span>
										{record?.data?.debtorName || "-"}
									</span>
								)}
							/>
							<Column
								title="Administratör"
								render={(text, record) => (
									<AdminLinkSelect
										id={record?.id}
										adminId={record?.adminId}
										admins={admins}
										me={me}
									/>
								)}
							/>
							<Column
								key="loan_details"
								render={(text, record) => (
									<>
										<div className="actionBtn">
											<Link
												to={`${
													"/credit-request/detail?id=" +
													record?.id
												}`}
											>
												<button
													buttontype="type10"
													className="buttonType10"
													size="small"
													style={{
														width: "100%",
														backgroundColor: "#fff",
														display: "inline-flex",
														alignItems: "center",
													}}
												>
													<EyeIcon
														style={{
															marginRight: 10,
															width: 20,
														}}
													/>
													Visa
												</button>
											</Link>
											<div className="width"></div>
										</div>
									</>
								)}
							/>
						</Table>
					</Card>
				</Col>
			</div>
		</Spin>
	);
};

const mapStateToProps = (store) => {
	return {
		me: store.me.profile,
		debtors: store.companies.debtors,
		loading: store.companies.isFetching_debtors,
		admins: store.users.list,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchCompanyUsers: (id) => dispatch(fetchDebtors(id)),
		fetchUsersAdmins: (currentPage, pageSize, sortField, sorrtOrder) =>
			dispatch(
				fetchUsersAdmins(currentPage, pageSize, sortField, sorrtOrder)
			),
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(UsersInfo)
);
