import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Text } from "../../../../uikit/Text";
// import { CustomButton } from '../../../../uikit/Button';
import { Form, Card, Spin, Row, Col } from "antd";
import { getQueryParam } from "../../../../utils/helpers";
import { fetchCompanyUser } from "../../../../actions/users/companies";
import UsersForm from "./components/UsersForm";
import "./style.scss";

const CompanyUsersDetailsEditForm = (props) => {
    let id = "";
    if (!!getQueryParam("id")) {
        id = getQueryParam("id");
    }

    // let userId = '';
    // if (!!getQueryParam('userId')) {
    //     userId = getQueryParam('userId')
    // }

    const [form] = Form.useForm();
    const [user, setUser] = useState({});

    useEffect(() => {
        props.fetchCompanyUser(id).then((res) => {
            if (!!res && !!res.user) {
                form.setFieldsValue({
                    firstName: res.user.firstName,
                    lastName: res.user.lastName,
                    phoneNumber: res.user.phoneNumber,
                    email: res.user.email,
                    role: res.user.role,
                    ssn: res.user.ssn,
                });

                setUser({
                    firstName: res.user.firstName,
                    lastName: res.user.lastName,
                    phoneNumber: res.user.phoneNumber,
                    email: res.user.email,
                    role: res.user.role,
                    ssn: res.user.ssn,
                    companyId: res.user.company.id,
                });
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="goBack">
                <div
                    style={{ paddingBottom: 30 }}
                    className={"backLink backBtn titleWrapper"}
                >
                    <Link
                        to={{
                            pathname: "/companies/detail",
                            search: `?id=${user.companyId}`,
                        }}
                        style={{
                            float: "left",
                            paddingTop: 5,
                            fontSize: 13,
                            fontWeight: 400,
                            color: "#9199AA",
                        }}
                    >
                        <ArrowLeftOutlined style={{ marginRight: 14 }} />
                        Gå tillbaka
                    </Link>
                    <h2 className="text-center">{"Användare 2"}</h2>
                </div>
            </div>
            <div>
                <div style={{ background: "#FFFFFF" }}>
                    <Row gutter={24} type="flex">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Card
                                // loading={loading}
                                className="cardDetaill bidDetaill"
                                style={{ height: "100%" }}
                                bodyStyle={{
                                    padding: "28",
                                    borderRadius: 6,
                                }}
                            >
                                <Text
                                    texttype="back"
                                    colortype="back"
                                    customclass="cardTitle"
                                >
                                    Användare
                                </Text>
                                <p
                                    style={{
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        color: "#2a2b2d",
                                        lineHeight: "23px",
                                    }}
                                >
                                    Lägg till uppgifter om användare nedan.
                                </p>
                                <Spin spinning={props.loadingCompanyUser}>
                                    <UsersForm
                                        form={form}
                                        companyId={id}
                                        user={user}
                                    />
                                </Spin>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
    // }
};

const mapStateToProps = (store) => {
    return {
        company: store.companies.company,
        loadingCompany: store.companies.isFetching_company,
        user: store.me.profile,
        users: store.companies.companyUsers,
        loadingCompanyUser: store.companies.isFetching_user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCompanyUser: (id) => dispatch(fetchCompanyUser(id)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyUsersDetailsEditForm);
