import React, { Component } from "react";
import { 
    Table, 
    Spin,
    Checkbox,
    Space,
    Button
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactComponent as EyeIcon } from '../../../../assets/admin/eye.svg';
import { fetchLoanOffers } from '../../../../actions/loan-offers'
import { getStatus, getProduct } from '../../../../utils/helpers';
import CustomTable from '../../../../components/CumtomTable';
import './style.scss';
class LoanOffersTable extends Component {

    filterDropdownCheckbox = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, dataIndex="status", filters }) => {
    
        return (
        <div
          className="filterDropdown"
          style={{ padding: 8, display: 'flex', flexDirection: 'column' }}
        >
          <Checkbox.Group
            className="filtersCheckbox"
            options={filters.map(item => {return {value: item.value, label: item.text}} )}
            value={selectedKeys}
            onChange={checked => {
              setSelectedKeys(checked ? checked : [])
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              className="filterBtn filterRtnSearch"
            //   style={{ width: 90, backgroundColor: "#00CCBD", borderColor: "#00CCBD", height: '40px' }}
            >
              Search
            </Button>
            <Button
                className="filterBtn filterRtnReset" 
                onClick={() => this.handleReset(clearFilters)} 
                size="small" 
                style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      )}
    
    handleSearch = (selectedKeys, confirm) => {
        confirm();
    };

    handleReset = clearFilters => {
        clearFilters();
    };

    render() {
        const { Column } = Table;

        return (
            <Spin spinning={this.props.loading}>
                <CustomTable
                    className="tableLoanOffersTable" 
                    dataSource={this.props.loanOfferList}
                    dataSourcePagination={this.props.listPagination}
                    fetchData={this.props.fetchLoanOffers}
                >
                    <Column
                        title="id"
                        dataIndex="id"
                        key="id"
                        sorter={true}
                        render={(text, record) => (
                            <div className='actionBtn'>
                                <Link 
                                    to={`${'/loan-offers/detail?id='+record?.creditRequest?.id}`}
                                    style={{color: 'inherit', fontWeight: 800}}
                                >
                                    {record?.id}
                                </Link>
                                <div className='width'></div>
                            </div>
                        )}
                    />
                    <Column
                        title="Låntagare"
                        dataIndex="creditRequest.application.debtorName"
                        key="debtorName"
                        sorter={true}
                        render={(text, record) => {
                            return (
                            <span>
                                {record?.creditRequest?.application?.data?.debtorName || '-'}
                            </span>
                        )}}
                    />
                    <Column
                        title="Status"
                        dataIndex="status"
                        key="status"
                        sorter={true}
                        filterDropdown={(setSelectedKeys, selectedKeys, confirm, clearFilters, filters) => 
                            this.filterDropdownCheckbox(setSelectedKeys, selectedKeys, confirm, clearFilters, 'status', filters)}
                        filters={[
                            { text: 'Godkänt', value: 'STATUS_APPROVED' }
                        ]}
                        render={(text, record) => (
                            <span>
                                {getStatus(record?.status)}
                            </span>
                        )}
                    />
                    <Column
                        title="Belopp"
                        dataIndex="amount"
                        key="amount"
                        sorter={true}
                        render={(text, record) => (
                            <span>
                                {!!record.amount ? `${record.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} SEK` : 0}
                            </span>
                        )}
                    />
                    <Column
                        title="Ändamål"
                        dataIndex="creditRequest.application.type"
                        key="creditRequest.application.type"
                        sorter={true}
                        render={(text, record) => (
                            <span>
                                {getProduct(record?.creditRequest?.application?.type)}
                            </span>
                        )}
                    />

                    <Column
                        title="Ränta"
                        dataIndex="interest"
                        key="interest"
                        sorter={true}
                        render={(text, record) => (
                            <span >
                                {text ? `${text} %` : 0}
                            </span>
                        )}
                    />
                    <Column
                        title="Löptid"
                        dataIndex="duration"
                        key="duration"
                        sorter={true}
                        render={(text, record) => (
                            <span>
                                {text ? `${text} Mån` : '-'}
                            </span>
                        )}
                    />
                    <Column
                        title="Kreditförfrågan"
                        dataIndex="creditRequest.id"
                        key="creditRequest.id"
                        sorter={true}
                        render={(text, record) => (
                            <span>
                                {record?.creditRequest?.id}
                            </span>
                        )}
                    />
                    <Column
                        key="loan_details"
                        render={(text, record) => (
                            <>
                            <div className='actionBtn'>
                                <Link to={`${'/loan-offers/detail?id='+record?.creditRequest?.id}`}>
                                    <button 
                                        buttontype="type10"
                                        className="buttonType10"
                                        size="small"
                                        style={{width: '100%', backgroundColor: '#fff', display: 'inline-flex', alignItems: 'center'}}
                                    >
                                        <EyeIcon style={{marginRight: 10, width: 20}}/>
                                        Visa
                                    </button>
                                </Link>
                                <div className='width'></div>
                            </div>
                            </>
                        )}
                    />
                </CustomTable>
            </Spin>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        loanOfferList: store.loanOffers.list,
        listPagination: store.loanOffers.list_pagination,
        loading: store.loanOffers.isFetching_list
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchLoanOffers: (currentPage, pageSize, sortField, sorrtOrder, filters) => {
            filters = {
                ...filters, 
                statuses: !!filters?.status ? ['ex,STATUS_DECLINED_BY_BANK', ...filters?.status.map(i => `in,${i}`)] : ['ex,STATUS_DECLINED_BY_BANK']
            }
            return dispatch(
                fetchLoanOffers(currentPage, pageSize, sortField, sorrtOrder, filters)
            )
        }
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoanOffersTable));