import React from "react";
import { Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PublicLayout from "../layouts/PublicLayout";

const AnonymousRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => (
				<PublicLayout>
					<Component {...props} />
                    <ToastContainer/>
				</PublicLayout>
			)}
		/>
	);
};

export default AnonymousRoute;
