import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import Header from "../../../components/header/Header";
import AvatarDropdown from "../../../components/AvatarDropdown";
import { connect } from "react-redux";
import {init} from "../../../actions/app";
import GlobalLoader from "../../../components/Loaders/GlobalLoader";
import { deleteTokens } from "../../../utils/auth";
import { fetchNotifications } from '../../../actions/notifications';
import 'react-toastify/dist/ReactToastify.css';

import "./ProfileLayout.scss";


class ProfileLayout extends Component {

    state = { visible: false };

    componentDidMount() {
        this.props.init().then(() => {
            if (!!this.props.user.role && this.props.user.role !== 'CREDITOR') {

                deleteTokens();
                return window.location.href = '/login';
            }
          });
    }

    render() {
        const { user } = this.props;

        return (
            <div>
                {(!!user.role && user.role === 'CREDITOR') ? (
                    <>
                        <Header >
                            <AvatarDropdown />
                        </Header>

                        <div 
                            className="d-flex"
                            style={{ position: 'relative',  overflow: 'hidden' }}
                        >
                            <div className="masterLayout-content">
                                {this.props.children}
                            </div>
                        </div>
                        <GlobalLoader isLoading={this.props.app.showLoader}/>
                        <ToastContainer 
                            autoClose={2000}
                            hideProgressBar
                            position="top-right"
                            limit={4}
                        />
                    </>
                ) : (
                    <>
                        <div className="roleError">Authority is required</div>
                        <GlobalLoader isLoading={this.props.app.showLoader}/>
                    </>
                )}

                
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.me.profile,
        app: store.app,
        notifications: store.notifications.list,
        isLoaded: store.notifications.isLoaded_list
    }
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        init: () => dispatch(init()),
        fetchNotifications: (date) => dispatch(fetchNotifications(date))
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(ProfileLayout);