import React, { useState } from "react";
// import { EditOutlined, DeleteOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Form, Card, Row, Col } from "antd";
import { CustomInput } from "../../../../../uikit/Input";
import { CustomButton } from "../../../../../uikit/Button";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getQueryParam } from "../../../../../utils/helpers";
import http from "../../../../../api";
import "./style.scss";

const UsersForm = (props) => {
    const { user, form } = props;

    const [errors, setErrors] = useState({});

    let id = "";
    if (!!getQueryParam("id")) {
        id = getQueryParam("id");
    }

    // const [ form ] = Form.useForm();

    const onSubmit = (value) => {
        let values = form.getFieldsValue();

        http(`/api/v1/user/${id}/`, {
            method: "PUT",
            data: {
                ...values,
            },
        })
            .then((res) => props.history.goBack())
            .catch((err) => {
                if (!!err?.response?.data?.errors) {
                    setErrors(err.response.data.errors);
                }
            });
    };

    return (
        <div style={{ background: "#FFFFFF" }}>
            <Row gutter={24} type="flex">
                <Col xl={7} lg={10} md={22} sm={22} xs={24}>
                    <Card
                        className="cardDetaill bidDetaill"
                        style={{ height: "100%" }}
                        bodyStyle={{
                            padding: "28",
                            borderRadius: 6,
                        }}
                    >
                        <Form
                            form={form}
                            layout="vertical"
                            className="companyForm"
                            hideRequiredMark
                            initialValues={user}
                            onFinish={onSubmit}
                        >
                            <Row>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <CustomInput
                                        name="firstName"
                                        key="firstName"
                                        label="Förnamn"
                                        validateStatus={
                                            !!errors.firstName ? "error" : ""
                                        }
                                        help={
                                            !!errors.firstName
                                                ? String(errors.firstName)
                                                : null
                                        }
                                        inputprops={{
                                            placeholder: "Förnamn",
                                            onChange: (e) => {
                                                setErrors({
                                                    ...errors,
                                                    firstName: "",
                                                });
                                            },
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <CustomInput
                                        name="lastName"
                                        key="lastName"
                                        label="Efternamn"
                                        validateStatus={
                                            !!errors.lastName ? "error" : ""
                                        }
                                        help={
                                            !!errors.lastName
                                                ? String(errors.lastName)
                                                : null
                                        }
                                        inputprops={{
                                            placeholder: "Efternamn",
                                            onChange: (e) => {
                                                setErrors({
                                                    ...errors,
                                                    lastName: "",
                                                });
                                            },
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <CustomInput
                                        label="Telefonnummer"
                                        key="phoneNumber"
                                        name="phoneNumber"
                                        validateStatus={
                                            !!errors.phoneNumber ? "error" : ""
                                        }
                                        help={
                                            !!errors.phoneNumber
                                                ? String(errors.phoneNumber)
                                                : null
                                        }
                                        inputprops={{
                                            placeholder: "Telefonnummer",
                                            onChange: (e) => {
                                                setErrors({
                                                    ...errors,
                                                    phoneNumber: "",
                                                });
                                            },
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <CustomInput
                                        label="Ssn"
                                        key="ssn"
                                        name="ssn"
                                        validateStatus={
                                            !!errors.ssn ? "error" : ""
                                        }
                                        help={
                                            !!errors.ssn
                                                ? String(errors.ssn)
                                                : null
                                        }
                                        inputprops={{
                                            placeholder: "Ssn",
                                            onChange: (e) => {
                                                setErrors({
                                                    ...errors,
                                                    ssn: "",
                                                });
                                            },
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <CustomInput
                                        label="E-postadress"
                                        key="email"
                                        name="email"
                                        validateStatus={
                                            !!errors.email ? "error" : ""
                                        }
                                        help={
                                            !!errors.email
                                                ? String(errors.email)
                                                : null
                                        }
                                        inputprops={{
                                            placeholder: "E-postadress",
                                            disabled: true,
                                            onChange: (e) => {
                                                setErrors({
                                                    ...errors,
                                                    email: "",
                                                });
                                            },
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
            <Row>
                <div className="rowbuttons">
                    <CustomButton
                        buttontype="type5"
                        colortype="type8"
                        size="large"
                        style={{ margin: "0 10px 20px" }}
                        onClick={onSubmit}
                    >
                        Editera
                    </CustomButton>
                </div>
            </Row>
        </div>
    );
};

const mapStateToProps = (store) => {
    return {
        loading: store.companies.isFetching_user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // createLoanOffer: (id, data) => dispatch(createLoanOffer(id, data)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UsersForm)
);
