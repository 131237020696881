import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import PublicRoute from "./routes/PublicRoute";
import ConfirmRoute from "./routes/ConfirmRoute";
import PrivateRoute from "./routes/creditor/PrivateRoute";
import LoginContainer from "./pages/Login/LoginContainer";
import DashboardContainer from "./pages/creditor/Dashboard/DashboardContainer";
import LoanOffers from "./pages/creditor/LoanOffers";
import LoanOfferDetails from "./pages/creditor/LoanOffers/LoanOfferDetails";
import LoanOfferForm from "./pages/creditor/LoanOffers/LoanOfferForm";
import CreditRequest from "./pages/creditor/CreditRequest/CreditRequest";
import CreditRequestDetails from "./pages/creditor/CreditRequestDetails";

import ProfileRoute from "./routes/creditor/ProfileRoute";
import Profile from "./components/Profile";
import EmailConfirmation from "./components/Profile/EmailConfirmation";
import PasswordConfirmation from "./components/Confirmation/PasswordConfirmation";

import PrivateRouteAdmin from "./routes/admin/PrivateRoute";
import Dashboard from "./pages/admin/DashboardContainer";
import CreditRequestAdmin from "./pages/admin/CreditRequest";
import CreditRequestDetailsAdmin from "./pages/admin/CreditRequestDetails";
import LoanOffersAdmin from "./pages/admin/LoanOffers";
import LoanOfferDetailsAdmin from "./pages/admin/LoanOffers/LoanOfferDetails";
import Companies from "./pages/admin/Companies";
import CompanyDetailsAdmin from "./pages/admin/Companies/CompanyDetails";
import CompanyDetailsForm from "./pages/admin/Companies/CompanyDetailsForm";
import CompanyUsersDetailsAdmin from "./pages/admin/Companies/CompanyUsersDetailsForm";
import CompanyUsersDetailsEdit from "./pages/admin/Companies/CompanyUsersDetailsEdit";
import UsersAdmins from "./pages/admin/Admins";
import UsersAdminsForm from "./pages/admin/Admins/AdminDetailsForm";
import UsersAdminsAddForm from "./pages/admin/Admins/AdminAddForm";
import UsersBanks from "./pages/admin/Banks";
import BankAddForm from "./pages/admin/Banks/BankAddForm";
import BankDetailsForm from "./pages/admin/Banks/BankDetailsForm";
import UsersBanksDetails from "./pages/admin/Banks/BankDetails";
import UsersBankAddForm from "./pages/admin/Banks/BannkUsersAddForm";
import BankUsersEditForm from "./pages/admin/Banks/BankUsersEditForm";
import MyLending from "./pages/creditor/MyLending";
import MyLendingDetail from "./pages/creditor/MyLending/MyLendingDetails";

import ProfileRouteAdmin from "./routes/admin/ProfileRoute";
import Aml from "./pages/admin/Aml";
import AnonymousRoute from "./routes/AnonymousRoute";



function App() {
  if (window.location.host.toString().includes("creditor")) {
    return (
      <>
      {/* <div>Some text</div> */}
      <BrowserRouter>
        <Switch>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <PrivateRoute exact path="/dashboard" component={DashboardContainer} />
            <PrivateRoute exact path="/loan-offers" component={LoanOffers} />
            <PrivateRoute exact path="/loan-offers/detail" component={LoanOfferDetails} />
            <PrivateRoute exact path="/loan-offers/detail/edit" component={LoanOfferForm} />
            <PrivateRoute exact path="/credit-request" component={CreditRequest} />
            <PrivateRoute exact path="/credit-request/detail" component={CreditRequestDetails} />
            <PrivateRoute exact path="/credit/list" component={MyLending} />
            <PrivateRoute exact path="/credit/detail" component={MyLendingDetail} />

            <PublicRoute exact path="/login" component={LoginContainer} />
            <PublicRoute exact path="/" component={LoginContainer} />

            <ConfirmRoute exact path="/account/profile/password-confirmation" component={PasswordConfirmation} />
            <ConfirmRoute exact path="/account/profile/password-reset" component={PasswordConfirmation} />

            <ProfileRoute exact path="/profile" component={Profile} />
            <ProfileRoute exact path="/account/profile/email-change-confirmation" component={EmailConfirmation} />

        </Switch>
      </BrowserRouter>
      </>
    );
  } else {
    return (
      <BrowserRouter>
        <Switch>
            <PublicRoute exact path="/login" component={LoginContainer} />
            <PublicRoute exact path="/" component={LoginContainer} />
            <AnonymousRoute exact path="/aml" component={Aml} />
            <PrivateRouteAdmin exact path="/dashboard" component={Dashboard} />
            <PrivateRouteAdmin exact path="/credit-request" component={CreditRequestAdmin} />
            <PrivateRouteAdmin path="/credit-request/detail" component={CreditRequestDetailsAdmin} />
            <PrivateRouteAdmin exact path="/loan-offers" component={LoanOffersAdmin} />
            <PrivateRouteAdmin exact path="/loan-offers/detail" component={LoanOfferDetailsAdmin} />
            <PrivateRouteAdmin exact path="/credit/list" component={MyLending} />
            <PrivateRouteAdmin exact path="/credit/detail" component={MyLendingDetail} />
            <PrivateRouteAdmin exact path="/companies" component={Companies} />
            <PrivateRouteAdmin exact path="/companies/detail" component={CompanyDetailsAdmin} />
            <PrivateRouteAdmin exact path="/companies/detail/user" component={CompanyUsersDetailsAdmin} />
            <PrivateRouteAdmin exact path="/companies/detail/user-edit" component={CompanyUsersDetailsEdit} />
            <PrivateRouteAdmin exact path="/companies/detail/edit" component={CompanyDetailsForm} />
            <PrivateRouteAdmin exact path="/users/admins" component={UsersAdmins} />
            <PrivateRouteAdmin exact path="/users/admins/detail" component={UsersAdminsForm} />
            <PrivateRouteAdmin exact path="/admin/new" component={UsersAdminsAddForm} />
            <PrivateRouteAdmin exact path="/users/banks" component={UsersBanks} />
            <PrivateRouteAdmin exact path="/users/banks/detail" component={UsersBanksDetails} />
            <PrivateRouteAdmin exact path="/users/banks/detail/edit" component={BankDetailsForm} />
            <PrivateRouteAdmin exact path="/users/banks/new" component={BankAddForm} />
            <PrivateRouteAdmin exact path="/users/banks/detail/user" component={UsersBankAddForm} />
            <PrivateRouteAdmin exact path="/users/banks/detail/user-edit" component={BankUsersEditForm} />

            <ProfileRouteAdmin exact path="/profile" component={Profile} />
            <ProfileRouteAdmin exact path="/account/profile/email-change-confirmation" component={EmailConfirmation} />

            <ConfirmRoute exact path="/account/profile/password-confirmation" component={PasswordConfirmation} />
            <ConfirmRoute exact path="/account/profile/password-reset" component={PasswordConfirmation} />
        </Switch>
      </BrowserRouter>
    );
  }

}

export default App;
