import React from 'react';
import {Redirect, Route } from "react-router-dom";
import { isLoggedIn } from "../utils/auth";
import PublicLayout from "../layouts/PublicLayout";

const PublicRoute = ({ component: Component, ...rest }) => {

    return (
        <Route
            {...rest}
            render={(props) =>
                isLoggedIn() ? (
                     <Redirect to={{ pathname: '/dashboard' }} />
                ) : (
                    <PublicLayout>
                        <Component {...props} />
                    </PublicLayout>
                )
            }
        />
    );
};

export default PublicRoute;
