import React, { useState } from "react";
import { Card, Row, Col, Form, Spin, message } from "antd";
import { Text } from "../../../../uikit/Text";
import { CustomButton } from "../../../../uikit/Button";
import http from "../../../../api";
import { CustomInput } from "../../../../uikit/Input";
import { connect } from "react-redux";
import { fetchApplication } from "../../../../actions/applications";
import AmlFile from "./AmlFile";

const AmlFiles = (props) => {
	const { amlFiles, id } = props;
	return (
		!!amlFiles &&
		amlFiles.length > 0 && (
			<Spin spinning={false}>
				<Row className="dashboard loanOffersTableWrapper" gutter={24}>
					<Col
						xl={24}
						lg={24}
						md={24}
						sm={24}
						xs={24}
						style={{ marginBottom: 30 }}
					>
						<Card
							className="cardDetaill"
							style={{ minHeight: "100px", height: "100%" }}
							bodyStyle={{
								paddingBottom: 24,
								borderRadius: 6,
							}}
						>
							<Text
								texttype="back"
								colortype="back"
								customclass="cardTitle"
							>
								AML/PEP kontroll bolag
							</Text>
							{!!amlFiles &&
								amlFiles.map((file) => (
									<AmlFile amlFile={file} id={id} />
								))}
						</Card>
					</Col>
				</Row>
			</Spin>
		)
	);
};

const mapStateToProps = (store) => {
	return {
		application: store.applications.application,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchApplication: (id) => dispatch(fetchApplication(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AmlFiles);
