import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Button, Card, Spin, Row, Col, message } from "antd";
import { Text } from "../../../uikit/Text";
import { CustomButton } from "../../../uikit/Button";
import { getQueryParam } from "../../../utils/helpers";
import moment from "moment";
import http from "../../../api";
import BusinessInfo from "./sections/businessInfo";
import CreditInfo from "./sections/CreditInfo";
import ApllicationDetailsMessage from "./sections/message";
import AppComment from "../../../uikit/Comment";
import BisnodeSection from "./sections/BisnodeSection";
import FilesList from "./sections/FilesList";
import { ReactComponent as DownloadIcon } from "../../../assets/admin/download.svg";
import { ReactComponent as SearchIcon } from "../../../assets/admin/search.svg";
import BanksModal from "./Modal/BanksModal";
import CreditRequestTable from "./sections/CreditRequestsTable";
import { fetchBanks } from "../../../actions/credit-requests";
import { fetchApplication } from "../../../actions/applications";
import { toast } from "react-toastify";
import { CheckCircleOutlined } from "@ant-design/icons";
import config from "../../../config";
import UploaderMultipleFiles from "./sections/UploaderMultipleFiles";
import AmlFiles from "./sections/AmlFiles";
import EventTable from "./sections/EventTable";
import Risk from "./sections/Risk";

const topColResponsiveProps3 = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 24,
    style: {
        marginBottom: 24,
    },
};

const CreditRequestDetails = (props) => {
    const { loading, loanDataById, user } = props;

    let id = "";
    if (!!getQueryParam("id")) {
        id = getQueryParam("id");
    }

    const [showModal, setShowModal] = useState(false);
    const [isLoadingPdf, setIsLoadingPdf] = useState(false);

    useEffect(() => {
        props.fetchApplication(id);
        props.fetchBanks();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    let comments = [];
    if (!!loanDataById) {
        loanDataById?.creditRequestsShort?.forEach((credit) => {
            if (credit.adminComment !== null)
                comments.push({
                    ...credit.adminComment,
                    bankId: credit.bankId,
                    bankName: credit.bankName,
                });
        });
    }

    const handlePrewiewPdf = () => {
        setIsLoadingPdf(true);
        http(`/api/v1/application/${id}/pdf/generate`, {
            method: "get",
            responseType: "blob",
            headers: {
                "Content-Type": "application/pdf",
            },
        })
            .then((blob) => {
                if (!!blob && !!blob.data) {
                    const fileURL = URL.createObjectURL(blob.data);

                    window.open(fileURL);
                } else {
                    message.error("Ett fel uppstod");
                }
                setIsLoadingPdf(false);
            })
            .catch(() => setIsLoadingPdf(true));
    };

    const handleDownloadPdf = () => {
        setIsLoadingPdf(true);
        http(`/api/v1/application/${id}/pdf/generate`, {
            method: "get",
            responseType: "blob",
            headers: {
                "Content-Type": "application/pdf",
            },
        })
            .then((blob) => {
                if (!!blob && !!blob.data) {
                    const fileURL = URL.createObjectURL(blob.data);

                    const link = document.createElement("a");
                    link.href = fileURL;
                    link.setAttribute(
                        "download",
                        `Kredit PM - ${
                            !!loanDataById?.data?.debtorName
                                ? loanDataById.data.debtorName
                                : ""
                        } ID ${loanDataById?.id}`
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                } else {
                    message.error("Ett fel uppstod");
                }
                setIsLoadingPdf(false);
            })
            .catch(() => setIsLoadingPdf(true));
    };

    const cancelApplication = () => {
        http(`/api/v1/application/${id}/status/cancel`, { method: "POST" })
            .then((response) => {
                props.fetchApplication(id);
                toast.success(
                    <div className="toast-body">
                        <CheckCircleOutlined className="toast__icon toast__icon--success" />
                        <p className="toast-text">{`Ansökan har blivit avslagen.`}</p>
                    </div>
                );
            })
            .catch((err) => {});
    };

    const closeApplication = () => {
        http(`/api/v1/application/${id}/status/close`, { method: "POST" })
            .then((response) => {
                props.fetchApplication(id);
                toast.success(
                    <div className="toast-body">
                        <CheckCircleOutlined className="toast__icon toast__icon--success" />
                        <p className="toast-text">{`Ansökan har blivit avslutad.`}</p>
                    </div>
                );
            })
            .catch((err) => {});
    };

    const handleStatus = (status) => {
        // setIsLoading(true);
        if (status === "STATUS_DRAFT") {
            http(`/api/v1/application/${id}/status/processing`, {
                method: "POST",
            })
                .then((response) => {
                    props.fetchApplication(id);
                    // setIsLoading(false);
                    toast.success(
                        <div className="toast-body">
                            <CheckCircleOutlined className="toast__icon toast__icon--success" />
                            <p className="toast-text">{`Statusen har ändrats för bearbetning`}</p>
                        </div>
                    );
                })
                .catch((err) => {});
        } else if (status === "STATUS_PROCESSING") {
            http(`/api/v1/application/${id}/status/active`, {
                method: "POST",
            }).then((response) => {
                props.fetchApplication(id);
                toast.success(
                    <div className="toast-body">
                        <CheckCircleOutlined className="toast__icon toast__icon--success" />
                        <p className="toast-text">{`Kreditförfrågan är satt till aktiv`}</p>
                    </div>
                );
            });
        } else {
            setShowModal(true);
        }
    };

    const getStatusButtonName = (status) => {
        switch (status) {
            case "STATUS_DRAFT":
                return "Lås för handläggning";

            case "STATUS_PROCESSING":
                return "Godkänn";

            case "STATUS_ACTIVE":
                return "Skicka till banker";

            default:
                return "Skicka till banker";
        }
    };

    const handleUpload = () => {
        props.fetchApplication(id);
    };

    const handleCancel = async () => {
        setShowModal((value) => !value);
    };

    const loginToClient = () => {
        window.open(
            `${config.WEB_APP}/user/login?admin_token=${btoa(
                JSON.stringify({
                    id: loanDataById?.user?.id,
                    token: user?.token,
                })
            )}`
        );
    };

    const openUpdateTab = () => {
        let type = loanDataById.type,
            id = loanDataById.id,
            route = "";

        if (type === "TYPE_BUILDING_CREDIT") {
            route = `/forms/byggnadskreditivupdate?id=${id}`;
        }
        if (type === "TYPE_BUILDING_CREDIT_HYRESRATTER") {
            route = `/forms/byggnadskreditivupdate?id=${id}`;
        }
        if (type === "TYPE_BUILDING_CREDIT_BOSTADSRATTER") {
            route = `/forms/byggnadskreditivupdate?id=${id}`;
        }
        if (type === "TYPE_REAL_ESTATE_LOAN") {
            route = `/forms/fastighetslånupdate?id=${id}`;
        }
        if (type === "TYPE_REAL_ESTATE_LOAN_AUTO") {
            route = `/forms/fastighetslånautoupdate?id=${id}`;
        }
        if (type === "TYPE_PROPERTY_ACQUISITION") {
            route = `/forms/fastighetsförvärvupdate?id=${id}`;
        }
        if (type === "TYPE_SHORT_TERM_LOAN") {
            route = `/forms/brygglånupdate?id=${id}`;
        }
        if (type === "TYPE_ASSOCIATION_LOAN") {
            route = `/forms/föreningslånupdate?id=${id}`;
        }
        if (type === "TYPE_REFINANCING") {
            route = `/forms/refinansieringupdate?id=${id}`;
        }
        window.open(`${config.WEB_APP}${route}&token=${user?.token}`);
    };

    return (
        <div style={{ position: "relative" }}>
            <div className="goBack">
                <div
                    style={{ paddingBottom: 30 }}
                    className={"backLink backBtn titleWrapper"}
                >
                    <Link
                        to={{
                            pathname: "/credit-request",
                        }}
                        onClick={() => {
                            props.history.goBack();
                        }}
                        style={{
                            float: "left",
                            paddingTop: 5,
                            fontSize: 13,
                            fontWeight: 400,
                            color: "#9199AA",
                        }}
                    >
                        <ArrowLeftOutlined style={{ marginRight: 14 }} />
                        Gå tillbaka
                    </Link>
                    <h2 className="text-center">Kreditförfrågningar</h2>
                </div>
            </div>
            <Row className="dashboard d-flex loanOffersTableWrapper">
                <BusinessInfo
                    style={{
                        paddingLeft: "12px",
                        paddingRight: "12px",
                        marginBottom: "24px",
                    }}
                />
                <CreditInfo
                    style={{
                        paddingLeft: "12px",
                        paddingRight: "12px",
                        marginBottom: "24px",
                    }}
                />
            </Row>
            <Row className="loanOffersTableWrapper">
                <CustomButton
                    buttontype="type5"
                    colortype="type8"
                    size="large"
                    style={{ margin: "20px 10px 40px auto", width: "250px" }}
                    onClick={(e) => {
                        openUpdateTab(e);
                    }}
                >
                    Redigera ansökan
                </CustomButton>
                <CustomButton
                    buttontype="type5"
                    colortype="type8"
                    size="large"
                    style={{ margin: "20px auto 40px 10px", width: "250px" }}
                    onClick={loginToClient}
                >
                    Logga in på kundens konto
                </CustomButton>
            </Row>
            <Row className="dashboard loanOffersTableWrapper" gutter={24}>
                <Col
                    xl={24}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    style={{ marginBottom: 30 }}
                >
                    <Card
                        className="cardDetaill"
                        style={{
                            marginBottom: 30,
                            minHeight: "100px",
                            height: "100%",
                        }}
                        bodyStyle={{
                            padding: "28",
                            borderRadius: 6,
                        }}
                    >
                        <Text
                            texttype="back"
                            colortype="back"
                            customclass="cardTitle"
                        >
                            Kredit-PM
                        </Text>
                        <Spin spinning={isLoadingPdf}>
                            <Row gutter={16} style={{ width: "100%" }}>
                                <Col
                                    padding={10}
                                    className="gutter_row"
                                    xl={8}
                                    lg={8}
                                    md={8}
                                    sm={8}
                                    xs={24}
                                >
                                    <span className="tableFile">
                                        {`Kredit PM - ${
                                            !!loanDataById?.data?.debtorName
                                                ? loanDataById.data.debtorName
                                                : ""
                                        } ID ${id}`}
                                    </span>
                                </Col>
                                <Col
                                    padding={10}
                                    className="gutter_row"
                                    xl={4}
                                    lg={4}
                                    md={4}
                                    sm={4}
                                    xs={4}
                                >
                                    <span className="messageText">{`pdf`}</span>
                                </Col>
                                <Col
                                    padding={10}
                                    className="gutter_row"
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={6}
                                >
                                    <span className="messageText">
                                        {!!loanDataById.updatedAt
                                            ? moment(
                                                  loanDataById?.updatedAt
                                              ).format("YYYY-MM-DD")
                                            : "-"}
                                    </span>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                    <div>
                                        <Button
                                            className="iconBtn"
                                            onClick={() => handlePrewiewPdf()}
                                        >
                                            <SearchIcon />
                                        </Button>
                                        <Button
                                            className="iconBtn"
                                            onClick={() => handleDownloadPdf()}
                                        >
                                            <DownloadIcon />
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Spin>
                    </Card>
                </Col>
            </Row>
            {loanDataById?.creditRequestsShort?.length > 0 && (
                <Row className="dashboard loanOffersTableWrapper" gutter={24}>
                    <Col
                        xl={24}
                        lg={24}
                        md={24}
                        sm={24}
                        xs={24}
                        style={{ marginBottom: 30 }}
                    >
                        <Card
                            className="cardDetaill"
                            style={{
                                marginBottom: 30,
                                minHeight: "100px",
                                height: "100%",
                            }}
                            bodyStyle={{
                                padding: "28",
                                borderRadius: 6,
                            }}
                        >
                            <Text
                                texttype="back"
                                colortype="back"
                                customclass="cardTitle"
                            >
                                Ansökan skickad till
                            </Text>
                            <Spin spinning={false}>
                                <Row gutter={16} style={{ width: "100%" }}>
                                    {loanDataById?.creditRequestsShort?.map(
                                        (el) => (
                                            <Col
                                                padding={10}
                                                className="gutter_row"
                                                xl={6}
                                                lg={6}
                                                md={6}
                                                sm={6}
                                                xs={24}
                                            >
                                                <Link
                                                    to={`/users/banks/detail?id=${el?.bankId}`}
                                                >
                                                    <span className="bankName">
                                                        {el?.bankName} - ID{" "}
                                                        {el?.bankId}
                                                    </span>
                                                </Link>
                                            </Col>
                                        )
                                    )}
                                </Row>
                            </Spin>
                        </Card>
                    </Col>
                </Row>
            )}
            {comments.length > 0 && (
                <Row className="dashboard loanOffersTableWrapper" gutter={24}>
                    <Col
                        xl={24}
                        lg={24}
                        md={24}
                        sm={24}
                        xs={24}
                        style={{ marginBottom: 30 }}
                    >
                        <Card
                            className="cardDetaill"
                            style={{
                                marginBottom: 30,
                                minHeight: "100px",
                                height: "100%",
                            }}
                            bodyStyle={{
                                padding: "28",
                                borderRadius: 6,
                            }}
                        >
                            <Text
                                texttype="back"
                                colortype="back"
                                customclass="cardTitle"
                            >
                                MEDDELANDE FRÅN BETTRES KREDITOR
                            </Text>
                            {comments.map((comment) => {
                                return (
                                    <div className="comment-wrapper">
                                        <Text
                                            texttype="info"
                                            colortype="primary"
                                            customclass="comment-title"
                                        >
                                            {comment.bankName} - ID{" "}
                                            {comment.bankId}
                                        </Text>
                                        <Text
                                            texttype="info"
                                            colortype="primary"
                                            customclass="comment-message"
                                        >
                                            {comment.message}
                                        </Text>
                                    </div>
                                );
                            })}
                        </Card>
                    </Col>
                </Row>
            )}
            <CreditRequestTable id={id} />
            <Spin spinning={loading}>
                <ApllicationDetailsMessage id={id} />
            </Spin>
            <Risk id={id} appRisks={loanDataById?.risk} />
            {!!loanDataById &&
                loanDataById?.creditRequestsShort?.map((credit) => {
                    return (
                        <AppComment
                            title={`KOMMENTAR FRÅN BETTRE TILL ${credit?.bankName}`}
                            id={credit.id}
                            comment={credit.creditorComment?.message}
                            credit={credit}
                        />
                    );
                })}
            {/* <Spin spinning={loading}> */}
            <BisnodeSection id={id} />
            <AmlFiles amlFiles={loanDataById?.amlFiles} id={loanDataById?.id} />
            {/* </Spin> */}
            <Row
                className={`dashboard upload-container loanOffersTableWrapper`}
                gutter={24}
                style={{ marginBottom: 30 }}
            >
                <Col {...topColResponsiveProps3}>
                    <Card
                        style={{
                            marginBottom: 30,
                            height: "100%",
                            minHeight: 100,
                            pading: 20,
                        }}
                        bodyStyle={{
                            padding: "0",
                            borderRadius: 6,
                        }}
                    >
                        <div className={"tableTitle"}>{"Upload files"}</div>
                        <div style={{ padding: 30 }}>
                            <UploaderMultipleFiles
                                applicationId={loanDataById?.id}
                                applicationType={loanDataById?.type}
                                applicationStatus={loanDataById?.status}
                                projectType={loanDataById?.data?.projectType}
                                handleChange={handleUpload}
                                status={loanDataById?.status}
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
            <FilesList id={id} />
            <EventTable id={id} />
            {loanDataById?.status !== "STATUS_CANCELED" &&
                loanDataById?.status !== "STATUS_CLOSED" &&
                !loanDataById?.hasApprovedLoanOffer && (
                    <Row
                        className="dashboard"
                        gutter={24}
                        style={{ justifyContent: "center" }}
                    >
                        <Col>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <div className="buttons">
                                    <CustomButton
                                        buttontype="type5"
                                        colortype="type8"
                                        size="large"
                                        style={{ margin: "0" }}
                                        onClick={() => {
                                            handleStatus(loanDataById?.status);
                                        }}
                                    >
                                        {getStatusButtonName(
                                            loanDataById?.status
                                        )}
                                    </CustomButton>
                                    {!loanDataById?.sentToBanks && (
                                        <CustomButton
                                            buttontype="type5"
                                            colortype="type8"
                                            size="large"
                                            style={{ margin: "0 0 0 50px" }}
                                            onClick={cancelApplication}
                                        >
                                            {"Återkalla"}
                                        </CustomButton>
                                    )}

                                    {loanDataById?.status ===
                                        "STATUS_ACTIVE" && (
                                        <CustomButton
                                            buttontype="type5"
                                            colortype="type8"
                                            size="large"
                                            style={{ margin: "0 0 0 50px" }}
                                            onClick={closeApplication}
                                        >
                                            {"Avslutad"}
                                        </CustomButton>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}

            <BanksModal
                showLeadDialog={showModal}
                handleCancel={handleCancel}
                company={loanDataById?.data?.debtorName || ""}
                banks={props.banks}
                id={id}
            />
        </div>
    );
};

const mapStateToProps = (store) => {
    return {
        loanDataById: store.applications.application,
        loading: store.applications.isFetching_application,
        banks: store.creditRequests.banks,
        user: store.me.profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchApplication: (id) => dispatch(fetchApplication(id)),
        fetchBanks: () => dispatch(fetchBanks()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreditRequestDetails);
