import React, {Fragment, useEffect} from "react";
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { fetchLoanOffer } from '../../../../actions/loan-offers';
import { fetchCreditRequest } from '../../../../actions/credit-requests';
import { Text } from '../../../../uikit/Text';
import {
    Card,
    Row,
    Col,
    Spin
} from 'antd';
import { getQueryParam, getStatus, getProduct } from '../../../../utils/helpers';
import './style.scss';

const MyLendingDetails = (props) => {
    const { 
        loanOfferDataById,
        // loanOfferList
    } = props;

    let id = '';
    if (!!getQueryParam('id')) {
      id = getQueryParam('id')
    } 

    useEffect(() => {
        props.fetchLoanOffer(id);
        props.fetchCreditRequest(id);
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

    const topColResponsiveProps = {
        xs: 24,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 24,
        style: {
          marginBottom: 24,
        },
    };
   
        return (
            <>
                <div className='goBack'>
                    <div style={{paddingBottom: 30}} className={"backLink backBtn titleWrapper"}>
                        <Link
                            to={
                                {
                                    pathname: '/credit/list'
                                }
                            }
                            style={{float: 'left', paddingTop:5, fontSize: 13, fontWeight: 400, color: '#9199AA'}}
                        >
                            <ArrowLeftOutlined style={{ marginRight: 14 }} />
                            Gå tillbaka
                        </Link>
                        <h2 className='text-center'>Utlåning</h2>
                    </div>  
                </div>
            <div>
                <Spin spinning={props.loading}>
                    <div style={{background: '#FFFFFF'}}>
                        <Row gutter={24} type="flex">
                            <Col {...topColResponsiveProps}>
                                <Card
                                    // loading={loading}
                                    className="cardDetaill bidDetaill"
                                    style={{marginBottom: 30, height: '100%'}}
                                    bodyStyle={{
                                    padding: '28',
                                    borderRadius: 6
                                    }}
                                >
                                        <Text texttype="back" colortype="back" customclass="cardTitle">
                                            LÅNEBESKRIVNING OCH STATUS
                                        </Text>
                                        <Row gutter={16}>
                                            <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                                <div className="labelitem">{'Långivare:'}</div>
                                            </Col>
                                            <Col className="gutter_row" xl={10} lg={8} md={8} sm={24} xs={24}>
                                                <div className="valueitem">
                                                    {loanOfferDataById?.creditRequest?.company?.name || '-'}
                                                    </div>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                                <div className="labelitem">{'Utbetalningsdatum:'}</div>
                                            </Col>
                                            <Col className="gutter_row" xl={10} lg={8} md={8} sm={24} xs={24}>
                                                <div className="valueitem">
                                                    {!!loanOfferDataById && !!loanOfferDataById.actualPayoutDate ? loanOfferDataById.actualPayoutDate : '-'}
                                                    </div>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                                <div className="labelitem">{'Lånebelopp:'}</div>
                                            </Col>
                                            <Col className="gutter_row" xl={10} lg={8} md={8} sm={24} xs={24}>
                                                <div className="valueitem">
                                                    {!!loanOfferDataById 
                                                        && !!loanOfferDataById.amount 
                                                        ? `${loanOfferDataById.amount.toString().replace( /\B(?=(\d{3})+(?!\d))/g, ',')} SEK`
                                                        : '-'}
                                                    </div>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                                <div className="labelitem">
                                                {`Avgift utgår för lånet:`}
                                                </div>
                                            </Col>
                                            <Col className="gutter_row" xl={10} lg={8} md={8} sm={24} xs={24}>
                                                <div className="valueitem">{loanOfferDataById?.feeTypes ? 'Ja' : 'Nej'}</div>
                                            </Col>
                                        </Row>
                                        {!!loanOfferDataById.feeTypes && (
                                            loanOfferDataById.feeTypes.map((feeType, index) => (
                                            <Fragment key={index}>

                                                <Row gutter={16}>
                                                <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                                    <div className="labelitem">{'Angiven avgiftsform för lånet:'}</div>
                                                </Col>
                                                <Col className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
                                                    <div className="valueitem">{feeType.feeType || '-'}</div>
                                                </Col>
                                                </Row>
                                                <Row gutter={16}>
                                                <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                                    <div className="labelitem">
                                                    {`Avgift för lånet:`}
                                                    </div>
                                                </Col>
                                                <Col className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
                                                    <div className="valueitem">
                                                        {!!feeType.feeAmount ? `${feeType.feeAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} SEK` : '-'}
                                                    </div>
                                                </Col>
                                                </Row>    
                                            </Fragment>
                                            ))
                                        )}
                                    <Row gutter={16}>
                                        <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                            <div className="labelitem">{'Räntesats:'}</div>
                                        </Col>
                                        <Col className="gutter_row" xl={10} lg={8} md={8} sm={24} xs={24}>
                                            <div className="valueitem">
                                                {loanOfferDataById?.interest + ' %' || '-'}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                            <div className="labelitem">{'Amorteringstyp:'}</div>
                                        </Col>
                                        <Col className="gutter_row" xl={10} lg={8} md={8} sm={24} xs={24}>
                                            <div className="valueitem">
                                                {loanOfferDataById?.amortizationType || '-'}
                                                </div>
                                        </Col>
                                    </Row>
                                    {!!loanOfferDataById.amortizationCustomType && (
                                        <Row gutter={16}>
                                            <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                                <div className="labelitem">{'Amorteringstakt:'}</div>
                                            </Col>
                                            <Col className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
                                                <div className="valueitem">
                                                    {loanOfferDataById?.amortizationCustomType}
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row gutter={16}>
                                        <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                            <div className="labelitem">{'Säkerhetsvillkor:'}</div>
                                        </Col>
                                        <Col className="gutter_row" xl={10} lg={8} md={8} sm={24} xs={24}>
                                            <div className="valueitem" style={{whiteSpace: 'pre-wrap'}}>
                                                {loanOfferDataById?.securityTerms || 'N/A'}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                            <div className="labelitem">{'Produkt:'}</div>
                                        </Col>
                                        <Col className="gutter_row" xl={10} lg={8} md={8} sm={24} xs={24}>
                                            <div className="valueitem">
                                                {getProduct(loanOfferDataById?.creditRequest?.application?.type) || '-'}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                            <div className="labelitem">{'Status:'}</div>
                                        </Col>
                                        <Col className="gutter_row" xl={10} lg={8} md={8} sm={24} xs={24}>
                                            <div className="valueitem">
                                                {!!loanOfferDataById.status ? getStatus(loanOfferDataById.status) : "-"}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                            <div className="labelitem">{'Total löptid:'}</div>
                                        </Col>
                                        <Col className="gutter_row" xl={10} lg={8} md={8} sm={24} xs={24}>
                                            <div className="valueitem">
                                                {!!loanOfferDataById.duration ? loanOfferDataById.duration + ' mån' : '-'}
                                                </div>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col padding={10} className="gutter_row" xl={10} lg={16} md={16} sm={24} xs={24}>
                                            <div className="labelitem">{'Återstående löptid:'}</div>
                                        </Col>
                                        <Col className="gutter_row" xl={10} lg={8} md={8} sm={24} xs={24}>
                                            <div className="valueitem">
                                                {!!loanOfferDataById.endDate ? loanOfferDataById.endDate + ' mån' : '-'}
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        </div>
                    </Spin>
                </div>
          </>
        );
    // }
}

const mapStateToProps = (store) => {
    return {
        user: store.me.profile,
        creditRequest: store.creditRequests.creditRequest,
        loanOffers: store.loanOffers.list,
        loanOfferDataById: store.loanOffers.loanOffer,
        loading: store.loanOffers.isFetching_loanOffer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchLoanOffer: (id) => dispatch(fetchLoanOffer(id)),
        fetchCreditRequest: (id) => dispatch(fetchCreditRequest(id)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyLendingDetails);