const initialState = {
    showLoader: false,
    isLoaded: false,
}

export function appReducer(state = initialState, action) {
    switch (action.type) {
        case 'app/showLoader':
            return {
                ...state,
                showLoader: true,
            };

        case 'app/hideLoader':
            return {
                ...state,
                showLoader: false,
            };

        case 'app/loaded':
            return {
                ...state,
                isLoaded: true,
            };

        default:
            return state
    }
}