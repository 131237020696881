import React, { Component } from "react";
import { Button, Table } from "antd";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ReactComponent as EyeIcon } from "../../../../assets/admin/eye.svg";
import "./style.scss";
import { Spin } from "antd";
import CustomTable from "../../../../components/CumtomTable/index";
import { fetchBanks } from "../../../../actions/users/banks";
import { CheckCircleFilled, DeleteOutlined } from "@ant-design/icons";
import DeleteModal from "../../DeleteModal";
import { toast } from "react-toastify";
import { deleteCompany } from "../../../../actions/users/admins";

class UsersBanksTable extends Component {
    constructor() {
        super();
        this.state = {
            companyToDelete: null,
        };
    }
    getRole = (role) => {
        let className;
        let value = "-";

        if (role === "ADMIN") {
            className = "black";
            value = "Admin";
        }
        return <span className={`${className}`}>{value}</span>;
    };

    formatLastActivityDate = (date) => {
        if (!!date) {
            return date
                .toString()
                .replace(/T/, " ")
                .substring(0, date.length - 3);
        } else {
            return "-";
        }
    };

    onDeleteOpen = (user) => () => {
        this.setState({ companyToDelete: user });
    };

    onSuccess = () => {
        this.props.fetchBanks(0, 10, null, null);
        this.setState({ companyToDelete: null });
        toast.success(
            <div className="toast-body">
                <CheckCircleFilled className="toast__icon toast__icon--success" />
                <p className="toast-text">Company has been deleted!</p>
            </div>
        );
    };

    onDeleteCompany = (user) => () => {
        this.props.deleteCompany(user.id, this.onSuccess);
    };

    onModalCancel = () => {
        this.setState({ companyToDelete: null });
    };

    render() {
        const { Column } = Table;

        const { companyToDelete } = this.state;

        return (
            <Spin spinning={this.props.loading}>
                <CustomTable
                    className="tableLoanOffersTable"
                    dataSource={this.props.banks}
                    dataSourcePagination={this.props.listPagination}
                    fetchData={this.props.fetchBanks}
                >
                    <Column
                        title="id"
                        dataIndex="id"
                        key="id"
                        style={{ textAlign: "start" }}
                        sorter={true}
                        render={(text, record) => (
                            <div className="actionBtn">
                                <Link
                                    to={`${
                                        "/users/banks/detail?id=" + record?.id
                                    }`}
                                    style={{
                                        color: "inherit",
                                        fontWeight: 800,
                                    }}
                                    // loanDataById={record}
                                >
                                    {record?.id}
                                </Link>
                                <div className="width"></div>
                            </div>
                        )}
                    />
                    <Column
                        title="Konto"
                        dataIndex="name"
                        key="name"
                        sorter={true}
                        render={(text, record) => record?.name || "-"}
                    />
                    <Column
                        title="Org. nr"
                        dataIndex="companyNumber"
                        key="companyNumber"
                        sorter={true}
                    />
                    <Column
                        title="Senaste aktivitet"
                        dataIndex="lastActivity"
                        key="lastActivity"
                        sorter={true}
                        render={(text, record) => (
                            <span>
                                {this.formatLastActivityDate(
                                    record?.lastActivity
                                ) || "-"}
                            </span>
                        )}
                    />
                    <Column
                        key="details"
                        render={(text, record) => (
                            <>
                                <div className="actionBtn">
                                    <Link
                                        to={`${
                                            "/users/banks/detail?id=" +
                                            record?.id
                                        }`}
                                    >
                                        <button
                                            buttontype="type10"
                                            className="buttonType10"
                                            size="small"
                                            style={{
                                                width: "100%",
                                                backgroundColor: "#fff",
                                                display: "inline-flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <EyeIcon
                                                style={{
                                                    marginRight: 10,
                                                    width: 20,
                                                }}
                                            />
                                            Visa
                                        </button>
                                    </Link>
                                    <Button
                                        className="buttonType10 iconBtnTable widthNone"
                                        styles={{ width: "fit-conent" }}
                                        onClick={this.onDeleteOpen(record)}
                                    >
                                        <DeleteOutlined className="iconDelete" />
                                    </Button>
                                    <div className="width"></div>
                                </div>
                            </>
                        )}
                    />
                </CustomTable>
                <DeleteModal
                    show={!!companyToDelete}
                    isLoading={false}
                    title="Delete user"
                    description={`Are you sure you want to delete company - ${companyToDelete?.name} (ID: ${companyToDelete?.id})`}
                    handleCancel={this.onModalCancel}
                    onDelete={this.onDeleteCompany(companyToDelete)}
                />
            </Spin>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        banks: store.users.banks,
        listPagination: store.users.banks_pagination,
        loading: store.users.isFetching_banks,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBanks: (currentPage, pageSize, sortField, sorrtOrder) =>
            dispatch(fetchBanks(currentPage, pageSize, sortField, sorrtOrder)),
        deleteCompany: (id, onSuccess) =>
            dispatch(deleteCompany(id, onSuccess)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UsersBanksTable)
);
