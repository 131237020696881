import React from 'react';
import { Form, Input } from 'antd';
import '../scss/uikit.scss';

export const TextArea = (props) => {  
	const classes = props.type === 'borderless' ? "textAreaNoBorder" : "textAreaBorder";
	return (
		<Form.Item {...props} className={[ "textArea", classes ].join(' ')}>
			<Input.TextArea {...props.inputprops} />
		</Form.Item>
	);
};

export default TextArea;
