import React from 'react';
import { Modal } from 'antd';

export const PreviewModal = (props) => {
	const { title, showLeadDialog, handleCancel } = props;
	
	const onToggleViewDialog = async () => {
		handleCancel(false);
	};

	return (
		<Modal
			className="modalContainer"    
			animation="zoom"
			maskAnimation="fade"
			forceRender
			visible={showLeadDialog}
			title={title}
			size={'large'}
			footer={false}        
			destroyOnClose
			style={{top: '30%'}}
			bodyStyle={{
				padding: '32px 30px 48px',
			}}

			onCancel={() =>onToggleViewDialog()}
		>
			<div className="uploadModal" style={{textAlign:'center'}}>
				<h2 style={{marginBottom: 30, padding: "0 10px"}}>Förhandsgranska</h2>
				{/* <div className={styles.file}>Befintlig fil:<span className={styles.filename}>Prognoskalkyl.pdf</span></div> */}

				<p style={{fontSize: '16px'}}>
					Dokumentet är inte tillgängligt för förhandsvisning. Vänligen ladda ner för att visa dokumentet.
				</p>
			</div>
		</Modal>
	);
};

export default PreviewModal;
