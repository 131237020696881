import http from "../../api";
// import {showGlobalLoader} from "../app";

export function showLoader() {
    return {
        type: 'loan-offers/showLoader',
    };
}

export function hideLoader() {
    return {
        type: 'loan-offers/hideLoader',
    };
}

export function fetchLoanOffers(page, size, sortField, sorrtOrder, filters) {
    let filterString = ''
    if (filters?.statuses?.length > 0)
        filterString = `?filter=${encodeURIComponent(JSON.stringify({status: filters.statuses}))}`
    // if (!!filters && !!filters.status)
    //     filterString.status = [...filterString.status, `in,${filters.status}`]
    return (dispatch) => {
        dispatch(showLoader(true));

        return http.get(`/api/v1/credit-request/loan-offers${filterString}`, {
            params: {
                page: !!page ? page : 0,
                size: !!size ? size : 10,
                sort: `${!!sortField ? sortField : 'id'},${!!sorrtOrder ? sorrtOrder : 'DESC'}`,
                myCreditRequests: !!filters?.myCreditRequests && filters.myCreditRequests.length > 0 ? filters.myCreditRequests[0] : false
                // status: !!status ? status : ""
            }
        })
            .then((response) => {
                dispatch(hideLoader(false));
                
                if (!!response.data) {
                    return dispatch(setLoanOffers(response.data));
                }

            })
            .catch(err => {
                dispatch(hideLoader(false));
            })
    }
}

export function fetchLoanOffersApproved(page, size, sortField, sorrtOrder, filters, isAdmin=false) {
   
    return (dispatch) => {
        dispatch(showLoader(true));

        let params = {
            page: !!page ? page : 0,
            size: !!size ? size : 10,
            sort: `${!!sortField ? sortField : 'id'},${!!sorrtOrder ? sorrtOrder : 'DESC'}`,
            status: 'STATUS_PAID_OUT'
        }

        if(isAdmin) {
            params = {
                ...params,
                myCreditRequests: !!filters?.myCreditRequests && filters.myCreditRequests.length > 0 ? filters.myCreditRequests[0] : false
            }
        }

        return http.get("/api/v1/credit-request/loan-offers", {
            params: params
        })
            .then((response) => {
                dispatch(hideLoader(false));

                if (!!response.data) {
                    return dispatch(setLoanOffers(response.data));
                }

            })
            .catch(err => {
                dispatch(hideLoader(false));
            })
    }
}

export const createLoanOffer = (id, data) => async (dispatch) => {
    await http.post(`api/v1/credit-request/${id}/loan-offer`, data);
    
    return dispatch ({
        type: 'loan-offers/createLoanOffer'
    });
}

export function showLoanOfferLoader() {
    return {
        type: 'loan-offer/showLoader',
    };
}

export function hideLoanOfferLoader() {
    return {
        type: 'loan-offer/hideLoader',
    };
}

export const fetchLoanOffer = (id) => async (dispatch) => {
    dispatch(showLoanOfferLoader());
    await http.get(`/api/v1/credit-request/${id}/loan-offer`)
        .then(res => {
            dispatch(hideLoanOfferLoader());

            return dispatch ({
                type: 'loan-offers/setLoanOffer',
                loanOffer: res.data.data,
            });
        })
        .catch(err => {
            dispatch(hideLoanOfferLoader());

            if (!!err && !!err.response && !!err.response.data) {
                dispatch ({
                    type: 'loan-offers/setErrors',
                    errors: err.response.data,
                })
            }
        })

}

export const fetchApplicationLoanOffers = (page, size, sortField, sorrtOrder, filters, data, id) => async (dispatch) => {
    const response = await http.get(`/api/v1/application/${id}/loan-offers`, {
        params: {
            page: !!page ? page : 0,
            size: !!size ? size : 10,
            sort: `${!!sortField ? sortField : 'id'},${!!sorrtOrder ? sorrtOrder : 'DESC'}`,
        }
    });

    return dispatch ({
        type: 'loan-offers/setApplicationLoanOffers',
        loanOffers: response.data.data,
        pagination: response.data.pagination
    });
}

export function setLoanOffers(loanOffers) {
    return {
        type: 'loan-offers/setList',
        list: loanOffers.data,
        pagination: loanOffers.pagination
    };
}
