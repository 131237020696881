import http from "../../../api";
// import {showGlobalLoader} from "../app";

export function showLoader() {
    return {
        type: "admins/showLoader",
    };
}

export function hideLoader() {
    return {
        type: "admins/hideLoader",
    };
}

export function fetchUsersAdmins(page, size, sortField, sorrtOrder) {
    return (dispatch) => {
        dispatch(showLoader());

        return http
            .get("/api/v1/user/admins", {
                params: {
                    page: !!page ? page : 0,
                    size: !!size ? size : 10,
                    sort: `${!!sortField ? sortField : "id"},${
                        !!sorrtOrder ? sorrtOrder : "DESC"
                    }`,
                },
            })
            .then((response) => {
                dispatch(setUsersAdmin(response.data));
                dispatch(hideLoader());
            })
            .catch((err) => {
                dispatch(hideLoader());
            });
    };
}

export function setUsersAdmin(admins) {
    return {
        type: "admins/setList",
        list: admins.data,
        pagination: admins.pagination,
    };
}

export function showAdminLoader() {
    return {
        type: "admin/showLoader",
    };
}

export function hideAdminLoader() {
    return {
        type: "admin/hideLoader",
    };
}

export const fetchUserAdmin = (id) => async (dispatch) => {
    dispatch(showAdminLoader());
    return await http
        .get(`/api/v1/user/${id}`)
        .then((res) => {
            dispatch(hideAdminLoader());
            return dispatch({
                type: "users/setAdmin",
                admin: res.data.data,
            });
        })
        .catch((err) => {
            dispatch(hideAdminLoader());
        });
};

export const deleteUser = (id, onSuccess) => async (dispatch) => {
    dispatch(showAdminLoader());
    return await http
        .delete(`/api/v1/user/${id}`)
        .then((res) => {
            dispatch(hideAdminLoader());
            onSuccess();
        })
        .catch((err) => {
            dispatch(hideAdminLoader());
        });
};

export const deleteApplication = (id, onSuccess) => async (dispatch) => {
    dispatch(showAdminLoader());
    return await http
        .delete(`/api/v1/application/${id}`)
        .then((res) => {
            dispatch(hideAdminLoader());
            onSuccess();
        })
        .catch((err) => {
            dispatch(hideAdminLoader());
        });
};

export const deleteCompany = (id, onSuccess) => async (dispatch) => {
    dispatch(showAdminLoader());
    return await http
        .delete(`/api/v1/company/${id}`)
        .then((res) => {
            dispatch(hideAdminLoader());
            onSuccess();
        })
        .catch((err) => {
            dispatch(hideAdminLoader());
        });
};

export function fetchBanks(page, size, sortField, sorrtOrder) {
    return (dispatch) => {
        dispatch(showLoader());

        return http
            .get("/api/v1/banks", {
                params: {
                    page: !!page ? page : 0,
                    size: !!size ? size : 10,
                    sort: `${!!sortField ? sortField : "id"},${
                        !!sorrtOrder ? sorrtOrder : "DESC"
                    }`,
                },
            })
            .then((response) => {
                dispatch(setBanks(response.data));
                dispatch(hideLoader());
            })
            .catch((err) => {
                dispatch(hideLoader());
            });
    };
}

export function setBanks(banks) {
    return {
        type: "users/setBanks",
        banks: banks.data,
    };
}
