import React from "react";
import { Button, message } from "antd";
import http from "../../../../api";
import { DeleteOutlined } from "@ant-design/icons";
import { ReactComponent as DownloadIcon } from "../../../../assets/admin/download.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/admin/search.svg";

const FileItem = (props) => {
	const { file, setIsLoading, setFile, id } = props;

	const handlePrewiewPdf = () => {
		if (setIsLoading) setIsLoading(true);
		http(`/api/v1/application/${file?.id}/file`, {
			method: "get",
			responseType: "blob",
		})
			.then((blob) => {
				if (!!blob && !!blob.data) {
					const fileURL = URL.createObjectURL(blob.data);

					window.open(fileURL);
				} else {
					message.error("Ett fel uppstod");
				}
				if(setIsLoading) setIsLoading(false)
			})
			.catch(() => {if(setIsLoading) setIsLoading(false)});
	};

	const handleDownloadPdf = () => {
		if (setIsLoading) setIsLoading(true);
		http(`/api/v1/application/${file?.id}/file`, {
			method: "get",
			responseType: "blob",
			headers: {
				"Content-Type": "application/pdf",
			},
		})
			.then((blob) => {
				if (setIsLoading) setIsLoading(false);
				const url = window.URL.createObjectURL(new Blob([blob.data]));

				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", `${file?.name}`);

				// Append to html link element page
				document.body.appendChild(link);

				// Start download
				link.click();

				// Clean up and remove the link
				link.parentNode.removeChild(link);
			})
		.catch(() => {if (setIsLoading) setIsLoading(true)});
	};

	const handleDeleteFile = () => {
		http(`/api/v1/credit-request/${id}/file`, {
			method: "DELETE",
		})
			.then((request) => {
				if(setFile) setFile(null);
			})
			.catch((err) => {});
	};

	return (
		<div>
			<span style={{ marginRight: "40px" }}>{file?.name}</span>
			<Button className="iconBtn" onClick={() => handlePrewiewPdf()}>
				<SearchIcon />
			</Button>
			<Button className="iconBtn" onClick={() => handleDeleteFile()}>
				<DeleteOutlined className="iconDelete" />
			</Button>
			<Button className="iconBtn" onClick={() => handleDownloadPdf()}>
				<DownloadIcon />
			</Button>
		</div>
	);
};

export default FileItem;
