import http from "../../api";
// import {showGlobalLoader} from "../app";

export function showLoader() {
    return {
        type: 'credit-requests/showLoader',
    };
}

export function hideLoader() {
    return {
        type: 'credit-requests/hideLoader',
    };
}

export function fetchCreditRequests(page, size, sortField, sorrtOrder, filters, search = '') {
    let params = {
        page: !!page ? page : 0,
        size: !!size ? size : 10,
        sort: `${!!sortField ? sortField : 'id'},${!!sorrtOrder ? sorrtOrder : 'DESC'}`
    }
    if (search !== null && search !== undefined && search !== '')
        params = {
            ...params,
            search
        }
        const filterString = {
            status: !!filters?.declinedAndLost ? ['in,STATUS_DECLINED', 'in,STATUS_REJECTED', 'in,STATUS_LOST'] : ['ex,STATUS_DECLINED', 'ex,STATUS_REJECTED', 'ex,STATUS_LOST']
        }
        if(!!filters && filters.status?.length > 0)
            filterString.status = [...filterString.status, `in,${filters.status}`]
    return (dispatch) => {
        dispatch(showLoader());
        return http.get(`/api/v1/credit-requests?filter=${encodeURIComponent(JSON.stringify(filterString))}`, {
            params
        })
            .then((response) => {
                dispatch(setCreditRequests(response.data));
                dispatch(hideLoader());
            })
            .catch(err => {
                dispatch(hideLoader());
            })
    }
    
}

export function fetchBanks() {
    return (dispatch) => {
        return http.get("/api/v1/banks")
            .then((response) => {
                dispatch(setBanks(response.data));
            })
            .catch(e => {});
    }
}

export function showLocalLoader() {
    return {
        type: 'credit-request/showLoader',
    };
}

export function hideLocalLoader() {
    return {
        type: 'credit-request/hideLoader',
    };
}

export const fetchCreditRequest = (id) => async (dispatch) => {
    dispatch(showLocalLoader())
    return await http.get(`/api/v1/credit-request/${id}`)
        .then(res => {
            dispatch(hideLocalLoader())
            return dispatch ({
                type: 'credit-requests/setCreditRequest',
                creditRequest: res.data.data,
            });
        })
        .catch(err => {
            dispatch(hideLocalLoader())
        })
}

export const fetchApplicationCreditRequests = (id) => async (dispatch) => {
    const response = await http.get(`/api/v1/application/${id}/credit-requests`);
    return dispatch ({
        type: 'credit-requests/setApplicationCreditRequests',
        creditRequests: response.data.data,
    });
}

export function setCreditRequests(creditRequests) {

    return {
        type: 'credit-requests/setList',
        list: creditRequests.data,
        pagination: creditRequests.pagination
    };
}

export function setBanks(banks) {

    return {
        type: 'credit-requests/setBanks',
        banks: banks.data
    };
}
