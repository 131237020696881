const initialState = {
    list: [],
	list_pagination: {},
	events_pagination: {},
	isLoaded_list: false,
	isFetching_list: false,

	application: {},
	isFetching_application: false,
	isLoaded_application: false,
};

export function applicationsReducer(state = initialState, action) {
    switch (action.type) {
		case 'applications/setList':
            return {
                ...state,
                list: action.list,
				list_pagination: action.pagination,
                isLoaded_list: true,
            };

		case 'applications/setApplication':
			return {
				...state,
				application: action.application,
				isLoaded_application: true,
			};

		case 'applications/setApplicationEvents':
			return {
				...state,
				events: action.events,
				events_pagination: action.pagination,
				isLoaded_events: true,
			};

		case 'applications/showLoader':
			return {
				...state,
				isFetching_list: true,
			};

		case 'applications/hideLoader':
			return {
				...state,
				isFetching_list: false,
			};

		case 'application/showLoader':
			return {
				...state,
				isFetching_application: true,
			};

		case 'application/hideLoader':
			return {
				...state,
				isFetching_application: false,
			};

		case 'applicationEvents/showLoader': 
			return {
				...state,
				isFetching_events: true,
			};

		case 'applicationEvents/hideLoader': 
			return {
				...state,
				isFetching_events: false,
			};
        default:
            return state;
    }
}
