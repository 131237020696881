import React, { useState } from "react";
import { Form } from "antd";
import { Upload, Spin, Button, message } from "antd";
import UploaderSelect from "../../../../uikit/uploaderSelect";
import http from "../../../../api";
import Message from "../../../../uikit/Message";
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { ReactComponent as DownloadIcon } from "../../../../assets/admin/download.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/admin/search.svg";
import FileItem from "./FileItem";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Uploader = (props) => {
	const { creditRequest } = props;
	const [validationerror, setValidationerror] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [file, setFile] = useState(null);

	useEffect(() => {
		setFile(creditRequest?.file);
	}, [creditRequest]);

	const handleUpload = (file) => {
		setValidationerror([]);
		setIsLoading(true);

		const MAX_SIZE = 10485760;
		if (!!file.file && !!file.file.size && file.file.size > MAX_SIZE) {
			setIsLoading(false);
			setValidationerror([
				"Maximum upload size exceeded. Max file size is 15 MB.",
			]);
			return;
		}

		const formData = new FormData();
		if (!!file.file) {
			const MAX_SIZE = 15728640;
			if (!!file.file && !!file.file.size && file.file.size > MAX_SIZE) {
				setValidationerror([
					"Maximum upload size exceeded. Max file size is 15 MB.",
				]);
				return;
			}
			formData.append("file", file.file);
			formData.append("fileType", "DUMMY");
		}

		http(`/api/v1/credit-request/${creditRequest?.id}/file`, {
			method: "post",
			data: formData,
		})
			.then(function (response) {
				setFile(response?.data?.file);
				setIsLoading(false);
			})
			.catch(function (error) {
				if (!!error && !!error.response.data) {
					if (!!error.response.data.message) {
						setValidationerror([error.response.data.message]);
					}
					if (!!error.response.data.error) {
						setValidationerror([error.response.data.error]);
					}
					if (
						!!error.response.data.status &&
						error.response.data.status === 500
					) {
						setValidationerror([
							"Something went wrong. File wasn't added",
						]);
					}
				}
				setIsLoading(false);
			});
	};

	const innerProps = {
		showUploadList: false,
		onChange: (info) => {
			// 	if (file.status !== 'uploading') {
			// console.log('info', info);
			// 	}
		},
	};

	const handlePrewiewPdf = () => {
		setIsLoading(true);
		http(`/api/v1/application/${file?.id}/file`, {
			method: "get",
			responseType: "blob"
		})
			.then((blob) => {
				if (!!blob && !!blob.data) {
					const fileURL = URL.createObjectURL(blob.data);

					window.open(fileURL);
				} else {
					message.error("Ett fel uppstod");
				}
				setIsLoading(false);
			})
			.catch(() => setIsLoading(false));
	};

	const handleDownloadPdf = () => {
		setIsLoading(true);
		http(`/api/v1/application/${file?.id}/file`, {
			method: "get",
			responseType: "blob",
			headers: {
				"Content-Type": "application/pdf",
			},
		})
			.then((blob) => {
				setIsLoading(false)
				const url = window.URL.createObjectURL(new Blob([blob.data]));

				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", `${file?.name}`);

				// Append to html link element page
				document.body.appendChild(link);

				// Start download
				link.click();

				// Clean up and remove the link
				link.parentNode.removeChild(link);
			})
			.catch(() => setIsLoading(false));
	};

	const handleDeleteFile = () => {
		http(`/api/v1/credit-request/${creditRequest?.id}/file`, {
			method: "DELETE",
		})
			.then((request) => {
				setFile(null);
			})
			.catch((err) => {});
	};

	return (
		<Form>
			{!!file && (
				<FileItem id={creditRequest?.id} file={file} setFile={setFile} setIsLoading={setIsLoading}/>
			)}
			<div style={{ maxWidth: "264px", marginTop: "20px" }}>
				<Upload.Dragger
					{...innerProps}
					name="file"
					accept="image/jpg, image/jpeg, image/png, application/pdf, application/msword, application/vnd.ms-excel,
				application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
				.doc, .docx, .rtf,
				application/vnd.openxmlformats-officedocument.wordprocessingml.document,
				application/rtf,
				application/msword,
				application/vnd.ms-word.document.macroenabled.12,
				application/vnd.openxmlformats-officedocument.wordprocessingml.document,
				application/vnd.ms-word.template.macroenabled.12,
			   	application/vnd.openxmlformats-officedocument.wordprocessingml.template"
					customRequest={handleUpload}
				>
					<div className="ant-upload-text">
						Bifoga offert
						{isLoading && (
							<Spin
								indicator={antIcon}
								style={{ marginLeft: "16px" }}
							/>
						)}
					</div>
				</Upload.Dragger>
				{validationerror.map((error) => (
					<div style={{ marginTop: "24px" }}>
						<Message content={error} type="error" />
					</div>
				))}
			</div>
		</Form>
	);
};

export default Uploader;
