import React, { Component } from "react";
import { Button, Table } from "antd";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ReactComponent as EyeIcon } from "../../../../assets/admin/eye.svg";
import "./style.scss";
import { Spin } from "antd";
import CustomTable from "../../../../components/CumtomTable/index";
import {
    fetchCompanies,
    fetchCompaniesExport,
} from "../../../../actions/users/companies";
import { getColumnSearchProps } from "../../../../utils/helpers";
import { CustomInput } from "../../../../uikit/Input";
import { CustomButton } from "../../../../uikit/Button";
import { Search } from "../../../../uikit/Search";
import {
    getQueryParam,
    getFilter,
    setFilter,
    isFiltered,
} from "../../../../utils/helpers";
import {
    CheckCircleFilled,
    DeleteOutlined,
    FileExcelOutlined,
    LoginOutlined,
} from "@ant-design/icons";
import config from "../../../../config";
import { deleteCompany } from "../../../../actions/users/admins";
import { toast } from "react-toastify";
import DeleteModal from "../../DeleteModal";

class CompaniesTable extends Component {
    constructor() {
        super();
        this.state = {
            search: "",
            companyToDelete: null,
        };
    }

    saveRequestParams = (
        currentPage,
        pageSize,
        sortField,
        sorrtOrder,
        filters
    ) => {
        this.setState({
            currentPage,
            pageSize,
            sortField,
            sorrtOrder,
            filters,
        });
    };

    getCompanies = () => {
        const {
            currentPage,
            pageSize,
            sortField,
            sorrtOrder,
            filters,
            search,
        } = this.state;
        this.props.fetchCompanies(
            currentPage,
            pageSize,
            sortField,
            sorrtOrder,
            filters,
            search
        );
    };

    getCompaniesExport = () => {
        const {
            currentPage,
            pageSize,
            sortField,
            sorrtOrder,
            filters,
            search,
        } = this.state;
        this.props.fetchCompaniesExport(
            currentPage,
            pageSize,
            sortField,
            sorrtOrder,
            filters,
            search
        );
    };

    setSearch = (search) => {
        this.setState({ search: search });
    };

    getType = (type) => {
        let className;
        let value = "-";
        if (type === "TYPE_DEBTOR") {
            className = "black";
            value = "Kund";
        } else if (type === "TYPE_CREDITOR") {
            className = "black";
            value = "Kreditgivare";
        }
        return <span className={`${className}`}>{value}</span>; //Bud lagt
    };

    getCareerType = (text, record) => {
        switch (record?.careerType) {
            case "BUILDER":
                return "Bostadsutvecklare";
            case "REAL_ESTATE":
                return "Fastighetsbolag";
            case "COOPERATIVE":
                return "Bostadsrättsförening";
            default:
                return "-";
        }
    };

    getStatus = (text, record) => {
        switch (record?.status) {
            case "STATUS_IN_REVIEW":
                return <span>Under granskning</span>;
            case "STATUS_REJECTED":
                return <span className="red">Nekad</span>;
            case "STATUS_APPROVED":
                return <span className="green">Godkänd</span>;
            case "STATUS_VERIFIED":
                return <span className="green">Verifierad</span>;
            default:
                return <span>-</span>;
        }
    };

    getStatusColor = (text, record) => {
        switch (record?.status) {
            case "STATUS_IN_REVIEW":
                return "inherit";
            case "STATUS_REJECTED":
                return "red";
            case "STATUS_APPROVED":
                return "green";
            case "STATUS_VERIFIED":
                return "green";
            default:
                return "-";
        }
    };

    formatDate = (date) => {
        return date.toString().substring(0, date.length - 3);
    };

    formatLastActivityDate = (date) => {
        if (!!date) {
            return date
                .toString()
                .replace(/T/, " ")
                .substring(0, date.length - 3);
        } else {
            return "-";
        }
    };

    loginToClient = (id) => {
        const { user } = this.props;
        window.open(
            `${config.WEB_APP}/user/login?admin_token=${btoa(
                JSON.stringify({ id, token: user?.token })
            )}`
        );
    };

    onDeleteOpen = (user) => () => {
        this.setState({ companyToDelete: user });
    };

    onSuccess = () => {
        this.props.fetchCompanies(
            0,
            10,
            null,
            null,
            {
                includeRejected: false,
            },
            null
        );
        this.setState({ companyToDelete: null });
        toast.success(
            <div className="toast-body">
                <CheckCircleFilled className="toast__icon toast__icon--success" />
                <p className="toast-text">Company has been deleted!</p>
            </div>
        );
    };

    onDeleteCompany = (user) => () => {
        this.props.deleteCompany(user.id, this.onSuccess);
    };

    onModalCancel = () => {
        this.setState({ companyToDelete: null });
    };

    render() {
        const { Column } = Table;
        const { companyToDelete } = this.state;

        return (
            <Spin spinning={this.props.loading}>
                <Search
                    search={this.state.search}
                    setSearch={this.setSearch}
                    fetchData={this.getCompanies}
                />
                <CustomTable
                    className="tableLoanOffersTable"
                    dataSource={this.props.companies}
                    dataSourcePagination={this.props.listPagination}
                    fetchData={this.props.fetchCompanies}
                    saveRequestParams={this.saveRequestParams}
                >
                    <Column
                        title="id"
                        dataIndex="id"
                        key="id"
                        style={{ textAlign: "start" }}
                        sorter={true}
                        render={(text, record) => (
                            <div className="actionBtn">
                                <Link
                                    to={`${
                                        "/companies/detail?id=" + record?.id
                                    }`}
                                    style={{
                                        color: "inherit",
                                        fontWeight: 800,
                                    }}
                                    // loanDataById={record}
                                >
                                    {record?.id}
                                </Link>
                                <div className="width"></div>
                            </div>
                        )}
                    />
                    <Column
                        title="Konto"
                        dataIndex="name"
                        key="name"
                        sorter={true}
                        render={(text, record) => record?.name || "-"}
                    />
                    <Column
                        title="Bolagstyp"
                        dataIndex="careerType"
                        key="careerType"
                        sorter={true}
                        render={this.getCareerType}
                    />
                    <Column
                        title="Status"
                        dataIndex="status"
                        key="status"
                        sorter={true}
                        render={this.getStatus}
                    />
                    <Column
                        title="Ägare"
                        dataIndex="admin.firstName"
                        key="adminName"
                        sorter={true}
                        render={(data, record) => (
                            <span>{record?.adminName || "-"}</span>
                        )}
                    />
                    <Column
                        title="Återkomst"
                        dataIndex="followUpDate"
                        key="followUpDate"
                        sorter={true}
                        render={(data, record) =>
                            record?.followUpDate ? (
                                <span className="follow-up-date">
                                    {record?.followUpDate || "-"}
                                    <div className="follow-up-comment">
                                        {record?.followUpComment ||
                                            "Ingen kommentar"}
                                    </div>
                                    <div className="follow-up-triangle"></div>
                                </span>
                            ) : (
                                "-"
                            )
                        }
                    />
                    <Column
                        title="Senaste aktivitet"
                        dataIndex="lastActivity"
                        key="lastActivity"
                        sorter={true}
                        render={(text, record) => (
                            <span>
                                {this.formatLastActivityDate(
                                    record?.lastActivity
                                ) || "-"}
                            </span>
                        )}
                    />
                    {/* <Column
						title="Antal anv"
						dataIndex="numberOfUsers"
						key="numberOfUsers"
						sorter={true}
						render={(text, record) => (
							<span>{record?.numberOfUsers || "-"}</span>
						)}
					/> */}
                    <Column
                        key="login"
                        render={(text, record) => (
                            <div className="actionBtn">
                                <Link to={""}>
                                    <button
                                        onClick={() => {
                                            this.loginToClient(record?.ownerId);
                                        }}
                                        buttontype="type10"
                                        className="buttonType10"
                                        size="small"
                                        style={{
                                            width: "100%",
                                            backgroundColor: "#fff",
                                            display: "inline-flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <LoginOutlined />
                                    </button>
                                </Link>
                            </div>
                        )}
                    />
                    <Column
                        key="myCreditRequests"
                        title={() => {
                            return (
                                <div
                                    className="export-container"
                                    onClick={this.getCompaniesExport}
                                >
                                    <FileExcelOutlined className="export-icon" />
                                </div>
                            );
                        }}
                        dataIndex="myCreditRequests"
                        className="table-custom-filter"
                        filters={[
                            {
                                text: "Mina företag",
                                value: "myCreditRequests",
                            },
                            {
                                text: "Nekade",
                                value: "includeRejected",
                            },
                            // { text: 'Draft', value: 'STATUS_DRAFT' },
                        ]}
                        {...getColumnSearchProps({
                            isChecked: () => getFilter("companies-filters"),
                            setChecked: (value) =>
                                setFilter("companies-filters", value),
                        })}
                        render={(text, record) => (
                            <>
                                <div className="actionBtn">
                                    <Link
                                        to={`${
                                            "/companies/detail?id=" + record?.id
                                        }`}
                                    >
                                        <button
                                            buttontype="type10"
                                            className="buttonType10"
                                            size="small"
                                            style={{
                                                width: "100%",
                                                backgroundColor: "#fff",
                                                display: "inline-flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <EyeIcon
                                                style={{
                                                    marginRight: 10,
                                                    width: 20,
                                                }}
                                            />
                                            Visa
                                        </button>
                                    </Link>
                                    <Button
                                        className="buttonType10 iconBtnTable widthNone"
                                        styles={{ width: "fit-conent" }}
                                        onClick={this.onDeleteOpen(record)}
                                    >
                                        <DeleteOutlined className="iconDelete" />
                                    </Button>
                                    <div className="width"></div>
                                </div>
                            </>
                        )}
                    />
                </CustomTable>
                <DeleteModal
                    show={!!companyToDelete}
                    isLoading={false}
                    title="Delete user"
                    description={`Are you sure you want to delete company - ${companyToDelete?.name} (ID: ${companyToDelete?.id})`}
                    handleCancel={this.onModalCancel}
                    onDelete={this.onDeleteCompany(companyToDelete)}
                />
            </Spin>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        companies: store.companies.list,
        listPagination: store.companies.list_pagination,
        loading: store.companies.isFetching_list,
        user: store.me.profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteCompany: (id, onSuccess) =>
            dispatch(deleteCompany(id, onSuccess)),
        fetchCompanies: (
            currentPage,
            pageSize,
            sortField,
            sorrtOrder,
            filters
        ) => {
            let store = getFilter("companies-filters");
            filters = {
                ...filters,
                myCreditRequests: !!store.find(
                    (el) => el === "myCreditRequests"
                ),
                includeRejected: !!store.find((el) => el === "includeRejected"),
            };
            let search = getQueryParam("search");
            return dispatch(
                fetchCompanies(
                    currentPage,
                    pageSize,
                    sortField,
                    sorrtOrder,
                    filters,
                    search
                )
            );
        },
        fetchCompaniesExport: (
            currentPage,
            pageSize,
            sortField,
            sorrtOrder,
            filters
        ) => {
            let store = getFilter("companies-filters");
            filters = {
                ...filters,
                myCreditRequests: isFiltered(store, "myCreditRequests"),
                includeRejected: isFiltered(store, "includeRejected"),
            };
            let search = getQueryParam("search");
            return dispatch(
                fetchCompaniesExport(
                    currentPage,
                    pageSize,
                    sortField,
                    sorrtOrder,
                    filters,
                    search
                )
            );
        },
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CompaniesTable)
);
