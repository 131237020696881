import React, {useState, useEffect} from 'react';
import { Modal } from 'antd';
import { Checkbox, Alert } from 'antd';
import { CustomButton } from '../../../../uikit/Button';
import http from "../../../../api"; //<CheckSquareOutlined />
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { CheckCircleOutlined } from '@ant-design/icons';
import { fetchApplicationCreditRequests } from '../../../../actions/credit-requests';
import { fetchApplication } from '../../../../actions/applications';
import "./style.scss";

const LoginMessage = ({ content }) => (
	<Alert
		style={{
			marginTop: 15,
			marginBottom: 10,
			borderRadius: 6
		}}
		closable
		description={content}
		type="error"
		showIcon
	/>
);

export const BanksModal = (props) => {
	const { 
		title, 
		showLeadDialog, 
		handleCancel, 
		company, 
		banks, 
		creditRequests 
	} = props;

	const [ checked, setChecked ] = useState({});
	const [ selectedBanks, setSelectedBanks] = useState([]);
	const [ previousSendToBanks, setPreviousSendToBanks ] = useState({});
	const [ previousSendToBanksChecked, setPreviousSendToBanksChecked ] = useState({});
	const [ previousSendToBanksArray, setPreviousSendToBanksArray ] = useState([]);
	const [ errors, setErrors ] = useState({});
	const [ successMessage, setSuccessMessage ] = useState(false);

	useEffect(() => {
		props.fetchApplicationCreditRequests(props.id)
			.then(res => {
				if (!!res && !!res.creditRequests) {
					setChecked(res.creditRequests.reduce((acc, creditRequest) => {
						return ({...acc, [creditRequest.company.id]: true})
					}, {}));

					let banks = [...selectedBanks];
					let banksMap = {}
					res.creditRequests.forEach(creditRequest => {
						if (!!creditRequest?.company?.id && banks.indexOf(creditRequest.company.id) === -1 ) {
							banks.push(creditRequest.company.id)
							banksMap = {...banksMap, [creditRequest.company.id]: true}
						}
					})
					setSelectedBanks(Array.from(new Set(banks)));
					setPreviousSendToBanksArray(Array.from(new Set(banks)));
				}
			})
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const sendToBanks = creditRequests.map(creditRequest => {
			return (creditRequest.company.id)
		});
		setPreviousSendToBanks(creditRequests.reduce((acc, creditRequest) => {
			return ({...acc, [creditRequest.company.id]: true})
		}, {}));
		setPreviousSendToBanksChecked(creditRequests.reduce((acc, creditRequest) => {
			return ({...acc, [creditRequest.company.id]: true})
		}, {}))
		setChecked(props.banks.reduce((acc, bank, index) => {
			return { 
				...acc,
				[bank.id]: sendToBanks.some(item => item === bank.id) ? true : false 
			}
		}, {}))
	}, [banks, creditRequests]); // eslint-disable-line react-hooks/exhaustive-deps

	const onToggleViewDialog = async () => {
		handleCancel(false);
	};

	function onChange(e, id) {
		setChecked(prevState => ({
			...prevState,
			[id]: previousSendToBanks[id] ? true : e.target.checked
		}));
		if (e.target.checked === true) {
			let newBanks = [...selectedBanks, id];
			setSelectedBanks(Array.from(new Set(newBanks)));
		} else {
			setSelectedBanks(selectedBanks.filter(bank => bank !== id))
		}
	}

	const handleCreditRequests = () => {
		http(`api/v1/application/${props.id}/credit-request`, {
			method: 'post',
			data: {"banks": selectedBanks}
		})
		.then(function(response) {
			props.fetchApplicationCreditRequests(props.loanDataById.id);
			props.fetchApplication(props.loanDataById.id);
			setSuccessMessage(true);
			props.handleCancel(false);
			toast.success(
				<div className="toast-body">
					<CheckCircleOutlined className="toast__icon toast__icon--success" />
					<p className="toast-text">{`Kreditförfrågan har skickats`}</p>
				</div>)
			setPreviousSendToBanksArray(Array.from(new Set([...previousSendToBanksArray, ...selectedBanks])));
		})
		.catch(function(error) {
			if (!!error.response && !!error.response.data && !!error.response.data.errors)
			setErrors(error.response.data.errors);
		});
	}

	const disabledBtn = !!banks && banks.length === previousSendToBanksArray.length
		? true
		: !selectedBanks.length
		? true
		: false;

	return (
		<Modal
			className="modalContainer"      
			animation="zoom"
			maskAnimation="fade"
			forceRender
			visible={showLeadDialog}
			title={title}
			size={'large'}
			footer={false}        
			destroyOnClose
			bodyStyle={{
				padding: '32px 30px 48px',
			}}

			onCancel={() =>onToggleViewDialog()}
		>
			<div className="uploadModal" style={{textAlign:'center'}}>
				<h2 style={{marginBottom: 30, padding: "0 10px"}}>{`Skicka kreditförfrågan till banker från ${company}`}</h2>

				<p>Välj kreditgivare som ska få kreditförfrågan nedan. Klicka sedan på “skicka“.</p>
				<div style={{marginTop: 15}}>
					{banks.map((bank, index) => {
						return (
							<Checkbox
								key={bank.id}
								checked={checked[bank.id]}
								disabled={previousSendToBanksChecked[bank.id]}
								onChange={e => {onChange(e, bank.id)}}
							>
								{bank.name}
							</Checkbox>
					)})}
					{!!errors && !!errors.banks ? <LoginMessage content={errors.banks} /> : false}
					{!!successMessage && (
						<Alert
							style={{
								marginTop: 15,
								marginBottom: 10,
								borderRadius: 6,
								backgroundColor: 'rgba(0, 204, 189, 0.05)',
								borderColor: 'rgba(0, 204, 189, 0.05)'
							}}
							message="Kreditbegäran har skickats"
							type="success"
							closable
						/>
					)}
				</div>

				<div className="buttons" style={{marginTop: 15}}>
					<CustomButton 
						buttontype="type6"
						size="large"
						onClick={() => {
							handleCreditRequests()												
						}}
						disabled={disabledBtn}
					>
						Godkänn och skicka
					</CustomButton>
				</div>
			</div>
		</Modal>
	);
};

const mapStateToProps = (store) => {
	return {
	  loanDataById: store.applications.application,
	  creditRequests: store.creditRequests.applicationCreditRequests	
	}
  };

const mapDispatchToProps = (dispatch) => {
	return {
	  fetchApplicationCreditRequests: (id) => dispatch(fetchApplicationCreditRequests(id)),//fetchApplication
	  fetchApplication: (id) => dispatch(fetchApplication(id))
	}
  };

export default connect(mapStateToProps, mapDispatchToProps)(BanksModal);
