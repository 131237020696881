import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { connect } from "react-redux";
import "../style.scss";
import { fetchDebtors } from "../../../../../actions/users/companies";
import { getQueryParam } from "../../../../../utils/helpers";
import { withRouter } from "react-router-dom";
import http from "../../../../../api";
import { CustomSelect } from "../../../../../uikit/Select";

const formItemLayout = {
	labelCol: {
		span: 5,
	},
	wrapperCol: {
		span: 19,
	},
};

const AdminLinkSelect = (props) => {
	const { id, adminId, admins, me } = props;

	const [form] = Form.useForm();
	const [adminsInner, setAdmins] = useState([]);

	useEffect(() => {
		form.setFieldsValue({ adminId });
	}, [adminId]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setAdmins(admins);
	}, [admins]);

	const onChange = (adminId) => {
		http.post(`/api/v1/application/${id}/link/${adminId}`)
			.then((response) => {})
			.catch((err) => {});
	};

	let selectAdmins =
		admins.length > 0 && !!me
			? admins
					?.filter((a) => a.id !== me.id)
					?.map((a) => {
						return { value: a.id, label: a.name };
					})
			: [];
	selectAdmins.unshift({ value: me.id, label: "Jag" });
	return (
		<Form
			{...formItemLayout}
			form={form}
			layout="vertical"
			className="companyForm"
			hideRequiredMark
			initialValues={props.company}
			// initialValues={data}
		>
			<CustomSelect
				key="adminId"
				name="adminId"
				// validateStatus={!!errors.adminId ? "error" : ""}
				// help={!!errors.adminId ? String(errors.adminId) : null}
				style={{ marginBottom: 0 }}
				inputprops={{
					size: "large",
					placeholder: "Välj administratör",
					style: { fontWeight: "300" },
					onChange: onChange,
				}}
				options={selectAdmins}
			/>
		</Form>
	);
};

const mapStateToProps = (store) => {
	return {
		debtors: store.companies.debtors,
		loading: store.companies.isFetching_debtors,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchCompanyUsers: (id) => dispatch(fetchDebtors(id)),
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(AdminLinkSelect)
);
