import React, { Component } from "react";
import { Table, Spin } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactComponent as EyeIcon } from '../../../../assets/admin/eye.svg';
import { fetchLoanOffersApproved } from '../../../../actions/loan-offers'
import CustomTable from '../../../../components/CumtomTable';
import './style.scss';
import { getColumnSearchProps } from "../../../../utils/helpers";

class MyLendingTable extends Component {


    render() {
        const { Column } = Table;
        let isAdmin = window.location.host.toString().includes("admin");
        let adminFilter = isAdmin ? getColumnSearchProps({
            isChecked: () => localStorage.getItem("l-mcr"),
            setChecked: (value) =>
                localStorage.setItem("l-mcr", value)
        }) : {}
        if (isAdmin) {
            adminFilter = {
                ...adminFilter,
                filters: [
                    {
                        text: "Mina krediter",
                        value: "true",
                        field: "myCreditRequests",
                    },
                    // { text: 'Draft', value: 'STATUS_DRAFT' },
                ],
                key:"myCreditRequests",
                dataIndex:"myCreditRequests",
                className:"table-custom-filter"
            }
        }

        return (
            <Spin spinning={this.props.loading}>
                <CustomTable
                    className="tableLoanOffersTable" 
                    dataSource={this.props.loanOfferList}
                    dataSourcePagination={this.props.listPagination}
                    fetchData={this.props.fetchLoanOffersApproved}
                >
                    <Column
                        title="id"
                        dataIndex="id"
                        key="id"
                        sorter={true}
                        render={(text, record) => (
                            <div className='actionBtn'>
                                <Link 
                                    to={`${'/credit/detail?id='+record?.creditRequest?.id}`}
                                    style={{color: 'inherit', fontWeight: 800}}
                                >
                                    {record?.id}
                                </Link>
                                <div className='width'></div>
                            </div>
                        )}
                    />
                    {window.location.host.toString().includes("admin") &&
                        <Column
                            title="Kredittagare"
                            dataIndex="creditRequest.company.name"
                            key="creditRequest.company.name"
                            sorter={true}
                            render={(text, record) => {
                                return (
                                <span>
                                    {record?.creditRequest?.company?.name || '-'}
                                </span>
                            )}}
                        />
                    }
                    <Column
                        title="Låntagare"
                        dataIndex="creditRequest.application.debtorName"
                        key="debtorName"
                        sorter={true}
                        render={(text, record) => {
                            return (
                            <span>
                                {record?.creditRequest?.application?.data?.debtorName || '-'}
                            </span>
                        )}}
                    />
                    <Column
                        title="Belopp"
                        dataIndex="amount"
                        key="amount"
                        sorter={true}
                        render={(text, record) => (
                            <span>
                                {!!record.amount ? `${record.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} SEK` : 0}
                            </span>
                        )}
                    />
                    <Column
                        title="Löptid"
                        dataIndex="duration"
                        key="duration"
                        sorter={true}
                        render={(text, record) => (
                            <span>
                                {text ? `${text} Mån` : '-'}
                            </span>
                        )}
                    />
                    <Column
                        title="Ränta"
                        dataIndex="interest"
                        key="interest"
                        sorter={true}
                        render={(text, record) => (
                            <span >
                                {text ? `${text} %` : 0}
                            </span>
                        )}
                    />
                    <Column
                        key="loan_details"
                        {...adminFilter}
                        render={(text, record) => (
                            <>
                            <div className='actionBtn'>
                                <Link 
                                    to={`${'/credit/detail?id='+record?.creditRequest?.id}`}
                                >
                                    <button 
                                        buttontype="type10"
                                        className="buttonType10"
                                        size="small"
                                        style={{width: '100%', backgroundColor: '#fff', display: 'inline-flex', alignItems: 'center'}}
                                    >
                                        <EyeIcon style={{marginRight: 10, width: 20}}/>
                                        Visa
                                    </button>
                                </Link>
                                <div className='width'></div>
                            </div>
                            </>
                        )}
                    />
                </CustomTable>
            </Spin>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        loanOfferList: store.loanOffers.list,
        listPagination: store.loanOffers.list_pagination,
        loading: store.loanOffers.isFetching_list
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchLoanOffersApproved: (currentPage, pageSize, sortField, sorrtOrder, filters) => {
            filters = {
                ...filters,
                myCreditRequests: [localStorage.getItem('l-mcr')]
            }
            return dispatch(
                fetchLoanOffersApproved(currentPage, pageSize, sortField, sorrtOrder, filters, window.location.host.toString().includes("admin"))
            )
        }
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyLendingTable));