import React from 'react';
import { Form, Select } from 'antd';
import '../scss/uikit.scss';
const { Option } = Select;

export const CustomSelect = (props) => {
	return (
		<Form.Item {...props} className="select">
			<Select	{...props.inputprops} >
				{props.options.map((item, index) => (
					<Option key={index} value={item.value}>
						{item.label}
					</Option>
				))}
			</Select>
		</Form.Item>
	);
};

export default CustomSelect;
