import React, {useState, useEffect } from "react";
import { Table, Spin } from 'antd';
import { connect } from 'react-redux';
import './styles.scss';

const CustomTable = (props) => {
    const { className, data, dataSource, dataSourcePagination, loading, fetchData, id, columns, saveRequestParams } = props;

    const [ pagination, setPagination ] = useState({ 
        current: 1,
        total: 10, // totalElements
        pageSize: 10, //numberOfElements
        showSizeChanger: false,
        // pageSizeOptions: ['10', '20', '30']
    })   

    useEffect(() => {
        getData(pagination.current - 1, pagination.pageSize, 'id', 'DESC', data, id);
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        setPagination({
          ...pagination,
          current: !!dataSourcePagination && !!dataSourcePagination.currentPage ? dataSourcePagination.currentPage + 1 : 1,
          total: !!dataSourcePagination && !!dataSourcePagination.totalElements ? dataSourcePagination.totalElements : 10,
          pageSize: pagination.pageSize
        });
    }, [dataSourcePagination]) // eslint-disable-line react-hooks/exhaustive-deps


    const getData = (currentPage, pageSize, sortField, sorrtOrder, filters, data) => {
        fetchData(currentPage, pageSize, sortField, sorrtOrder, filters, data, id)
    }

    const handleTableChange = (pagination, filters={}, sorter={}) => {
        const currentPage = !!pagination.current && pagination.current !== 0 ? pagination.current - 1 : 0;
        const pageSize = !!pagination.pageSize ? pagination.pageSize : 10;
        const sortField = !!sorter && !!sorter.field ? sorter.field : 'id';
        const sorrtOrder = !!sorter && !!sorter.order && sorter.order === 'ascend' ? 'ASC' : 'DESC';
        // const statusFilter = !!filters.status ? {status: [...filters.status.map((item, i) => {
        //     return `in,${item}`
        //   })]} : {};
        if (!!saveRequestParams)
            saveRequestParams(currentPage, pageSize, sortField, sorrtOrder, filters)
        getData(currentPage, pageSize, sortField, sorrtOrder, filters, data, id);
    }

    return (
        <Spin spinning={loading}>
            <Table
                className={className}
                rowKey={record => record.id}
                dataSource={dataSource} 
                pagination={pagination}
                sorter={true}
                scroll={{ x: 'fit-content' }}
                onChange={(pagination, filter, sorter) => handleTableChange(pagination, filter, sorter)}
                showSizeChanger
                columns={columns}
            >
                {props.children}
            </Table>
        </Spin>
    );
}

const mapStateToProps = (store) => {
    return {
        notifications: store.notifications.list,
        loading: store.notifications.isFetching_list
    }
};


export default connect(mapStateToProps)(CustomTable);