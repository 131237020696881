import React, { Component } from "react";
import { Table, Spin, Checkbox, Space, Button } from "antd";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomTable from "../../../../components/CumtomTable";
import { ReactComponent as EyeIcon } from "../../../../assets/admin/eye.svg";
import { getStatus, getProduct } from "../../../../utils/helpers";
import { fetchCreditRequests } from "../../../../actions/credit-requests";
import { SearchOutlined } from "@ant-design/icons";
import { getColumnSearchProps, getFilter, setFilter, isFiltered } from "../../../../utils/helpers";

import "./style.scss";
class CreditRequestTable extends Component {
	formatDate = (item, column) => {
		let value;
		if (item && item[column]) {
			value = new Date(item[column]).toLocaleDateString("sv-SE");
		} else {
			value = "-";
		}
		// return dateStr.toString().slice(0, 10).split('-').reverse().join('.');
		return value;
	};

	componentDidMount() {
		setFilter("lo-declined-lost", [])
	}

	filterDropdownCheckbox = ({
		setSelectedKeys,
		selectedKeys,
		confirm,
		clearFilters,
		dataIndex = "status",
		filters,
	}) => {
		return (
			<div
				className="filterDropdown"
				style={{ padding: 8, display: "flex", flexDirection: "column" }}
			>
				<Checkbox.Group
					className="filtersCheckbox"
					options={filters.map((item) => {
						return { value: item.value, label: item.text };
					})}
					value={selectedKeys}
					onChange={(checked) => {
						setSelectedKeys(checked ? checked : []);
					}}
				/>
				<Space>
					<Button
						className="filterBtn filterRtnSearch"
						type="primary"
						onClick={() =>
							this.handleSearch(selectedKeys, confirm, dataIndex)
						}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button
						className="filterBtn filterRtnReset"
						onClick={() => this.handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
				</Space>
			</div>
		);
	};

	handleSearch = (selectedKeys, confirm) => {
		confirm();
	};

	handleReset = (clearFilters) => {
		clearFilters();
	};

	render() {
		const { Column } = Table;

		return (
			<div className="loanOffersTableWrapper">
				<Spin spinning={this.props.loading}>
					<CustomTable
						className="tableLoanOffersTable"
						dataSource={this.props.loanList}
						dataSourcePagination={this.props.listPagination}
						fetchData={this.props.fetchCreditRequests}
						// defaultCurrent={3}
					>
						<Column
							title="id"
							dataIndex="id"
							key="id"
							sorter={true}
							style={{ textAlign: "start" }}
							render={(text, record) => (
								<div className="actionBtn">
									<a
										href={`${
											"/credit-request/detail?id=" +
											record?.id
										}`}
										style={{
											color: "inherit",
											fontWeight: 800,
										}}
										// loanDataById={record}
									>
										{record?.id}
									</a>
									<div className="width"></div>
								</div>
							)}
						/>
						<Column
							title="Låntagare"
							dataIndex="application.debtorName"
							key="debtorName"
							sorter={true}
							render={(text, record) =>
								record?.application?.data?.debtorName
							}
						/>
						<Column
							title="Datum"
							dataIndex="createdAt"
							key="createdAt"
							sorter={true}
							render={(data, record) => (
								<span>
									{this.formatDate(
										record?.application,
										"createdAt"
									) || "-"}
								</span>
							)}
						/>
						<Column
							title="Status"
							dataIndex="status"
							key="status"
							sorter={true}
							filterDropdown={(
								setSelectedKeys,
								selectedKeys,
								confirm,
								clearFilters,
								filters
							) =>
								this.filterDropdownCheckbox(
									setSelectedKeys,
									selectedKeys,
									confirm,
									clearFilters,
									"status",
									filters
								)
							}
							filters={[
								{ text: "Aktiv", value: "STATUS_ACTIVE" },
								// { text: 'Draft', value: 'STATUS_DRAFT' },
							]}
							render={(text, record) => (
								<span>
									{getStatus(
										record?.status,
										record?.loanOffer?.status
									)}
								</span>
							)}
						/>
						<Column
							title="Belopp"
							dataIndex="application.amount"
							key="amount"
							sorter={true}
							render={(text, record) => (
								<span>
									{!!record?.application?.data?.amount
										? `${record?.application?.data?.amount
												.toString()
												.replace(
													/\B(?=(\d{3})+(?!\d))/g,
													","
												)} SEK`
										: 0}
								</span>
							)}
						/>
						<Column
							title="Ändamål"
							dataIndex="application.type"
							key="application.type"
							sorter={true}
							render={(text, record) => (
								<span>
									{getProduct(record?.application?.type)}
								</span>
							)}
						/>

						{/* <Column
                        title="Antal bud"
                        dataIndex="loanOfferCount"
                        key="loanOfferCount"
                        sorter={true}
                        render={(text, record) => (
                            <span >
                                {!!record?.application?.loanOfferCount ? record?.application?.loanOfferCount : 0}
                            </span>
                        )}
                    /> */}
						<Column
							key="loan_details"
							className="table-custom-filter"
							render={(text, record) => (
								<>
									<div className="actionBtn">
										<Link
											to={`${
												"/credit-request/detail?id=" +
												record?.id
											}`}
										>
											<button
												buttontype="type10"
												className="buttonType10"
												size="small"
												style={{
													width: "100%",
													backgroundColor: "#fff",
													display: "inline-flex",
													alignItems: "center",
												}}
											>
												<EyeIcon
													style={{
														marginRight: 10,
														width: 20,
													}}
												/>
												Visa
											</button>
										</Link>
										<div className="width"></div>
									</div>
								</>
							)}
                            filters = {
                                [
                                    {
                                        text: "Avslagna & förlorade",
                                        value: "declinedAndLost"
                                    }
                                    // { text: 'Draft', value: 'STATUS_DRAFT' },
                                ]
                            }
							{...getColumnSearchProps({
								isChecked: () => getFilter("lo-declined-lost"),
								setChecked: (value) => setFilter("lo-declined-lost", value)
							})}
						/>
						{/* </Table> */}
					</CustomTable>
				</Spin>
			</div>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		loanList: store.creditRequests.list,
		listPagination: store.creditRequests.list_pagination,
		loading: store.creditRequests.isFetching_list,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchCreditRequests: (
			currentPage,
			pageSize,
			sortField,
			sorrtOrder,
			filters
		) => {

            let store = getFilter("lo-declined-lost");
			filters = {
				...filters,
				declinedAndLost: isFiltered(store, "declinedAndLost"),
			};

            return dispatch(
				fetchCreditRequests(
					currentPage,
					pageSize,
					sortField,
					sorrtOrder,
					filters
				)
			)
        },
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(CreditRequestTable)
);
