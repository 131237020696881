import React, { useState, useCallback, useEffect } from "react";
import { Form } from "antd";
import { Upload, Spin } from "antd";
import http from "../api";
import Message from "./Message";
import { LoadingOutlined } from "@ant-design/icons";
import { DeleteOutlined } from "@ant-design/icons";

import debounce from "lodash.debounce";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const LogoUploader = (props) => {
	const { id, logo } = props;
	const [validationerror, setValidationerror] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [file, setFile] = useState(null);
	const [fileBase64, setFileBase64] = useState(null);
	const [ isHover, setHover ] = useState(false)

	useEffect(() => {
		setFileBase64(logo);
	}, [logo]); // eslint-disable-line react-hooks/exhaustive-deps

	const deleteLogo = () => {
		http(`/api/v1/company/${id}/logo`, {
			method: "delete"
		}).then(() => {
			setFileBase64(null)
			setFile(null)
			setHover(false)
		})
	}

	const handleUpload = (file, id) => {
		setValidationerror([]);
		setIsLoading(true);

		const MAX_SIZE = 15728640;
		if (!!file && !!file.size && file.size > MAX_SIZE) {
			setIsLoading(false);
			setValidationerror(["Max storlek om 15MB på fil överskriden."]);
			return;
		}

		const formData = new FormData();
		formData.append("logo", file.originFileObj);

		http(`/api/v1/company/${id}/logo`, {
			method: "put",
			data: formData,
		})
			.then(function (response) {
				setIsLoading(false);
				setFileBase64(response.data.data.logo);
			})
			.catch(function (error) {
				if (!!error && !!error?.response?.data) {
					if (!!error.response.data.message) {
						setValidationerror([error.response.data.message]);
					}
					if (!!error.response.data.error) {
						setValidationerror([error.response.data.error]);
					}
					if (
						!!error.response.data.errors &&
						!!error.response.data.errors.files
					) {
						setValidationerror([error.response.data.errors.files]);
					}
					if (
						!!error.response.data.errors &&
						Object.keys(error.response.data.errors).some((key) =>
							key.startsWith("file")
						)
					) {
						setValidationerror([
							Object.values(error.response.data.errors)[0],
						]);
					}
					if (
						!!error.response.data.status &&
						error.response.data.status === 500
					) {
						setValidationerror([
							"Ett fel uppstod. Uppladdning av file lyckades inte",
						]);
					}
				}
				setIsLoading(false);
			});
		setIsLoading(false);
	};

	const debouncedChangeHandler = useCallback(debounce(handleUpload, 500), []); // eslint-disable-line react-hooks/exhaustive-deps

	const innerProps = {
		showUploadList: false,
		multiple: false,
		onChange: (info) => {
			if (!!info && !!info.file) {
				setFile(info.file);
			}
		},
		customRequest: (files) => {
			debouncedChangeHandler(file, id);
		},
	};

	return (
		<Form>
			<Upload.Dragger
				{...innerProps}
				name="file"
				accept="image/jpg, image/jpeg, image/png"
			>
				<div className="ant-upload-text">
					Bifoga logotyp
					{isLoading && (
						<Spin
							indicator={antIcon}
							style={{ marginLeft: "16px" }}
						/>
					)}
				</div>
			</Upload.Dragger>
			{validationerror.map((error, index) => (
				<div style={{ marginTop: "24px" }} key={index}>
					<Message content={error} type="error" />
				</div>
			))}
			{!!fileBase64 && (
				<div
					className="logoUploader-logo"
					onMouseOver={() => setHover(true)}
					onMouseOut={() => setHover(false)}
					onClick={deleteLogo}
				>
					<div
						className={`logoUploader-logo-hover ${isHover ? 'show' : 'hide'}`}
					>
					</div>
					<DeleteOutlined className={`logoUploader-logo-delete ${isHover ? 'show' : 'hide'}`} />
					<img
						style={{ width: "100%" }}
						src={`data:image/png;base64,${fileBase64}`}
						alt="logo"
					/>
				</div>
			)}
		</Form>
	);
};

export default LogoUploader;
