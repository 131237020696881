import React, { useState } from "react";
import { Spin } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const BankIdModal = (props) => {
    const { users, onSelect, bankIdUrl, closePopup } = props;
    return (
        <div className="loginPopup-container">
            <div className="loginPopup-loader">
                <CloseOutlined
                    onClick={closePopup}
                    className="loginPopup-close"
                />

                {!!bankIdUrl && !users ? (
                    <iframe
                        title="BankID login"
                        style={{
                            width: "100%",
                            height: "100%",
                            border: "0",
                        }}
                        src={bankIdUrl}
                    />
                ) : (
                    <div className="loginPopup-loader loginPopup-list">
                        <p>
                            Please, select the account you would like to login
                            to
                        </p>
                        {users?.map((u) => (
                            <div
                                onClick={() => onSelect(u.id)}
                                className="loginPopup-list-item"
                            >
                                {u.email}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};
export default BankIdModal;
