import React, { Component } from "react";
import { Button, Table } from "antd";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./style.scss";
import { Spin } from "antd";
import CustomTable from "../../../../components/CumtomTable/index";
import { deleteUser, fetchUsersAdmins } from "../../../../actions/users/admins";
import { ReactComponent as EyeIcon } from "../../../../assets/admin/eye.svg";
import { CheckCircleFilled, DeleteOutlined } from "@ant-design/icons";
import DeleteModal from "../../DeleteModal";
import { toast } from "react-toastify";

class UsersAdminsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userToDelete: null,
        };
    }

    getRole = (role) => {
        let className;
        let value = "-";

        if (role === "ADMIN") {
            className = "black";
            value = "Admin";
        }
        return <span className={`${className}`}>{value}</span>;
    };

    formatLastActivityDate = (date) => {
        if (!!date) {
            return date
                .toString()
                .replace(/T/, " ")
                .substring(0, date.length - 3);
        } else {
            return "-";
        }
    };

    onDeleteOpen = (user) => () => {
        this.setState({ userToDelete: user });
    };

    onSuccess = () => {
        this.props.fetchUsersAdmins(0, 10, null, "id,DESC");
        this.setState({ userToDelete: null });
        toast.success(
            <div className="toast-body">
                <CheckCircleFilled className="toast__icon toast__icon--success" />
                <p className="toast-text">User has been deleted!</p>
            </div>
        );
    };

    onDeleteUser = (user) => () => {
        const { currentUser } = this.props;
        // Admin cannot delete his own account
        if (user?.id !== currentUser?.id)
            this.props.deleteUser(user.id, this.onSuccess);
    };

    onModalCancel = () => {
        this.setState({ userToDelete: null });
    };

    render() {
        const { Column } = Table;
        const { userToDelete } = this.state;
        const { currentUser } = this.props;

        return (
            <Spin spinning={this.props.loading}>
                <CustomTable
                    className="tableLoanOffersTable"
                    dataSource={this.props.admins}
                    dataSourcePagination={this.props.listPagination}
                    fetchData={this.props.fetchUsersAdmins}
                >
                    <Column
                        title="id"
                        dataIndex="id"
                        key="id"
                        style={{ textAlign: "start" }}
                        sorter={true}
                        render={(text, record) => (
                            <div className="actionBtn">
                                <Link
                                    to={`${
                                        "/users/admins/detail?id=" + record?.id
                                    }`}
                                    style={{
                                        color: "inherit",
                                        fontWeight: 800,
                                    }}
                                    // loanDataById={record}
                                >
                                    {record?.id}
                                </Link>
                                <div className="width"></div>
                            </div>
                        )}
                    />
                    <Column
                        title="Förnamn"
                        dataIndex="firstName"
                        key="firstName"
                        sorter={true}
                    />
                    <Column
                        title="Efternamn"
                        dataIndex="lastName"
                        key="lastName"
                        sorter={true}
                    />
                    <Column
                        title="E-postadress"
                        dataIndex="email"
                        key="email"
                        sorter={true}
                    />
                    <Column
                        title="Senaste aktivitet"
                        dataIndex="lastActivity"
                        key="lastActivity"
                        sorter={true}
                        render={(text, record) => (
                            <span>
                                {this.formatLastActivityDate(
                                    record?.lastActivity
                                ) || "-"}
                            </span>
                        )}
                    />
                    <Column
                        key="company_details"
                        render={(text, record) => (
                            <>
                                <div className="actionBtn">
                                    <Link
                                        to={`${
                                            "/users/admins/detail?id=" +
                                            record?.id
                                        }`}
                                    >
                                        <button
                                            buttontype="type10"
                                            className="buttonType10"
                                            size="small"
                                            style={{
                                                width: "100%",
                                                backgroundColor: "#fff",
                                                display: "inline-flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <EyeIcon
                                                style={{
                                                    marginRight: 10,
                                                    width: 20,
                                                }}
                                            />
                                            Visa
                                        </button>
                                    </Link>
                                    <div className="width"></div>
                                    <Button
                                        className="buttonType10 iconBtnTable widthNone"
                                        styles={{ width: "fit-conent" }}
                                        onClick={this.onDeleteOpen(record)}
                                        disabled={
                                            currentUser?.id === record?.id
                                        }
                                    >
                                        <DeleteOutlined className="iconDelete" />
                                    </Button>
                                </div>
                            </>
                        )}
                    />
                </CustomTable>
                <DeleteModal
                    show={!!userToDelete}
                    isLoading={false}
                    title="Delete user"
                    description={`Are you sure you want to delete user - ${userToDelete?.firstName} ${userToDelete?.lastName} (ID: ${userToDelete?.id})`}
                    handleCancel={this.onModalCancel}
                    onDelete={this.onDeleteUser(userToDelete)}
                />
            </Spin>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        currentUser: store.me.profile,
        admins: store.users.list,
        listPagination: store.users.list_pagination,
        loading: store.users.isFetching_list,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUsersAdmins: (currentPage, pageSize, sortField, sorrtOrder) =>
            dispatch(
                fetchUsersAdmins(currentPage, pageSize, sortField, sorrtOrder)
            ),
        deleteUser: (id, onSuccess) => dispatch(deleteUser(id, onSuccess)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UsersAdminsTable)
);
