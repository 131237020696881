import http from "../../api";
import { priorDate } from "../../utils/helpers";

export function showLoader() {
    return {
        type: 'notifications/showLoader',
    };
}

export function hideLoader() {
    return {
        type: 'notifications/hideLoader',
    };
}

export function fetchNotifications(page, size, sortField, sorrtOrder) {
    return (dispatch) => {
        dispatch(showLoader());

        return http.get("/api/v1/notifications", {
            params: {
                start_from: priorDate,
                page: page,
                size: size,
                sort: `${!!sortField ? sortField : 'id'},${!!sorrtOrder ? sorrtOrder : 'DESC'}`
            }
        })
            .then((response) => {
                dispatch(hideLoader());
                return dispatch(setNotifications(response.data));
            })
            .catch(err => {
                dispatch(hideLoader());
            })
    }
}

export function setNotifications(notifications) {

    return {
        type: 'notifications/setList',
        list: notifications
    };
}

export function showLastNotificationsLoader() {
    return {
        type: 'lastNotifications/showLoader',
    };
}

export function hideLastNotificationsLoader() {
    return {
        type: 'lastNotifications/hideLoader',
    };
}

export const fetchLastNotifications = (date) => async (dispatch) => {
    dispatch(showLastNotificationsLoader());
    await http.get("/api/v1/notifications", {
        params: {
            start_from: date,
            page: 0,
            size: 10
        }
    })
        .then(res => {
            dispatch(hideLastNotificationsLoader());
            return dispatch ({
                type: 'notifications/setLastNotifications',
                lastNotifications: res.data.data,
            });
        })
        .catch(err => {
            dispatch(hideLastNotificationsLoader());
        })

}
