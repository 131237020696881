import React, { useState, useEffect } from 'react';
import { Button, Badge } from 'antd';
import { ReactComponent as NotificationIcon } from '../../../assets/admin/bell.svg';
import { connect } from "react-redux";
import "./styles.scss";

const NotificationsDrawerButton = (props) => {

  const [ unread, setUnread ] = useState(false)

  useEffect(() => {
    if (!!props.notifications) {
      let isUnread = props.notifications.some(notification => notification.isRead === false);
      setUnread(isUnread);
    }
	}, [props.notifications])

  return (
    <>
      <Button 
        className="notificationButton"
        size="large"
        style={{
            padding: '0',
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'center',
            border: '2px solid transparent',
            boxShadow: 'none'
        }}
        onClick={props.showDrawer}
      >
        <Badge dot={unread} >
          <NotificationIcon />
        </Badge>
      </Button>
    </>
  )
}

const mapStateToProps = (store) => {
  return {
    notifications: store.notifications.lastNotifications
  };
};

export default connect(mapStateToProps)(NotificationsDrawerButton);