import React, { useState } from 'react';
import {
  Card,
  Row,
  Col,
  Form,
  Spin,
  message
} from 'antd';
import { Text } from '../../../../uikit/Text';
import { CustomButton } from '../../../../uikit/Button';
import http from "../../../../api";
import { CustomInput } from '../../../../uikit/Input';
import { connect } from 'react-redux';
import { fetchApplication } from '../../../../actions/applications';
import { useSsn } from '../../../../utils/helpers';
import { useEffect } from 'react';

const BisnodeSection = (props) => {
  const { id, fetchApplication } = props;
  const [ form ] = Form.useForm();

  const [ errors, setErrors ] = useState({});
  const [ isLoading, setIsLoading ] = useState(false);
  const [ ssn, setSsn] = useSsn("");

  const handleBisnodeRequest = () => {
    setIsLoading(true);

    http(`/api/v1/company/${ssn}/${id}/screening`, {
			method: 'GET'
		})
		.then((res) => {
      if (!!res && !!res.data) {
          const file = new Blob([JSON.stringify(res, null, 2)], {type: 'text/plain'});
          const filename = res.headers['content-disposition'].replaceAll('inline; filename=', '')
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute(
            'download',
            `${filename}`,
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setIsLoading(false);
          fetchApplication(id);
      } else {
        message.error("Ett fel uppstod");
        setIsLoading(false);
      }
      setIsLoading(false);
		})
		.catch(err => {
      if ((!!err.response && !!err.response.data && !!err.response.data.errors)) {
        setIsLoading(false);
        setErrors(err.response.data.errors)
      }
		});
  }

  return (
    <Spin spinning={isLoading}>
      <Row className="dashboard loanOffersTableWrapper" gutter={24}>
        <Col xl={24} lg={24}	md={24} sm={24} xs={24} style={{marginBottom: 30}}>
          <Card
            className="cardDetaill"
            style={{minHeight: '100px', height: '100%'}}
            bodyStyle={{
              paddingBottom: 24,
              borderRadius: 6
            }}
          >
            <Text texttype="back" colortype="back" customclass="cardTitle">
              AML/PEP kontroll bolag
            </Text>
            <Row gutter={16}>
              <Col padding={10} className="gutter_row" xl={24} lg={24}	md={24} sm={24} xs={24}>
                <Form 
                  form={form}
                  initialValues={{message: props.applicationMessage}}
                >
                  <CustomInput
                    // label="Org nr"
                    validateStatus={ !!errors.file ? 'error' : ''}
                    help={!!errors.file ? String(errors.file) : null}
                    value={ssn}
                    inputprops={{
                        placeholder: 'Ange org nr',
                        value: ssn,
                        onChange: (e) => {
                            setSsn(e.target.value)
                            setErrors({ ...errors, file: '' });
                        }
                    }}
                  />
                </Form>
              </Col>
            </Row>
            <Row>
              <CustomButton
                buttontype="type5"
                colortype="type8"
                size="large"
                style={{margin: '0 auto'}}
                onClick={(e) => {
                  handleBisnodeRequest(e)
                }}
              >
                {'Kontrollera'}
              </CustomButton>
            </Row>
          </Card>
        </Col>
      </Row>
    </Spin>
  )
};

const mapStateToProps = (store) => {
  return {
    application: store.applications.application,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApplication: (id) => dispatch(fetchApplication(id)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(BisnodeSection);
