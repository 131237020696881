import React, { useState, useEffect } from "react";
import { Form } from "antd";
import "./LoginContainer.scss";
import Header from "../../components/header/Header";
import AvatarDropdownLogin from "../../components/AvatarDropdownLogin";
import CustomInput from "../../uikit/Input";
import { Text } from "../../uikit/Text";
import { CustomButton } from "../../uikit/Button";
import { ReactComponent as Mailicon } from "../../assets/formicons/mail.svg";
import { ReactComponent as Keyicon } from "../../assets/formicons/key.svg";
import logo from "../../assets/light_logo_text.svg";
import { saveTokens } from "../../utils/auth";
import { toast, ToastContainer } from "react-toastify";
import { CheckCircleOutlined } from "@ant-design/icons";
import LoginMessage from "../../uikit/Message";
import http from "../../api";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { IdcardOutlined } from "@ant-design/icons";
import BankIdModal from "./BankIdModal";
import ResetPasswordEmail from "./ResetPasswordEmail";

const LoginContainer = (props) => {
    const [errors, setErrors] = useState({});
    const [validationerror, setValidationerror] = useState([]);
    const [ssnLogin, setSsnLogin] = useState(true);
    const [ssn, setSsn] = useState("");
    const [ssnLoginLoaderShow, setSsnLoginLoaderShow] = useState(false);
    const [bankIdUrl, setBankIdUrl] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [code, setCode] = useState(null);
    const [state, setState] = useState(null);
    const [users, setUsers] = useState(null);
    const [bankIdAccessToken, setBankIdAccessToken] = useState(null);
    const [resetPasswordEmailShow, setResetPasswordEmailShow] = useState(false);

    const isCreditor = window.location.host.toString().includes("creditor");

    useEffect(() => {
        window.addEventListener("message", (e) => {
            if (
                e.data &&
                (typeof e.data === "string" || e.data instanceof String)
            ) {
                const message = JSON.parse(e.data);
                console.log("IN LISTENER", { message, data: e.data });
                if (message.action === "gii::redirect") {
                    setCode(message.code);
                    setState(message.state);
                }
            }
        });
    });

    useEffect(() => {
        if (!!code && !!state) {
            let role = isCreditor ? "CREDITOR" : "ADMIN";
            http.post(`/api/v1/auth/ssn-login`, {
                code,
                state,
                redirectUrl,
                role,
            })
                .then((response) => {
                    if (response?.data?.accessToken) {
                        saveTokens(response.data);
                        props.history.push("/dashboard");
                        setSsnLoginLoaderShow(false);
                    } else {
                        setUsers(response?.data?.data?.users);
                        setBankIdAccessToken(
                            response?.data?.data?.bankIdAccessToken
                        );
                    }
                })
                .catch((errors) => {
                    setSsnLoginLoaderShow(false);
                    if (
                        !!errors &&
                        !!errors.response &&
                        !!errors.response.data
                    ) {
                        // setServiceError(true);
                        const { data } = errors.response;

                        // if(!!data.message) {
                        // message.error(e.data.message);
                        // }

                        if (!!data && !!data.message) {
                            setValidationerror([data.message]);
                        }

                        if (!!data.errors) {
                            if (!!data.errors.ssn)
                                setValidationerror([...data.errors.ssn]);
                            setErrors(data.errors);
                        }
                    }
                });
        }
    }, [code, state]);

    const prepareSsnLogin = () => {
        let role = "";
        if (window.location.host.toString().includes("creditor")) {
            role = "CREDITOR";
        }

        if (window.location.host.toString().includes("admin")) {
            role = "ADMIN";
        }
        http.post(`/api/v1/auth/ssn-prepare`, {
            role,
        })
            .then((response) => {
                const { url, redirectUrl } = response?.data?.data;
                setBankIdUrl(url);
                setRedirectUrl(redirectUrl);
                setSsnLoginLoaderShow(true);
            })
            .catch((errors) => {
                if (!!errors && !!errors.response && !!errors.response.data) {
                    // setServiceError(true);
                    const { data } = errors.response;

                    // if(!!data.message) {
                    // message.error(e.data.message);
                    // }

                    if (!!data && !!data.message) {
                        setValidationerror([data.message]);
                    }

                    if (!!data.errors) {
                        setErrors(data.errors);
                    }
                }
            });
    };

    const handleSubmit = (values) => {
        const data = {
            ...values,
        };

        if (window.location.host.toString().includes("creditor")) {
            data.userRole = "CREDITOR";
        }

        if (window.location.host.toString().includes("admin")) {
            data.userRole = "ADMIN";
        }

        if (ssnLogin && isCreditor) {
            let role = isCreditor ? "CREDITOR" : "ADMIN";
            http.post(`/api/v1/auth/ssn-prepare`, {
                ssn,
                role,
            })
                .then((response) => {
                    const { url, redirectUrl } = response?.data?.data;
                    setBankIdUrl(url);
                    setRedirectUrl(redirectUrl);
                    setSsnLoginLoaderShow(true);
                })
                .catch((errors) => {
                    if (
                        !!errors &&
                        !!errors.response &&
                        !!errors.response.data
                    ) {
                        // setServiceError(true);
                        const { data } = errors.response;

                        // if(!!data.message) {
                        // message.error(e.data.message);
                        // }

                        if (!!data && !!data.message) {
                            setValidationerror([data.message]);
                        }

                        if (!!data.errors) {
                            setErrors(data.errors);
                        }
                    }
                });
        } else
            http.post(`/api/v1/auth/login`, data)
                .then((response) => {
                    // Save token
                    saveTokens(response.data);

                    // Redirect
                    props.history.push("/dashboard");
                })
                .catch((errors) => {
                    if (
                        !!errors &&
                        !!errors.response &&
                        !!errors.response.data
                    ) {
                        // setServiceError(true);
                        const { data } = errors.response;

                        // if(!!data.message) {
                        // message.error(e.data.message);
                        // }

                        if (!!data && !!data.message) {
                            setValidationerror([data.message]);
                        }

                        if (!!data.errors) {
                            setErrors(data.errors);
                        }
                    }
                });
    };

    const handleChangeSsn = (e) => {
        setErrors({});
        const { value } = e.target;
        let newval = value !== undefined ? value.replace(/[^-\d]/gm, "") : "";
        newval =
            !!newval.match(/-/g) && newval.match(/-/g).length > 1
                ? ssn
                : newval;
        setSsn(newval);
    };

    const onSelect = (id) => {
        http.post(`/api/v1/auth/ssn-select/${id}`, {
            bankIdAccessToken,
        })
            .then((response) => {
                saveTokens(response.data);
                props.history.push("/dashboard");
                setSsnLoginLoaderShow(false);
            })
            .catch((errors) => {
                if (!!errors && !!errors.response && !!errors.response.data) {
                    // setServiceError(true);
                    const { data } = errors.response;

                    // if(!!data.message) {
                    // message.error(e.data.message);
                    // }

                    if (!!data && !!data.message) {
                        setValidationerror([data.message]);
                    }

                    if (!!data.errors) {
                        setErrors(data.errors);
                    }
                }
            });
    };

    const handleChange = (e) => {
        setErrors({});
    };

    const initiateResetPassword = (email, setErrors) => {
        http("/api/v1/user/password-reset-init", {
            method: "POST",
            data: { email },
        })
            .then((resp) => {
                setResetPasswordEmailShow(false);
                toast.success(
                    <div className="toast-body">
                        <CheckCircleOutlined className="toast__icon toast__icon--success" />
                        <p className="toast-text">{`Tack för din beställning - ett meddelande om återställning har skickats till angiven email.`}</p>
                    </div>
                );
            })
            .catch((error) => {
                if (error?.response?.status === 422)
                    setErrors(error?.response?.data?.errors);
            });
    };

    return (
        <section className="loginLayout">
            <ToastContainer />
            <Header>
                <AvatarDropdownLogin />
            </Header>

            <main className="loginLayout-content main">
                <div className="login-box card d-flex flex-row">
                    <div className="login-form text-center">
                        <h2>Logga in</h2>
                        <div className="login-form-wrapper">
                            <Form onFinish={handleSubmit}>
                                {validationerror.map((error) => (
                                    <LoginMessage
                                        content={error}
                                        type="error"
                                    />
                                ))}
                                <SwitchTransition mode="out-in">
                                    <CSSTransition
                                        key={ssnLogin}
                                        addEndListener={(node, done) => {
                                            node.addEventListener(
                                                "transitionend",
                                                done,
                                                false
                                            );
                                        }}
                                        classNames="fade"
                                    >
                                        <div className="ssn-sw">
                                            {ssnLogin && isCreditor ? null : (
                                                <>
                                                    <CustomInput
                                                        className="custom-input-login"
                                                        name="email"
                                                        validateStatus={
                                                            !!errors.email
                                                                ? "error"
                                                                : ""
                                                        }
                                                        help={
                                                            !!errors.email
                                                                ? String(
                                                                      errors.email
                                                                  )
                                                                : null
                                                        }
                                                        onChange={handleChange}
                                                        size="large"
                                                        type="borderless"
                                                        id="email"
                                                        inputprops={{
                                                            placeholder:
                                                                "E-post",
                                                            prefix: (
                                                                <Mailicon />
                                                            ),
                                                        }}
                                                    />

                                                    <CustomInput
                                                        name="password"
                                                        validateStatus={
                                                            !!errors.password
                                                                ? "error"
                                                                : ""
                                                        }
                                                        help={
                                                            !!errors.password
                                                                ? String(
                                                                      errors.password
                                                                  )
                                                                : null
                                                        }
                                                        onChange={handleChange}
                                                        size="large"
                                                        type="borderless"
                                                        id="password"
                                                        inputprops={{
                                                            type: "password",
                                                            placeholder:
                                                                "Lösenord",
                                                            prefix: <Keyicon />,
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </CSSTransition>
                                </SwitchTransition>
                                {ssnLogin && isCreditor ? null : (
                                    <div
                                        onClick={() =>
                                            setResetPasswordEmailShow(true)
                                        }
                                        className="links"
                                    >
                                        <Text texttype="link" colortype="info">
                                            Glömt lösenord?
                                        </Text>
                                    </div>
                                )}
                                <Form.Item>
                                    <CustomButton
                                        buttontype="type4DarkWider"
                                        type="primary"
                                        //loading={submitting}
                                        htmlType="submit"
                                    >
                                        {ssnLogin && isCreditor
                                            ? "Använd Bank ID"
                                            : "Logga in"}
                                    </CustomButton>
                                </Form.Item>
                                {/* {isCreditor && (
									<Form.Item>
										<CustomButton
											buttontype="type4DarkWider"
											type="primary"
											onClick={prepareSsnLogin}
										>
											{ssnLogin
												? "Använd email och lösenord"
												: "Använd Bank ID"}
										</CustomButton>
									</Form.Item>
								)} */}
                            </Form>
                        </div>
                    </div>
                    <div className="contentsection">
                        <img alt="logo" className="logo" src={logo} />

                        <Text texttype="headline" colortype="light">
                            Hej!
                        </Text>

                        <div className="contentsectiontext">
                            <Text texttype="info" colortype="light">
                                Välkommen till er plattform för
                                kreditansökningar
                            </Text>
                        </div>
                    </div>
                    <CSSTransition
                        key="ssn"
                        in={ssnLoginLoaderShow}
                        // in={true}
                        timeout={300}
                        unmountOnExit
                        classNames="loginPopup"
                    >
                        <BankIdModal
                            users={users}
                            onSelect={onSelect}
                            bankIdUrl={bankIdUrl}
                            closePopup={() => setSsnLoginLoaderShow(false)}
                        />
                    </CSSTransition>
                    <CSSTransition
                        key="pw-reset"
                        in={resetPasswordEmailShow}
                        // in={true}
                        timeout={300}
                        unmountOnExit
                        classNames="loginPopup"
                    >
                        <ResetPasswordEmail
                            closePopup={() => setResetPasswordEmailShow(false)}
                            onClick={initiateResetPassword}
                        />
                    </CSSTransition>
                </div>
            </main>
        </section>
    );
};
export default LoginContainer;
