import React, { useState, useEffect } from 'react';
import {
  Card,
  Row,
  Col,
  Form,
  Spin
} from 'antd';
import { Text } from '../../../../uikit/Text';
import { TextArea } from '../../../../uikit/TextArea';
import { CustomButton } from '../../../../uikit/Button';
import http from "../../../../api";
import { toast } from "react-toastify";
import { CheckCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { fetchApplication } from '../../../../actions/applications';

const ApllicationDetailsMessage = (props) => {
  const { id, applicationMessage } = props;
  const [ form ] = Form.useForm();

  const [ message, setMessage ] = useState('');
  const [ errors, setErrors] = useState({});

  useEffect(() => {
    setMessage(applicationMessage);
    form.setFieldsValue({'message': applicationMessage});
  }, [applicationMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMessage = (e) => {
    http(`/api/v1/application/${id}/message`, {
			method: 'PATCH',
      data: { "message": message }
		})
		.then((res) => {
      props.fetchApplication(id);
      toast.success(
        <div className="toast-body">
            <CheckCircleOutlined className="toast__icon toast__icon--success" />
            <p className="toast-text">{`Meddelande sänt`}</p>
        </div>)
		})
		.catch(err => {
      if (!!err.response && !!err.response.data && !!err.response.data.errors) {
        setErrors(err.response.data.errors)
      }
		});
  }

  return (
    <Spin spinning={props.loading}>
      <Row
        className="dashboard loanOffersTableWrapper" gutter={24}
      >
        <Col xl={24} lg={24}	md={24} sm={24} xs={24} style={{marginBottom: 30}}>
          <Card
            className="cardDetaill"
            style={{minHeight: '100px', height: '100%'}}
            bodyStyle={{
              paddingBottom: 24,
              borderRadius: 6
            }}
          >
            <Text texttype="back" colortype="back" customclass="cardTitle">
              Tack för din ansökan! Vänligen komplettera med
            </Text>
            <Row gutter={16}>
              <Col padding={10} className="gutter_row" xl={24} lg={24}	md={24} sm={24} xs={24}>
                <Form 
                  form={form}
                  initialValues={{message: props.applicationMessage}}
                >
                  <TextArea
                    key="message"
                    name="message"
                    validateStatus={ !!errors.message ? 'error' : ''}
                    help={!!errors.message ? String(errors.message) : null}
                    inputprops={{
                        rows: 3,
                        placeholder: 'Fyll i',
                        autoSize: true,
                        style: {resize: 'none'},
                        onChange: (e) => {
                          setMessage(e.target.value);
                          form.setFieldsValue(e.target.value);
                          setErrors({ ...errors, message: '' });
                        }
                    }}
                  />
                </Form>

                {/* <ul className="message-list">
                  <li className="messageText-list" style={{marginLeft: '15px'}}>oreviderade siffror - resultat och balans</li>
                  <li className="messageText-list" style={{marginLeft: '15px'}}>startbesked</li>
                  <li className="messageText-list" style={{marginLeft: '15px'}}>signerat entreprenadavtal</li>
                </ul> */}
              </Col>
            </Row>
            <Row>
              <CustomButton
                buttontype="type5"
                colortype="type8"
                size="large"
                style={{margin: '0 auto'}}
                onClick={(e) => {
                  handleMessage(e)
                }}
              >
                {'Meddelande sänt'}
              </CustomButton>
            </Row>
          </Card>
        </Col>
      </Row>
    </Spin>
  )
};

const mapStateToProps = (store) => {
  return {
    application: store.applications.application,
    applicationMessage: !!store.applications.application.message ? store.applications.application.message : '',
    loading: store.applications.isFetching_application,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApplication: (id) => dispatch(fetchApplication(id)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ApllicationDetailsMessage);
