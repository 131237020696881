import React from 'react';
import { List, message } from 'antd';
import { connect } from 'react-redux';
import { fetchNotifications } from '../../../actions/notifications';
import { ArrowRightOutlined } from '@ant-design/icons';
import http from "../../../api";
import "./styles.scss";

const NotificatiosList = (props) => {
  const { notifications } = props;
 
  const handleNotification = (item) => {
    http(`/api/v1/notification/${item.id}/read`, {
			method: 'post',
		})
		.then(function(response) {
      window.location.href = item.link
		})
		.catch(function(error) {
		  message.error("Ett fel uppstod");
		});
  }

  return (
    <List
      className="notificationsList"
      dataSource={notifications}
      renderItem={(item, i) => {

        return (
          <List.Item
            className="item"
            key={item.key || i}
            onClick={() => handleNotification(item)}
          >
            <List.Item.Meta
              className="meta"
              avatar={props.getIconType(item)}
              title={
                <div className="title">
                  {item.summary}
                  <div className="extra">{item.extra}</div>
                </div>
              }
              description={
                <div>
                  <div className="description">{item.text}</div>
                  <a 
                    href={item.link} 
                    className="notificationLink"
                  >
                    {'Gå till'}
                    <ArrowRightOutlined style={{ marginLeft: 14 }} />
                  </a>
                  <div className="datetime">{item.datetime}</div>
                </div>
              }
            />
          </List.Item>
        );
      }}
    />
  )
}

const mapStateToProps = (store) => {
  return {
    notifications: store.notifications.lastNotifications
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
      fetchNotifications: (date) => dispatch(fetchNotifications(date))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificatiosList);
