import React, {Component} from "react";
import { Link } from 'react-router-dom';
import UsersAdminsTable from './table'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { CustomButton } from '../../../uikit/Button';
import "./style.scss";
class UsersAdmins extends Component {

    render() {
        return (
            <div style={{position: 'relative'}}>
                <div className='goBack'>
                    <div style={{paddingBottom: 30}} className={"backLink backBtn titleWrapper"}>
                        {/* <Link
                            to="#" 
                            onClick={() => { this.props.history.push("/") }} 
                            style={{float: 'left', paddingTop:5, fontSize: 13, fontWeight: 400, color: '#9199AA'}}
                        >
                            <ArrowLeftOutlined style={{ marginRight: 14 }} />
                            Gå tillbaka
                        </Link> */}
                        <h2 className='text-center'>Ska vara användare</h2>
                    </div>
                </div>
                <div className="usersAdminsTableWrapper">
                    <div className="rowbuttons" style={{display: 'flex', justifyContent: 'flex-end', paddingRight: '16px'}}>
                        <Link to={`/admin/new`}>
                            <CustomButton 
                                buttontype="type5"
                                colortype="type8"
                                size="large"
                                style={{margin: '0 10px 20px', minWidth: '215px'}}
                            >
                                Lägg till administratör
                            </CustomButton>
                        </Link>
                    </div>
                    <div>
                        <UsersAdminsTable />
                    </div>
                </div>
            </div>
        );
    }
}

export default UsersAdmins;