import React from "react";
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { CustomButton } from '../../../../uikit/Button';
import { CustomInput } from '../../../../uikit/Input';
import {
    Form,
    Space,
    Row,
    Col
} from 'antd';
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";

const LoanOfferOtherTerms = (props) => {
    // const { form } = props;

    return (
        <div style={{position: 'relative'}}>
            <p
                style={{
                    fontWeight: 700,
                    fontSize: '18px',
                    color: '#2a2b2d',
                    lineHeight: '23px',
                    marginTop: '15px'
                }}
            >Ytterligare villkor
            </p>
            <Row>
                <Col xl={24} lg={24} md={22} sm={22} xs={24}>
                    <Form.List name="otherTerms" key="otherTerms" style={{width: '100%'}}>
                        {(fields, { add, remove }) => {
                            return (
                                <div key={`otherTerms${fields.key}`} className="longListWrapper">
                                    <Form.Item style={{marginBottom: '10px'}}>
                                        <CustomButton
                                            buttontype="type8"
                                            colortype="type8"
                                            size="large"
                                            className="otherTermsBtn"
                                            style={{
                                                margin: '0 0 20px',
                                                display: 'flex', 
                                                alignItems: 'center',
                                                border: '2px solid transparent',
                                                boxShadow: 'none'
                                            }}
                                            onClick={(e) => { add() }}
                                        >
                                            Lägg till ytterligare villkor
                                            <PlusCircleOutlined style={{color: '#008aaa'}} className="otherTermsBtnIcon" />
                                        </CustomButton>
                                    </Form.Item>
                                    <Form.Item
                                        shouldUpdate={(prevValues, curValues) =>
                                            prevValues['otherTerms'] !== curValues['otherTerms']}
                                    >
                                        {() => {
                                            return fields.map((field, index) => {
                                                return (
                                                    <Space
                                                        size={5}
                                                        key={`otherTerm${index}`}
                                                        className="vspace"
                                                        align="center"
                                                        style={{width: '100%'}}
                                                        // direction="vertical"
                                                    >
                                                        <CustomInput
                                                            {...field}
                                                            name={[ field.name, 'otherTerm' ]}
                                                            fieldKey={[ field.fieldKey, 'otherTerm' ]}
                                                            inputprops={{
                                                                placeholder: 'Fyll i villkor',
                                                                className: "otherTermInput",
                                                                style: {width: '100%'},
                                                            }}
                                                        />
                                                        <CustomButton
                                                            buttontype="type8"
                                                            colortype="type8"
                                                            size="large"
                                                            style={{
                                                                margin: '0 0 20px',
                                                                maxWidth: '40px',
                                                                padding: '0',
                                                                display: 'flex', 
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                border: '2px solid transparent',
                                                                boxShadow: 'none'
                                                            }}
                                                            onClick={() => remove(field.name)}
                                                        >
                                                            <MinusCircleOutlined />
                                                        </CustomButton>
                                                    </Space>
                                            )});
                                        }}
                                    </Form.Item>
                                </div>
                            );
                        }}
                    </Form.List>
                </Col>
            </Row>
        </div>
    );
}

export default LoanOfferOtherTerms;