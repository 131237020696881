import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { fetchCreditRequest } from "../../../actions/credit-requests";
import BusinessInfo from "./sections/businessInfo";
import CreditInfo from "./sections/CreditInfo";
import { Text } from "../../../uikit/Text";
import { CustomButton } from "../../../uikit/Button";
import AppComment from "../../../uikit/Comment";
import { Button, Card, Spin, Row, Col, message } from "antd";
import { getQueryParam } from "../../../utils/helpers";
import FilesList from "./sections/FilesList";
import moment from "moment";
import { ReactComponent as SearchIcon } from "../../../assets/admin/search.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/admin/download.svg";
import http from "../../../api";
import { toast } from "react-toastify";
import { CheckCircleOutlined } from "@ant-design/icons";

const CreditRequestDetails = (props) => {
	const { creditRequest } = props;

	let id = "";
	if (!!getQueryParam("id")) {
		id = getQueryParam("id");
	}

	const [isLoadingPdf, setIsLoadingPdf] = useState(false);
	const [applicationId, setApplicationId] = useState("");

	useEffect(() => {
		props.fetchCreditRequest(id).then((request) => {
			setApplicationId(request.creditRequest.application.id);
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handlePrewiewPdf = () => {
		setIsLoadingPdf(true);
		http(`/api/v1/application/${applicationId}/pdf/generate`, {
			method: "get",
			responseType: "blob",
			headers: {
				"Content-Type": "application/pdf",
			},
		})
			.then((blob) => {
				setIsLoadingPdf(false);
				if (!!blob && blob.data) {
					const fileURL = URL.createObjectURL(blob.data);
					window.open(fileURL);
				} else {
					message.error("Ett fel uppstod");
				}
			})
			.catch(() => setIsLoadingPdf(false));
	};

	const declineCR = () => {
		http(`/api/v1/loan-offer/${id}/status/reject`, {
			method: "post",
		})
			.then(() => {
				props.fetchCreditRequest(id);
				toast.success(
					<div className="toast-body">
						<CheckCircleOutlined className="toast__icon toast__icon--success" />
						<p className="toast-text">{`Kreditförfrågan har fått status avslagen.`}</p>
					</div>
				);
			})
			.catch();
	};

	const handleDownloadPdf = () => {
		setIsLoadingPdf(true);
		http(`/api/v1/application/${applicationId}/pdf/generate`, {
			method: "get",
			responseType: "blob",
			headers: {
				// 'Accept': 'application/pdf',
				"Content-Type": "application/pdf",
			},
		})
			.then((blob) => {
				setIsLoadingPdf(false);
				if (!!blob && blob.data) {
					const fileURL = URL.createObjectURL(blob.data);

					const link = document.createElement("a");
					link.href = fileURL;
					link.setAttribute(
						"download",
						`Kredit PM - ${
							!!creditRequest?.application?.data?.debtorName
								? creditRequest.application.data.debtorName
								: ""
						} ID ${applicationId}`
					);

					// Append to html link element page
					document.body.appendChild(link);

					// Start download
					link.click();

					// Clean up and remove the link
					link.parentNode.removeChild(link);
				} else {
					message.error("Ett fel uppstod");
				}
			})
			.catch(() => setIsLoadingPdf(false));
	};

	const disabledBtn =
		creditRequest.status === "STATUS_REJECTED" ||
		(!!creditRequest.loanOffer &&
			!!creditRequest.loanOffer.status &&
			(creditRequest.loanOffer.status === "STATUS_APPROVED" ||
				creditRequest.loanOffer.status === "STATUS_DECLINED" ||
				creditRequest.loanOffer.status === "STATUS_DECLINED_BY_BANK"))
                || creditRequest?.status === 'STATUS_LOST';

	return (
		<div style={{ position: "relative" }}>
			<div className="goBack">
				<div
					style={{ paddingBottom: 30 }}
					className={"backLink backBtn titleWrapper"}
				>
					<Link
						to={{
							pathname: "/credit-request",
						}}
						style={{
							float: "left",
							paddingTop: 5,
							fontSize: 13,
							fontWeight: 400,
							color: "#9199AA",
						}}
					>
						<ArrowLeftOutlined style={{ marginRight: 14 }} />
						Gå tillbaka
					</Link>
					<h2 className="text-center">Kreditförfrågningar</h2>
				</div>
			</div>
			<Row className="dashboard d-flex">
				<BusinessInfo
					style={{
						paddingLeft: "12px",
						paddingRight: "12px",
						marginBottom: "24px",
					}}
				/>
				<CreditInfo
					style={{
						paddingLeft: "12px",
						paddingRight: "12px",
						marginBottom: "24px",
					}}
				/>
			</Row>
			<Row className="dashboard" gutter={24}>
				<Col
					xl={24}
					lg={24}
					md={24}
					sm={24}
					xs={24}
					style={{ marginBottom: 30 }}
				>
					<Spin spinning={isLoadingPdf}>
						<Card
							className="cardDetaill"
							style={{
								marginBottom: 30,
								minHeight: "100px",
								height: "100%",
							}}
							bodyStyle={{
								padding: "28",
								borderRadius: 6,
							}}
						>
							<Text
								texttype="back"
								colortype="back"
								customclass="cardTitle"
							>
								Kredit-PM
							</Text>
							<Row gutter={16} style={{ width: "100%" }}>
								<Col
									padding={10}
									className="gutter_row"
									xl={8}
									lg={8}
									md={8}
									sm={8}
									xs={24}
								>
									<span className="tableFile">
										{`Kredit PM - ${
											!!creditRequest?.application?.data
												?.debtorName
												? creditRequest.application.data
														.debtorName
												: ""
										}
                                            ID ${
												creditRequest?.application?.id
											}`}
									</span>
								</Col>
								<Col
									padding={10}
									className="gutter_row"
									xl={4}
									lg={4}
									md={4}
									sm={4}
									xs={4}
								>
									<span className="messageText">{`pdf`}</span>
								</Col>
								<Col
									padding={10}
									className="gutter_row"
									xl={6}
									lg={6}
									md={6}
									sm={6}
									xs={6}
								>
									<span className="messageText">
										{!!creditRequest?.application?.updatedAt
											? moment(
													creditRequest?.application
														?.updatedAt
											  ).format("YYYY-MM-DD")
											: "-"}
									</span>
								</Col>
								<Col xl={6} lg={6} md={6} sm={6} xs={6}>
									<div className="iconBtnsWrapper">
										<Button
											className="iconBtn"
											onClick={() => handlePrewiewPdf()}
										>
											<SearchIcon />
										</Button>
										<Button
											className="iconBtn"
											onClick={() => handleDownloadPdf()}
										>
											<DownloadIcon />
										</Button>
									</div>
								</Col>
							</Row>
						</Card>
					</Spin>
				</Col>
			</Row>
			{creditRequest?.creditorComment && (
				<Row className="dashboard" gutter={24}>
					<Col
						xl={24}
						lg={24}
						md={24}
						sm={24}
						xs={24}
						style={{ marginBottom: 30 }}
					>
						<Card
							className="cardDetaill"
							style={{
								marginBottom: 30,
								minHeight: "100px",
								height: "100%",
							}}
							bodyStyle={{
								padding: "28",
								borderRadius: 6,
							}}
						>
							<Text
								texttype="back"
								colortype="back"
								customclass="cardTitle"
							>
								Kommentar från Bettre om kreditförfrågan
							</Text>
							<Text
								texttype="info"
								colortype="primary"
								customclass="comment-message"
							>
								{creditRequest?.creditorComment.message}
							</Text>
						</Card>
					</Col>
				</Row>
			)}
			<AppComment
				title={`KOMMENTAR TILL BETTRE OM KREDITFÖRFRÅGAN`}
				id={creditRequest?.id}
				comment={creditRequest?.adminComment?.message}
			/>
			<FilesList />
			<Row
				className="dashboard"
				gutter={24}
				style={{ justifyContent: "center" }}
			>
				{
					<Col>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<div className="rowbuttons">
								<Link
									to={`${
										"/loan-offers/detail/edit?id=" + id
									}`}
								>
									<CustomButton
										buttontype="type5"
										colortype="type8"
										size="large"
										style={{
											margin: "0 10px 20px",
											minWidth: "215px",
										}}
										disabled={disabledBtn}
									>
										Ge bud / Uppdatera bud
									</CustomButton>
								</Link>
							</div>
						</div>
					</Col>
				}
				{!creditRequest?.loanOffer &&
					creditRequest?.loanOffer?.status !==
						"STATUS_DECLINED_BY_BANK" &&
					creditRequest?.status !== "STATUS_LOST" &&
					creditRequest?.status !== "STATUS_REJECTED" && (
						<Col>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
								}}
							>
								<div className="rowbuttons">
									<CustomButton
										buttontype="type5"
										colortype="type8"
										size="large"
										style={{
											margin: "0 10px 20px",
											minWidth: "215px",
										}}
										onClick={declineCR}
									>
										Avböja kreditförfrågan
									</CustomButton>
								</div>
							</div>
						</Col>
					)}
			</Row>
		</div>
	);
};

const mapStateToProps = (store) => {
	return {
		creditRequest: store.creditRequests.creditRequest,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchCreditRequest: (id) => dispatch(fetchCreditRequest(id)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreditRequestDetails);
