import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import { Drawer  } from 'antd';
import Header from "../../../components/header/Header";
import MenuAdmin from "./MenuAdmin";
import NotificationsDrawerButton from "../../../components/Notifications/NotificationsDrawerButton";
import AvatarDropdown from "../../../components/AvatarDropdown";
import { connect } from "react-redux";
import {init} from "../../../actions/app";
import GlobalLoader from "../../../components/Loaders/GlobalLoader";
import { deleteTokens } from "../../../utils/auth";
import HeaderLeft from "../../../components/header/HeaderLeft";
import MenuButton from "../../../components/header/MenuButton";
import RightPanelDrawer from "../../../components/RightPanelDrawer";
import NotificationsListWrapper from "../../../components/Notifications/NotificationsListWrapper";
import 'react-toastify/dist/ReactToastify.css';
import "./MasterLayout.scss";
import BackToTop from "../../../uikit/BackToTop";
class MasterLayout extends Component {
    state = { 
        visible: false,
        collapsedMenu: true
    };

    componentDidMount() {
        this.props.init().then(() => {
            if (!!this.props.user.role && this.props.user.role !== 'ADMIN') {

                deleteTokens();
                return window.location.href = '/login';
            }
        });
    }

    showDrawer = () => {
        this.setState({
          visible: !this.state.visible,
        });
    };
    
    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    handleCollapsedMenu = () => {
        this.setState({
            collapsedMenu: !this.state.collapsedMenu
        });
    }

    render() {
        const { user } = this.props;
        const { collapsedMenu } = this.state;

        return (
            <div>
                {(!!user.role && user.role === 'ADMIN') ? (
                    <>
                        <Header>
                            <HeaderLeft>
                                <MenuButton handleCollapsedMenu={this.handleCollapsedMenu} />
                            </HeaderLeft>
                            <NotificationsDrawerButton showDrawer={this.showDrawer}/>
                            <AvatarDropdown />
                        </Header>
                        <div className="d-flex masterLayout" 
                            style={{ position: 'relative',  overflow: 'hidden' }}>
                            <div className="layout-leftPanel">
                                <MenuAdmin />
                            </div>
                            <div className="masterLayout-content">
                                {this.props.children}
                            </div>
                            <RightPanelDrawer 
                                visible={this.state.visible} 
                                onClose={this.onClose}
                                title="Notiser"
                            >
                                <NotificationsListWrapper />
                            </RightPanelDrawer>
                        </div>
                        <GlobalLoader isLoading={this.props.app.showLoader}/>
                        <ToastContainer 
                            autoClose={2000}
                            // autoClose={false}
                            hideProgressBar
                            position="top-right"
                            limit={4}
                        />
                        <Drawer
                            className="menuDrawer"
                            title="Meny"
                            placement="left"
                            onClick={() => this.setState({collapsedMenu :true})}
                            onClose={() => this.setState({collapsedMenu :true})}
                            visible={!collapsedMenu}
                        >
                            <MenuAdmin />
                        </Drawer>
                        <BackToTop />
                    </>
                ) : (
                    <>
                        <div className="roleError">{`Authority is required (admin)`}</div>
                        <GlobalLoader isLoading={this.props.app.showLoader}/>
                    </>
                )}

                
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.me.profile,
        app: store.app,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: () => dispatch(init()),
        // loaded: () => dispatch(loaded())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterLayout);