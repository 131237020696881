import React, { useState, useEffect, Fragment } from "react";
import { Button, Card, Row, Col, Form, Spin, message } from "antd";
import { Text } from "../../../../uikit/Text";
import { CustomButton } from "../../../../uikit/Button";
import { TextArea } from "../../../../uikit/TextArea";
import http from "../../../../api";
import { CustomInput } from "../../../../uikit/Input";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { fetchApplication } from "../../../../actions/applications";
import { ReactComponent as DownloadIcon } from "../../../../assets/admin/download.svg";
import { DeleteOutlined } from "@ant-design/icons";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import { ReactComponent as SearchIcon } from "../../../../assets/admin/search.svg";

const AmlFile = (props) => {
	const { amlFile, id } = props;
	const [form] = Form.useForm();

	const [errors, setErrors] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [isMessage, setIsMessage] = useState(false);
	const [message, setMessage] = useState("");

	useEffect(() => {
		setIsMessage(amlFile.isMessage);
		setMessage(!amlFile.message ? "" : amlFile.message);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handleBisnodeRequest = () => {
		setIsLoading(true);
		let ssn = form.getFieldValue("file");

		http(`/api/v1/company/${ssn}/screening`, {
			method: "GET",
		})
			.then((res) => {
				if (!!res && !!res.data) {
					const file = new Blob([JSON.stringify(res, null, 2)], {
						type: "text/plain",
					});
					const fileURL = URL.createObjectURL(file);
					const link = document.createElement("a");
					link.href = fileURL;
					link.setAttribute("download", `${ssn}`);
					document.body.appendChild(link);
					link.click();
					link.parentNode.removeChild(link);
					setIsLoading(false);
				} else {
					message.error("Ett fel uppstod");
					setIsLoading(false);
				}
				setIsLoading(false);
			})
			.catch((err) => {
				if (
					!!err.response &&
					!!err.response.data &&
					!!err.response.data.errors
				) {
					setErrors(err.response.data.errors);
				}
			});
	};

	const handleDownloadPdf = () => {
		setIsLoading(true);
		http(`/api/v1/application/${amlFile?.id}/file`, {
			method: "get",
			responseType: "blob",
		})
			.then((blob) => {
				const url = window.URL.createObjectURL(new Blob([blob.data]));

				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", `${amlFile?.name}`);

				// Append to html link element page
				document.body.appendChild(link);

				// Start download
				link.click();

				// Clean up and remove the link
				link.parentNode.removeChild(link);
			})
			.catch(() => setIsLoading(true));
	};

	const handlePreview = () => {
		setIsLoading(true);
		http(`/api/v1/application/${amlFile?.id}/file`, {
			method: "get",
			responseType: "blob",
		})
			.then((blob) => {
				const fileURL = URL.createObjectURL(blob.data);
				window.open(fileURL);
			})
			.catch(() => setIsLoading(true));
	};

	const onChange = (e) => {
		setIsMessage((isMessage) => {
			http(`/api/v1/application/file/${amlFile?.id}/is-message`, {
				method: "PATCH",
				responseType: "application/json",
				data: {
					isMessage: !isMessage,
				},
			});
			return !isMessage;
		});
	};

	const handleMessage = (e) => {
		http(`/api/v1/application/file/${amlFile?.id}/message`, {
			method: "PATCH",
			data: { message: message },
		})
			.then((res) => {
				toast.success(
					<div className="toast-body">
						<CheckCircleOutlined className="toast__icon toast__icon--success" />
						<p className="toast-text">{`Meddelande sänt`}</p>
					</div>
				);
			})
			.catch((err) => {
				if (
					!!err.response &&
					!!err.response.data &&
					!!err.response.data.errors
				) {
					setErrors(err.response.data.errors);
				}
			});
	};

	const handleDeleteFile = () => {
		http(`/api/v1/application/${amlFile?.id}/file`, {
			method: "DELETE",
		})
			.then((request) => {
				props.fetchApplication(id);
			})
			.catch((err) => {});
	};

	return (
		<Row gutter={16} style={{ width: "100%", margin: "10px 0 0 0" }}>
			<Col
				padding={10}
				className="gutter_row"
				xl={8}
				lg={8}
				md={8}
				sm={8}
				xs={24}
			>
				<span className="tableFile">{amlFile?.name}</span>
			</Col>
			<Col
				padding={10}
				className="gutter_row"
				xl={4}
				lg={4}
				md={4}
				sm={4}
				xs={4}
			></Col>
			<Col
				padding={10}
				className="gutter_row"
				xl={6}
				lg={6}
				md={6}
				sm={6}
				xs={6}
			></Col>
			<Col xl={6} lg={6} md={6} sm={6} xs={6}>
				<div className="iconBtnsWrapper">
					<Button
						className="iconBtn"
						onClick={handlePreview}
					>
						<SearchIcon />
					</Button>
					<Button
						className="iconBtn"
						onClick={handleDeleteFile}
					>
						<DeleteOutlined className="iconDelete" />
					</Button>
					<Button
						className="iconBtn"
						onClick={handleDownloadPdf}
					>
						<DownloadIcon />
					</Button>
					<Checkbox
						style={{ margin: "5px 0 0 20px" }}
						key={amlFile?.id}
						checked={isMessage}
						onChange={onChange}
						aria-controls="example-collapse-text"
						aria-expanded={isMessage}
					/>
				</div>
			</Col>
			{isMessage && (
				<>
					<Form
						form={form}
						style={{ width: "100%", marginTop: "10px" }}
						initialValues={{ message: message }}
					>
						<TextArea
							key="message"
							name="message"
							validateStatus={!!errors.message ? "error" : ""}
							help={
								!!errors.message ? String(errors.message) : null
							}
							inputprops={{
								rows: 3,
								placeholder: "Fyll i",
								autoSize: true,
								style: { resize: "none" },
								onChange: (e) => {
									setMessage(e.target.value);
									form.setFieldsValue(e.target.value);
									setErrors({
										...errors,
										message: "",
									});
								},
							}}
						/>
					</Form>
					<CustomButton
						buttontype="type5"
						colortype="type8"
						size="large"
						style={{ margin: "0 auto" }}
						onClick={(e) => {
							handleMessage(e);
						}}
					>
						{"Meddelande sänt"}
					</CustomButton>
				</>
			)}
		</Row>
	);
};

const mapStateToProps = (store) => {
	return {
		application: store.applications.application,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchApplication: (id) => dispatch(fetchApplication(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AmlFile);
