import React from 'react';
import { Button } from 'antd';
// import './uikit.scss';

export const CustomButton = (props) => {
	let classes;
	let colorClasses;
	if (props.buttontype === 'type1') {
		classes = "buttonType1";
	} else if (props.buttontype === 'type2') {
		classes = "buttonType2";
	} else if (props.buttontype === 'type3') {
		classes = "buttonType3";
	} else if (props.buttontype === 'type4White') {
		classes = "buttonType4White";
	} else if (props.buttontype === 'type4') {
		classes = "buttonType4";
	} else if (props.buttontype === 'type4Dark') {
		classes = "buttonType4Dark";
	} else if (props.buttontype === 'type4DarkWider') {
		classes = "buttonType4DarkWider";
	} else if (props.buttontype === 'type5') {
		classes = "buttonType5";
	} else if (props.buttontype === 'type6') {
		classes = "buttonType6";
	} else if (props.buttontype === 'type7') {
		classes = "buttonType7";
	} else if (props.buttontype === 'type8') {
		classes = "buttonType8";
	} else if (props.buttontype === 'type9') {
		classes = "buttonType9";
	} else if (props.buttontype === 'type10') {
		classes = "buttonType10";
	} else if (props.buttontype === 'type11') {
		classes = "buttonType11";
	} else {
		classes = "buttonType4";
	}

	if (props.colortype === 'type8') {
		colorClasses = "textSecondaryColor";
	}

	return <Button {...props} className={[ "button", classes, colorClasses, props.className ].join(' ')} type="primary" />;
};

export default CustomButton;
