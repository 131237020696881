import React, { useEffect } from 'react';
import {
  Card,
  Spin,
  Row,
  Col
} from 'antd';
import { connect } from 'react-redux';
import '../style.scss';
import { Text } from '../../../../../uikit/Text';
import { fetchCompany } from '../../../../../actions/users/companies';
import { getQueryParam } from '../../../../../utils/helpers';
import { Link } from 'react-router-dom';
import { CustomButton } from '../../../../../uikit/Button';

const BankInfo = (props) => {
  const { company } = props;

  const topColResponsiveProps = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 24,
    style: {
      marginBottom: 24,
    },
  };

  let id = '';
  if (!!getQueryParam('id')) {
    id = getQueryParam('id')
  } 

    useEffect(() => {
      props.fetchCompany(id);
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Spin spinning={props.loadingCompany}>
      <Col {...topColResponsiveProps}>
        <Card
          // loading={loading}
          className="cardDetaill"
          style={{marginBottom: 30}}
          bodyStyle={{
          padding: '28',
          borderRadius: 6
          }}
        >
          <Text texttype="back" colortype="back" customclass="cardTitle">
            Företagsuppgifter
          </Text>
          <Row gutter={16}>
            <Col padding={10} className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
              <div className="labelitem">{'Företagsnamn:'}</div>
            </Col>
            <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
              <div className="valueitem">{company?.name || '-'}</div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col padding={10} className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
              <div className="labelitem">{'Org. nr:'}</div>
            </Col>
            <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
              <div className="valueitem">{company?.companyNumber || '-'}</div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col padding={10} className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
              <div className="labelitem">{'E-postadress:'}</div>
            </Col>
            <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
              <div className="valueitem">{company?.email || '-'}</div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col padding={10} className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
              <div className="labelitem">{'Adress:'}</div>
            </Col>
            <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
              <div className="valueitem">{company?.address || '-'}</div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col padding={10} className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
              <div className="labelitem">{'Post nr:'}</div>
            </Col>
            <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
              <div className="valueitem">{company?.zip || '-'}</div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col padding={10} className="gutter_row" xl={10} lg={8}	md={8} sm={24} xs={24}>
              <div className="labelitem">{'Ort:'}</div>
            </Col>
            <Col className="gutter_row" xl={10} lg={16}	md={16} sm={24} xs={24}>
              <div className="valueitem">{company?.city || '-'}</div>
            </Col>
          </Row>
            <div className="rowbuttons">
              <Link to={`${'/users/banks/detail/edit?id='+id}`}>
                  <CustomButton 
                      buttontype="type5"
                      colortype="type8"
                      size="large"
                      style={{margin: '0 10px 20px'}}
                  >
                      Editera
                  </CustomButton>
              </Link>
            </div>
        </Card>
      </Col>
    </Spin>
  );
}

const mapStateToProps = (store) => {
  return {
    company: store.companies.company,
    loadingCompany: store.companies.isFetching_company,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
      fetchCompany: (id) => dispatch(fetchCompany(id)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(BankInfo);
