import React from 'react';
import { Modal, Spin } from 'antd';
import { CustomButton } from '../../../../uikit/Button';

export const DeleteModal = (props) => {
	const { title, showLeadDialog, handleCancel, fileItem, onDelete, isLoading } = props;
	
	const onToggleViewDialog = async () => {
		handleCancel(false);
	};

	return (
		<Modal
			className="modalContainer"    
			animation="zoom"
			maskAnimation="fade"
			forceRender
			visible={showLeadDialog}
			title={title}
			size={'large'}
			footer={false}        
			destroyOnClose
			style={{top: '30%'}}
			bodyStyle={{
				padding: '32px 30px 48px',
			}}

			onCancel={() => onToggleViewDialog()}
		>
			<Spin spinning={isLoading}>
				<div className="uploadModal" style={{textAlign:'center'}}>
					<h2 style={{marginBottom: 30, padding: "0 10px"}}>Ta bort fil</h2>

					<p style={{fontSize: '16px'}}>
						Befintlig fil
						<span style={{fontWeight: 700}}>{` ${fileItem?.name} `}</span>
						kommer att tas bort, vill du fortsätta?
					</p>
				</div>
				<div className="buttons" style={{display: 'flex', justifyContent: 'center', marginTop: 15}}>
					<CustomButton 
						buttontype="type6"
						size="large"
						style={{margin: '0 10px 0 0'}}
						onClick={() => {onDelete(fileItem)}}
					>
						Ja
					</CustomButton>
					<CustomButton 
						buttontype="type8"
						size="large"
						style={{margin: '0 0 0 10px'}}
						onClick={() => { onToggleViewDialog() }}
					>
						Nej
					</CustomButton>
				</div>
			</Spin>
		</Modal>
	);
};

export default DeleteModal;
