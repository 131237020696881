import React, { useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { Text } from '../../../../uikit/Text';
import { CustomButton } from '../../../../uikit/Button';
import {
    Form,
    Card,
    Row,
    Col
} from 'antd';
import http from "../../../../api";
import { CustomInput } from '../../../../uikit/Input';
// import { CustomSelect } from '../../../../../uikit/Select';

import './style.scss';

const AdminDetailsForm = (props) => {
    const { 
        company,
    } = props;

    const [ form ] = Form.useForm();

    const [ errors, setErrors] = useState({});

    useEffect(() => {

	}, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = () => {
        let values = form.getFieldsValue();

        http(`/api/v1/user/`, {
            method: 'POST',
            data: {
                ...values,
                role: 'ADMIN'
            }
        })
            .then((res) => {
                props.history.push(`/users/admins`)
            })
            .catch(err => {
                if(!!err?.response?.data?.errors) {
                    setErrors(err.response.data.errors)
                }
            })

	};

    return (
        <>
            <div className='goBack'>
                <div style={{paddingBottom: 30}} className={"backLink backBtn titleWrapper"}>
                    <Link
                        to="#" 
                        onClick={() => { props.history.push('/users/admins') }} 
                        style={{float: 'left', paddingTop:5, fontSize: 13, fontWeight: 400, color: '#9199AA'}}
                    >
                        <ArrowLeftOutlined style={{ marginRight: 14 }} />
                        Gå tillbaka
                    </Link>
                    <h2 className='text-center'>
                        {!!company && company.name ? company.name : 'Användare' }
                    </h2>
                </div>  
            </div>
        <div>
            <div style={{background: '#FFFFFF'}}>
                <Row gutter={24} type="flex">
                    <Col xl={7} lg={10} md={22} sm={22} xs={24}>
                        <Card
                            className="cardDetaill bidDetaill"
                            style={{height: '100%'}}
                            bodyStyle={{
                            padding: '28',
                            borderRadius: 6
                            }}
                        >
                            <Text texttype="back" colortype="back" customclass="cardTitle">
                                Användare
                            </Text>
                            {/* <Spin spinning={props.loadingAdmin}> */}
                            <Form
                                form={form}
                                layout="vertical"
                                className="companyForm"
                                hideRequiredMark
                                onFinish={onSubmit}
                            >
                                <Row>
                                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <CustomInput
                                            name="firstName"
                                            key="firstName"
                                            label="Förnamn"
                                            validateStatus={ !!errors.firstName ? 'error' : ''}
                                            help={!!errors.firstName ? String(errors.firstName) : null}
                                            inputprops={{
                                                placeholder: 'Förnamn',
                                                onChange: (e) => {
                                                    setErrors({ ...errors, firstName: '' });
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <CustomInput
                                            name="lastName"
                                            key="lastName"
                                            label="Efternamn"
                                            validateStatus={ !!errors.lastName ? 'error' : ''}
                                            help={!!errors.lastName ? String(errors.lastName) : null}
                                            inputprops={{
                                                placeholder: 'Efternamn',
                                                onChange: (e) => {
                                                    setErrors({ ...errors, lastName: '' });
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <CustomInput
                                            label="Telefonnummer"
                                            key="phoneNumber"
                                            name="phoneNumber"
                                            validateStatus={ !!errors.phoneNumber ? 'error' : ''}
                                            help={!!errors.phoneNumber ? String(errors.phoneNumber) : null}
                                            inputprops={{ 
                                                placeholder: 'Telefonnummer',
                                                onChange: (e) => {
                                                    setErrors({ ...errors, phoneNumber: '' });
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <CustomInput
                                            label="Personnummer"
                                            key="ssn"
                                            name="ssn"
                                            validateStatus={ !!errors.ssn ? 'error' : ''}
                                            help={!!errors.ssn ? String(errors.ssn) : null}
                                            inputprops={{ 
                                                placeholder: 'Ange personnummer',
                                                onChange: (e) => {
                                                    setErrors({ ...errors, ssn: '' });
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <CustomInput
                                            label="E-postadress"
                                            key="email"
                                            name="email"
                                            validateStatus={ !!errors.email ? 'error' : ''}
                                            help={!!errors.email ? String(errors.email) : null}
                                            
                                            inputprops={{ 
                                                placeholder: 'E-postadress',
                                                onChange: (e) => {
                                                    setErrors({ ...errors, email: '' });
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                            {/* </Spin> */}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <div className="rowbuttons">
                        <CustomButton
                            buttontype="type5"
                            colortype="type8"
                            size="large"
                            style={{margin: '0 10px 20px'}}
                            onClick={onSubmit}
                        >
                            Add
                        </CustomButton>
                    </div>
                </Row>
            </div>
        </div>
        </>
    );
}

const mapStateToProps = (store) => {
    return {
        user: store.me.profile,
    }
};

export default connect(mapStateToProps)(AdminDetailsForm);