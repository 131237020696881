import React, {Component} from "react";
// import "./DashboardContainer.scss";
import { Link } from 'react-router-dom';
import LoanOffersTable from './table'
import { ArrowLeftOutlined } from '@ant-design/icons';
import "./style.scss";
class LoanOffers extends Component {
    render() {
        return (
            <div style={{position: 'relative'}}>
                <div className='goBack'>
                    <div style={{paddingBottom: 30}} className={"backLink backBtn titleWrapper"}>
                        {/* <Link
                            to="#" 
                            onClick={() => { this.props.history.goBack(); }} 
                            style={{float: 'left', paddingTop:5, fontSize: 13, fontWeight: 400, color: '#9199AA'}}
                        >
                            <ArrowLeftOutlined style={{ marginRight: 14 }} />
                            Gå tillbaka
                        </Link> */}
                        <h2 className='text-center'>Låneerbjudanden</h2>
                    </div>
                </div>
                <div className="loanOffersTableWrapper">
                    <LoanOffersTable />
                </div>
            </div>
        );
    }
}
export default LoanOffers;