import React from 'react';
import { Redirect, Route } from "react-router-dom";
import { isLoggedIn } from "../../utils/auth";
import ProfileLayout from "../../layouts/creditor/Profile/ProfileLayout";

const ProfileRoute = ({component: Component, ...rest}) => {

    return (
        <Route {...rest} render={props =>
            isLoggedIn() ? (
                <ProfileLayout>
                    <Component {...props} />
                </ProfileLayout>
            ) : (
                <Redirect to={{ pathname: "/login" }} />
            )
        } />
    );
}

export default ProfileRoute;
