import React, { useState, useEffect } from "react";
import { CustomButton } from '../../../../uikit/Button';
import { CustomInput } from '../../../../uikit/Input';
import { CustomSelect } from '../../../../uikit/Select';
import {
    Form,
    Divider
} from 'antd';
import { connect } from 'react-redux';
import { fetchCreditRequest } from '../../../../actions/credit-requests';
import { fetchCreditRequests } from '../../../../actions/credit-requests';
import { fetchLoanOffer } from '../../../../actions/loan-offers';
import { createLoanOffer } from '../../../../actions/loan-offers';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";

const LoanOfferGuarantors = (props) => {
    const {
        form,
        guarantorTypeForm,
        guaranteeTypeForm 
    } = props;

    const [ guarantorType, setGuarantorType] = useState({});
    const [ guaranteeType, setGuaranteeType] = useState('');

    useEffect(() => {
        setGuarantorType(guarantorTypeForm);
	}, [guarantorTypeForm]);

    useEffect(() => {
        setGuaranteeType(guaranteeTypeForm);
	}, [guaranteeTypeForm]);

  const onChange = (value, key) => {
    const fields = form.getFieldsValue()
    const { guarantors } = fields
    Object.assign(guarantors[key], { guaranteeAmount: (value !== undefined ? value.replace(/\D/g, '') : '').replace(
      /\B(?=(\d{3})+(?!\d))/g, ','
  ) })
    form.setFieldsValue({ guarantors })
  }

    return (
      <>
      <Form.List name="guarantors" key="guarantors">
        {(fields, { add, remove }) => {
          return (
            <div className="listWrapper">
              <CustomButton
                buttontype="type8"
                colortype="type8"
                size="large"
                style={{
                  margin: !!form.getFieldValue('guarantors') ? '0 0 20px' : '0',
                  minWidth: '240px',
                  display: 'flex', 
                  alignItems: 'center',
                  border: '2px solid transparent',
                  boxShadow: 'none'
                }}
                onClick={(e) => { add() }}
              >
                Lägg till borgensman
                <PlusCircleOutlined style={{color: '#008aaa'}} />
              </CustomButton>

              {fields.map((field, index) => {
                return (
                  <div
                    key={field.key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="start"
                  >
                      <div>
                        {index !== 0 && (<Divider className="formDivider" style={{marginTop: '0'}} />)}
                        <CustomSelect
                          // {...field}
                          name={[ field.name, 'guarantorType' ]}
                          fieldKey={[ field.fieldKey, 'guarantorType' ]}
                          label="Borgensman typ"
                          key={`guarantorType${field.fieldKey}`}
                          inputprops={{ 
                            size: 'large', 
                            placeholder: 'Välj borgensman typ', 
                            style: {maxWidth: '264px', marginRight: '15px'},
                            onChange: (val) => {
                              setGuarantorType({
                                ...guarantorType,
                                [index]: val
                              });
                            }
                          }}
                          options={[
                              { value: '', label: 'Välj borgensman type' },
                              { value: 'privat', label: 'Privat' },
                              { value: 'företag', label: 'Företag' }
                          ]}
                        />
                        {guarantorType[index] === 'privat' && (
                          <CustomInput
                            // {...field}
                            label="Borgensman personnummer"
                            name={[ field.name, 'guarantorSSN' ]}
                            fieldKey={[ field.fieldKey, 'guarantorSSN' ]}
                            inputprops={{
                                style: {maxWidth: '264px'},
                                placeholder: 'Fyll i'
                            }}
                          />
                        )}
                        {guarantorType[index] === 'företag' && (
                          <CustomInput
                            // {...field}
                            label="Borgensman org nr"
                            name={[field.name, 'guarantorSSN']}
                            fieldKey={[ field.fieldKey, 'guarantorSSN``' ]}
                            inputprops={{ 
                                style: {maxWidth: '264px'},
                                placeholder: 'Fyll i' 
                            }}
                          />
                        )}
                        {!!guarantorType[index] && (
                          <>
                            <CustomInput
                              // {...field}
                              label="Borgensman namn"
                              name={[ field.name, 'guarantorName' ]}
                              fieldKey={[ field.fieldKey, 'guarantorName' ]}
                              inputprops={{
                                  style: {maxWidth: '264px'},
                                  placeholder: 'Fyll i' 
                              }}
                            />
                            <CustomSelect
                              // {...field}
                              name={[ field.name, 'guaranteeType' ]}
                              fieldKey={[ field.fieldKey, 'guaranteeType' ]}
                              label="Typ"
                              inputprops={{ 
                                size: 'large', 
                                placeholder: 'Välj typ', 
                                style: {maxWidth: '264px'},
                                onChange: (val) => {
                                  setGuaranteeType({
                                    ...guaranteeType,
                                    [index]: val
                                  });
                                }
                              }}
                              options={[
                                { value: '', label: 'Välj typ' },
                                { value: 'obegränsad', label: 'Obegränsad' },
                                { value: 'begränsad', label: 'Begränsad' }
                              ]}
                              />
                              {guaranteeType[index] === 'begränsad' && (
                                <CustomInput
                                  // {...field}
                                  label="Begränsat belopp"
                                  name={[ field.name, 'guaranteeAmount' ]}
                                  fieldKey={[ field.fieldKey, 'guaranteeAmount' ]}
                                  inputprops={{
                                    placeholder: 'Fyll i summa',
                                    suffix: 'SEK',
                                    maxLength: 25,
                                    style: {maxWidth: '264px'},
                                    onChange: (e) => {onChange(e.target.value, index)}
                                    // onChange: (e) => {
                                    //   const { value } = e.target;
                                    //   let newval =
                                    //     value !== undefined ? value.replace(/\D/g, '') : '';
                                    //   form.setFieldsValue({
                                    //     'guaranteeAmount': newval.replace(
                                    //         /\B(?=(\d{3})+(?!\d))/g, ','
                                    //     )
                                    //   });
                                    // }
                                  }}
                                />
                              )}
                              <CustomInput
                                // {...field}
                                label="Borgen - kommentar"
                                name={[ field.name, 'guaranteeTerms' ]}
                                fieldKey={[ field.fieldKey, 'guaranteeTerms' ]}
                                inputprops={{
                                  style: {maxWidth: '264px'},
                                  placeholder: 'Fyll i villkor' 
                                }}
                              />
                            </>
                        )}
                    </div>

                    <CustomButton
                      buttontype="type8"
                      colortype="type8"
                      size="large"
                      style={{
                        margin: index === 0 ? '33px 0 20px 10px' : '57px 0 20px 10px',
                        maxWidth: '40px',
                        padding: '0',
                        display: 'flex', 
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '2px solid transparent',
                        boxShadow: 'none'
                      }}
                      onClick={() => remove(field.name)}
                    >
                        <MinusCircleOutlined />
                    </CustomButton>
                  </div>
                );
              })}
            </div>
          );
        }}
      </Form.List>
        </>
    
    );
}

const mapStateToProps = (store) => {
    return {
        creditRequest: store.creditRequests.creditRequest,
        loanOffer: store.creditRequests.creditRequest.loanOffer,
        isFetching_creditRequest: store.creditRequests.isFetching_creditRequest
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        createLoanOffer: (id, data) => dispatch(createLoanOffer(id, data)),
        fetchLoanOffer: (id) => dispatch(fetchLoanOffer(id)),
        fetchCreditRequest: (id) => dispatch(fetchCreditRequest(id)),
        fetchCreditRequests: () => dispatch(fetchCreditRequests())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanOfferGuarantors);