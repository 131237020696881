import React, { useState } from "react";
import { TextArea } from "../../../uikit/TextArea";
import { CustomButton } from "../../../uikit/Button";
import http from "../../../api";
import "./style.scss";
import { successAlert, errorAlert } from "../../../utils/helpers"

const Aml = () => {
	const [message, setMessage] = useState('');
	const [errors, setErrors] = useState({});

    const sendMessage = () => {
        http('/api/v1/aml-email', {
            method: 'POST',
            data: {
                message
            }
        }).then(() => {
            successAlert('Meddelande sänt')
            setMessage('')
        }).catch((error) => {
            if(error?.response?.status === 422)
                setErrors(error?.response?.data?.errors)
            else 
                errorAlert(error?.response?.data?.message)
        })
    }

	return (
		<div className="cfa-container">
			<div className="cfa-content">
				<div className="cfa-content-header">
					Beskrivning av incident eller händelse
					<div className="cfa-content-header-small">
						(ange så detaljerat det går med datum, händelser,
						personal, kunder och system/kanaler)
					</div>
				</div>
				<TextArea
					validateStatus={!!errors.message ? "error" : ""}
					help={!!errors.message ? String(errors.message) : null}
					inputprops={{
                        value: message,
						rows: 3,
						placeholder: "Fyll i",
						autoSize: { minRows: 14, maxRows: 14 },
						onChange: (e) => {
							setMessage(e.target.value);
							setErrors({
								...errors,
								message: "",
							});
						},
					}}
				/>
				<CustomButton
					buttontype="type5"
					colortype="type8"
					size="large"
					onClick={sendMessage}
				>
					Skicka in
				</CustomButton>
			</div>
		</div>
	);
};

export default Aml;
