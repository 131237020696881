import React, {Component} from "react";
import { Link } from 'react-router-dom';
import CreditRequestTable from './table'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col } from 'antd';
import "./style.scss";
class CreditRequest extends Component {

    render() {
        return (
            <div style={{position: 'relative'}}>
                <div className='goBack'>
                    <div className="backLink backBtn titleWrapper">
                        {/* <Link
                            to="#" 
                            onClick={() => { this.props.history.goBack(); }} 
                            style={{float: 'left', paddingTop:5, fontSize: 13, fontWeight: 400, color: '#9199AA'}}
                        >
                            <ArrowLeftOutlined style={{ marginRight: 14 }} />
                            Gå tillbaka
                        </Link> */}
                        <h2 className='text-center' style={{marginBottom: '45px', marginTop: '20px'}}>Kreditförfrågningar</h2>
                    </div>
                </div>
                <Col xl={24} lg={24} md={24} sm={24} xs={24} className="creditRequestTable">
                    <CreditRequestTable />
                </Col>
            </div>
        );
    }
}


export default CreditRequest;