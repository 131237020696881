import React from 'react';
import { Form, Input } from 'antd';

export const CustomInput = (props) => {
	const classes = props.type === 'borderless' ? "inputNoBorder" : "inputBorder";
	let iconClass = props.hideIcon ? "inputHideIcon" : false;

	if (!props.inputprops.prefix) {
		iconClass = "inputHideIcon";
		props.inputprops.prefix = <div style={{ display: 'none' }} />;
	}

	return (
		<Form.Item {...props} className={[ "input", classes, iconClass ].join(' ')}>
			<Input {...props.inputprops} />
		</Form.Item>
	);
};

export default CustomInput;
