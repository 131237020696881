const initialState = {
    list: [],
	list_pagination: {
		current: 1,
		total: 10,
		pageSize: 10
	},
	isLoaded_list: false,
	isFetching_list: false,

	loanOffer: {},
	isFetching_loanOffer: false,
	isLoaded_loanOffer: false,

	applicationLoanOffers: [],
	applicationLoanOffers_pagination: {
		current: 1,
		total: 10,
		pageSize: 10
	},
	isFetching_applicationLoanOffers: false,
	isLoaded_applicationLoanOffers: false,

	errors: {}
};

export function loanOffersReducer(state = initialState, action) {
    switch (action.type) {
		case 'loan-offers/setList':
            return {
                ...state,
                list: action.list,
				list_pagination: action.pagination,
                isLoaded_list: true,
            };
		
		case 'loan-offers/showLoader':
			return {
				...state,
				isFetching_list: true,
			};

		case 'loan-offers/hideLoader':
			return {
				...state,
				isFetching_list: false,
			};

		case 'loan-offers/setErrors':
			return {
				...state,
				errors: action.errors,
			};

		case 'loan-offers/setLoanOffer':
			return {
				...state,
				loanOffer: action.loanOffer,
				isLoaded_loanOffer: true
			};

		case 'loan-offer/showLoader':
			return {
				...state,
				isFetching_loanOffer: true,
			};

		case 'loan-offer/hideLoader':
			return {
				...state,
				isFetching_loanOffer: false,
			};

		case 'loan-offers/setApplicationLoanOffers':
			return {
				...state,
				applicationLoanOffers: action.loanOffers,
				applicationLoanOffers_pagination: action.pagination,
				isLoaded_applicationLoanOffers: true
			};
			
        default:
            return state;
    }
}
