import React from "react";
import { Modal, Spin } from "antd";
import { CustomButton } from "../../uikit/Button";

export const DeleteModal = (props) => {
    const { title, description, show, handleCancel, onDelete, isLoading } =
        props;

    const onToggleViewDialog = async () => {
        handleCancel();
    };

    return (
        <Modal
            className="modalContainer"
            animation="zoom"
            maskAnimation="fade"
            forceRender
            visible={show}
            title={title}
            size={"large"}
            footer={false}
            destroyOnClose
            style={{ top: "30%" }}
            bodyStyle={{
                padding: "32px 30px 48px",
            }}
            onCancel={() => onToggleViewDialog()}
        >
            <Spin spinning={isLoading}>
                <div className="uploadModal" style={{ textAlign: "center" }}>
                    <p style={{ fontSize: "16px" }}>{description}</p>
                </div>
                <div
                    className="buttons"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 15,
                    }}
                >
                    <CustomButton
                        buttontype="type6"
                        size="large"
                        style={{ margin: "0 10px 0 0" }}
                        onClick={onDelete}
                    >
                        Ja
                    </CustomButton>
                    <CustomButton
                        buttontype="type8"
                        size="large"
                        style={{ margin: "0 0 0 10px" }}
                        onClick={() => {
                            onToggleViewDialog();
                        }}
                    >
                        Nej
                    </CustomButton>
                </div>
            </Spin>
        </Modal>
    );
};

export default DeleteModal;
