import React from "react";
import { Table, Spin } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { fetchNotifications } from '../../../actions/notifications';
import { getPriorDate } from '../../../utils/helpers';
import CustomTable from '../../CumtomTable/index';
import './styles.scss';

const NotificationsTable = (props) => {
    const { notifications, loading } = props;
    const priorDate = getPriorDate();

    const { Column } = Table;

    const formatDate = (date) => {
        return date.toString().replace(/T/, ' ').substring(0, date.length-3)
    }

    return (
        <div className="notifications-table-wrapper">
            <Spin spinning={loading}>
                <CustomTable
                    className="dashboard-notifications-table" 
                    dataSource={notifications}
                    dataSourcePagination={props.listPagination}
                    fetchData={props.fetchNotifications}
                    data={priorDate}
                >
                    <Column
                        title="Kreditförfrågan"
                        dataIndex="id"
                        key="id"
                        sorter={true}
                    />
                    <Column
                        title="Ämne"
                        dataIndex="subject"
                        key="subject"
                        sorter={true}
                    />
                    <Column
                        title="Kredittagare"
                        dataIndex="debtorName"
                        key="debtorName"
                        sorter={true}
                    />
                    <Column
                        title="Datum"
                        dataIndex="createdAt"
                        key="createdAt"
                        sorter={true}
                        render={(text, record) => (
                            formatDate(text)
                        )}
                    />
                    <Column
                        title="Notifiering"
                        dataIndex="text"
                        key="text"
                        sorter={true}
                    />
                    <Column
                        dataIndex="text"
                        key="text"
                        render={(text, record) => {
                            return (
                                <a href={record?.link} className={"notificationsLink"}>
                                    {'\u00A0 Gå till'}
                                    <ArrowRightOutlined style={{ marginLeft: 8, marginRight: 12, fontSize: '12px' }} />
                                </a>
                        )}
                        }
                    />
                </CustomTable>
            </Spin>
        </div>

    );
}

const mapStateToProps = (store) => {
    return {
        notifications: store.notifications.list,
        loading: store.notifications.isFetching_list,
        listPagination: store.notifications.listPagination
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchNotifications: (page, size, sortField, sorrtOrder, date) => dispatch(
            fetchNotifications(page, size, sortField, sorrtOrder, date)
        ),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsTable);